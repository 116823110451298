<template>
    <iframe
            :src="url"
            width="100%"
            height="100%"
            frameborder="0" >
    </iframe>
</template>
<script>
    export default{
        data(){
            return{
                url: "https://greenpestsolutions.pestroutes.com/overview.php?#loadAction=viewCustomer&autoOfficeSwap=1&customerID="
            }
        },
        created(){
            let id = this.$route.query.fieldRouteId;
            if(id !== undefined){
                this.url += `${id}`;
            }
        }
    }
</script>