import Api from "@/services/api";
import Vue from "vue";
export default {
    namespaced: true,
    state: {
        productName: null,
        currentCompany: {},
        planId: {},
        appFeatures: {},
        steps: {
            planSelection: { complete: false },
            payment: { complete: false }
        },
        appSteps: {},
        loading: false
    },
    mutations: {
        SET_PRODUCT_NANE(state, productName){
            state.productName = productName;
        },
        SET_CURRENT_COMPANY(state, currentCompany){
            state.currentCompany = currentCompany;
        },
        SET_PLAN_ID(state, planId){
            state.planId = planId;
        },
        SET_APP_FEATURES(state, appFeatures){
            state.appFeatures = appFeatures;
        },
        SET_STEP_COMPLETE(state, step) {
            state.steps[step].complete = true;
        },
        SET_APP_STEPS(state, appSteps){
            state.appSteps = appSteps;
        },
        SET_LOADING(state, loading){
            state.loading = loading;
        },
    },
    actions: {
        saveProductName({commit},currentSubscriptionId){
            commit('SET_CURRENT_SUBSCRIPTION_ID',currentSubscriptionId);
        },

        setPlanId({commit},planId){
            commit('SET_PLAN_ID',planId);
        },

        async getSmartRampPlans({},appName){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
             
            let response = await Api().post(`/Web/Registration/GetSmartRampPlans`,appName,config)
            .catch(err => {
                console.error(err.message);
            });
 
 
            return response;
            
        },

        setCurrentCompany({commit},company){
            commit('SET_CURRENT_COMPANY',company);
        },

        async registerCompanyProduct({state,commit}, product){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let authUser = await Vue.prototype.$auth.user;
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
             
            let companyData = {
                firstName: authUser.given_name,
                lastName: authUser.family_name,
                email: authUser.email,
                authId: authUser.sub,
                phoneNumber: state.currentCompany.userPhone,
                companyName: state.currentCompany.companyName,
                companyUrl: state.currentCompany.companyURL,
                product: "",
                agreement: state.currentCompany.agreedTerms
            };

            await Api().post(`/Web/Registration/CreateCompanyProduct`, companyData, config)
            .catch(err => {
                console.error(err.message);
                return false;
            });
 
            let user = await Api().get(`/Web/User/GetUserAndCompany`,config)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            commit('SET_CURRENT_COMPANY',user.data.company);
            return true;
         },

        setAppFeatures({commit},appFeatures){
           commit('SET_APP_FEATURES',appFeatures);
        },

        async getAppFeatures({},appName){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
             
            let response = await Api().post(`/Web/Registration/GetSmartRampAppsFeatures`,appName,config)
            .catch(err => {
                console.error(err.message);
            });
 
 
            return response;
            
        },

        async getAppsSteps({commit},appName){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
             
            let response = await Api().post(`/Web/Registration/GetAppsSteps`,appName,config)
            .catch(err => {
                console.error(err.message);
            });
 
            commit('SET_APP_STEPS',response.data);
        },

        async createBillingDetails({state},{token: token, billing: billing, billingDetails: billingDetails,
            company: company, user: user, product: product,price:price}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };

            let data = {
                billingDetailsId: billingDetails.billingDetails.id,
                firstName: billing.firstName, 
                lastName: billing.lastName, 
                email: Vue.prototype.$auth.user.email,
                address: billing.address,
                address2: billing.address2,
                city: billing.city, 
                state: billing.state,
                zip: billing.zip,
                token: token,
                recurlySubscriptionId: state.planId.recurlyPlanId,
                companyName: company.name,
                hSContact: user == null ? -1 : user, 
                hSCompany: company.hubSpotCompanyId == null ? -1 : company.hubSpotCompanyId,
                product: product,
                price: price,
                planId: state.planId.id,
                planName: state.planId.name
            };
            let response = await Api().post(`/Web/Registration/${company.id}/CreateBillingDetails`,data,config).catch(() => {
                // Catch Error and return false to caller
                return false;
            });
            if(response.data.hasError){
                return false;
            }else{
                return true;
            }
        },
        
        async addRecurlySubscription({state},{token: token, billing: billing, billingDetails: billingDetails,
            company: company, user: user, product: product, price:price}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };

            let data = {
                billingDetailsId: billingDetails.billingDetails.id,
                firstName: billing.firstName, 
                lastName: billing.lastName, 
                email: Vue.prototype.$auth.user.email,
                address: billing.address,
                address2: billing.address2,
                city: billing.city, 
                state: billing.state,
                zip: billing.zip,
                token: token,
                recurlySubscriptionId: state.planId.recurlyPlanId,
                companyName: company.name,
                hSContact: user == null ? -1 : user, 
                hSCompany: company.hubSpotCompanyId == null ? -1 : company.hubSpotCompanyId,
                product: product,
                price: price,
                planId: state.planId.id,
                planName: state.planId.name
            };
            let response = await Api().post(`/Web/Registration/${company.id}/AddRecurlySubscription`,data,config).catch(() => {
                // Catch Error and return false to caller
                return false;
            });
            if(response.data.hasError){
                return false;
            }else{
                return true;
            }
        },

        async addNewFreeSubscription({state},{company: company, user: user, product: product,billingDetails: billingDetails}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };

            let data = {
                billingDetailsId: billingDetails.billingDetails.id,
                companyName: company.name,
                hSContact: user == null ? -1 : user, 
                hSCompany: company.hubSpotCompanyId == null ? -1 : company.hubSpotCompanyId,
                product: product,
                price: 0,
                planId: state.planId.id,
                planName: state.planId.name

            };

            let response = await Api().post(`/Web/Registration/${company.id}/AddNewFreeSubscription`,data,config).catch(() => {
                // Catch Error and return false to caller
                return false;
            });
            if(response.data.hasError){
                return false;
            }else{
                return true;
            }
        },
    },
    getters: {
        getProductName: state => { return state.productName},
        getCurrentCompany: state => { return state.currentCompany },
        getPlanId: state => { return state.planId },
        getAppFeatures: state => { return state.appFeatures },
        getSteps: state => { return state.steps },
        getAppSteps: state => { return state.appSteps },
        getLoading: state => { return state.loading},

    }
};