import Api from "@/services/api";
import Vue from "vue";
export default {
    namespaced: true,
    state: {
        
    },
    mutations: {
        
    },
    actions: {
        async getAdminReport({}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Admin/AdminReport?filter=currentweek`,config)
            .catch(err => {
                console.error(err.message);
            });
            return response.data;
        },
        async getCompaniesByUser({}, {search: search}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Admin/GetCompaniesByUser/${search}`,config)
            .catch(err => {
                console.error(err.message);
            });
            return response.data;
        },

        async getOverageReport({}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Admin/OverageReport`,config)
            .catch(err => {
                console.error(err.message);
            });
            return response.data;
        },
        async getOverageStatuses({}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Admin/GetOverageStatuses`,config)
            .catch(err => {
                console.error(err.message);
            });
            return response.data;
        },

        async updateOverageStatus({}, {data:data}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().put(`/Web/Admin/UpdateOverageStatus`,data,config)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            return response;

        },
        async updateAgencyRelationship({}, {companyId:companyId,agencyId:agencyId}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Admin/UpdateAgencyRelationship/${companyId}/${agencyId}`,config)

            .catch(err => {
                console.error(err.message);
                return false;
            });
            return response;

        },
        async removeAgencyRelationship({}, {companyId:companyId}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().delete(`/Web/Admin/RemoveAgencyRelationship/${companyId}`,config)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            return response;

        }
        
    },
    getters: {
        
    }
};