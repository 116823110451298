<template>
    <v-container fluid class="pa-0">
        <v-card flat>
            <v-toolbar
            dark
            color="iq_darkerblue"
            app>
                <v-toolbar-title class="display-3 mb-1">
                    <div class="d-flex flex-column">
                        Uptime - MIQ Applications
                        <span class="caption">Check our apps unbeatable stability during the last 30 days</span>
                    </div>
                </v-toolbar-title>
                <v-toolbar-items>
                </v-toolbar-items>
            </v-toolbar>
        </v-card>
        <v-container>
            <div v-for="app in apps" :key="app.name">
                <UpTimeBot :icon="app.icon" :color="app.color"  :name="app.name" :entries="app.entries"></UpTimeBot>
            </div>
        </v-container>

       
    </v-container>
</template>
<script>
import Templates from '../../components/AdminComponents/Templates.vue';
import UpTimeBot from '../../components/shared/_uptimeBot.vue';

export default {
    components: {
        UpTimeBot,
        Templates,
    },
    data () {
      return {
          apps: [
              {
                name:'MIQ',
                icon:'$messageIQIcon',
                color:'primary',
                entries: [
                    { dateStamp: '01 Jan 2022 00:34:00 GMT', uptime:  99.96 },
                    { dateStamp: '02 Jan 2022 00:34:00 GMT', uptime:  99.9 },
                    { dateStamp: '03 Jan 2022 00:34:00 GMT', uptime:  99.23 },
                    { dateStamp: '04 Jan 2022 00:34:00 GMT', uptime:  99.38 },
                    { dateStamp: '05 Jan 2022 00:34:00 GMT', uptime:  99.35 },
                    { dateStamp: '06 Jan 2022 00:34:00 GMT', uptime:  99.65 },
                    { dateStamp: '07 Jan 2022 00:34:00 GMT', uptime:  99.47 },
                    { dateStamp: '08 Jan 2022 00:34:00 GMT', uptime:  99.51 },
                    { dateStamp: '09 Jan 2022 00:34:00 GMT', uptime:  99.51 },
                    { dateStamp: '10 Jan 2022 00:34:00 GMT', uptime:  99.6 },
                    { dateStamp: '11 Jan 2022 00:34:00 GMT', uptime:  59.99 },
                    { dateStamp: '12 Jan 2022 00:34:00 GMT', uptime:  99.32 },
                    { dateStamp: '13 Jan 2022 00:34:00 GMT', uptime:  49.8 },
                    { dateStamp: '14 Jan 2022 00:34:00 GMT', uptime:  99.21 },
                    { dateStamp: '15 Jan 2022 00:34:00 GMT', uptime:  99.47 },
                    { dateStamp: '16 Jan 2022 00:34:00 GMT', uptime:  99.38 },
                    { dateStamp: '17 Jan 2022 00:34:00 GMT', uptime:  99.85 },
                    { dateStamp: '18 Jan 2022 00:34:00 GMT', uptime:  99.70 },
                    { dateStamp: '19 Jan 2022 00:34:00 GMT', uptime:  99.48 },
                    { dateStamp: '20 Jan 2022 00:34:00 GMT', uptime:  99.51 },
                    { dateStamp: '21 Jan 2022 00:34:00 GMT', uptime:  99.97 },
                    { dateStamp: '22 Jan 2022 00:34:00 GMT', uptime:  99.39 },
                    { dateStamp: '23 Jan 2022 00:34:00 GMT', uptime:  99.6 },
                    { dateStamp: '24 Jan 2022 00:34:00 GMT', uptime:  99.27 },
                    { dateStamp: '25 Jan 2022 00:34:00 GMT', uptime:  99.2 },
                    { dateStamp: '26 Jan 2022 00:34:00 GMT', uptime:  99.58 },
                    { dateStamp: '27 Jan 2022 00:34:00 GMT', uptime:  99.38 },
                    { dateStamp: '28 Jan 2022 00:34:00 GMT', uptime:  99.8 },
                    { dateStamp: '29 Jan 2022 00:34:00 GMT', uptime:  99.57 },
                    { dateStamp: '30 Jan 2022 00:34:00 GMT', uptime:  99.94 },
                    { dateStamp: '31 Jan 2022 00:34:00 GMT', uptime:  99.94 }
                ]
              },
              {
                name:'IQ Integration',
                icon:'$messageIQIcon',
                color:'success',
                entries: [
                    { dateStamp: '01 Jan 2022 00:34:00 GMT', uptime:  99.96 },
                    { dateStamp: '02 Jan 2022 00:34:00 GMT', uptime:  99.9 },
                    { dateStamp: '03 Jan 2022 00:34:00 GMT', uptime:  99.23 },
                    { dateStamp: '04 Jan 2022 00:34:00 GMT', uptime:  99.38 },
                    { dateStamp: '05 Jan 2022 00:34:00 GMT', uptime:  99.35 },
                    { dateStamp: '06 Jan 2022 00:34:00 GMT', uptime:  99.65 },
                    { dateStamp: '07 Jan 2022 00:34:00 GMT', uptime:  99.47 },
                    { dateStamp: '08 Jan 2022 00:34:00 GMT', uptime:  99.51 },
                    { dateStamp: '09 Jan 2022 00:34:00 GMT', uptime:  99.51 },
                    { dateStamp: '10 Jan 2022 00:34:00 GMT', uptime:  99.6 },
                    { dateStamp: '11 Jan 2022 00:34:00 GMT', uptime:  59.99 },
                    { dateStamp: '12 Jan 2022 00:34:00 GMT', uptime:  99.32 },
                    { dateStamp: '13 Jan 2022 00:34:00 GMT', uptime:  49.8 },
                    { dateStamp: '14 Jan 2022 00:34:00 GMT', uptime:  99.21 },
                    { dateStamp: '15 Jan 2022 00:34:00 GMT', uptime:  99.47 },
                    { dateStamp: '16 Jan 2022 00:34:00 GMT', uptime:  99.38 },
                    { dateStamp: '17 Jan 2022 00:34:00 GMT', uptime:  99.85 },
                    { dateStamp: '18 Jan 2022 00:34:00 GMT', uptime:  99.70 },
                    { dateStamp: '19 Jan 2022 00:34:00 GMT', uptime:  99.48 },
                    { dateStamp: '20 Jan 2022 00:34:00 GMT', uptime:  99.51 },
                    { dateStamp: '21 Jan 2022 00:34:00 GMT', uptime:  99.97 },
                    { dateStamp: '22 Jan 2022 00:34:00 GMT', uptime:  99.39 },
                    { dateStamp: '23 Jan 2022 00:34:00 GMT', uptime:  99.6 },
                    { dateStamp: '24 Jan 2022 00:34:00 GMT', uptime:  99.27 },
                    { dateStamp: '25 Jan 2022 00:34:00 GMT', uptime:  99.2 },
                    { dateStamp: '26 Jan 2022 00:34:00 GMT', uptime:  99.58 },
                    { dateStamp: '27 Jan 2022 00:34:00 GMT', uptime:  99.38 },
                    { dateStamp: '28 Jan 2022 00:34:00 GMT', uptime:  99.8 },
                    { dateStamp: '29 Jan 2022 00:34:00 GMT', uptime:  99.57 },
                    { dateStamp: '30 Jan 2022 00:34:00 GMT', uptime:  99.94 },
                    { dateStamp: '31 Jan 2022 00:34:00 GMT', uptime:  99.94 }
                ]
              },
              {
                name:'Treasure',
                icon:'mdi-gift',
                color: 'secondary',
                entries: [
                    { dateStamp: '01 Jan 2022 00:34:00 GMT', uptime:  99.96 },
                    { dateStamp: '02 Jan 2022 00:34:00 GMT', uptime:  99.9 },
                    { dateStamp: '03 Jan 2022 00:34:00 GMT', uptime:  99.23 },
                    { dateStamp: '04 Jan 2022 00:34:00 GMT', uptime:  99.38 },
                    { dateStamp: '05 Jan 2022 00:34:00 GMT', uptime:  99.35 },
                    { dateStamp: '06 Jan 2022 00:34:00 GMT', uptime:  99.65 },
                    { dateStamp: '07 Jan 2022 00:34:00 GMT', uptime:  99.47 },
                    { dateStamp: '08 Jan 2022 00:34:00 GMT', uptime:  99.51 },
                    { dateStamp: '09 Jan 2022 00:34:00 GMT', uptime:  99.51 },
                    { dateStamp: '10 Jan 2022 00:34:00 GMT', uptime:  99.6 },
                    { dateStamp: '11 Jan 2022 00:34:00 GMT', uptime:  99.70 },
                    { dateStamp: '12 Jan 2022 00:34:00 GMT', uptime:  99.32 },
                    { dateStamp: '13 Jan 2022 00:34:00 GMT', uptime:  99.8 },
                    { dateStamp: '14 Jan 2022 00:34:00 GMT', uptime:  99.21 },
                    { dateStamp: '15 Jan 2022 00:34:00 GMT', uptime:  99.47 },
                    { dateStamp: '16 Jan 2022 00:34:00 GMT', uptime:  99.38 },
                    { dateStamp: '17 Jan 2022 00:34:00 GMT', uptime:  99.85 },
                    { dateStamp: '18 Jan 2022 00:34:00 GMT', uptime:  99.70 },
                    { dateStamp: '19 Jan 2022 00:34:00 GMT', uptime:  99.48 },
                    { dateStamp: '20 Jan 2022 00:34:00 GMT', uptime:  99.51 },
                    { dateStamp: '21 Jan 2022 00:34:00 GMT', uptime:  99.97 },
                    { dateStamp: '22 Jan 2022 00:34:00 GMT', uptime:  99.39 },
                    { dateStamp: '23 Jan 2022 00:34:00 GMT', uptime:  99.6 },
                    { dateStamp: '24 Jan 2022 00:34:00 GMT', uptime:  99.27 },
                    { dateStamp: '25 Jan 2022 00:34:00 GMT', uptime:  99.2 },
                    { dateStamp: '26 Jan 2022 00:34:00 GMT', uptime:  99.58 },
                    { dateStamp: '27 Jan 2022 00:34:00 GMT', uptime:  99.38 },
                    { dateStamp: '28 Jan 2022 00:34:00 GMT', uptime:  99.8 },
                    { dateStamp: '29 Jan 2022 00:34:00 GMT', uptime:  99.57 },
                    { dateStamp: '30 Jan 2022 00:34:00 GMT', uptime:  99.94 }
                ]
              },
              {
                name:'Integrity Tool',
                icon:'mdi-check-all',
                color: 'accent',
                entries: [
                    { dateStamp: '01 Jan 2022 00:34:00 GMT', uptime:  79.96 },
                    { dateStamp: '02 Jan 2022 00:34:00 GMT', uptime:  99.9 },
                    { dateStamp: '03 Jan 2022 00:34:00 GMT', uptime:  79.23 },
                    { dateStamp: '04 Jan 2022 00:34:00 GMT', uptime:  89.38 },
                    { dateStamp: '05 Jan 2022 00:34:00 GMT', uptime:  99.35 },
                    { dateStamp: '06 Jan 2022 00:34:00 GMT', uptime:  99.65 },
                    { dateStamp: '07 Jan 2022 00:34:00 GMT', uptime:  69.47 },
                    { dateStamp: '08 Jan 2022 00:34:00 GMT', uptime:  59.51 },
                    { dateStamp: '09 Jan 2022 00:34:00 GMT', uptime:  89.51 },
                    { dateStamp: '10 Jan 2022 00:34:00 GMT', uptime:  69.6 },
                    { dateStamp: '11 Jan 2022 00:34:00 GMT', uptime:  69.70 },
                    { dateStamp: '12 Jan 2022 00:34:00 GMT', uptime:  99.32 },
                    { dateStamp: '13 Jan 2022 00:34:00 GMT', uptime:  79.8 },
                    { dateStamp: '14 Jan 2022 00:34:00 GMT', uptime:  99.21 },
                    { dateStamp: '15 Jan 2022 00:34:00 GMT', uptime:  79.47 },
                    { dateStamp: '16 Jan 2022 00:34:00 GMT', uptime:  99.38 },
                    { dateStamp: '17 Jan 2022 00:34:00 GMT', uptime:  69.85 },
                    { dateStamp: '18 Jan 2022 00:34:00 GMT', uptime:  89.70 },
                    { dateStamp: '19 Jan 2022 00:34:00 GMT', uptime:  89.48 },
                    { dateStamp: '20 Jan 2022 00:34:00 GMT', uptime:  99.51 },
                    { dateStamp: '21 Jan 2022 00:34:00 GMT', uptime:  69.97 },
                    { dateStamp: '22 Jan 2022 00:34:00 GMT', uptime:  79.39 },
                    { dateStamp: '23 Jan 2022 00:34:00 GMT', uptime:  89.6 },
                    { dateStamp: '24 Jan 2022 00:34:00 GMT', uptime:  99.27 },
                    { dateStamp: '25 Jan 2022 00:34:00 GMT', uptime:  99.2 },
                    { dateStamp: '26 Jan 2022 00:34:00 GMT', uptime:  59.58 },
                    { dateStamp: '27 Jan 2022 00:34:00 GMT', uptime:  69.38 },
                    { dateStamp: '28 Jan 2022 00:34:00 GMT', uptime:  79.8 },
                    { dateStamp: '29 Jan 2022 00:34:00 GMT', uptime:  69.57 },
                    { dateStamp: '30 Jan 2022 00:34:00 GMT', uptime:  99.94 }
                ]
              },
              {
                name:'MIQ Stocks',
                icon:'mdi-chart-bar',
                color: 'accent',
                entries: [
                    { dateStamp: '01 Jan 2022 00:34:00 GMT', uptime:  19.96 },
                    { dateStamp: '02 Jan 2022 00:34:00 GMT', uptime:  59.9 },
                    { dateStamp: '03 Jan 2022 00:34:00 GMT', uptime:  39.23 },
                    { dateStamp: '04 Jan 2022 00:34:00 GMT', uptime:  29.38 },
                    { dateStamp: '05 Jan 2022 00:34:00 GMT', uptime:  49.35 },
                    { dateStamp: '06 Jan 2022 00:34:00 GMT', uptime:  39.65 },
                    { dateStamp: '07 Jan 2022 00:34:00 GMT', uptime:  49.47 },
                    { dateStamp: '08 Jan 2022 00:34:00 GMT', uptime:  39.51 },
                    { dateStamp: '09 Jan 2022 00:34:00 GMT', uptime:  29.51 },
                    { dateStamp: '10 Jan 2022 00:34:00 GMT', uptime:  59.6 },
                    { dateStamp: '11 Jan 2022 00:34:00 GMT', uptime:  49.70 },
                    { dateStamp: '12 Jan 2022 00:34:00 GMT', uptime:  49.32 },
                    { dateStamp: '13 Jan 2022 00:34:00 GMT', uptime:  29.8 },
                    { dateStamp: '14 Jan 2022 00:34:00 GMT', uptime:  29.21 },
                    { dateStamp: '15 Jan 2022 00:34:00 GMT', uptime:  39.47 },
                    { dateStamp: '16 Jan 2022 00:34:00 GMT', uptime:  19.38 },
                    { dateStamp: '17 Jan 2022 00:34:00 GMT', uptime:  39.85 },
                    { dateStamp: '18 Jan 2022 00:34:00 GMT', uptime:  29.70 },
                    { dateStamp: '19 Jan 2022 00:34:00 GMT', uptime:  29.48 },
                    { dateStamp: '20 Jan 2022 00:34:00 GMT', uptime:  29.51 },
                    { dateStamp: '21 Jan 2022 00:34:00 GMT', uptime:  19.97 },
                    { dateStamp: '22 Jan 2022 00:34:00 GMT', uptime:  59.39 },
                    { dateStamp: '23 Jan 2022 00:34:00 GMT', uptime:  49.6 },
                    { dateStamp: '24 Jan 2022 00:34:00 GMT', uptime:  39.27 },
                    { dateStamp: '25 Jan 2022 00:34:00 GMT', uptime:  59.2 },
                    { dateStamp: '26 Jan 2022 00:34:00 GMT', uptime:  39.58 },
                    { dateStamp: '27 Jan 2022 00:34:00 GMT', uptime:  59.38 },
                    { dateStamp: '28 Jan 2022 00:34:00 GMT', uptime:  49.8 },
                    { dateStamp: '29 Jan 2022 00:34:00 GMT', uptime:  39.57 },
                    { dateStamp: '30 Jan 2022 00:34:00 GMT', uptime:  59.94 }
                ]
              },

          ]
      }
    }
}
</script>