<template>
    <v-card
        elevation="2"
        outlined
        shaped
        max-width="800"
    >
        <v-card-title>{{selectedPlanName}}</v-card-title>
        <form ref="form" class="container" @submit.prevent="submitRecurlyPayment">
            <v-container>
                <v-row>
                    <div ref="recurly-card" class="recurly-card" data-recurly="card" style="width: 100%"></div>
                    <input type="hidden" name="recurly-token" data-recurly="token">
                    <p v-if="recurlyTokenError !== ''" style="color: red">{{recurlyTokenError}}</p>
                </v-row>
                <v-row>
                    <v-text-field
                    label="First Name"
                    data-recurly="first_name"
                    required
                    v-model="trialUpgradeBilling.firstName"
                    ></v-text-field>
                </v-row>
                <v-row>
                    <v-text-field
                    label="Last Name"
                    data-recurly="last_name"
                    required
                    v-model="trialUpgradeBilling.lastName"
                    ></v-text-field>
                </v-row>
                <v-row>
                    <v-text-field
                    label="Address"
                    data-recurly="address1"
                    required
                    v-model="trialUpgradeBilling.address"
                    ></v-text-field>
                </v-row>
                <v-row>
                    <v-text-field
                    label="Address 2"
                    data-recurly="address2"
                    v-model="trialUpgradeBilling.address2"
                    ></v-text-field>
                </v-row>
                <v-row>
                    <v-text-field
                    label="City"
                    data-recurly="city"
                    v-model="trialUpgradeBilling.city"
                    required
                    ></v-text-field>
                </v-row>
                <v-row>
                    <v-select
                    :items="states"
                    label="State"
                    v-model="trialUpgradeBilling.state"
                    required
                    ></v-select>
                </v-row>
                <v-row>
                    <v-text-field
                    label="Zip"
                    data-recurly="postal_code"
                    v-model="trialUpgradeBilling.zip"
                    required
                    ></v-text-field>
                </v-row>
                <v-row>
                    <v-btn
                        v-on:click="submitRecurlyPayment"
                        color="primary"
                        :loading="loading"
                        :disabled="loaded"
                    >Submit Payment: ${{commaSeparateNumber(newPlan.price)}}</v-btn>
                </v-row>
                <input type="hidden" value="US" data-recurly="country" />
                <input type="hidden" v-model="trialUpgradeBilling.state" data-recurly="state" />
            </v-container>
        </form>
    </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            newPlan: 'company/getNewPlan',
            selectedPlanName: 'company/getSelectedPlanName'
        })
    },
    data(){
        return{
            trialUpgradeBilling: {
                recurlyToken: "",
                firstName: "",
                lastName: "",
                address: "",
                address2: "",
                city: "",
                state: "",
                zip: ""
            },
            states: [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 
            'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 
            'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 
            'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ],
            recurlyTokenError: "",
            loading: false
        }
    },
    mounted(){
        recurly.configure({publicKey: process.env.VUE_APP_RECURLY_ID});
        const elements = recurly.Elements()
        elements.CardElement(this.$refs['recurly-card'])
    },
    methods: {
        submitRecurlyPayment(e){
            let self = this;
            let form = this.$refs['form'];
            this.loading = true;
            recurly.token(form, async function (err, token) {
                if (err) {
                    console.error(err.message);
                    self.recurlyTokenError = err.message;
                    self.loading = false;
                } else {
                    let response = await self.$store.dispatch('company/upgradeTrialUser',{
                        token: token.id,
                        trialUpgradeBilling: self.trialUpgradeBilling
                    });

                    if(response){
                        self.$swal({
                            icon: 'success',
                            title: 'Account Successfully Upgraded',
                            text: 'Your account has been successfully upgraded!',
                            confirmButtonText: "Continue",
                        }).then(async result => {
                            self.$router.push('/settings/billing');
                        });
                    }else{
                        self.$swal({
                            icon: 'error',
                            title: 'Error Upgrading Account',
                            text: 'There was an error upgrading your account. Please try again later.',
                            confirmButtonText: "Continue",
                        }).then(async result => {
                            self.$router.push('/settings/billing');
                        });
                    }
                }
            });
        }
    }
}
</script>