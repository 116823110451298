<template>
    <div>
        <v-toolbar
        dark
        flat
        app
        color="iq_darkerblue" 
        id="ConfigureStockIQ"
    >
        <v-toolbar-title class="display-3">
            Integrate IQ Sync Status Logs
        </v-toolbar-title>
    </v-toolbar>
    <v-layout fill-height>
        <v-container fluid class="fill-height">
            <v-row>
                <v-col cols="12" class="d-flex justify-center align-center">
                    <v-autocomplete
                        :items="customers"
                        v-model="customerSelection"
                        @change="retrieveSyncPerCustomer"
                        item-text="name"
                        item-value="customerId"
                        label="Select Customer"
                        outlined
                        clearable
                        hide-details
                    ></v-autocomplete>
                    <v-autocomplete
                        :disabled="!syncs"
                        :items="syncs"
                        v-model="syncSelection"
                        @change="retrieveSubSyncPerSync"
                        item-text="name"
                        item-value="syncId"
                        label="Select Sync"
                        outlined
                        clearable
                        hide-details
                    ></v-autocomplete>
                    <v-autocomplete
                        :disabled="!subSyncs"
                        :items="subSyncs"
                        v-model="subSyncSelection"
                        return-object
                        item-text="name"
                        item-value="subSyncId"
                        label="Select Sub-Sync"
                        outlined
                        clearable
                        hide-details
                    ></v-autocomplete>
                    <v-btn @click="retrieveExecutionPerSubSync" large :disabled="!subSyncs && !subSyncSelection" color="primary"> Show Logs <v-icon>mdi-arrow-right</v-icon></v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-layout fill-height>
                    <v-container fluid>
                        <v-row fill-height>
                            <v-col cols="3" lg="2" class="d-flex flex-column pr-0" v-if="execution">
                                <v-card outlined v-if="execution.data.length > 0" >
                                    <v-card-title class="d-flex flex-column align-start">
                                        <h2 class="font-weight-light">Executions</h2>
                                        <v-spacer></v-spacer>
                                        <span class="d-flex flex-column">
                                            <span class="font-weight-bold">{{subSyncSelection.name}}</span>
                                            <div class="d-flex ">
                                                <span class="body-2"> Page:  <span class="font-weight-bold">{{ this.executionsPageNumber }}</span> of {{ this.totalExecutionsPagesPerSize }} </span>
                                                <v-spacer></v-spacer>
                                                <v-btn x-small outlined @click="loadPreviousExecutionPage"><v-icon>mdi-chevron-left</v-icon></v-btn>
                                                <v-btn x-small outlined @click="loadNextExecutionPage"><v-icon>mdi-chevron-right</v-icon></v-btn>
                                            </div>
                                        </span>
                                    </v-card-title>
                                    <v-card-text class="pa-0">
                                        <v-progress-linear v-if="this.timingExecutionsPrev"
                                            color="primary"
                                            indeterminate
                                            rounded
                                            height="4"
                                        ></v-progress-linear>
                                        <v-list class="executionList"  style="overflow-y: scroll;" :height="window.height - this.appBarHeight - this.appHeaderToolbar - 45 - 80 - 60 - 24 - 25 " ref="scrollExecutionsList">
                                                <v-list-item v-for="item in execution.data" 
                                                    :key="item.executionId"
                                                    @click="retrieveActionsPerExecution( item )" 
                                                    :class="{ 'selected-item': selectedExecutionItem === item.executionId }"
                                                >
                                                    <v-list-item-avatar width="10" min-width="10">
                                                        <v-icon x-small v-if="item.status === 'success'"> mdi-check </v-icon>
                                                        <v-icon x-small v-else> mdi-close </v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            Started: {{ formatDateIntl(item.start) }}
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle class="caption">
                                                            Done: {{ formatDateIntl(item.end) }}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                        </v-list>
                                        <v-progress-linear v-if="this.timingExecutionsNext"
                                            color="primary"
                                            indeterminate
                                            rounded
                                            height="4"
                                        ></v-progress-linear>
                                        <v-overlay opacity="0.85" color="white" v-model="executionLoading" absolute>
                                            <v-progress-circular
                                                indeterminate
                                                color="primary"
                                            ></v-progress-circular>
                                            <span class=" ml-2 iq_dark--text">Loading Executions...</span>
                                        </v-overlay>
                                    </v-card-text>
                                </v-card>
                                <v-card outlined class="flex-grow-1 d-flex align-center justify-center" v-else >
                                    <span class="font-weight-bold">No executions available for this SubSync</span>
                                </v-card>
                            </v-col>
                            <v-col cols="3" lg="2" class="d-flex flex-column px-0" v-if="actions">
                                 <v-card outlined v-if="actions.data.length > 0" >
                                    <v-card-title class="d-flex flex-column align-start">
                                        <h2 class="font-weight-light">Step</h2>
                                        <v-spacer></v-spacer>
                                        <span class="d-flex flex-column">
                                            <span class="font-weight-bold">{{selectedExecution.start}}</span>
                                            <div  class="d-flex ">
                                                <span class="caption"> Page:  {{ this.actionsPageNumber }} of {{ this.totalActionsPagesPerSize }} </span>
                                                <v-spacer></v-spacer>
                                                <v-btn x-small outlined @click="loadPreviousActionsPage"><v-icon>mdi-chevron-left</v-icon></v-btn>
                                                <v-btn x-small outlined @click="loadNextActionsPage"><v-icon>mdi-chevron-right</v-icon></v-btn>
                                            </div>
                                        </span>
                                    </v-card-title>
                                    <v-card-text class="pa-0">
                                        <v-progress-linear v-if="this.timingActionsPrev"
                                            color="primary"
                                            indeterminate
                                            rounded
                                            height="4"
                                        ></v-progress-linear>                                       
                                        <v-list class="action-list" style="overflow-y: scroll;" :height="window.height - this.appBarHeight - this.appHeaderToolbar - 45 - 80 - 60 - 24 - 25 " ref="scrollActionsList">
                                            <v-list-item v-for="(item, index) in actions.data" 
                                                :key="item.id"
                                                @click="retrieveActionsDetailsByActionID( item )"
                                                :class="{ 'selected-item': selectedActionItem === item.id }"
                                            >
                                                <v-list-item-avatar   width="10" min-width="10">
                                                    <v-icon x-small v-if="item.status === 'success'"> mdi-check </v-icon>
                                                    <v-icon x-small v-else> mdi-close </v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-text="item.name"></v-list-item-title>
                                                    <v-list-item-subtitle class="caption" v-text="item.type"></v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>                                           
                                        </v-list>
                                        <v-progress-linear v-if="this.timingActionsNext"
                                            color="primary"
                                            indeterminate
                                            rounded
                                            height="4"
                                        ></v-progress-linear>
                                        <v-overlay opacity="0.85" color="white" v-model="actionLoading" absolute>
                                            <v-progress-circular
                                                indeterminate
                                                color="primary"
                                            ></v-progress-circular>
                                            <span class="iq_dark--text ml-2 ">Loading Actions...</span>
                                        </v-overlay>

                                    </v-card-text>

                                </v-card>
                                <v-card outlined v-else class="flex-grow-1 d-flex align-center justify-center" style="position: sticky; top: 0px;">
                                    No Actions available for this Execution
                                </v-card>
                            </v-col>
                            <v-col cols="6" lg="8" class="d-flex flex-column pl-0" v-if="actionDetails" >
                                        
                                <v-card color="iq_lightslategray lighten-5" outlined  v-if="Object.keys(actionDetails).length > 0" >
                                    <v-card-title v-if="this.selectedAction" class="d-flex flex-column flex-grow-1 justify-start align-start">
                                        <h2 class="font-weight-light">Details: 
                                                {{ selectedAction.name }} 
                                                <v-chip :color="selectedAction.status === 'success' ? 'success': 'error'"> {{selectedAction.status}}</v-chip>
                                        </h2>
                                        <span class="body-1"> Type:{{ selectedAction.type }} </span>
                                        <span class="body-2 font-weight-light"> Timestamp:{{ selectedAction.actionTime }} </span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="6" class="pa-0" >
                                                <v-card flat style="background-color: transparent; position: sticky; top: 0px;">
                                                    <v-card-title>
                                                        Input:
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <JsonViewer :value="actionDetails.input"
                                                        :expand-depth=3
                                                        copyable
                                                        boxed
                                                        expanded
                                                        ></JsonViewer>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="6" class="pa-0" >
                                                <v-card flat style=" background-color: transparent; position: sticky; top: 0px;">
                                                    <v-card-title>
                                                        Output:
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <!-- {{ actionDetails.output }} -->
                                                        <JsonViewer :value="actionDetails.output"
                                                        :expand-depth=3
                                                        copyable
                                                        boxed
                                                        expanded
                                                        ></JsonViewer>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                        
                                    </v-card-text>
                                </v-card>
                                <v-card outlined v-else  class="flex-grow-1" style="position: sticky; top: 0px;">
                                    <span class="font-weight-bold">This Action contains no input/Output</span>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-layout>
            </v-row>
        </v-container>
    </v-layout>
    </div>
</template>
<script>
    import JsonViewer from 'vue-json-viewer'
    import 'vue-json-viewer/style.css'

    import { mapGetters } from 'vuex';


    export default{
        components: {
            JsonViewer
        },
        async created(){
            this.$auth.auth0ClientPromise.then(async () => {
                await this.$store.dispatch('iqIntegration/getCustomers');
                // await this.$store.dispatch('iqIntegration/getSyncs', "1271ce3e-aa96-4764-b604-b8e811d1be37");
                // await this.$store.dispatch('iqIntegration/getSubSyncs', "4c88f5ea-164d-45f3-aaad-360cd689c42d");
                // await this.$store.dispatch('iqIntegration/getExecutions', "341acd2d-2f15-4868-a28f-8a20a3db0cf6");
                // await this.$store.dispatch('iqIntegration/getActions', "03e9730e-33b1-4934-a556-95b0999835f6");
                // await this.$store.dispatch('iqIntegration/getActionsData', "006c3875-e63e-4a97-90dc-24d3ab4151c1");
            });

            //LAYOUT TOOL: resize handler
            window.addEventListener('resize', this.handleResize); 
            this.handleResize();

        }, 
        destroyed() { //LAYOUT TOOL: on leave dispose
            window.removeEventListener('resize', this.handleResize);
        },
        data () {
            return {
                customerSelection: null,
                syncSelection: null,
                subSyncSelection: null,

                selectedExecution: null,
                selectedExecutionItem: null,
                timingExecutionsNext: false,
                timingExecutionsPrev: false,

                selectedAction: null, 
                selectedActionItem: null,
                actionsNeedsScrollbars: false,
                timingActionsNext: false,
                timingActionsPrev: false,
                
                window: { //LAYOUT TOOL: store location when page will need vertical space calculations)
                    width: 0,
                    height: 0
                },
            }
        },
        mounted() {
            // this.setupScrollListener();
        },
        beforeDestroy() {
            // window.removeEventListener('scroll', this.handleScroll);
        },
        computed: {
            ...mapGetters({
                customers: 'iqIntegration/getCustomers',

                syncs: 'iqIntegration/getSyncs',    

                subSyncs: 'iqIntegration/getSubSyncs',  

                execution: 'iqIntegration/getExecution', 
                executionsPageNumber: 'iqIntegration/getExecutionsPageNumber', 
                executionsPageSize: 'iqIntegration/getExecutionsPageSize', 
                executionsTotalItems: 'iqIntegration/getExecutionsTotalItems', 
                executionLoading: 'iqIntegration/getExecutionLoading',

                actions: 'iqIntegration/getActions', 
                actionsPageNumber: 'iqIntegration/getActionsPageNumber', 
                actionsPageSize: 'iqIntegration/getActionsPageSize', 
                actionsTotalItems: 'iqIntegration/getActionsTotalItems', 
                actionLoading: 'iqIntegration/getActionsLoading',


                actionDetails: 'iqIntegration/getActionsData', 
            }),
            totalExecutionsPagesPerSize(){
                if(this.executionsTotalItems > 0){
                    return Math.ceil(this.executionsTotalItems / this.executionsPageSize);
                }else{
                    return 0;
                }
            }, 
            totalActionsPagesPerSize(){
                if(this.actionsTotalItems > 0){
                    return Math.ceil(this.actionsTotalItems / this.actionsPageSize);
                }else{
                    return 0;
                }
            }, 
            
            
        },
        methods: {

            formatDateIntl(inputDate) {
                    const date = new Date(inputDate);

                    // Specify the locale and options for formatting
                    const locale = 'en-US'; // You can change this to any locale you prefer
                    const options = {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true // Use 12-hour time with AM/PM
                    };

                    // Create an Intl.DateTimeFormat instance with the specified locale and options
                    const formatter = new Intl.DateTimeFormat(locale, options);
                    
                    // Use the formatter to format the date
                    return formatter.format(date);
            },
            async retrieveSyncPerCustomer(){
                if(this.customerSelection !== null){
                    await this.$store.dispatch('iqIntegration/getSyncs', this.customerSelection);
                }else{
                    await this.$store.dispatch('iqIntegration/clearFromCustomer');                
                }
            },
            
            async retrieveSubSyncPerSync(){
                if(this.syncSelection !== null){
                    await this.$store.dispatch('iqIntegration/getSubSyncs', this.syncSelection);
                }else{
                    await this.$store.dispatch('iqIntegration/clearFromSyncs');
                }
            },


//EXECUTIONS *********************************************
            async retrieveExecutionPerSubSync(){

                if(this.subSyncSelection !== null){
                    await this.$store.dispatch('iqIntegration/getExecutions', this.subSyncSelection.subSyncId).then(() => {
                        if(this.execution.data.length > 0){
                            this.$nextTick(() => {
                                const listElement = this.$refs.scrollExecutionsList.$el;
                                listElement.addEventListener('scroll', this.handleScrollExecutions);
                            });
                        }
                            
                    });

                }else{
                    await this.$store.dispatch('iqIntegration/clearFromSubSyncs');
                }
            },
            handleScrollExecutions(event) {
                const { scrollTop, scrollHeight, clientHeight } = event.target;
             
                // Check if scrolled to bottom
                if (scrollTop + clientHeight + 2 >= scrollHeight ) {
                    const initialBottomPosition = scrollTop + clientHeight + 2;
                    this.timingExecutionsNext = true;

                    setTimeout(() => {
                        // Check if position remains the same
                        if (event.target.scrollTop + event.target.clientHeight + 2 === initialBottomPosition) {
                            this.loadNextExecutionPage();

                        }else{
                            this.timingExecutionsNext = false;
                        }
                    }, 1000); // 1000 milliseconds delay

                }

                // Check if scrolled to top
                if (scrollTop === 0 && scrollHeight > clientHeight) {
                    const initialTopPosition = scrollTop;
                    this.timingExecutionsPrev = true;

                    setTimeout(() => {
                        // Check if position remains the same
                        if (event.target.scrollTop === initialTopPosition) {
                            this.loadPreviousExecutionPage();
                        }else{
                            this.timingExecutionsPrev = false;
                        }
                    }, 1000); // 1000 milliseconds delay

                    // Handle top of the list logic here
                }else{
                    //no need to pull list is too short
                }
            },
            async loadNextExecutionPage() {
                if (this.executionLoading || this.executionsPageNumber >= this.totalExecutionsPagesPerSize ) {
                    this.timingExecutionsNext = false;
                    return;
                }

                await this.$store.dispatch('iqIntegration/incrementExecutionsPageNumber');
                await this.$store.dispatch('iqIntegration/getExecutions', this.subSyncSelection.subSyncId).then(() => {
                    const listElement = this.$refs.scrollExecutionsList.$el;
                    if (listElement) {
                        listElement.scrollTop = 20;
                    }
                    this.timingExecutionsNext = false;

                });                 
            },

            async loadPreviousExecutionPage() {
                if (this.executionLoading || this.executionsPageNumber <= 1) {
                    this.timingExecutionsPrev = false;
                    return;
                };
                
                await this.$store.dispatch('iqIntegration/decreaseExecutionsPageNumber');
                await this.$store.dispatch('iqIntegration/getExecutions', this.subSyncSelection.subSyncId).then(() => {
                    const listElement = this.$refs.scrollExecutionsList.$el;
                    if (listElement) {
                        listElement.scrollTop = 20;
                    }
                    this.timingExecutionsPrev = false;

                });
            },

//ACTIONS *******************************
            async retrieveActionsPerExecution(execution){
                await this.$store.dispatch('iqIntegration/setActionsPageNumber', 1);
                if(execution && execution.executionId !== null ){
                    this.selectedExecutionItem = execution.executionId;
                    this.selectedExecution = execution;
                    await this.$store.dispatch('iqIntegration/getActions', execution.executionId).then(() => {
                        if(this.actions.data.length > 0){
                            this.$nextTick(() => {
                                const element = this.$refs.scrollActionsList.$el;
                                element.addEventListener('scroll', this.handleScrollActions);
                            });
                        }
                        
                    });
                }else{
                    await this.$store.dispatch('iqIntegration/clearFromExecution');

                }
            },

            handleScrollActions (event) {
                const { scrollTop, scrollHeight, clientHeight } = event.target;
                
                // Check if scrolled to bottom
                if (scrollTop + clientHeight + 2 >= scrollHeight ) {
                    const initialBottomPosition = scrollTop + clientHeight + 2;
                    this.timingActionsNext = true;

                    setTimeout(() => {
                        // Check if position remains the same
                        if (event.target.scrollTop + event.target.clientHeight + 2 === initialBottomPosition) {
                            this.loadNextActionsPage();

                        }else{
                            this.timingActionsNext = false;
                        }
                    }, 1000); // 1000 milliseconds delay


                    // Handle bottom of the list logic here
                }

                // Check if scrolled to top
                if (scrollTop === 0 && scrollHeight > clientHeight) {
                    const initialTopPosition = scrollTop;
                    this.timingActionsPrev = true;

                    setTimeout(() => {
                        // Check if position remains the same
                        if (event.target.scrollTop === initialTopPosition) {
                            this.loadPreviousActionsPage();
                        }else{
                            this.timingActionsPrev = false;
                        }
                    }, 1000); // 1000 milliseconds delay
                }else{
                    //no need to pull list is too short
                }
            },
            async loadNextActionsPage() {
                if (this.actionLoading || this.actionsPageNumber >= this.totalActionsPagesPerSize ) {
                    this.timingActionsNext = false;
                    return;
                }

                await this.$store.dispatch('iqIntegration/incrementActionsPageNumber');
                await this.$store.dispatch('iqIntegration/getActions', this.selectedExecution.executionId).then(() => {
                    this.$nextTick(() => {
                        const element = this.$refs.scrollActionsList.$el;
                        if (element) {
                            element.scrollTop = 20;
                        }
                    });
                    this.timingActionsNext = false;
                });                 
            },
            async loadPreviousActionsPage() {
                if (this.actionLoading || this.actionsPageNumber <= 1) {
                    this.timingActionsPrev = false;
                    return;
                }
                
                await this.$store.dispatch('iqIntegration/decreaseActionsPageNumber');
                await this.$store.dispatch('iqIntegration/getActions', this.selectedExecution.executionId).then(() => {
                    this.$nextTick(() => {
                        const element = this.$refs.scrollActionsList.$el;
                        if (element) {
                            element.scrollTop = 20;
                        }
                    });
                    this.timingActionsPrev = false;
                });
            },

//ACTIONS DATA *******************************
            async retrieveActionsDetailsByActionID(action){

                if(action.id !== null ){
                    this.selectedActionItem = action.id;
                    this.selectedAction = action;
                    await this.$store.dispatch('iqIntegration/getActionsData', action.id);
                }   
            },

        }
    }
</script>
<style>
.list-container {
    max-height: 500px; /* Adjust the height as needed */
    overflow-y: auto;
  }

  .selected-item{
    background-color: var(--v-primary-lighten3) !important;
  }

  .executionList, .action-list{
    .v-list-item{
        &:first-child{
            margin-top: 25px;
        }
        &:last-child{
            margin-bottom: 25px;
        }
    }
  }

</style>