<template>
    <div fill-width justify="center" align="center">
        <v-overlay color="iq_dark" :value="loading">
            <v-icon color="iq_light" x-large>mdi-loading mdi-spin</v-icon>
            <span class="iq_light--text font-weight-bold ">Pulling Products List...</span>
        </v-overlay>

        <v-overlay color="iq_dark" :value="showCreateEditModal">
            <CreateEditProduct
                @closeDialog="hideCreateEdit"
                :createEditTitle="createEditTitle"
                :okButtonText="okButtonText"
                :isEdit="isEdit"
                :editId="editId"
                :editPortalId="editPortalId"
            ></CreateEditProduct>
        </v-overlay>

        <v-container>
            <v-card flat elevation="0">
                <v-card-text class="pa-0">
                    <v-row class="d-flex align-center mb-1">
                        <v-col md="6" cols="12"  class="d-flex align-center">
                            <h1>Stock IQ Products</h1>
                            <v-btn small @click="showCreateEdit(false)" class="ml-4"  color="primary">
                                <v-icon  class="iq_light--text">mdi-plus</v-icon > Add New Product
                            </v-btn>
                        </v-col>
                        <v-col md="6" cols="12" class="text-right d-flex">
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                                class="mt-0 pt-2"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-data-table
                    :headers="headers"
                    :items="tableData"
                    :search="search"
                    :sort-by="['updateDate']"
                    :sort-desc="[true]"
                    multi-sort
                    class="fill-height"
                    dense
                    fixed-header
                    :height="window.height - this.appBarHeight - this.appHeaderToolbar - this.dataTableFooter - 15 "
                >
                    <template v-slot:item="row">
                        <tr>
                            <td>{{row.item.portalId}}</td>
                            <td>{{row.item.name}}</td>
                            <td>{{row.item.description}}</td>
                            <td>{{row.item.sku}}</td>
                            <td>{{row.item.systemId}}</td>
                            <td>{{row.item.hubSpotProductId}}</td>
                            <td>
                                <v-row justify="center" class="ma-2">
                                    <v-btn outlined color="primary" x-small @click="showCreateEdit(true, row.item.id, row.item.portalId)" depressed>
                                        Edit
                                    </v-btn>
                                    <v-btn outlined color="primary" x-small @click="deleteProduct(row.item.id, row.item.portalId)" depressed>
                                        Delete
                                    </v-btn>
                                </v-row>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:no-data>
                        <v-card  flat :min-height="window.height - 32 - 32 - 73 - 80 - 60 - 48 " class="d-flex justify-center align-center">
                            <v-row>
                                <v-col class="pt-8" cols="12">
                                    No Products Available
                                </v-col>
                                <v-col class="pb-8" cols="12">
                                    <v-btn @click="showCreateEdit(false)"  color="primary">
                                        <v-icon  class="iq_light--text">mdi-plus</v-icon >
                                            Add New Product
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </template>
                </v-data-table>
            </v-card>
        </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import CreateEditProduct from './CreateEditProduct.vue';
export default {
    components: {
        CreateEditProduct
    },
    computed: {
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany',
            products: 'priceIQ/getProducts'
        }),
        headers() {
            return [
                {
                    text: "Portal Id",
                    align: "start",
                    value: "portalId",
                    filterable: true,
                },
                {
                    text: "Name",
                    value: "name",
                    filterable: true,
                },
                {
                    text: "Description",
                    value: "description",
                    filterable: true,
                },
                {
                    text: "SKU",
                    value: "sku",
                    filterable: true,
                },
                {
                    text: "System Id",
                    value: "systemId",
                    filterable: false,
                },
                {
                    text: "HubSpot Product Id",
                    value: "hubSpotProductId",
                    filterable: false,
                },
                {
                    text: "Actions",
                    align: "center",
                    filterable: false,

                }
            ]
        }, 
    },
    data() {
        return {
            loading: true,
            showCreateEditModal: false,
            tableData: [],
            absolute: true,
            search: '',
            window: { //LAYOUT TOOL: store location when page will need vertical space calculations         )
                width: 0,
                height: 0
            },

            //Modal properties
            createEditTitle: "Add Product",
            okButtonText: "Add",
            isEdit: false,
            editId: "",
            editPortalId: null
        }
    },
    created() {
        //LAYOUT TOOL: resize handler
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        this.$auth.auth0ClientPromise.then(async () => {
            this.showCreateEditModal = false;

            let produts = await this.getProductsLists();
            produts ? this.tableData = produts : this.tableData = [];
            this.loading = false;
        });
    },
    destroyed() { //LAYOUT TOOL: on leave dispose
        window.removeEventListener('resize', this.handleResize);
    },
    methods: { 
        async getProductsLists() {
            let response = await this.$store.dispatch('stockIQ/getProductsList');
            if(!response){
                this.$swal({
                    title: `Unable to fetch products list.`,
                    icon: 'warning',
                    showDenyButton: false,
                    confirmButtonText: "Close"
                });
            } else {
                return response;
            }
        },
        showCreateEdit(isEdit, id, portalId) {
            this.showCreateEditModal = true;
            if(isEdit) {
                this.createEditTitle = "Edit Product";
                this.okButtonText = "Edit";
                this.isEdit = isEdit;
                this.editId = id;
                this.editPortalId = portalId;
            } else {
                this.createEditTitle = "Add Product";
                this.okButtonText = "Add"
                this.isEdit = isEdit;
                this.editId = "";
                this.editPortalId = null;
            }
        },
        async hideCreateEdit(reload) {
            this.showCreateEditModal = false;
            if(reload) {
                let produts = await this.getProductsLists();
                produts ? this.tableData = produts : this.tableData = [];
            }
        },
        async deleteProduct(id, portalId) {
            const response = await this.$store.dispatch('stockIQ/deleteProduct', {"id": id, "portalId": portalId});
            
            if(response && response.status == 200) {
                let produts = await this.getProductsLists();
                produts ? this.tableData = produts : this.tableData = [];
            } else {
                this.$swal({
                    title: `Unable to delete this product.`,
                    icon: 'warning',
                    showDenyButton: false,
                    confirmButtonText: "Close"
                });
            }
        }
    }
}
</script>