<template>
    <div>
        <v-toolbar
            dark
            flat
            app
            color="iq_darkerblue" 
            id="ConfigureStockIQ"
        >
            <v-toolbar-title class="display-3">
                Configure your StockIQ
            </v-toolbar-title>
        </v-toolbar>
        <v-layout fill-height>  
            <v-container class="fill-height">
                <div class="d-flex flex-column flex-grow-1">
                    <v-stepper elevation="0" outlined rounded alt-labels v-model="e1" id="stepper-StockIQ">
                        <v-stepper-header>
                            <v-stepper-step step="1" :complete="connectedToHubSpot" :editable="!connectedToHubSpot">
                                <div class="d-flex align-center flex-column flex-wrap">
                                    <div class="pa-4">
                                        <v-img width="40px" src="@/assets/images/integrations/Hubspot.png"></v-img>
                                    </div>
                                    <span :class="this.$vuetify.breakpoint.smAndDown ? 'small':''">
                                        Connect to Hubspot 
                                        <small class="pt-4" v-if="!connectedToHubSpot"><v-chip x-small color="error">Required</v-chip></small>
                                        <small  class="pt-4" v-else><v-chip x-small color="success">Connected</v-chip></small>
                                    </span>
                                </div>
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step  step="2" :complete="hasNetSuite" :editable="!hasNetSuite">
                                <div class="d-flex align-center flex-column flex-wrap">
                                    <div class="pa-4">
                                        <v-img width="40px" src="@/assets/images/integrations/netsuite.png"></v-img>
                                    </div>
                                    <span :class="this.$vuetify.breakpoint.smAndDown ? 'small':''"> 
                                        Connect to NetSuite 
                                        <small class="pt-4" v-if="!hasNetSuite"><v-chip x-small color="error">Required</v-chip></small>
                                        <small  class="pt-4" v-else><v-chip x-small color="success">Connected</v-chip></small>
                                    </span>
                                </div>
                            </v-stepper-step>
                            <v-divider></v-divider>
                            
                            <v-stepper-step step="3" :editable="connectedToHubSpot && hasNetSuite">
                                <div class="d-flex align-center flex-column flex-wrap">
                                    <v-icon class="xxx-large primary--text">$stockIQicon</v-icon>
                                    <!-- <v-img width="50px" src="@/assets/images/integrations/netsuite.png"></v-img> -->
                                    <span :class="this.$vuetify.breakpoint.smAndDown ? 'small':''"> 
                                        Select </br> Inventory
                                        <small class="pt-4" v-if="!connectedToHubSpot || !hasNetSuite "><v-chip x-small color="error">Pending Connections</v-chip></small>
                                    </span>
                                </div>
                            </v-stepper-step>

                        </v-stepper-header>
                        <v-divider></v-divider>
                        <v-stepper-items>
                        
                            <v-stepper-content step="1">
                                <v-card elevation="10" min-height="300" class="d-flex flex-column" >
                                    <v-card-title>
                                        Setup Your Hubspot Connection 
                                    </v-card-title>
                                    <v-card-subtitle>
                                        You'll be directed to your Hubspot connection Page
                                    </v-card-subtitle>
                                    <v-divider></v-divider>
                                    <v-card-text  class="flex-grow-1 d-flex align-center justify-center">
                                        <v-btn color="warning" x-large class="ml-2" @click="hubspotConnect"><v-icon class="iq_light--text">$hubspotIcon</v-icon >Connect to Hubspot</v-btn>
                                    </v-card-text>
                                    <v-card-actions class="d-flex justify-end">
                                        <v-btn fab color="primary" @click="nextStep(1)">
                                            <v-icon>mdi-arrow-right</v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <v-card elevation="10" min-height="300" class="d-flex flex-column" >
                                    <v-card-title>
                                        Setup Your NetSuite Connection 
                                    </v-card-title>
                                    <v-card-subtitle>
                                        If you need guidance click the how to link bellow.
                                    </v-card-subtitle>
                                    <v-divider></v-divider>
                                    <v-card-text class="flex-grow-1 d-flex align-center justify-center">
                                        <NetSuiteConnectForm @netSuitePopUp="netSuitePopUp"/>
                                    </v-card-text>
                                    <v-card-actions class="d-flex justify-end">
                                        <v-btn fab color="primary" @click="nextStep(0)" >
                                            <v-icon>mdi-arrow-left</v-icon>
                                        </v-btn>
                                        <v-btn fab color="primary" :disabled="!connectedToHubSpot || !hasNetSuite" @click="nextStep(2)">
                                            <v-icon>mdi-arrow-right</v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-stepper-content>
                            <v-stepper-content step="3">
                                <v-card elevation="10" min-height="300" class="d-flex flex-column">
                                    <v-card-title class="d-flex justify-space-between">
                                        One final Step! <span v-if="this.selectedLocation">Current Inventory: {{this.location.name}} </span>
                                    </v-card-title>
                                    <v-card-subtitle>
                                        Select an inventory location and save to see changes reflected in Hubspot
                                    </v-card-subtitle>
                                    <v-divider></v-divider>
                                    <v-card-text class="flex-grow-1 d-flex align-center justify-center">
                                        <v-select 
                                            label="Select Inventory Locations"
                                            :items="locations"
                                            item-text="name"
                                            item-value="id"
                                            v-model="selectedLocation"
                                        ></v-select>
                                    </v-card-text>
                                    <v-card-actions class="d-flex justify-end">
                                        <v-btn 
                                            color="primary" 
                                            :disabled="this.location.id === this.selectedLocation"
                                            :loading="loadingBtn"
                                            @click="handleLocationChange"
                                        >
                                            Save Selection
                                        </v-btn>
                                    </v-card-actions>
                    
                                </v-card>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </div>


                <!-- <v-overlay
                    :absolute="absolute"
                    :value="connectedToHubSpot"
                >
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-card class="pa-3" outlined elevation="10" light color="">
                                    <v-card-title>
                                        You're Not Connected to Hubspot
                                    </v-card-title>
                                    <v-card-subtitle>
                                        Please connect to HubSpot to start using Stock IQ.
                                    </v-card-subtitle>
                                    <v-divider></v-divider>
                                    <v-card-actions class="d-flex justify-center">
                                        <v-btn color="success" x-large class="ml-2" @click="hubspotConnect"><v-icon class="iq_light--text">$hubspotIcon</v-icon >Connect to Hubspot</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-overlay> -->
                <v-overlay color="iq_dark" :value="loading">
                    <v-icon color="iq_light" x-large>mdi-loading mdi-spin</v-icon>
                    <span class="iq_light--text font-weight-bold ">Loading...</span>
                </v-overlay>
                <v-overlay light
                    absolute
                    :value="showNetSuiteForm">
                    <v-card width="600" light>
                        <v-card-title class="d-flex justify-space-between">NetSuite Connection 
                            <v-btn rounded text @click="netSuitePopUp(false)"><v-icon>mdi-close</v-icon></v-btn>
                        </v-card-title>
                        <NetSuiteConnectForm @netSuitePopUp="netSuitePopUp"/>
                    </v-card>
                    
                </v-overlay>
        <!--

                <v-card>
                    <v-card-title> Stock IQ</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-select label="Select Inventory Locations"></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="success" :disabled="hasNetSuite" @click="netSuitePopUp(true)" x-large >Connect ot NetSuite</v-btn>
                    </v-card-actions>
                </v-card> -->
            </v-container>
        </v-layout>

    </div>
</template>
<script>
    import { mapGetters } from 'vuex';
    import NetSuiteConnectForm from '../../components/SmartRamp/NetSuiteConnectForm.vue'
    export default {
        components:  {
            NetSuiteConnectForm
        },
        computed: {
            ...mapGetters({
                currentCompany: 'company/getCurrentCompany',
                currentCompanyApps: 'company/getCurrentCompanyApps'
            }),
        },
        data () {
            return {
                overlayNotHubspot: false,
                showNetSuiteForm: false,

                loading: true,
                absolute: true,
                connectedToHubSpot: true,
                hasNetSuite: false, 
                e1: 1,

                locations: [],
                selectedLocation: null,
                location: { id: -1, name: "" },
                loadingBtn: false,
            }
        },
        watch: {
            connectedToHubSpot(newVal, oldVal){
                if(newVal){
                    if(this.hasNetSuite){
                        this.e1 = 3;
                    }else{
                        this.e1 = 2;
                    }
                }
            },
            hasNetSuite(newVal, oldVal){
                if(newVal){
                    if(this.connectedToHubSpot){
                        this.e1 = 3;
                    }else{
                        this.e1 = 1;
                    }
                }
            }
        },
        created(){
            if(!this.currentCompanyApps.stockIq){
                this.$router.push(`product/plan-selection/StockIQ`);
            }
            this.$auth.auth0ClientPromise.then(async () => {
                await this.$store.dispatch('getUserCompanyApps','StockIQ');
                if(this.$route.query.hsConnectStatus === "success"){
                    this.$swal({
                        icon: 'success',
                        title: 'HubSpot Connected',
                        text: 'You have successfully connected to HubSpot!',
                        toast: true,
                        timer: 3000,
                        position: 'top-end'
                    });

                }else if(this.$route.query.hsConnectStatus === "fail"){
                    this.$swal({
                        icon: 'error',
                        title: 'Error connecting to HubSpot',
                        text: 'There was an error connecting to HubSpot. Please try again later.',
                        toast: true,
                        timer: 3000,
                        position: 'top-end'
                    });
                }

                let response = await this.$store.dispatch('stockIQ/getCompanyNetSuitePortals');
                if(response.data != null && response.data != undefined && response.data != ""){
                    this.hasNetSuite = true;

                    const selectLocation = await this.$store.dispatch('stockIQ/getCurrentInventoryLocation')
                    const locations = await this.$store.dispatch('stockIQ/getInventoryLocations')

                    if(locations != null && locations != undefined && locations != "") { 
                        this.locations = [{ id: 0, name: 'Default Location' }, ...locations];

                        if(selectLocation != null && selectLocation != undefined && selectLocation != "") {
                            this.selectedLocation = selectLocation;
                            this.location = locations.find(loc => loc.id === selectLocation);
                        } else {
                            this.$swal({
                                icon: 'error',
                                title: 'Error Pulling Your Location',
                                text: 'There was an error when pulling your inventory location. Please try again later.',
                                toast: true,
                                timer: 3000,
                                position: 'top-end'
                            });
                        }
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: 'Error Pulling Locations',
                            text: 'There was an error when pulling your inventory locations. Please try again later.',
                            toast: true,
                            timer: 3000,
                            position: 'top-end'
                        });
                    }

                }

                if(!this.currentCompany.isStockIQConnected){
                    this.loading = false;
                    this.connectedToHubSpot = false;
                }else{
                    this.loading = false;
                }


            })
            
        },
        methods: {
            netSuitePopUp(show) {
                this.showNetSuiteForm = show;
            },
            hubspotConnect() {
                window.location.href = process.env.VUE_APP_API_URL + `/api/HubSpotConnect/StockIQ?companyId=${this.currentCompany.id}`;
            },
            nextStep (n) {
                if (n === this.steps) {
                    this.e1 = 1
                } else {
                    this.e1 = n + 1
                }
            },
            async handleLocationChange() {
                this.loadingBtn = true;
                const result = await this.$store.dispatch('stockIQ/saveCompanyLocation', this.selectedLocation);

                if(result.status === 200) {
                    this.$swal({
                        icon: 'success',
                        title: 'Location Updated',
                        text: 'You have been successfully updated your inventory location!',
                        toast: true,
                        timer: 3000,
                        position: 'top-end'
                    });

                    this.location = this.locations.find(loc => loc.id === this.selectedLocation);
                } else {
                    this.$swal({
                        icon: 'error',
                        title: 'Error Updating Location',
                        text: 'There was an error when updating your inventory location. Please try again later.',
                        toast: true,
                        timer: 3000,
                        position: 'top-end'
                    });
                }

                this.loadingBtn = false;
            },
        }
    }
</script>
<style lang="scss">
    #stepper-StockIQ{
        .v-stepper__header{
            .v-stepper__step{
                @media #{map-get($display-breakpoints, 'sm-and-down')} {
                    .v-stepper__label{
                        display: block !important;
                    }
                    flex-basis: 33%;
                }

                .v-stepper__step__step{
                    width: 34px;
                    height: 34px;
                }
            }
        }
    }
    .small{
        font-size: 12px;
        word-wrap: break-word;
    }
</style>