/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {

    },
    registered () {

    },
    cached () {

    },
    updatefound () {

    },
    updated () {

    },
    offline () {
      
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}

// Check if service workers are supported
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register(`${process.env.BASE_URL}service-worker.js`, {
    scope: '/',
    // add the path to the manifest.json file
    // so the browser can download the icons
    // specified in the manifest
    updateViaCache: 'none',
    cacheName: 'SR-pwa-cache',
    manifest: {
      url: '/manifest.json'
    }
  }).then((registration) => {
    
  }).catch((error) => {
    console.error('Service worker registration failed:', error);
  });
}