import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VImg,{staticClass:"clickable-image",attrs:{"src":_vm.thumbnailSrc,"height":"100%","contain":""},on:{"click":_vm.openViewer}}),_c(VDialog,{attrs:{"width":"auto","max-width":"80%"},model:{value:(_vm.viewerOpen),callback:function ($$v) {_vm.viewerOpen=$$v},expression:"viewerOpen"}},[_c(VCard,[_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.closeViewer}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"d-flex justify-center align-center"},[_c(VImg,{staticClass:"clickable-image",attrs:{"contain":"","max-height":"100%","min-width":"200","src":_vm.fullSizeSrc}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }