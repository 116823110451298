<template>
    <div>
        <productPage :pageData="this.page" :productData="this.products[$route.params.id]"></productPage>    
        <v-container>
        </v-container>
    </div>
</template>
<script>
import productPage from '../../components/SmartRamp/shared/_productView.vue';

export default {
    components:  {
        productPage
    },
     data () {
        return {
            page: {
                title: "Integrate IQ Product",
                isRecipe: false,
            },
            products: [
                {
                    productName: "Message IQ",
                    productIcon: "$messageIQIcon",
                    productColor: "primary",
                    isPremium: true, 
                    isApp: true,
                    productCategory: "Communication", 
                    productTags: [
                        { tagName: 'marketing'},
                        { tagName: 'messaging'},
                        { tagName: 'communication'},
                    ],
                    productRate: 4.5,
                    productRatings: 52500, 
                    productVideo: "",
                    productInfoHTML: "<p>Hi I'm content</p><p>Commodo amet ea elit esse incididunt ut reprehenderit magna nisi non tempor eiusmod do. Deserunt nulla eu incididunt id aute enim. Velit deserunt duis officia qui consectetur consectetur id ea.Id magna aute cupidatat in labore labore. Ipsum enim minim magna sint velit ex sint occaecat sunt Lorem laboris labore ipsum officia. Adipisicing ut do proident aliquip adipisicing sint deserunt in id pariatur incididunt. Officia quis amet anim incididunt consequat consectetur sit.Consequat Lorem Lorem dolore in aute. Laborum eiusmod et magna mollit dolor anim ex occaecat dolor veniam. Cillum labore incididunt amet ea consectetur qui laborum proident sit nulla dolor. Nulla non ipsum esse voluptate exercitation quis do cillum est excepteur incididunt quis non do. Nisi adipisicing aliqua magna fugiat irure eiusmod ipsum dolor velit aliqua non. Proident exercitation Lorem ullamco Lorem labore ut voluptate consectetur.</p>",
                    productRelated: [
                        { productName: "IntegrateIQ", productIcon: "$integrateIQIcon", productColor:"secondary", link:'/products'},
                        { productName: "MonitorIQ", productIcon: "$integrateIQIcon", productColor:"success", link: '/products'}
                    ],
                    productActions: [
                         { title: 'Request a Demo', color:'primary', icon: '', link: '/' , isExternal: false },
                         { title: 'Start Using now', color:'primary', icon: '', link: '/' , isExternal: false },
                    ]
                },
                {
                    productName: "Integrate IQ",
                    productIcon: "$integrateIQIcon",
                    productColor: "success",
                    isPremium: true, 
                    isApp: true,
                    productCategory: "Communication", 
                    productTags: [
                        { tagName: 'marketing'},
                        { tagName: 'messaging'},
                        { tagName: 'communication'},
                    ],
                    productRate: 3.5,
                    productRatings: 2500, 
                    productVideo: "",
                    productInfoHTML: "<h3 class='font-weight-bold'>Why do you need MessageIQ?</h3><p>Commodo amet ea elit esse incididunt ut reprehenderit magna nisi non tempor eiusmod do. Deserunt nulla eu incididunt id aute enim. Velit deserunt duis officia qui consectetur consectetur id ea.Id magna aute cupidatat in labore labore. Ipsum enim minim magna sint velit ex sint occaecat sunt Lorem laboris labore ipsum officia. Adipisicing ut do proident aliquip adipisicing sint deserunt in id pariatur incididunt. Officia quis amet anim incididunt consequat consectetur sit.Consequat Lorem Lorem dolore in aute. Laborum eiusmod et magna mollit dolor anim ex occaecat dolor veniam. Cillum labore incididunt amet ea consectetur qui laborum proident sit nulla dolor. Nulla non ipsum esse voluptate exercitation quis do cillum est excepteur incididunt quis non do. Nisi adipisicing aliqua magna fugiat irure eiusmod ipsum dolor velit aliqua non. Proident exercitation Lorem ullamco Lorem labore ut voluptate consectetur.</p>",
                    productRelated: [
                        { productName: "IntegrateIQ", productIcon: "$integrateIQIcon", productColor:"secondary", link:'/products'},
                        { productName: "MonitorIQ", productIcon: "$integrateIQIcon", productColor:"success", link: '/products'}
                    ],
                    productActions: [
                         { title: 'Request a Demo', color:'primary', icon: '', link: '/' , isExternal: false },
                         { title: 'Start Using now', color:'primary', icon: '', link: '/' , isExternal: false },
                    ]
                },
            ],
        }
    },
    methods: {
        goToExtURL(url){

        }
    }
}
</script>

<style lang="scss" scoped>
.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>