import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
//layout
import Iframed from '@/components/layouts/Iframed.vue'
import ProductRegistrationLayout from '@/components/layouts/ProductRegistrationLayout.vue'
import Registration from '@/components/layouts/Registration.vue'
import StandaloneApp from '@/components/layouts/StandaloneApp.vue'
import UserPages from '@/components/layouts/UserPages.vue'

//registration
import Step1 from '@/components/AccountSetup/accountStep1.vue'
import Step2 from '@/components/AccountSetup/accountStep3.vue'
import Step3 from '@/components/AccountSetup/accountStep4.vue'
import Step4 from '@/components/AccountSetup/accountStep5.vue'
import Step5 from '@/components/AccountSetup/accountStep6.vue'
import AccountSetup from '../views/AccountSetup.vue'


import Calls from '../views/Calls.vue'
import Charts from '../views/Charts.vue'
import Contacts from '../views/Contacts.vue'
import Conversation from '../views/Conversation.vue'
import Conversations from '../views/Conversations.vue'

import Account from '@/components/AdminComponents/Account.vue'
import AgencyCompanies from '@/components/AdminComponents/AgencyCompanies'
import Automation from '@/components/AdminComponents/Automation.vue'
import Billing from '@/components/AdminComponents/Billing.vue'
import BillingUpgrade from '@/components/AdminComponents/BillingUpgrade.vue'
import Broadcast from '@/components/AdminComponents/Broadcast.vue'
import BusinessHours from '@/components/AdminComponents/BusinessHours.vue'
import Calculator from '@/components/AdminComponents/Calculator.vue'
import Integrations from '@/components/AdminComponents/Integrations.vue'
import Phones from '@/components/AdminComponents/Phones.vue'
import Tags from '@/components/AdminComponents/Tags.vue'
import Templates from '@/components/AdminComponents/Templates.vue'
import Users from '@/components/AdminComponents/Users.vue'
import UserSettings from '@/components/AdminComponents/UserSettings.vue'
import AdminReportData from '@/components/AdminReports/AdminReport.vue'
import OverageReport from '@/components/AdminReports/OverageReport.vue'
import AdminReport from '@/views/AdminReport'
import Help from '@/views/Help'
import ProductIdea from '@/views/ProductIdea'
import Settings from '../views/Settings.vue'

import AccountUpgrade from '@/views/AccountUpgrade'
import Login from '@/views/Login'
import Logout from '@/views/Logout'
import VerifyCompany from '@/views/VerifyCompany'

import PriceIQFrame from '@/views/HubSpotFrames/PriceIQFrame'
import StockIQFrame from '@/views/HubSpotFrames/StockIQIframe'
import StripeCheckoutFrame from '@/views/HubSpotFrames/StripeCheckoutFrame'

//Guards
import { authGuard } from '../auth/authGuard'
import { authGuardIframe } from '../auth/authGuardIframe'
import { productConfigGuard } from '../guards/productConfigGuard'
import { productRegGuard } from '../guards/productRegGuard'
import { smarRampRegGuard } from '../guards/smartRampRegGuard'

//smartRamp
import Cards from '../views/SmartRamp/Cards.vue'
import IntegrationsDashboard from '../views/SmartRamp/IntegrationDashboard.vue'
import Launcher from '../views/SmartRamp/Landing.vue'
import ProductView from '../views/SmartRamp/ProductView.vue'
import RecipeView from '../views/SmartRamp/RecipeView.vue'
import SmartRampRegistration from '../views/SmartRamp/SmartRampRegistration.vue'
import UpTimeApps from '../views/SmartRamp/UptimeApps.vue'


import CompleteRegistration from '../views/SmartRamp/ProductRegistration/CompleteRegistration.vue'
import PlansSelection from '../views/SmartRamp/ProductRegistration/PlansSelection.vue'
import ProductPayment from '../views/SmartRamp/ProductRegistration/ProductPayment.vue'
import ProductRegistration from '../views/SmartRamp/ProductRegistration/ProductRegistration.vue'
import ProductSummary from '../views/SmartRamp/ProductRegistration/ProductSummary.vue'

//Integrations
import Approval from '@/components/SmartRamp/Wizard/Approval.vue'
import Fields from '@/components/SmartRamp/Wizard/Fields.vue'
import Objects from '@/components/SmartRamp/Wizard/Objects.vue'
import Systems from '@/components/SmartRamp/Wizard/Systems.vue'
import Triggers from '@/components/SmartRamp/Wizard/Triggers.vue'
import Logs from '../views/Integrations/Logs.vue'
import Wizard from '../views/SmartRamp/Wizard.vue'

//Clover App
import CloverAuth from '@/components/SmartRamp/Integrations/Clover/Authentication.vue'
import CloverApp from '@/components/SmartRamp/Integrations/Clover/Form.vue'

//Status IQ
import MessageIQTrial from '../views/MessageIQTrial.vue'
import CreateTracker from '../views/StatusIQ/CreateTracker.vue'
import Trackers from '../views/StatusIQ/Trackers.vue'

// Price IQ
import CreatePriceList from '@/views/SmartRamp/PriceIQ/CreatePriceList.vue'
import EditPriceList from '@/views/SmartRamp/PriceIQ/EditPriceList.vue'
import PriceIQSettings from '@/views/SmartRamp/PriceIQ/PriceIQSettings.vue'

// Stock IQ
import StockIQ from "@/views/StockIQ/StockIQ.vue"
import StockIQProducts from "@/views/StockIQ/StockIQProducts.vue"

// GreenLawn
import FieldRoutesFrame from '@/views/HubSpotFrames/GreenLawn/FieldRoutesFrame'
import RealGreenFrame from '@/views/HubSpotFrames/GreenLawn/RealGreenFrame'

import VueCookies from 'vue-cookies'
import StatusIQCustomization from '../views/StatusIQ/StatusIQCustomization.vue'

Vue.use(VueRouter)
Vue.use(VueCookies);

const routes = [
  { path: '/dash', name: 'Start', component: Launcher, meta: { layout: UserPages }, beforeEnter: authGuard },
  { path: '/help', name: 'Help', component: Help }, // Redirects to a new page (https://help.integrateiq.com/en/collections/2512978-message-iq)
  { path: '/login', name: 'Login', component: Login },
  { path: '/logout', name: 'Logout', component: Logout },
  { path: '/company/verify', name: 'VerifyCompany', component: VerifyCompany },
  { path: '/account-upgrade', name: 'AccountUpgrade', component: AccountUpgrade, beforeEnter: authGuard },
  // {
  //   path: '/admin/report',
  //   name: 'AdminReport',
  //   component: AdminReport,
  //   meta: { layout: UserPages },
  //   beforeEnter: authGuard
  // },

  /**
   * Smart Ramp
   */
  { path: '/integratestatus', name: 'Integrate IQ Logs', component: Logs, meta: { layout: UserPages }, beforeEnter: authGuard },

  { path: '/', name: 'Start ', component: Home, meta: { layout: UserPages }, beforeEnter: (to, from, next) => { authGuard(to, from, () => { smarRampRegGuard(to, from, next); }); }, },
  { path: '/products/:id', name: 'IQ Product', component: ProductView, meta: { layout: UserPages} }, // ??
  { path: '/recipe/:id', name: 'IQ Integration Recipe', component: RecipeView, meta: { layout: UserPages} }, // ??
  { path: '/appsbot', name: 'Applications Uptime', component: UpTimeApps, meta: { layout: UserPages } }, // ??
  { path: '/cards', name: 'Recipe Directory', component: Cards, meta: { layout: UserPages } }, // ??
  { path: '/integrations', name: 'Integrations ', component: IntegrationsDashboard, meta: { layout: UserPages } }, // ??

  { path: '/admin/report', component: AdminReport, props: true, beforeEnter: authGuard,
    children: [
      { path: "", name: 'Admin Reports', meta: { layout: UserPages }, component: AdminReportData, },
      { path: "companies", name: 'Admin Report', meta: { layout: UserPages }, component: AdminReportData },
      { path: "overage", name: 'Overage Report', meta: { layout: UserPages }, component: OverageReport },    
    ]
  },

  { path: '/billing-upgrade', name: 'BillingUpgrade', component: BillingUpgrade, meta: { layout: UserPages }, beforeEnter: authGuard }, // ??
  { path: '/conversation', name: 'Conversation', component: Conversations, meta: { layout: Registration }, beforeEnter: authGuard }, // ??

  // Smart Ramp | Product Registration
  { path: '/product', component: ProductRegistration, meta: { layout: ProductRegistrationLayout }, props: true, beforeEnter: authGuard,
    children: [
      { path: "", redirect: '/product/complete-registration/SmartRamp' },

      //be carefull when changing the name, because it's use to get the current step info on the left pannel (views/SmartRamp/ProductRegistration/ProductRegistration.vue)
      { path: "complete-registration/:id", name: "complete-registration", meta: { layout: ProductRegistrationLayout }, component: CompleteRegistration, beforeEnter: productRegGuard },
      { path: "plan-selection/:id", name: "plan-selection", meta: { layout: ProductRegistrationLayout }, component: PlansSelection, beforeEnter: productRegGuard },
      { path: "payment/:id", name: "payment", meta: { layout: ProductRegistrationLayout }, component: ProductPayment, beforeEnter: productRegGuard },
      { path: "summary/:id", name: "summary", meta: { layout: UserPages }, component: ProductSummary, beforeEnter: productRegGuard },
      { path: "/product/:id", redirect: '/product/complete-registration/SmartRamp' }, // Do not chage the position.
      // (we use /product/:if if the user came with one url that doesn't match any of the above)
      // Used in this position because it has precedence. 

      // If the user came without a product in the URL send them to the default registration for Smart Ramp. 
      // If the user already have a company the productRegGuard will take care of sending them to the landing page.
      { path: "/product/complete-registration", redirect: '/product/complete-registration/SmartRamp' },
      { path: "/product/plan-selection", redirect: '/product/complete-registration/SmartRamp' },
      { path: "/product/payment", redirect: '/product/complete-registration/SmartRamp' },
      { path: "/product/summary", redirect: '/product/complete-registration/SmartRamp' },
    ]
  },

  /**
   * Message IQ
   */
  { path: '/conversations', name: 'Conversations', component: Conversations, meta: { layout: UserPages }, beforeEnter: (to, from, next) => { authGuard(to, from, () => { productConfigGuard(to, from, next); }); }, },
  { path: '/calls', name: 'Calls', component: Calls, meta: { layout: UserPages }, beforeEnter: (to, from, next) => { authGuard(to, from, () => { productConfigGuard(to, from, next); }); }, },
  { path: '/contacts', name: 'Contacts', component: Contacts, meta: { layout: UserPages }, beforeEnter: (to, from, next) => { authGuard(to, from, () => { productConfigGuard(to, from, next); }); }, },
  { path: '/charts', name: 'Charts', component: Charts, meta: { layout: UserPages }, beforeEnter: (to, from, next) => { authGuard(to, from, () => { productConfigGuard(to, from, next); }); }, },
  { path: '/registration/MessageIQ', component: MessageIQTrial, meta: { layout: Registration }, props: true, beforeEnter: authGuard,
    children: [
      { path: "", name: "Step1", meta: { layout: Registration }, component: Step1 },
      { path: "step1", name: "Step1 ", meta: { layout: Registration }, component: Step1 },
      { path: "step2", name: "Step2", meta: { layout: Registration }, component: Step3 },
      { path: "step3", name: "Step3", meta: { layout: Registration }, component: Step5 },
    ]
  },
  {
    path: '/settings', component: Settings, props: true, beforeEnter: (to, from, next) => { authGuard(to, from, () => { productConfigGuard(to, from, next); }); },
    children: [
      { path: "", name: 'Settings', meta: { layout: UserPages }, component: Account },
      { path: "user", name: 'User Settings', meta: { layout: UserPages }, component: UserSettings },
      { path: "account", name: 'Account', meta: { layout: UserPages }, component: Account },
      { path: 'automation', name: 'Automation', meta: { layout: UserPages }, component: Automation },
      { path: 'businesshours', name: 'Business Hours', meta: { layout: UserPages }, component: BusinessHours },
      { path: 'templates', name: 'Templates', meta: { layout: UserPages }, component: Templates },
      { path: 'billing', name: 'Billing', meta: { layout: UserPages }, component: Billing },
      { path: 'users', name: 'Users', meta: { layout: UserPages }, component: Users },
      { path: 'integrations', name: 'Integrations', meta: { layout: UserPages }, component: Integrations },
      { path: 'agency/customers', name: 'Agency Customers', meta: { layout: UserPages }, component: AgencyCompanies },
      { path: 'phones', name: 'Phones', meta: { layout: UserPages }, component: Phones },
      { path: 'calculator', name: 'Calculator', meta: { layout: UserPages }, component: Calculator },
      { path: 'tags', name: 'Tags', meta: { layout: UserPages }, component: Tags },
      { path: 'broadcast', name: 'Broadcast', meta: { layout: UserPages }, component: Broadcast },
    ]
  },
  {
    path: '/setup', component: AccountSetup, meta: { layout: Registration }, props: true, beforeEnter: authGuard,
    children: [
      { path: "", name: "Step1", meta: { layout: Registration }, component: Step1 },
      { path: "step1", name: "Step1 ", meta: { layout: Registration }, component: Step1 },
      { path: "step2", name: "Step2", meta: { layout: Registration }, component: Step2 },
      { path: "step3", name: "Step3", meta: { layout: Registration }, component: Step3 },
      { path: "step4", name: "Step4", meta: { layout: Registration }, component: Step4 },
      { path: "step5", name: "Step5", meta: { layout: Registration }, component: Step5 },
    ]
  },

  /**
   * HubSpot iFrame
   */
  { path: '/h/conversation', name: 'Conversation ', component: Conversation, meta: { layout: Iframed }, beforeEnter: authGuardIframe },
  { path: '/h/stripe/checkout', name: 'Stripe Checkout', component: StripeCheckoutFrame, meta: { layout: Iframed } },
  { path: '/h/StockIQ/CheckInventory', name: 'Stock IQ Inventory', component: StockIQFrame, meta: { layout: Iframed } },
  { path: '/h/PriceIQ/CheckPrice', name: 'Price IQ', component: PriceIQFrame, meta: { layout: Iframed } },
  { path: '/h/GreenLawn/FieldRoutes', name: 'Price IQ', component: FieldRoutesFrame, meta: { layout: Iframed } },
  { path: '/h/GreenLawn/RealGreen', name: 'Price IQ', component: RealGreenFrame, meta: { layout: Iframed } },

  /**
   * Price IQ
   */
  { path: '/PriceIQ', name: 'PriceIQ Settings', component: PriceIQSettings, meta: { layout: UserPages }, beforeEnter: (to, from, next) => { authGuard(to, from, () => { productConfigGuard(to, from, next); }); }, },
  { path: '/PriceIQ/create', name: 'Create Price List', component: CreatePriceList, meta: { layout: UserPages }, beforeEnter: (to, from, next) => { authGuard(to, from, () => { productConfigGuard(to, from, next); }); }, },
  { path: '/PriceIQ/edit', name: 'Edit Price List', component: EditPriceList, meta: { layout: UserPages }, beforeEnter: (to, from, next) => { authGuard(to, from, () => { productConfigGuard(to, from, next); }); }, },

  /**
   * Status IQ
   */
  { path: '/StatusIQ', name: 'Status IQ', component: Trackers, meta: { layout: UserPages}, beforeEnter: (to, from, next) => { authGuard(to, from, () => { productConfigGuard(to, from, next); }); }, },
  { path: '/StatusIQ/create', name: 'Status IQ ', component: CreateTracker, meta: { layout: UserPages}, beforeEnter: (to, from, next) => { authGuard(to, from, () => { productConfigGuard(to, from, next); }); }, },
  { path: '/StatusIQ/edit/:id', name: 'Status IQ  ', component: CreateTracker,meta: { layout: UserPages}, beforeEnter: (to, from, next) => { authGuard(to, from, () => { productConfigGuard(to, from, next); }); }, },
  { path: '/StatusIQ/app-customization', name: 'Status IQ  ', component: StatusIQCustomization,meta: { layout: UserPages}, beforeEnter: (to, from, next) => { authGuard(to, from, () => { productConfigGuard(to, from, next); }); }, },

  /**
   * Stock IQ
   */
  { path: '/StockIQ', name: 'StockIQ Settings', component: StockIQ, meta: { layout: UserPages }, beforeEnter: (to, from, next) => { authGuard(to, from, () => { productConfigGuard(to, from, next); }); }, },
  { path: '/StockIQ/products', name: 'StockIQ Products', component: StockIQProducts, meta: { layout: UserPages }, beforeEnter: (to, from, next) => { authGuard(to, from, () => { productConfigGuard(to, from, next); }); }, },

  /**
   * Clover
   */
  { path: '/clover', name: 'Clover App', component: CloverApp, meta: { layout: StandaloneApp }, beforeEnter: authGuard },
  { path: '/clover/registration', name: 'Clover Registration', component: CloverAuth, meta: { layout: StandaloneApp }, beforeEnter: authGuard },
  
  /**
   * Wizard
   */
  { path: '/wizard', component: Wizard, props: true,
    children: [
      { path: "", name: 'Systems', meta: { layout: UserPages }, component: Systems, props: (route) => ({ query: route.query.status }) },
      { path: "triggers", name: 'Triggers', meta: { layout: UserPages }, component: Triggers, props: (route) => ({ query: route.query.status }) },
      { path: "objects", name: 'Objects', meta: { layout: UserPages }, component: Objects, props: (route) => ({ query: route.query.status }) },
      { path: "fields", name: 'Fields', meta: { layout: UserPages }, component: Fields, props: (route) => ({ query: route.query.status }) },
      { path: "finish", name: 'Approval', meta: { layout: UserPages }, component: Approval, props: (route) => ({ query: route.query.status }) },
    ]
  },

  /**
   * Ask if required ??
   */
  { path: '/sr/registration', name: 'Status Registration', component: SmartRampRegistration, meta: { layout: Registration}, beforeEnter: authGuard },
  { path: '/about', name: 'About', meta: { layout: UserPages, Iframed },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  },
  
  { path: '/product-idea', name: 'Product Suggestion', component: ProductIdea }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
