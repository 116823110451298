import Api from "@/services/api";
export default {
    namespaced: true,
    state: {
        companyTemplates: []
    },
    mutations: {
        SET_COMPANY_TEMPLATES(state,companyTemplates){
            state.companyTemplates = companyTemplates;
        },
    },
    actions: {
        async getCompanyTemplatesByCompanyId({commit},{companyId:companyId,userId:userId}){
            let response = await Api().get(`/Web/Templates/GetByCompanyId/${companyId}/${userId}`)
            .catch(err => {
                console.error(err.message);
            });
            commit('SET_COMPANY_TEMPLATES',response.data);
            return response.data;
        },
        async createOrUpdateTemplate({dispatch},{template: template, templateFormat: templateFormat, companyId: companyId, userId: userId}){
            let templateObj = {
                "Id": template.id,
                "CompanyId": companyId,
                "UserId": userId,
                "MessageName": template.name,
                "MessageText": templateFormat
            };

            const formData = new FormData();
            formData.append('file',template.imgUrl);
            formData.append('jsonData',JSON.stringify(templateObj));

            let response = await Api().post(`/Web/Templates/CreateOrUpdateTemplate`,formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).catch(() => {
                // Catch Error and return false to caller
                return {
                    'result': false
                };
            });

            return {
                'result': true,
                'id': response.data
            };
        },
        async deleteTemplate({dispatch},{template: template, companyId: companyId}){
            await Api().delete(`/Web/Templates/${template.id}`).catch(() => {
                // Catch Error and return false to caller
                return false;
            });

            return true;
        },
        convertBase64StringtoFile({},template){
           //var mimeType = template.fileType;
           //mimeType = mimeType || (template.fileContent.match(/^data:([^;]+);/)||'')[1];
           //return (fetch(template.fileContent)
            //.then(function(res){return res.arrayBuffer();})
            //.then(function(buf){return new File([buf], template.fileName, {type:mimeType});})
          //);
        
         const imgName = template.fileName;
         const imgExt = imgName.split('.').pop();
         let mimeType = template.fileType;
         if (imgExt.toLowerCase() !== 'png') {
             mimeType = 'image/jpeg';
         }
         const imgB64 = template.fileContent;
         const bstr = atob(imgB64);
         let n = bstr.length;
         const u8arr = new Uint8Array(n);
         while (n--) {
           u8arr[n] = bstr.charCodeAt(n);
         }
         const file = new File([u8arr], imgName, {type: mimeType});
         return file;
       }
        
    },
    getters: {
        getCompanyTemplates: state => { return state.companyTemplates }
    }
};