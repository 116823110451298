<template>
  <hello-world />
</template>

<script>
  import HelloWorld from '../components/SmartRampStart.vue'
  export default {
    name: 'Home',
    components: {
      HelloWorld,
    }
  }
</script>
<style lang="scss">

</style>
