import Api from "@/services/api";
import Vue from "vue";
export default {
    namespaced: true,
    state: {
        
    },
    mutations: {
        
    },
    actions: {
        async getStripeCheckoutUrl({},{portalId,dealId}){
            let response = await Api().get(`/Stripe/Checkout/GetCheckoutUrl/${portalId}/${dealId}`)
            .catch(err => {
                console.error(err.message);
            });
            return response.data;
        }
    },
    getters: {
        
    }
};