<template>
    <integrationsLogs></integrationsLogs>
</template>
<script>
    import integrationsLogs from '../../components/SmartRamp/Integrations/IntegrationsLogs.vue';

    export default {
        components: {
            integrationsLogs
        },
        data(){
            return {

            }
        }
    }
</script>