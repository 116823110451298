<template>
    <v-container>
              <v-row>
                  <v-col>
                          <v-expansion-panels v-model="openedPanel">
                            <v-expansion-panel v-for="(feature, i) in features" :key="i">
                                <v-expansion-panel-header   :disabled="!feature.status" class="flex flex-wrap">  
                                    <v-row no-gutters class="d-flex align-start" style="width: 100%" :class="!feature.status ? 'text--disabled': '' ">
                                        
                                        <v-col cols="12"  md="3" sm="6">
                                            <span class="font-weight-black d-inline">
                                                <h3>
                                                    <v-icon small>{{feature.icon}}</v-icon>
                                                    {{feature.name}}
                                                    <Popover :title="'About ' + feature.name" :content="feature.description"></Popover>
                                                </h3>
                                                
                                            </span> 
                                        </v-col>
                                        <v-col cols="12" md="2" sm="6">
                                            <v-switch
                                                class="d-inline-flex mt-0 pt-0"
                                                v-model="feature.status"
                                                inset
                                                label="ON"
                                                v-on:click="updateFeature(feature)" 
                                                > 
                                                <template v-slot:prepend>
                                                    <span class="mt-1 mr-1">OFF</span>
                                                </template>
                                            </v-switch>
                                        </v-col>
                                        <v-col cols="12"  md="4" sm="6"  class="d-none d-sm-flex"> 
                                            <span class="body-2">{{feature.value}}</span>
                                        </v-col>
                                        <v-col cols="12"  md="3" sm="6">
                                            <span v-if="feature.status" class="d-block">Enabled</span>
                                            <span v-else class="d-block">Disabled</span>
                                            <span class="d-block">{{feature.lastUpdate}}</span>
                                        </v-col>
                                    </v-row>
                                    <template v-slot:actions append>
                                        <v-btn-toggle class="float-right" color="iq_darkblue" :value="feature.id === openedPanel ? 0 : undefined" v-if="feature.id !== openedPanel">
                                            <v-btn small :disabled="!feature.status"> Customize</v-btn>
                                        </v-btn-toggle>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-divider></v-divider>
                                    <v-row class="mt-1">
                                        <v-col class="d-flex flex-row align-center">
                                            <v-icon x-large class="mr-4 pb-5">{{feature.icon}}</v-icon>
                                            
                                            <v-textarea
                                                v-if="whatTypeIs(feature.value) === 'string'"
                                                flat
                                                class="chat-bubble-override left mb-6 pt-4 flex-grow-1"
                                                v-model="feature.value"
                                                label="Response"
                                                auto-grow
                                                rows="3"
                                                placeholder="Type a message"
                                                hide-details="true"
                                                :id="'editor-'+feature.id"
                                            ></v-textarea>
                                            <v-select
                                                v-else
                                                outlined
                                                :items="creditLimitValues"
                                                v-model="feature.value"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="d-flex justify-end">
                                            <v-btn color="primary" v-on:click="updateFeature(feature)">Save</v-btn>
                                            <v-btn outlined color="primary"  @click="resetChanges(i)">Cancel</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                  </v-col>
              </v-row>
    </v-container>
</template>
<script>
import Popover from '../../components/shared/_popover.vue';
import { mapGetters } from 'vuex';
export default {
    components: {
        Popover
    },
    computed: {
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany',
            currentCompanyAutomation: 'company/getCurrentCompanyAutomation'
        })
    },
    data () {
        return { 
            openedPanel: null,
            tooltipShow: false,
            features: [
                {id: 0, status: true, icon: "mdi-voicemail",
                name: "Call Forwarding Voice", 
                description: "Enables a voice message read to users by the automated system",  
                value: "Thank you for calling! Please Stay on the line. Your call is being connected to one of our representatives", 
                lastUpdate: "10-5-2020", customize: false},
                {id: 1, status: false,  icon: "mdi-phone-missed",
                name: "Missed Call Replies", 
                description: "Automated Response text for missed",  
                value: "Thank you for calling! Please Stay on the line. Your call is being connected to one of our representatives", 
                lastUpdate: "10-5-2020", customize: false},
                {id: 2, status: false,  icon: "mdi-weather-night ",
                name: "After Hours Replies", 
                description: "Response to customers when a call in non business hours takes place",  
                value: "Thank you for calling! Please Stay on the line. Your call is being connected to one of our representatives", 
                lastUpdate: "10-5-2020", customize: false},
                {id: 3, status: true,  icon: "mdi-account-off",
                name: "Possible Unsubscribe Replies", 
                description: "Response to customers who are typing words such as CANCEL, UNSUBSCRIBE that imply they want to unsubscribe.",  
                value: "Thank you for calling! Please Stay on the line. Your call is being connected to one of our representatives", 
                lastUpdate: "10-5-2020", customize: false},
                {id: 4, status: true,  icon: "mdi-traffic-light",
                name: "Set Your Credit Limit", 
                description: "Select your limit of credits you want to spend on an outgoing message. The telecom companies charge a message roughly every 160 characters, much like a tweet. If a message goes beyond your limit, we cut your message off and give the user a link to see the full message. (see example here).",  
                value: 5, 
                lastUpdate: "10-5-2020", customize: false}
            ],
            featuresBuffer: [],
            featureFormChanges: false,
            creditLimitValues: [1,2,3,4,5,6,7,8,9,10]
        }
    },
    async mounted() {
        this.$auth.auth0ClientPromise.then(async () => {
            if(Object.keys(this.currentCompany).length === 0){
                await this.$store.dispatch('getUserCompany');
                
                await this.$store.dispatch('company/getCompanyAutomationData',this.currentCompany.id);

                this.parseCompanyAutomationData();

                //makes a security copy of source data for restore 
                this.featuresBuffer = JSON.parse(JSON.stringify(this.features));
            }else{
                await this.$store.dispatch('company/getCompanyAutomationData',this.currentCompany.id);

                this.parseCompanyAutomationData();

                //makes a security copy of source data for restore 
                this.featuresBuffer = JSON.parse(JSON.stringify(this.features));
            }
        });
    },
    watch: {
        features: {
            handler: function(v) {
                return this.featuresObjHasChanged();
            },
            deep: true
        },
    },
    methods: {
        resetChanges(tabIndex){
            this.features[tabIndex].value =  JSON.parse(JSON.stringify(this.featuresBuffer[tabIndex].value));
            this.openedPanel = undefined;
        },
        featuresObjHasChanged(){
            if(JSON.stringify(this.features) !== JSON.stringify(this.featuresBuffer)){
                this.featureFormChanges = true
            }else{
                 this.featureFormChanges = false
            }
        },
        collapseAll(status){
            if(!status){
                this.openedPanel= null;
            }
        },
        whatTypeIs ( entry ) {
            return typeof(entry)
        },
        async updateFeature(feature){
            let result = await this.$store.dispatch('company/updateCompanyAutomationFeature',feature);
            
            if(result){
                this.$swal({
                    icon: 'success',
                    title: 'Automation Updated',
                    text: 'Automation feature successfully updated.',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });                
                this.parseCompanyAutomationData();

            }else{
                this.$swal({
                    icon: 'error',
                    title: 'Error Updating Automation',
                    text: 'An error occurred while updating the automation feature. Please try again later.',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });
            }
            if(feature.status){
                this.openedPanel = feature.id;
            }
            else{
                this.openedPanel = undefined;
            }
        },
        parseCompanyAutomationData(){
            var features = this.features;
            let autoResponseMessages = this.currentCompanyAutomation.autoResponseMessages;
            let companyCreditLimitSettings = this.currentCompanyAutomation.companySettings;

            // Set Call Forwarding Data
            var cfIndex = features.findIndex(f => f.id === 0);
            features[cfIndex].status = autoResponseMessages.isCallForwardingMessageEnabled;
            features[cfIndex].value = autoResponseMessages.callForwardingMessage;
            features[cfIndex].lastUpdate = this.formatDateTimeToDate(autoResponseMessages.callForwardingMessageDateUpdated);

            // Set Missed Calls Replies Data
            var cfIndex = features.findIndex(f => f.id === 1);
            features[cfIndex].status = autoResponseMessages.isMissedCallMessageEnabled;
            features[cfIndex].value = autoResponseMessages.missedCallMessage;
            features[cfIndex].lastUpdate = this.formatDateTimeToDate(autoResponseMessages.missedCallMessageDateUpdate);

            // Set After Hours Replies Data
            var cfIndex = features.findIndex(f => f.id === 2);
            features[cfIndex].status = autoResponseMessages.isAfterHoursMessageEnabled;
            features[cfIndex].value = autoResponseMessages.afterHoursMessage;
            features[cfIndex].lastUpdate = this.formatDateTimeToDate(autoResponseMessages.afterHoursMessageDateUpdate);

            // Set Possible Unsubscribe Replies Data
            var cfIndex = features.findIndex(f => f.id === 3);
            features[cfIndex].status = autoResponseMessages.isUnsubscribeEnabled;
            features[cfIndex].value = autoResponseMessages.unsubscribeMessage;
            features[cfIndex].lastUpdate = this.formatDateTimeToDate(autoResponseMessages.unsubscribeMessageDateUpdated);

            // Set Credit Limit Data
            var cfIndex = features.findIndex(f => f.id === 4);
            features[cfIndex].status = companyCreditLimitSettings.shortenMessagesEnabled;
            features[cfIndex].value = companyCreditLimitSettings.shortenMessagesSegmentsThreshold;
            features[cfIndex].lastUpdate = this.formatDateTimeToDate(companyCreditLimitSettings.dateUpdated);

            this.features = features;
            this.featuresBuffer = JSON.parse(JSON.stringify(this.features));
            
        }
    }
}
</script>