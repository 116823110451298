<template>
    <v-card elevation="0">
    <v-toolbar
        dark
        color="iq_darkerblue" 
        id="SettingsNav"
       
    >
        <v-toolbar-title class="display-3">Message IQ Settings </v-toolbar-title>
        
        <template v-slot:extension>
            <v-container class="pa-0">
                <v-tabs
                    v-model="tabs"
                    show-arrows
                    grow
                    background-color="iq_darkerblue"
                >
                    <v-tab 
                        v-for="setting  in settingComponents"
                        :key="setting.id"
                        :to="setting.route"
                        :class="tabs  == setting.route ? 'v-tab--active' : ''"
                    >
                        {{ setting.name }}
                    </v-tab>
                </v-tabs>
            </v-container>
        </template> 
    </v-toolbar>
    <v-tabs-items v-model="tabs">
        <router-view></router-view>
    </v-tabs-items>
  </v-card> 
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            userRole: 'getUserRole',
            currentCompany: 'company/getCurrentCompany',
            user: 'getUser'
        }),
    },
    data () {
        return{
            tabs: '/settings/account',
            settingsViewport: null,
            settingComponents:[
                {id:'0', name: 'Account', comp: 'Account', route:'/settings/account'},
                {id:'1', name: 'User Settings', comp: 'UserSettings', route:'/settings/user'},
            ]
        } 
    },
    mounted(){
        this.$auth.auth0ClientPromise.then(async () => {
            if(Object.keys(this.currentCompany).length === 0){
                    await this.$store.dispatch('getUserCompany');
            }
            if(this.userRole === "SuperAdmin" || this.userRole === "AccountAdmin"){
                this.settingComponents = this.settingComponents.concat([
                    {id:'2', name: 'Automation', comp: 'Automation', route:'/settings/automation'},
                    {id:'3', name: 'Business Hours', comp: 'BusinessHours', route:'/settings/businesshours'},
                    {id:'4', name: 'Templates', comp: 'Templates', route:'/settings/templates'},
                    {id:'5', name: 'Billing', comp: 'Billing', route:'/settings/billing'},
                    {id:'6', name: 'Users', comp: 'Users', route:'/settings/users'},
                    {id:'7', name: 'Integrations', comp: 'Integrations', route:'/settings/integrations'},
                    {id:'8', name: 'Phones', comp: 'Phones', route:'/settings/phones'},
                    {id:'9', name: 'Calculator', comp: 'Calculator', route:'/settings/calculator'},
                    {id:'11', name: 'Tags', comp: 'Tag', route:'/settings/tags'},
                    {id:'12', name: 'Broadcast', comp: 'Broadcast', route:'/settings/broadcast'}
                ]);
            }else if(this.userRole === "BillingAdmin"){
                this.settingComponents = this.settingComponents.concat([
                    {id:'5', name: 'Billing', comp: 'Billing', route:'/settings/billing'}
                ]);
            }

            if(this.currentCompany.isAgency || this.user.agencyId !== null){
                this.settingComponents = this.settingComponents.concat([
                    {id:'10', name: 'Agency Customers', comp: 'Agency Customers', route:'/settings/agency/customers'}
                ]);
            }
        });
    }
}
</script>