import Api from "@/services/api";
import Vue from 'vue';
export default {
    namespaced: true,
    actions: {
        async sendBroadcast({},{broadcast: broadcast}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().post(`/Web/Broadcast/SendBroadcast`, broadcast, config)
            .catch(err => {
                console.error(err.message);
            });
            return response;
        }
    }
}