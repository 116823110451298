import Api from "@/services/api";
import Vue from "vue";
import router from "../../router";
export default {
    namespaced: true,
    state: {
        user: null,
        currentCompany: {},
        userRole: null
    },
    mutations: {
        SET_USER(state,user){
            state.user = user;
        },
        SET_USER_ROLE(state,userRole){
            state.userRole = userRole;
        },
        SET_CURRENT_COMPANY(state,currentCompany){
            state.currentCompany = currentCompany;
        }
    },
    actions: {
        async getUserCompany({dispatch,commit}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            var user = await Api().get(`/Web/User/GetUserAndCompany`,config).catch(async err => {
            },config).catch(err => {
                console.error(err.message);
                return false;
            });
            commit("SET_USER",user.data.user);
            commit("SET_USER_ROLE",user.data.userRole);
            commit("SET_CURRENT_COMPANY",user.data.company);
            return true;
        },
        async registerUser({},{app: app, companyName: companyName, domain: domain, mid:mid}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let authUser = await Vue.prototype.$auth.user;
            await Api().post(`/Web/User/CreateIntegrationUser`,{
                firstName: authUser.given_name,
                lastName: authUser.family_name,
                email: authUser.email,
                accountId: mid,
                companyName: companyName,
                domainName: domain,
                app: app
            },config).catch(err => {
                console.error(err.message);
                return false;
            });
            return true;
        }
    },
    getters: {
        getCurrentCompany: state => { return state.currentCompany },
    }
};