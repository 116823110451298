<template>
    <v-container >
        <v-card flat>
            <v-card-text class="pa-0">
                <v-row class="d-flex align-center">
                    <v-col md="6" cols="12" class="d-flex align-center">
                        <h1>Company Phones</h1>
                    </v-col>   
                    <v-col md="6" cols="12" class="text-right">
                        <v-btn  outlined color="primary" @click="showAddNewPhone" class="ml-2">
                            <span>+ Add New Phone</span>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-data-table
                    :headers="phonesHeaders"
                    :items="companyPhones"
                    :items-per-page="30"
                    dense
                    fixed-header
                    :height="window.height - this.appBarHeight - this.appHeaderToolbar - this.dataTableFooter - 130"
                    class="elevation-1 fill-height"
                >   
                <template v-slot:item.actions="{ item }">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                x-small
                                outlined
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                :data-user="item.firstName + item.lastName"
                                :data-phone="item.phone "
                                >
                                    Actions <v-icon small>mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>
                                <v-list>
                                    <v-list-item
                                    v-for="(action, index) in actions"
                                    :key="index" 
                                    @click="callAction(action.title,item)"
                                    :data-user="item.firstName + item.lastName" 
                                    :data-phone="item.phone "
                                    >
                                        <v-list-item-title>{{ action.title }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                        </v-menu>
                    </template>
                </v-data-table>      
            </v-card-text>
        </v-card>

        <v-dialog
            v-model="dialog"
            width="600"
        >
            <v-card>
                <v-card-title class="justify-center pt-8">
                    {{this.isUpdate ?'Edit Phone' : 'Add Phone'}}
                </v-card-title>
                <div class="ma-auto position-relative  pb-16" style="max-width: 410px">
                    <v-card-text class="justify-center text-center py-0">
                        <v-container>
                            <span class="subtitle-1" v-if="!this.isUpdate">
                                Additional Message IQ numbers are $5 per month per number. To assign a user, the users email in Message IQ must match to a user in HubSpot.
                            </span>
                            <v-form v-model="valid" id="verifyPhoneForm" ref="verifyPhoneForm">
                                <v-row justify="center" class="pt-8">
                                    <v-col cols="8" class="pa-0">
                                        <v-text-field
                                            v-model="verifyPhoneForm.areaCode"
                                            v-mask="areaCodeMask"
                                            :color="!this.$vuetify.breakpoint.smAndDown ? 'iq_dark' : 'iq_light'"
                                            :background-color="!this.$vuetify.breakpoint.smAndDown ? 'iq_light' : ''"
                                            :dense="this.$vuetify.breakpoint.smAndDown"
                                            label="Area Code*"
                                            outlined
                                            required
                                            :rules="this.isUpdate ? '':requiredRule"
                                            v-if="!this.isUpdate"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="8" class="pa-0">
                                        <v-text-field
                                        class="pt-0"
                                            v-model="verifyPhoneForm.name"
                                            :color="!this.$vuetify.breakpoint.smAndDown ? 'iq_dark' : 'iq_light'"
                                            :background-color="!this.$vuetify.breakpoint.smAndDown ? 'iq_light' : ''"
                                            :dense="this.$vuetify.breakpoint.smAndDown"
                                            label="Name*"
                                            outlined
                                            required
                                            :rules="requiredRule"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="8" class="pa-0">
                                        <v-autocomplete
                                            v-model="verifyPhoneForm.assingUser"
                                            :color="!this.$vuetify.breakpoint.smAndDown ? 'iq_dark' : 'iq_light'"
                                            :background-color="!this.$vuetify.breakpoint.smAndDown ? 'iq_light' : ''"
                                            :dense="this.$vuetify.breakpoint.smAndDown"
                                            :items="users"
                                            item-value="id"
                                            :item-text="getItemText"
                                            label="Assigned User"
                                            outlined
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="8" class="pa-0">
                                        <v-text-field
                                            v-model="verifyPhoneForm.callForwardNumber"
                                            v-mask="phoneMask"
                                            :color="!this.$vuetify.breakpoint.smAndDown ? 'iq_dark' : 'iq_light'"
                                            :background-color="!this.$vuetify.breakpoint.smAndDown ? 'iq_light' : ''"
                                            :dense="this.$vuetify.breakpoint.smAndDown"
                                            label="Call Forward Number"
                                            outlined
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="8" class="pa-0">
                                        <v-text-field
                                            v-model="verifyPhoneForm.officeExtension"
                                            :color="!this.$vuetify.breakpoint.smAndDown ? 'iq_dark' : 'iq_light'"
                                            :background-color="!this.$vuetify.breakpoint.smAndDown ? 'iq_light' : ''"
                                            :dense="this.$vuetify.breakpoint.smAndDown"
                                            label="Office extension"
                                            outlined
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" class="pa-0" v-if="!this.isUpdate">
                                        <v-checkbox
                                        v-model="agree"
                                        :label="'I agree to purchase an additional Message IQ number for $5 / per month'"
                                        required
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="10" class="pa-0">
                                            <v-btn 
                                                x-large 
                                                :disabled="!valid || loadingSubmit" 
                                                outlined 
                                                :loading="loadingSubmit"
                                                color="primary" 
                                                @click="updatePhoneNumber" 
                                                class="ml-2"
                                                v-if="this.isUpdate"
                                            >
                                                <span>Update Number</span>
                                            </v-btn>
                                            <v-btn 
                                                x-large 
                                                :disabled="!valid || !agree || loadingSubmit" 
                                                outlined 
                                                :loading="loadingSubmit"
                                                color="primary" 
                                                @click="addNewPhone" 
                                                class="ml-2"
                                                v-if="!this.isUpdate"
                                            >
                                                <span>Purchase Number</span>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>
                </div>
                <!-- <v-snackbar
                    v-model="snackbar"
                    :color="snackbarColor"
                    :timeout="5000"
                >
                    <div class="text-center">{{ text }}</div>
                </v-snackbar> -->
            </v-card>
        </v-dialog>
              
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    data (){
        return {
            search: '',
            show:false,

            companyPhones: [],
            users:[],
            companyPhone: {},

            valid: false,
            dialog: false,
            agree: false,
            loadingSubmit: false,
            isUpdate : false,

            areaCodeMask: '(###)',
            phoneMask: '(###) ###-####',
            requiredRule: [
                v => !!v || 'Field is required'
            ],

            verifyPhoneForm:{
                areaCode: null,
                name: null,
                assingUser: null,
                callForwardNumber: null,
                officeExtension: null,
                id: null
            },

            window: { //LAYOUT TOOL: store location when page will need vertical space calculations         )
                width: 0,
                height: 0
            },
            actions: [
                { title: 'Update'},
                { title: 'Delete'},
            ],
        }
    },
    created() {
        this.$auth.auth0ClientPromise.then(async () => {
            await this.getUsers();
            await this.$store.dispatch('getUserCompany');

            //LAYOUT TOOL: resize handler
            window.addEventListener('resize', this.handleResize);
            this.handleResize();

            this.getPhoneNumbers();
        });
    },
    destroyed() { //LAYOUT TOOL: on leave dispose
        window.removeEventListener('resize', this.handleResize);
    },
    computed: {
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany'
        }),
        phonesHeaders() {
            return [
                {
                    text: "Message IQ Number",
                    value: "phoneNumber",
                    align: "center",
                },
                {
                    text: "Name", 
                    value: "name",
                    align: "center",
                },
                {
                    text: "User",
                    value: "userName",
                    align: "center",
                    groupable: false,
                },
                {
                    text: "Call Forward Number",
                    value: "callForwardNumber",
                    align: "left",
                }, 
                {
                    text: "Office extension",
                    value: "officeExtension",
                    align: "left",
                },              
                 {
                     text: "Actions",
                     value: "actions",
                     align: "center", 
                     groupable: false,
                     filterable: false,
                     sortable: false
                 }
            ]
        }
    },
    methods: {
        async getPhoneNumbers() {
            let phones = [];
            let response = await this.$store.dispatch('company/getPhoneNumbers',{
                "companyId": this.currentCompany.id
            });
            response.data.forEach( (phone) => {
                phones.push({
                    phoneNumber: this.formatPhoneNumber(phone.phoneNumber),
                    name: phone.name,
                    userName: phone.associatedUser ? phone.associatedUser.firstName +' '+ phone.associatedUser.lastName : '',
                    callForwardNumber: phone.callForwardNumber == undefined || phone.callForwardNumber == null ? this.currentCompany.forwardingNumber : this.formatPhoneNumber(phone.callForwardNumber),
                    idUser:phone.associatedUserId,
                    officeExtension:phone.officeExtension,
                    id:phone.id
                });
            });

            this.companyPhones = phones;
        },
        async getUsers() {
            this.$auth.auth0ClientPromise.then(async () => {
                let users = await this.$store.dispatch('company/getCompanyUsers');
                users.forEach(user => {
                    if(user.firstName === "" && user.lastName === ""){
                        user.firstName = user.email;
                    }
                    this.users.push({id: user.userId, firstName: user.firstName, lastName: user.lastName})
                });
            });
        },
        getItemText(item) {
            return `${item.firstName} ${item.lastName}`;
        },
        showAddNewPhone () {
            this.verifyPhoneForm = {};
            this.isUpdate = false;
            this.dialog = true;
        },
        async addNewPhone () {
            this.loadingSubmit = true;

            let response = await this.$store.dispatch('company/addPhone', {
                "companyPhone": this.getPhoneFormat(), 
                "areaCode": this.unmask(this.verifyPhoneForm.areaCode)
            });

            if(response){
                this.getPhoneNumbers();
                this.$swal({
                    icon: 'success',
                    title: 'Phone Successfully Added',
                    text: 'Phone Number Successfully Added',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });
                this.getPhoneNumbers();
                this.dialog = false;
                this.verifyPhoneForm = {};
                this.agree = false;
               
            }else{
                this.showError( 'We could not add this phone number.');
            }

            this.loadingSubmit = false
        },
        unmask(phone){
            return phone.replace(/[^\d]/g, ''); 
        },
        getPhoneFormat() {
            return { 
                CompanyId: this.currentCompany.id,
                AssociatedUserId: this.verifyPhoneForm.assingUser ? this.verifyPhoneForm.assingUser : "00000000-0000-0000-0000-000000000000",
                CallForwardNumber: this.verifyPhoneForm.callForwardNumber ? '+1' + this.unmask(this.verifyPhoneForm.callForwardNumber) : null,
                OfficeExtension: this.verifyPhoneForm.officeExtension,
                Name: this.verifyPhoneForm.name,
                Id: "00000000-0000-0000-0000-000000000000"
            }

        },
        showError(text){
            this.$swal({
                icon: 'error',
                title: text,
                text: '',
            });
        },
        callAction(e,f){
            if(e ==="Update"){
               this.showupdatePhoneNumber(f);
            }else{
                this.deleteModal(f);
            }
        },

        async deletePhoneNumber(phoneNumber){
              let response = await this.$store.dispatch('company/deleteCompanyPhone',{"phoneNumber": '1' + this.unmask(phoneNumber.phoneNumber),"companyId": this.currentCompany.id});
               if(response){
                this.getPhoneNumbers();
                this.$swal({
                    icon: 'success',
                    title: 'Phone Successfully Deleted',
                    text: 'Phone Number Successfully Deleted',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });               
            }else{
                this.showError( 'We could not delete this phone number.');
            }
        },

       showupdatePhoneNumber(phoneNumber){
            this.verifyPhoneForm = {};
            this.isUpdate = true;
            this.dialog = true;
            this.verifyPhoneForm.assingUser = phoneNumber.idUser;
            this.verifyPhoneForm.callForwardNumber = phoneNumber.callForwardNumber;
            this.verifyPhoneForm.officeExtension = phoneNumber.officeExtension;
            this.verifyPhoneForm.name = phoneNumber.name;
            this.verifyPhoneForm.Id = phoneNumber.id;
        },

        async updatePhoneNumber(){
            let companyPhone = this.getPhoneFormat();
            companyPhone.Id = this.verifyPhoneForm.Id;
             let response = await this.$store.dispatch('company/updateCompanyPhone',{"companyPhone": companyPhone});
             if(response){
                  this.getPhoneNumbers();
                 this.$swal({
                     icon: 'success',
                     title: 'Phone Successfully Updated',
                     text: 'Phone Number Successfully Updated',
                     toast: true,
                     timer: 3000,
                     position: 'top-end'
                 });   
                  this.dialog = false;            
             }else{
                 this.showError( 'We could not update this phone number.');
             }
        },

        deleteModal(phoneNumber){
            this.$swal.fire({
                title: 'Are you sure you want to delete this phone number?',
                icon: 'question',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Yes',
                denyButtonText: "No",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deletePhoneNumber(phoneNumber);
                    }
                })
        },


    }
}
</script>