<template>
  <v-container fluid style="height: 100%">
    <v-row class="d-flex align-center justify-center" style="height: 100%; width: 100%;" v-if="isLoadingCurrentConfig">
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </v-row>

    <v-row style="height: 100%" v-if="!isLoadingCurrentConfig">
      <v-col>
        <v-form id="customizationForm" ref="customizationForm" lazy-validation class="d-flex flex-column"
          @submit.prevent="handleSubmit">
          <v-card-title class="text-h5 px-0 pb-5 d-flex justify-space-between">
            <div>
              <p>
                Customize Your Status IQ Appearance
              </p>
            </div>
          </v-card-title>

          <div>
            <p class="text-h5 px-0 d-flex">
              Logo and domain
            </p>

            <div>
              <input type="file" ref="fileInput" id="custom-status-iq-logo" @change="handleFileChange"
                style="display: none;" />
              <v-btn color="primary" class="py-2 mb-4" @click="triggerFileInput">Choose logo</v-btn>

              <v-text-field name="Domain" :dense="this.$vuetify.breakpoint.smAndDown" outlined class="mr-2"
                label="Custom domain url" v-model="customizationForm.Domain"></v-text-field>

              <!-- This image tag is here just to hold the image as a reference for the color picker library, that's why it's with the display hidden -->
              <img width="100" id="img-preview" style="display: none;" :src="this.previewUrl" />
            </div>
          </div>

          <div>
            <p class="text-h5 px-0 d-flex">
              Colors
            </p>

            <div class="mt-4 d-flex flex-column" style="width: fit-content; gap: 12px;">
              <div style="height: fit-content; gap: 8px;" class="d-flex align-center">
                <div id="primary-color-picker-trigger" role="button" @click="() => triggerColorInput('primary')"
                  class="color-picker-trigger"
                  :style="{ backgroundColor: customizationForm.ColorConfiguration.primaryColor }"></div>
                <input type="color" ref="primaryColorPicker" id="primary-color-picker-input"
                  @change="(ev) => handleColorChange(ev, 'primary')" style="display: none;" />
                <p class="mb-0 pb-0 text-subtitle-2">Primary color</p>
              </div>

              <div style="height: fit-content; gap: 8px;" class="d-flex align-center">
                <div id="secondary-color-picker-trigger" role="button" @click="() => triggerColorInput('secondary')"
                  class="color-picker-trigger"
                  :style="{ backgroundColor: customizationForm.ColorConfiguration.secondaryColor }"></div>
                <input type="color" ref="secondaryColorPicker" id="secondary-color-picker-input"
                  @change="(ev) => handleColorChange(ev, 'secondary')" style="display: none;" />
                <p class="mb-0 pb-0 text-subtitle-2">Secondary color</p>
              </div>

              <div style="height: fit-content; gap: 8px;" class="d-flex align-center">
                <div id="tertiary-color-picker-trigger" role="button" class="color-picker-trigger"
                  @click="() => triggerColorInput('tertiary')"
                  :style="{ backgroundColor: customizationForm.ColorConfiguration.tertiaryColor }"></div>
                <input type="color" ref="tertiaryColorPicker" id="tertiary-color-picker-input"
                  @change="(ev) => handleColorChange(ev, 'tertiary')" style="display: none;" />
                <p class="mb-0 pb-0 text-subtitle-2">Tertiary color</p>
              </div>

              <!-- It will only show the pick colors from logo button, if the user uploaded an image (the local image URL reference is different from the public s3 bucket URL) -->
              <v-btn color="primary" class="py-2 mb-4" @click="pickColorsFromLogo">
                Pick from logo
              </v-btn>
            </div>
          </div>

          <v-btn color="primary" type="submit" class="py-2 mt-4">
            Save settings
          </v-btn>
        </v-form>
      </v-col>

      <v-col>
        <TrackCustomizationView v-if="this.stages" ref="trackView" :tracker="this.previewData" :stages="this.stages"
          :customLogoUrl="this.previewUrl"
          :colors="Object.keys(this.customizationForm.ColorConfiguration).length === 0 ? undefined : this.customizationForm.ColorConfiguration">
        </TrackCustomizationView>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ColorThief from "colorthief";
import TrackCustomizationView from '../StatusIQCustomization/TrackCustomizationView.vue';

export default {
  data() {
    const currentConfig = this.$store.getters['status/getStatusIqCompanySettings'];

    return {
      previewUrl: currentConfig ? currentConfig.logoUrl : "",
      isLoadingCurrentConfig: true,

      currentConfig, // returning to compare the current preview URL with the custom uploaded image URL

      customizationForm: {
        Domain: currentConfig ? currentConfig.domain : null,
        CustomImageInBase64: null,
        ColorConfiguration: currentConfig ? JSON.parse(currentConfig.colorConfiguration) : {},
      },

      previewData: {
        // Mocked props only to show the preview of tracker page
        Id: "00000000-0000-0000-0000-000000000000",
        Url: null,
        PhoneNumber: null,
        Email: null,
        SupportUrl: null,
        ObjectType: null,
        Property: 'Pipeline',
        SearchValue: null,
        StatusStages: []
      },

      stages: [{ "StatusOrder": 0, "title": "Pre-Qualification", "Description": "Pre-Qualification", "StatusMappings": [{ "Value": "186441301" }] }, { "StatusOrder": 1, "title": "Loan Application", "Description": "Loan Application", "StatusMappings": [{ "Value": "186441302" }] }, { "StatusOrder": 2, "title": "Loan Processing", "Description": "Loan Processing", "StatusMappings": [{ "Value": "186441305" }] }, { "StatusOrder": 3, "title": "Underwriting", "Description": "Underwriting", "StatusMappings": [{ "Value": "186508354" }] }, { "StatusOrder": 4, "title": "Loan Approval", "Description": "Loan Approval", "StatusMappings": [{ "Value": "186441306" }] }]
    }
  },

  mounted() {
    this.loadCurrentConfig();
  },

  methods: {
    async loadCurrentConfig() {
      this.isLoadingCurrentConfig = true;

      try {
        await this.$store.dispatch("status/fetchStatusIqCompanySettings");
        // Assuming the store action sets the currentConfig
        this.currentConfig = this.$store.state.status.currentConfig;
      } catch (error) {
        console.error('Error fetching config:', error);
      } finally {
        this.isLoadingCurrentConfig = false;
      }
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    rgbToHex(rgbValues) {
      return '#' + rgbValues.map(value => {
        const hex = value.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      }).join('');
    },

    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = error => reject(error);
      });
    },

    async handleFileChange(event) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];

        this.previewUrl = URL.createObjectURL(file);

        const base64String = await this.convertFileToBase64(file)

        this.customizationForm.CustomImageInBase64 = base64String;
      } else {
        this.previewUrl = ""
      }
    },

    /**
     * Changes the color of the custom colors based on the input value.
     *
     * @param {('tertiary' | 'secondary' | 'primary')} level - The color level to change the value.
     * @returns {void}
    */
    handleColorChange(event, level) {
      const colorValue = event.target.value;

      if (level === "primary") {
        this.customizationForm.ColorConfiguration = { ...this.customizationForm.ColorConfiguration, primaryColor: colorValue };
      }

      if (level === "secondary") {
        this.customizationForm.ColorConfiguration = { ...this.customizationForm.ColorConfiguration, secondaryColor: colorValue };
      }

      if (level === "tertiary") {
        this.customizationForm.ColorConfiguration = { ...this.customizationForm.ColorConfiguration, tertiaryColor: colorValue };
      }
    },

    /**
     * Triggers the colors inputs according to the levels.
     *
     * @param {('tertiary' | 'secondary' | 'primary')} level - The color level to change the value.
     * @returns {void}
    */
    triggerColorInput(level) {
      if (level === "primary") {
        this.$refs.primaryColorPicker.click();
      }

      if (level === "secondary") {
        this.$refs.secondaryColorPicker.click();
      }

      if (level === "tertiary") {
        this.$refs.tertiaryColorPicker.click();
      }
    },

    pickColorsFromLogo() {
      let domImg = document.querySelector('#img-preview');

      let colorthief = new ColorThief();

      const primaryColor = colorthief.getPalette(domImg)[0];
      const secondaryColor = colorthief.getPalette(domImg)[1];
      const tertiaryColor = colorthief.getPalette(domImg)[3];

      const convertedColors = {
        primaryColor: this.rgbToHex(primaryColor),
        secondaryColor: this.rgbToHex(secondaryColor),
        tertiaryColor: this.rgbToHex(tertiaryColor),
      }

      this.customizationForm.ColorConfiguration = convertedColors
    },

    async handleSubmit() {
      const payload = {
        ...this.customizationForm,
        ColorConfiguration: JSON.stringify(this.customizationForm.ColorConfiguration)
      }

      await this.$store.dispatch("status/saveCompanySettings", payload)
    }
  },

  components: {
    TrackCustomizationView
  }
}
</script>

<style scoped>
.color-picker-trigger {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid gray;
}
</style>