<template>
    <v-container class="mt-n1 pt-0">
        <v-row class="py-3">
            <v-col cols="6">
                <div class="d-flex flex-row align-start my-3"> 
                    <v-icon class="xx-large">mdi-transit-connection-variant</v-icon>
                    <div class="ml-2">
                        <h1 class="display-1"> Fields </h1>
                        <h4 class="body-2">Make your data relations</h4>
                    </div>
                </div>
                <p>Ea dolor ut duis esse occaecat nisi. Labore nulla officia minim est. Cillum fugiat aliqua enim dolor.
                </p>
            </v-col>
            <v-col cols="6">
                <Status :wizardState="this.status" :stepName="currentRouteName"></Status>
           </v-col>
        </v-row>
        <v-card rounded elevation="1" outlined>
            <v-expansion-panels multiple focusable v-model="panel">
            <v-row no-gutters>
                <v-col cols="12" lg="6" class="border-right">
                 <v-expansion-panel class="mt-0">
                    <v-expansion-panel-header>
                        <span class="text-h6 flex-grow-0"> Configurable Fields </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="mt-4">
                        <v-toolbar class="flex-grow-0" light elevation="0" flat>
                            <v-text-field
                                v-model="fieldsSearch"
                                clearable
                                dense
                                outlined
                                flat
                                solo
                                hide-details
                                prepend-inner-icon="mdi-magnify"
                                label="Search"
                                class="flex-grow-1 mr-1 compact"
                            ></v-text-field>
                           <v-select
                                flat
                                dense
                                outlined
                                solo
                                :clearable="fieldsViewByObject !== null"
                                hide-details
                                label="View Only" 
                                item-text="label"
                                item-value="value"
                                prepend-inner-icon="mdi-eye"
                                :items="fieldsViewByObjectSelect"
                                v-model="fieldsViewByObject"
                                class="flex-grow-1 mr-1"
                            ></v-select>
                            <v-select
                                v-model="fieldsSortBy"
                                :items="fieldsSortByItemKeys"
                                item-text="label"
                                item-value="value"
                                flat
                                dense
                                solo
                                outlined
                                hide-details
                                prepend-inner-icon="mdi-sort-variant"
                                label="Sort by"
                                class="flex-grow-0 mx-2"
                            ></v-select>
                            <v-btn-toggle
                                v-model="fieldsSortDesc"
                                mandatory
                            >
                                <v-btn
                                depressed
                                :value="false"
                                >
                                    <v-icon small>mdi-arrow-up</v-icon>
                                </v-btn>
                                <v-btn
                                depressed
                                :value="true"
                                >
                                    <v-icon small>mdi-arrow-down</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                        </v-toolbar>
                        <v-data-iterator
                            :items=" selectableFields"
                            :page.sync="fieldsPage"
                            :search="fieldsSearch"
                            :sort-by="fieldsSortBy"
                            :sort-desc="fieldsSortDesc"
                            :items-per-page="fieldsItemsPerPage"
                            :footer-props="{
                                itemsPerPageOptions: [8,16,22,25,50,100, -1]
                            }">
                                <template v-slot:default="props">
                                    <v-row no-gutters>
                                        <v-col cols="12" class="pa-0">
                                            <v-card flat class="pa-0 d-flex flex-column flex-start align-start text-break justify-space-between lighten-5">
                                                <v-card-text class="pa-2">
                                                        <v-row no-gutters >
                                                            <v-col cols="6">Source: <span class="body-1 font-weight-medium">{{status[0].value}}</span> <v-icon>mdi-arrow-right</v-icon> Destination: <span class="body-1 font-weight-medium">{{status[1].value}} </span>  </v-col>
                                                            <v-col cols="6"><span class="body-1">Integrate?</span></v-col>
                                                            <!-- <v-col cols="3" class="text-center"> <span class="body-1">Actions</span></v-col> -->
                                                        </v-row>
                                                </v-card-text> 
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" v-for="(item, optionIndex) in  props.items" :key="optionIndex" class="pa-0">
                                            <v-card flat outlined class="pa-0 ma-0 d-flex flex-column flex-start align-start text-break justify-space-between lighten-5" :color="getParentObject(item.object).color">
                                                <v-card-text class="pa-2">
                                                        <v-row no-gutters  class="d-flex align-center">
                                                            <v-col cols="6" v-if="item.name === 'Custom field'" class="d-flex flex-row">
                                                                <v-icon large>{{getParentObject(item.object).icon}}</v-icon>
                                                                <div class="ml-1">
                                                                          <v-text-field
                                                                            v-model="item.customFieldName"
                                                                            :label="item.name"
                                                                            clearable
                                                                            hide-details
                                                                            dense
                                                                            @blur="toggleSelectedField(item)"
                                                                        ></v-text-field>
                                                                    <span class="caption">{{item.object}}</span>
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="6" v-else class="d-flex flex-row">
                                                                <v-icon large>{{getParentObject(item.object).icon}}</v-icon>
                                                                <div class="ml-1">
                                                                    <span class="d-block body-1 font-weight-medium">{{item.name}}</span>
                                                                    <span class="caption">{{item.object}}</span>
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <v-switch
                                                                class="ma-0 pa-0"
                                                                v-model="item.selected"
                                                                hide-details
                                                                inset
                                                                color="accent"
                                                                :disabled="item.name === 'Custom field'"
                                                                ></v-switch>
                                                            </v-col>
                                                            <!-- <v-col cols="3" class="text-center">
                                                                <v-btn text small> View Data </v-btn>
                                                            </v-col> -->
                                                        </v-row>
                                                </v-card-text> 
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-data-iterator>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                </v-col>
                <v-col cols="12" lg="6">
                 <v-expansion-panel class="mt-0">
                    <v-expansion-panel-header>
                        <span class="text-h6 flex-grow-0"> PreFilled Fields </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="mt-4">
                    <v-toolbar class="flex-grow-0" light elevation="0" flat>
                        <v-text-field
                            v-model="preFieldsSearch"
                            clearable
                            dense
                            outlined
                            flat
                            solo
                            hide-details
                            prepend-inner-icon="mdi-magnify"
                            label="Search"
                            class="flex-grow-1 mr-1 compact"
                        ></v-text-field>
                        <v-select 
                                flat
                                dense
                                outlined
                                solo
                                :clearable="fieldsViewByObject !== null"
                                hide-details
                                label="View Only" 
                                item-text="label"
                                item-value="value"
                                prepend-inner-icon="mdi-eye"
                                :items="preFieldsViewByObjectSelect"
                                v-model="preFieldsViewByObject"
                                class="flex-grow-1 mr-1"
                        ></v-select>
                        <v-select
                            v-model="preFieldsSortBy"
                            :items="preFieldsSortByItemKeys"
                            item-text="label"
                            item-value="value"
                            flat
                            dense
                            solo
                            outlined
                            hide-details
                            prepend-inner-icon="mdi-sort-variant"
                            label="Sort by"
                            class="flex-grow-0 mx-2"
                        ></v-select>
                        <v-btn-toggle
                            v-model="preFieldsSortDesc"
                            mandatory
                        >
                            <v-btn
                            depressed
                            :value="false"
                            >
                                <v-icon small>mdi-arrow-up</v-icon>
                            </v-btn>
                            <v-btn
                            depressed
                            :value="true"
                            >
                                <v-icon small>mdi-arrow-down</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </v-toolbar>
                    <v-data-iterator
                            :items=" preselectedFields"
                            :page.sync="preFieldsPage"
                            :search="preFieldsSearch"
                            :sort-by="preFieldsSortBy"
                            :sort-desc="preFieldsSortDesc"
                            :items-per-page="preFieldsItemsPerPage"
                            :footer-props="{
                                itemsPerPageOptions: [8,16,22,25,50,100, -1]
                            }">
                                <template v-slot:default="props">
                                    <v-row no-gutters>
                                        <v-col cols="12" class="pa-0">
                                            <v-card flat class="pa-0 d-flex flex-column flex-start align-start text-break justify-space-between lighten-5">
                                                <v-card-text class="">
                                                        <v-row no-gutters >
                                                            <v-col cols="6">Source: <span class="body-1 font-weight-medium">{{status[0].value}}</span> <v-icon>mdi-arrow-right</v-icon> Destination: <span class="body-1 font-weight-medium">{{status[1].value}} </span>  </v-col>
                                                            <v-col cols="6"><span class="body-1">Integrate?</span></v-col>
                                                            <!-- <v-col cols="3" class="text-center"> <span class="body-1"> Actions </span></v-col> -->
                                                        </v-row>
                                                </v-card-text> 
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" v-for="(item, optionIndex) in  props.items" :key="optionIndex" class="pa-0">
                                            <v-card flat outlined class="pa-0 ma-0 d-flex flex-column flex-start align-start text-break justify-space-between lighten-5" :color="getParentObject(item.object).color">
                                                <v-card-text class="pa-2">
                                                        <v-row no-gutters  class="d-flex align-center">
                                                            <v-col cols="6" class="d-flex flex-row">
                                                                <v-icon large>{{getParentObject(item.object).icon}}</v-icon>
                                                                <div class="ml-1">
                                                                    <span class="d-block body-1 font-weight-medium">{{item.name}}</span>
                                                                    <span class="caption">{{item.object}}</span>
                                                                </div>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <v-switch
                                                                class="ma-0 pa-0"
                                                                v-model="item.selected"
                                                                hide-details
                                                                inset
                                                                color="accent"
                                                                :disabled="item.customerPicks ==='Required' ? true : false"
                                                                ></v-switch>
                                                            </v-col>
                                                            <!-- <v-col cols="3" class="text-center">
                                                                <v-btn text small> View Data </v-btn>
                                                            </v-col> -->
                                                        </v-row>
                                                </v-card-text> 
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-data-iterator>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                </v-col>
            </v-row>
               
               
            </v-expansion-panels>

        </v-card>
        <v-row>
            <v-col> &nbsp;  
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-footer color="iq_opaque" class="pt-4">
            <v-btn to="/wizard/finish" large color="primary"> Continue </v-btn>
            <v-btn text large > Cancel </v-btn>
            <v-btn to="/wizard/objects" color="secondary" text large class="ml-auto" > Back </v-btn>
        </v-footer>

    </v-container>
</template>
<script>
import Status from "./_status.vue";

export default {
    props: ['status'],
    components: {
        Status
    },
    data () {
        return{
            panel: null,
            fields:[
                {name: 'First Name',  object: 'Contact', customerPicks:"No", sampleData:"", selected:true, customFieldName:null},
                {name: 'Last Name',  object: 'Contact', customerPicks:"No", sampleData:"", selected:false, customFieldName:null},
                {name: 'Email',  object: 'Contact', customerPicks:"No", sampleData:"", selected:false, customFieldName:null},
                {name: 'Phone Number',  object: 'Contact', customerPicks:"No", sampleData:"", selected:true, customFieldName:null},
                {name: 'Company Name',  object: 'Contact', customerPicks:"Yes", sampleData:"", selected:false, customFieldName:null},
                {name: 'Street Address',  object: 'Contact', customerPicks:"No", sampleData:"", selected:false, customFieldName:null},
                {name: 'Street Address 2',  object: 'Contact', customerPicks:"No", sampleData:"", selected:true, customFieldName:null},
                {name: 'City',  object: 'Contact', customerPicks:"Yes", sampleData:"", selected:false, customFieldName:null},
                {name: 'State/Region',  object: 'Contact', customerPicks:"Required", sampleData:"", selected:true, customFieldName:null},
                {name: 'POstal Code',  object: 'Contact', customerPicks:"No", sampleData:"", selected:true, customFieldName:null},
                {name: 'ID',  object: 'Contact', customerPicks:"No", sampleData:"", selected:false, customFieldName:null},
                {name: 'Contact Owner',  object: 'Contact', customerPicks:"Required", sampleData:"", selected:true, customFieldName:null},
                {name: 'Custom field',  object: 'Contact', customerPicks:"Yes", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Contact', customerPicks:"No", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Contact', customerPicks:"No", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Contact', customerPicks:"No", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Contact', customerPicks:"Yes", sampleData:"", selected:false, customFieldName:null},
                {name: 'Company Name',  object: 'Company', customerPicks:"No", sampleData:"", selected:false, customFieldName:null},
                {name: 'Phone Number',  object: 'Company', customerPicks:"Required", sampleData:"", selected:true, customFieldName:null},
                {name: 'Website URL',  object: 'Company', customerPicks:"No", sampleData:"", selected:false, customFieldName:null},
                {name: 'Address 1',  object: 'Company', customerPicks:"Required", sampleData:"", selected:true, customFieldName:null},
                {name: 'Address 2',  object: 'Company', customerPicks:"No", sampleData:"", selected:true, customFieldName:null},
                {name: 'City',  object: 'Company', customerPicks:"Required", sampleData:"", selected:true, customFieldName:null},
                {name: 'State',  object: 'Company', customerPicks:"Yes", sampleData:"", selected:false, customFieldName:null},
                {name: 'Postal Code',  object: 'Company', customerPicks:"No", sampleData:"", selected:true, customFieldName:null},
                {name: 'Create Date',  object: 'Company', customerPicks:"Yes", sampleData:"", selected:true, customFieldName:null},
                {name: 'ID',  object: 'Company', customerPicks:"Yes", sampleData:"", selected:true, customFieldName:null},
                {name: 'Company Owner',  object: 'Company', customerPicks:"Required", sampleData:"", selected:true, customFieldName:null},
                {name: 'Custom field',  object: 'Company', customerPicks:"No", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Company', customerPicks:"No", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Company', customerPicks:"No", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Company', customerPicks:"No", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Company', customerPicks:"No", sampleData:"", selected:false, customFieldName:null},
                {name: 'Deal Name',  object: 'Deal', customerPicks:"Yes", sampleData:"", selected:true, customFieldName:null},
                {name: 'Close Date',  object: 'Deal', customerPicks:"Required", sampleData:"", selected:true, customFieldName:null},
                {name: 'Stage',  object: 'Deal', customerPicks:"Required", sampleData:"", selected:true, customFieldName:null},
                {name: 'Pipeline',  object: 'Deal', customerPicks:"No", sampleData:"", selected:true, customFieldName:null},
                {name: 'Deal Amount',  object: 'Deal', customerPicks:"Required", sampleData:"", selected:true, customFieldName:null},
                {name: 'Create date',  object: 'Deal', customerPicks:"Required", sampleData:"", selected:true, customFieldName:null},
                {name: 'ID',  object: 'Deal', customerPicks:"No", sampleData:"", selected:true, customFieldName:null},
                {name: 'Custom field',  object: 'Deal', customerPicks:"No", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Deal', customerPicks:"Yes", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Deal', customerPicks:"Yes", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Deal', customerPicks:"No", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Deal', customerPicks:"No", sampleData:"", selected:false, customFieldName:null},
                {name: 'Line item name',  object: 'Products', customerPicks:"Yes", sampleData:"", selected:true, customFieldName:null},
                {name: 'Line item description',  object: 'Products', customerPicks:"Yes", sampleData:"", selected:false, customFieldName:null},
                {name: 'Line item unit price',  object: 'Products', customerPicks:"No", sampleData:"", selected:true, customFieldName:null},
                {name: 'Line item unit cost',  object: 'Products', customerPicks:"Required", sampleData:"", selected:true, customFieldName:null},
                {name: 'Line item quantity',  object: 'Products', customerPicks:"No", sampleData:"", selected:true, customFieldName:null},
                {name: 'Custom field',  object: 'Products', customerPicks:"No", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Products', customerPicks:"Yes", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Products', customerPicks:"Yes", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Products', customerPicks:"Yes", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Products', customerPicks:"Yes", sampleData:"", selected:false, customFieldName:null},
                {name: 'Fullfillment ID',  object: 'Deal/Fullfilment', customerPicks:"Required", sampleData:"", selected:true, customFieldName:null},
                {name: 'Tracking ID',  object: 'Deal/Fullfilment', customerPicks:"No", sampleData:"", selected:false, customFieldName:null},
                {name: 'Tracking Carrier',  object: 'Deal/Fullfilment', customerPicks:"No", sampleData:"", selected:true, customFieldName:null},
                {name: 'Custom field',  object: 'Deal/Fullfilment', customerPicks:"No", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Deal/Fullfilment', customerPicks:"Yes", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Deal/Fullfilment', customerPicks:"Yes", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Deal/Fullfilment', customerPicks:"Yes", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Deal/Fullfilment', customerPicks:"Yes", sampleData:"", selected:false, customFieldName:null},
                {name: 'Invoice ID',  object: 'Deal/Fullfilment', customerPicks:"No", sampleData:"", selected:true, customFieldName:null},
                {name: 'Payment status',  object: 'Deal/Invoice', customerPicks:"Yes", sampleData:"", selected:true, customFieldName:null},
                {name: 'Invoice create date',  object: 'Deal/Invoice', customerPicks:"No", sampleData:"", selected:true, customFieldName:null},
                {name: 'Current Balance',  object: 'Deal/Invoice', customerPicks:"No", sampleData:"", selected:true, customFieldName:null},
                {name: 'Custom field',  object: 'Deal/Invoice', customerPicks:"No", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Deal/Invoice', customerPicks:"Yes", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Deal/Invoice', customerPicks:"Yes", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Deal/Invoice', customerPicks:"Yes", sampleData:"", selected:false, customFieldName:null},
                {name: 'Custom field',  object: 'Deal/Invoice', customerPicks:"Yes", sampleData:"", selected:false, customFieldName:null},
            ],
            fieldsSearch: '',
            fieldsFiter: {},
            fieldsOrderByKeys: [],
            fieldsSortDesc: false, 
            fieldsPage: 1, 
            fieldsItemsPerPage: -1, 
            fieldsSortBy: 'object',
            fieldsSortByItemKeys: [
                {label: 'Order By Name', value:'name'},
                {label: 'Order By Type', value: 'object'},
            ],
            fieldsViewByObject: null,
            fieldsViewByObjectSelect: [
                { id:"0" , value: "Contact", label: "Contact", count: null},
                { id:"1" , value: "Company", label: "Company", count: null},
                { id:"2" , value: "Deal", label: "Deal", count: null},
                { id:"3" , value: "Products", label: "Products", count: null},
                { id:"4" , value: "Deal/Fullfilment", label: "Deal/Fullfilment", count: null},
                { id:"5" , value: "Deal/Invoice", label: "Deal/Invoice", count: null},
                { id:"6" , value: null, label: "All", count: null}
            ],
            //preselected
            preFieldsSearch: '',
            preFieldsFiter: {},
            preFieldsOrderByKeys: [],
            preFieldsSortDesc: false, 
            preFieldsPage: 1, 
            preFieldsItemsPerPage: -1, 
            preFieldsSortBy: 'object',
            preFieldsSortByItemKeys: [
                {label: 'Order By Name', value:'name'},
                {label: 'Order By Type', value: 'object'},
            ],
            preFieldsViewByObject: null,
            preFieldsViewByObjectSelect: [
                { id:"0" , value: "Contact", label: "Contact", count: null},
                { id:"1" , value: "Company", label: "Company", count: null},
                { id:"2" , value: "Deal", label: "Deal", count: null},
                { id:"3" , value: "Products", label: "Products", count: null},
                { id:"4" , value: "Deal/Fullfilment", label: "Deal/Fullfilment", count: null},
                { id:"5" , value: "Deal/Invoice", label: "Deal/Invoice", count: null},
                { id:"6" , value: null, label: "All", count: null}
            ],
            objects: [
                {name: 'Contacts', value:'Contact',color:"primary lighten-4", icon:"mdi-account-check"},
                {name: 'Company' , value:'Company',color:"warning lighten-4", icon:"mdi-domain"},
                {name: 'Deal' , value:'Deal',color:"iq_lightblue lighten-5", icon:"mdi-handshake"},
                {name: 'Products' , value:'Products',color:"iq_aquagreen lighten-4", icon:"mdi-cube"},
                {name: 'Deal/Fullfilment' , value:'Deal/Fullfilment',color:"success lighten-4", icon:"mdi-cube-send"},
                {name: 'Deal/Invoice', value:'Deal/Invoice',color:"iq_desaturatedblue lighten-4", icon:"mdi-receipt"},
            ],
        }
    },
    computed: {
        preselectedFields(){
             var array = this.fields.filter( x => x.customerPicks === 'Required');
            this.status[6].value = array;
             array = array.filter((i) => {
                    return !this.preFieldsViewByObject || (i.object === this.preFieldsViewByObject);
            });
            return array;
        },
        selectableFields(){
            var array = this.fields.filter( x => x.customerPicks !== 'Required');
            this.status[5].value = array;
            array = array.filter((i) => {
                    return !this.fieldsViewByObject || (i.object === this.fieldsViewByObject);
            });
             return array;
        },
        currentRouteName() {
            return this.$route.name;
        },
    }, 
    methods: {
        getParentObject(name){
            var result = this.objects.find(x => x.value === name);
            return result;
        },
        toggleSelectedField(object){
            if(object.customFieldName !==  null){
                object.selected = true;
            }
            else{
                object.selected = false;
            }
        }
    },
    
}
</script>
<style lang="scss">
    .border-right{
        border-right: 1px solid rgba(0, 0, 0, 0.12);
    }
</style>