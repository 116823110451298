import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"d-flex align-center justify-center"},[_c(VCard,{attrs:{"elevation":"10","min-width":"350px","max-width":"450px","rounded":"","outlined":""}},[_c(VCardTitle,{staticClass:"primary iq_light--text"},[_vm._v("Registration")]),_c(VDivider),_c(VCardText,{staticClass:"mt-6"},[_c(VTextField,{attrs:{"outlined":"","dense":"","label":"Company Name","rules":[_vm.rules.required]},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}}),_c(VTextField,{attrs:{"outlined":"","dense":"","label":"Domain","rules":[_vm.rules.required]},model:{value:(_vm.companyUrl),callback:function ($$v) {_vm.companyUrl=$$v},expression:"companyUrl"}})],1),_c(VDivider),_c(VCardActions,{staticClass:"d-flex justify-space-between"},[_c(VBtn,{attrs:{"text":""}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"color":"primary","outlined":"","disabled":!_vm.companyName || !_vm.companyUrl,"loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.submitRegistration()}}},[_vm._v("Register")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }