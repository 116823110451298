import Api from "@/services/api";
import Vue from 'vue';
export default {
    namespaced: true,
    state: {
        currentCompany: {},
        currentCompanyApps: {},
        currentCompanyAutomation: {},
        currentCompanyBusinessHours: {},
        billingDetails: {
            billingDetails: {
                subType: null
            }
        },
        billingInvoices: [],
        currentCompanyPhone: {},
        currentContactProperties: {},
        currentContactPropertySelected: {},
        currentConversations:{},
        hubSpotMessages:[],
        searchResults: {},
        selectedPlanName: "",
        newPlan: {
            price: 0
        },
        calls: []
    },
    mutations: {
        SET_CURRENT_COMPANY(state, currentCompany){
            state.currentCompany = currentCompany;
        },
        SET_CURRENT_COMPANY_AUTOMATION(state, currentCompanyAutomation){
            state.currentCompanyAutomation = currentCompanyAutomation;
        },
        SET_CURRENT_COMPANY_BUSINESS_HOURS(state,currentCompanyBusinessHours){
            state.currentCompanyBusinessHours = currentCompanyBusinessHours;
        },
        SET_BILLING_DETAILS(state,billingDetails){
            state.billingDetails = billingDetails;
        },
        SET_BILLING_INVOICES(state,billingInvoices){
            state.billingInvoices = billingInvoices;
        },
        SET_CURRENT_COMPANY_PHONE(state,currentCompanyPhone){
            state.currentCompanyPhone = currentCompanyPhone;
        },
        SET_CURRENT_CONTACT_PROPERTIES(state,currentContactProperties){
            state.currentContactProperties = currentContactProperties;
        },
        SET_CURRENT_CONTACT_PROPERTY_SELECTED(state,currentContactPropertySelected){
            state.currentContactPropertySelected = currentContactPropertySelected;
        },
        SET_CURRENT_CONVERSATIONS(state,currentConversations){
            state.currentConversations = currentConversations;
        },
        SET_CURRENT_HS_MESSAGES(state,hsMessages) {
            state.hubSpotMessages = hsMessages;
        },
        SET_CURRENT_MESSAGE_SEARCH(state, results) {
            state.searchResults = results;
        },
        SET_SELECTED_PLAN_NAME(state,planName){
            state.selectedPlanName = planName;
        },
        SET_NEW_PLAN(state,newPlan){
            state.newPlan = newPlan;
        },
        SET_CURRENT_CALLS(state, callList) {
            state.calls = callList;
        },
        SET_CURRENT_COMPANY_APPS(state,apps){
            state.currentCompanyApps = apps;
        }
    },
    actions: {
        async getCompanyByCompanyId({commit}, companyId){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Company/${companyId}`,config)
            .catch(err => {
                console.error(err.message);
            });
            commit('SET_CURRENT_COMPANY',response.data);
            return response.data;
        },
        async updateCompany({state}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            await Api().put(`/Web/Company/${state.currentCompany.id}`,state.currentCompany,config).catch(() => {
                // Catch Error and return false to caller
                return false;
            });
            return true;
        },
        async getCompanyAutomationData({commit},companyId){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Company/Automation/${companyId}`,config)
            .catch(err => {
                console.error(err.message);
            });
            commit('SET_CURRENT_COMPANY_AUTOMATION',response.data);
            return response.data;
        },
        async updateCompanyAutomationFeature({state,dispatch},feature){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            await Api().put(`/Web/Company/Automation/Update`,{
                CompanyId: state.currentCompany.id,
                FeatureId: feature.id,
                Status: feature.status, 
                Value: feature.value
            },config).catch(() =>{
                return false;
            });
            await dispatch('getCompanyAutomationData',state.currentCompany.id);
            return true;
        },
        async getCompanyBusinessHours({commit},companyId){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Company/BusinessHours/${companyId}`,config)
            .catch((err) => {
                console.error(err.message);
            });
            commit('SET_CURRENT_COMPANY_BUSINESS_HOURS',response.data);
            return response.data;
        },
        async updateCompanyBusinessHours({},businessHourUpdate){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            await Api().put(`/Web/Company/BusinessHours/Update`,businessHourUpdate,config)
            .catch(() => {
                return false;
            });
            return true;
        },
        async getBillingDetailsByCompanyId({commit},companyId){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Billing/GetBillingDetailsByCompanyId/${companyId}`,config)
            .catch(err => {
                console.error(err.message);
            });
            commit('SET_BILLING_DETAILS',response.data);
            commit('SET_BILLING_INVOICES',response.data.billingDetails.invoices);
            return response.data;
        },
        setCurrentCompanyPhone({commit},companyPhone){
            commit('SET_CURRENT_COMPANY_PHONE',companyPhone);
        },
        async getContactProperties({commit},id){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Company/HubSpot/GetContactProperties/${id}`,config)
            .catch(err => {
                console.error(err.message);
            });
            commit('SET_CURRENT_CONTACT_PROPERTIES',response.data);
            return response.data;
        },
        async getContactPropertySelected({commit},{id: id, contactId: contactId, propertyName: propertyName}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Company/${id}/GetHubSpotContactPropertyValue/${contactId}/${propertyName}`,config)
            .catch(err => {
                console.error(err.message);
            });
            commit('SET_CURRENT_CONTACT_PROPERTY_SELECTED',response.data);
            return response.data;
        },
        async upgradeCompanyPlan({state},newPlan){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().put(`/Web/Company/${state.currentCompany.id}/UpgradePaidAccount`,newPlan.recurlyPlanId,config).catch(() => {
                // Catch Error and return false to caller
                return false;
            });
            if(response.data.hasError){
                return false;
            }else{
                return true;
            }
        },
        setCurrentCompany({commit},company){
            commit('SET_CURRENT_COMPANY',company);
        },
        setCurrentCompanyApps({commit},apps){
            commit('SET_CURRENT_COMPANY_APPS',apps);
        },
        async getConversations({commit},{id: id, skip: skip, top: top, filter: filter, messageHubSpot: messageHubSpot, messageName: messageName, idUser:idUser}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let response = null
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            if(messageHubSpot === ""){
                response = await Api().get(`/Web/Company/${id}/Conversations/Fast?top=${top}&skip=${skip}&filter=${filter}&messageHubSpot=${messageHubSpot}&messageName=${messageName}&idUser=${idUser}`,config)
                .catch(err => {
                    console.error(err.message);
                });
            }else{
                response = await Api().get(`/Web/Company/${id}/GetConversationsSearch?top=${top}&skip=${skip}&filter=${filter}&messageHubSpot=${messageHubSpot}&messageName=${messageName}&idUser=${idUser}`,config)
               .catch(err => {
                    console.error(err.message);
                });
            }
            commit('SET_CURRENT_CONVERSATIONS',response.data);
            return response.data;
        },
        async getCalls({commit}, {id: id}) {
            //true to get all calls : false to just the missed (parameter need it in API)
            let areCompleted = true;
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Company/Calls/${id}?areCompleted=${areCompleted}`,config)
            .catch(err => {
                console.error(err.message);
            });
            commit('SET_CURRENT_CALLS', response.data);
            return response.data;
        },
        async searchMessages({commit}, {id: id, searchText: searchText}) {
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Company/${id}/Messages/Search?search=${searchText}`,config)
            .catch(err => {
                console.error(err.message);
            })

            commit('SET_CURRENT_MESSAGE_SEARCH', response.data);
            return response.data;

        },
        async getHubSpotMessages({commit},{id: id}) {
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            try{
                let response = await Api().get(`/Web/Company/${id}/HubSpotMessages`,config)
                .catch(err => {
                    console.error(err.message);
                });
                commit('SET_CURRENT_HS_MESSAGES',response.data);
                return response.data;
            }catch(err){
                console.error(err.message);
                return null;
            }
        },
        async markCompanyAsSpam({},companyId){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            await Api().post(`/Web/Company/${companyId}/MarkAsSpam`,{},config)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            return true;
        },
        async deleteCompanyById({},companyId){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            await Api().delete(`/Web/Company/${companyId}/Delete`,config)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            return true;
        },
        setSelectedPlanName({commit},selectedPlanName){
            commit('SET_SELECTED_PLAN_NAME',selectedPlanName);
        },
        setNewPlan({commit},newPlan){
            commit('SET_NEW_PLAN',newPlan);
        },
        async upgradeTrialUser({state},{token: token, trialUpgradeBilling: trialUpgradeBilling}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let data = {
                billingDetailsId: state.billingDetails.billingDetails.id,
                firstName: trialUpgradeBilling.firstName, 
                lastName: trialUpgradeBilling.lastName, 
                email: Vue.prototype.$auth.user.email,
                address: trialUpgradeBilling.address,
                address2: trialUpgradeBilling.address2,
                city: trialUpgradeBilling.city, 
                state: trialUpgradeBilling.state,
                zip: trialUpgradeBilling.zip,
                token: token,
                recurlySubscriptionId: state.newPlan.recurlyPlanId
            };
            let response = await Api().post(`/Web/Company/${state.currentCompany.id}/UpgradeTrial`,data,config).catch(() => {
                // Catch Error and return false to caller
                return false;
            });
            if(response.data.hasError){
                return false;
            }else{
                return true;
            }
        },
        async getCompanyUsers({}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().get(`/Web/Company/GetCompanyUsers`,config)
            .catch((err) => {
                console.error(err.message);
            });
            return response.data;
        },
        async addNewUser({},user){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().get(`/Web/Company/AddUser`,user,config)
            .catch((err) => {
                console.error(err.message);
            });
            return response.data;
        },
        async getCompanyByHubspotId({commit}, id){
            let response = await Api().get(`/Web/Company/GetCompanyByHubspotId/${id}`)
            .catch(err => {
                console.error(err.message);
            });
            commit('SET_CURRENT_COMPANY',response.data);
        },
        async getContacts({dispatch},{companyId: companyId, archive:archive, skip:skip, top:top, IsSortedAscending:IsSortedAscending, currentCol:currentCol, columnName:columnName}){
            
            let response = await Api().get(`/Web/Company/Contacts/${companyId}?skip=${skip}&top=${top}&showArchive=${archive}&IsSortedAscending=${false}&currentCol=${currentCol}&columnName=${columnName}`)

            .catch(err => {
                console.error(err.message);
            });

            return response;
        },
        async getContactsFast({dispatch},{companyId: companyId, archive:archive, skip:skip, top:top, IsSortedAscending:IsSortedAscending, currentCol:currentCol, columnName:columnName, search:search, generalSearch:generalSearch}){

            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            
            let response = await Api().put(`/Web/Company/ContactsFast/${companyId}/${archive}?skip=${skip}&top=${top}&IsSortedAscending=${IsSortedAscending}&currentCol=${currentCol}&columnName=${columnName}&generalSearch=${generalSearch}`, search, config)
            .catch(err => {
                console.error(err.message);
            });
            
            return response;
        },

        async getContactsCount({dispatch},{companyId: companyId,archive:archive}){
            
            let response = await Api().get(`/Web/Company/ContactsCount/${companyId}/${archive}`)
            .catch(err => {
                console.error(err.message);
            });
            
            return response;
        },

        async uploadFileContacts({dispatch},{file: file, companyId: companyId, userId: userId}){
            let result = null;

            let templateObj = {
                "CompanyId": companyId,
                "userId": userId
            };

            const formData = new FormData();
            formData.append('file',file);
            formData.append('jsonData',JSON.stringify(templateObj));

            result = await Api().post(`/Web/Company/UploadFileContacts`,formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).catch(() => {
                return {
                    'result': false
                };
            });

            return result;
        },
        async getUsersWithPhoneNumbers({}, id){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().get(`/Web/Company/GetUsersWithPhoneNumbers/${id}`,config)
            .catch(err => {
                console.error(err.message);
            });
            return response.data;
        },
        async updateUserCompany({}, userModel){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let model ={
                 Email : userModel.email,
                 Role : userModel.roleID,
                 FirstName : userModel.firstName,
                 LastName : userModel.lastName,
                 userId: userModel.id
            }

            let response = await Api().post(`/Web/Company/updateUserCompany`,model,config)
            .catch(err => {
                console.error(err.message);
            });
            return response.data;
        },
        async deleteUserCompany({}, id){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            await Api().delete(`/Web/Company/DeleteUserCompany/${id}`,config)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            return true;
        },
        async getPhoneNumbers({}, {companyId: companyId}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().get(`/Web/Company/GetPhoneNumbers/${companyId}`,config)
            .catch(err => {
                console.error(err.message);
            });
            return response;
        },
        async addPhone({}, {companyPhone: companyPhone, areaCode: areacode}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().post(`/Web/Company/AddPhone/${areacode}`,companyPhone,config)
            .catch(err => {
                console.error(err.message);
            });
            return response;
        },
        async getAgencyCompanies({}, id){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().get(`/Web/Company/${id}/GetAgencyCompanies`,config)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            return response.data;
        },
        async deleteCompanyPhone({}, {phoneNumber: phoneNumber,companyId:companyId}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().delete(`/Web/Company/DeletePhone/${phoneNumber}/${companyId}`,config)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            return true;
        },
        async updateCompanyPhone({}, {companyPhone: companyPhone}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().put(`/Web/Company/UpdatePhone`,companyPhone,config)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            return response.data;
        },
        addCompanyApp({state, commit},app){
            let currentCompanyApps = state.companyApps;
            switch (app){
                case "MessageIQ":
                    currentCompanyApps.messageIq = true;
                    break;
                case "Integrations":
                    currentCompanyApps.integrations = true;
                    break;
                case "PriceIQ":
                    currentCompanyApps.priceIq = true;
                    break;
                case "StockIQ":
                    currentCompanyApps.stockIq = true;
                    break;
                case "StatusIQ":
                    currentCompanyApps.statusIq = true;
                    break;
                default:
                    break;
            }
            commit('SET_CURRENT_COMPANY_APPS',currentCompanyApps);
        },
        async inviteUser({},request){
            let result = true;
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().post(`/Web/User/InviteUser`,request,config)
            .catch(err => {
                console.error(err.message);
                result = false;
            });
            return result;
        },
        async resendInvite({},request){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().post(`/Web/User/ResendInvite`,request,config)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            return true;
        }, 
        async verifyCompany({},companyId){
            await Api().put(`/Web/Company/${companyId}/Verify`).catch(err => {
                console.error(err.message);
                return false;
            });
            return true;
        },
        async updateHSUserId({},{hsUserId:hsUserId}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            await Api().put(`/Web/User/UpdateHubSpotUser/${hsUserId}`,{},config).catch(err => {
                console.error(err.message);
                return false;
            });
            return true;
        },
        async enableSms({},companyId){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            await Api().post(`/Web/Company/EnableSms/${companyId}`,{},config)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            return true;
        },
        async canSendSms({},companyId){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };

            let response = await Api().get(`/Web/Company/CanSendSms/${companyId}`,config)
            .catch(err => {
                console.error(err.message);
                return null;
            });
            return response.data;
        },
        async deleteCompanyQueueById({},companyId){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            await Api().delete(`/Web/Admin/DeleteQueueByCompany/${companyId}`,config)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            return true;
        },
    },
    getters: {
        getCurrentCompany: state => { return state.currentCompany },
        getCurrentCompanyAutomation: state => { return state.currentCompanyAutomation },
        getCurrentCompanyBusinessHours: state => { return state.currentCompanyBusinessHours },
        getBillingDetails: state => { return state.billingDetails },
        getCurrentCompanyPhone: state => { return state.currentCompanyPhone},
        getCurrentContactProperties: state => { return state.currentContactProperties},
        getcurrentContactPropertySelected: state => { return state.currentContactPropertySelected},
        getBillingInvoices: state => { return state.billingInvoices},
        getCurrentConversations: state => { return state.currentConversations},
        getCurrentHSMessages: state => { return state.hubSpotMessages},
        getLatestSearchResult: state => { return state.searchResults },
        getSelectedPlanName: state => { return state.selectedPlanName },
        getNewPlan: state => { return state.newPlan },
        getCurrentCompanyApps: state => { return state.currentCompanyApps }
    }
};