import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"close-on-content-click":false,"nudge-width":200,"max-width":"350px","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"pa-0 d-inline popover-trigger",attrs:{"elevation":"0","color":_vm.hintColor,"x-small":"","text":"","fab":"","aria-label":'Hint.' + _vm.title + ',' + _vm.content}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.icon))])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,[_c(VCardTitle,{attrs:{"tabindex":"1","aria-label":_vm.title}},[_c(VIcon,{attrs:{"color":_vm.hintColor,"large":""}},[_vm._v("mdi-help-circle")]),_vm._v(" "+_vm._s(_vm.title)+" ")],1),_c(VDivider),_c(VCardText,{attrs:{"tabindex":"2","aria-label":_vm.content}},[_vm._v(" "+_vm._s(_vm.content)+" ")]),(_vm.html)?_c(VCardText,{staticClass:"pb-0",domProps:{"innerHTML":_vm._s(_vm.html)}}):_vm._e(),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Ok ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }