import Api from "@/services/api";
import Vue from "vue";
export default {
    namespaced: true,
    state: {
        companyPriceLists: [],
        hubSpotProducts: []
    },
    mutations: {
        SET_COMPANY_PRICE_LISTS(state, companyPriceLists){
            state.companyPriceLists = companyPriceLists;
        },
        SET_HUBSPOT_PRODUCTS(state, hubSpotProducts){
            state.hubSpotProducts = hubSpotProducts;
        }
    },
    actions: {
        async getCompanyPriceLists({commit}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/SmartRamp/PriceIQ/GetCompanyPriceLists`,config)
                .catch(err => {
                    console.error(err.message);
                    return false;
                });
            commit('SET_COMPANY_PRICE_LISTS', response.data);
            return response.data;
        },
        async getHubSpotProducts({commit}) {
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/SmartRamp/PriceIQ/GetCompanyProducts`,config)
                .catch(err => {
                    console.error(err.message);
                    return false;
                });
            commit('SET_HUBSPOT_PRODUCTS', response.data);
            return response.data;
        },
        async createPriceList({}, {name: name, discount: discount, allProducts: allProducts, products: products}) {
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { 
                    "Authorization": `Bearer ${token}`, 
                    "Content-Type": "application/json"
                }
            };
            let response = await Api().post(`/Web/SmartRamp/PriceIQ/PriceList/Create`, {
                Name: name,
                Discount: discount,
                AllProducts: allProducts,
                Products: products
            },config)
                .catch(err => {
                    console.error(err.message);
                    return false;
                });
            return true;
        },
        async updatePriceListInfo({}, {priceListId: priceListId, name: name, discount: discount, allProducts: allProducts, products: products}) {
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { 
                    "Authorization": `Bearer ${token}`, 
                    "Content-Type": "application/json"
                }
            };
            let response = await Api().put(`/Web/SmartRamp/PriceIQ/PriceList/${priceListId}`, {
                Name: name,
                Discount: discount,
                AllProducts: allProducts,
                Products: products
            },config)
                .catch(err => {
                    console.error(err.message);
                    return false;
                });
            return true;
        },
        async getPriceListById({commit}, {priceListId: priceListId}){
            let response = await Api().get(`/Web/SmartRamp/PriceIQ/PriceList/${priceListId}`)
                .catch(err => {
                    console.error(err.message);
                    return false;
                });
            return response.data;
        },
        async getCompanyPriceListsById({commit}, {portalId: portalId}){
            let response = await Api().get(`/Web/SmartRamp/PriceIQ/HubSpot/GetCompanyPriceLists/${portalId}`)
                .catch(err => {
                    console.error(err.message);
                    return false;
                });
            return response.data;
        },
        async calculatePriceList({commit}, {priceListId: priceListId, portalId: portalId, dealId: dealId}){
            let response = await Api().get(`/Web/SmartRamp/PriceIQ/PriceList/Calculate/${priceListId}/${portalId}/${dealId}`)
                .catch(err => {
                    console.error(err.message);
                    return false;
                });
            return response.data;
        },
        async updatePriceList({commit}, {priceListId: priceListId, portalId: portalId, dealId: dealId, userId: userId, itemsList: itemsList}){
            let config = {
                headers: { 
                    "Content-Type": "application/json"
                }
            };
            let response = await Api().post(`/Web/SmartRamp/PriceIQ/PriceList/UpdateDeal/${priceListId}/${portalId}/${dealId}/${userId}`, itemsList, config)
                .catch(err => {
                    console.error(err.message);
                    return false;
                });
            return true;
        },
        async revertDiscount({commit}, {portalId: portalId, dealId: dealId, userId: userId}){
            let response = await Api().post(`/Web/SmartRamp/PriceIQ/PriceList/RevertDiscount/${portalId}/${dealId}/${userId}`)
                .catch(err => {
                    console.error(err.message);
                    return false;
                });
            return true;
        },

        async CheckForDiscount({commit}, { portalId: portalId, dealId: dealId}){
            let response = await Api().get(`/Web/SmartRamp/PriceIQ/PriceList/CheckForDiscount/${portalId}/${dealId}`)
                .catch(err => {
                    console.error(err.message);
                    return true;
                });
            return response.data;
        },
        async CheckForProducts({commit}, { portalId: portalId, dealId: dealId}){
            let response = await Api().get(`/Web/SmartRamp/PriceIQ/PriceList/CheckForProducts/${portalId}/${dealId}`)
                .catch(err => {
                    console.error(err.message);
                    return false;
                });
            return response.data;
        },
    },
    getters: {
        getCompanyPriceLists: state => { return state.companyPriceLists },
        getHubSpotProducts: state => { return state.hubSpotProducts }
    }
};