<template>
<v-container>
    <v-row class="d-flex flex-row align-center mb-2">
        <v-col cols="5" class="text-center d-flex flex-row align-center justify-center"> 
            <v-img 
            position="top left"
            :src="require('@/assets/images/integrations/' + systemA.src )"
            contain
            max-height="60px"
            max-width="60px"
            ></v-img>  
            <h2 class="ml-2">{{systemA.title}} - Transaction</h2>      
        </v-col>
        <v-col cols="2" class="text-center d-flex flex-row align-center justify-center"> <v-icon x-large>mdi-arrow-right</v-icon></v-col>
        <v-col cols="5" class="text-center d-flex flex-row align-center justify-center"> 
            <v-img 
            position="top left"
            :src="require('@/assets/images/integrations/' + systemB.src )"
            contain
            max-height="60px"
            max-width="60px"
            ></v-img>
            <h2 class="ml-2">{{systemB.title}} - Transaction</h2>      

         </v-col>

    </v-row>
    <v-card outlined>
        <v-card-title>Creating and Updating Transactions</v-card-title>
        <v-card-text>
            <v-row class="d-flex flex-row align-center">
                <v-col cols="5"> 
                        <v-select
                        :items="cloverCreateOptions"
                        v-model="cloverCreateSelected"
                        disabled
                        item-text="name"
                        item-value="value"
                        :label="systemA.title + ' Action'"
                        ></v-select>
                 </v-col>
                <v-col cols="2" class="text-center"> <v-icon>mdi-arrow-right</v-icon> </v-col>
                <v-col cols="5"> 
                    <v-select
                        :items="hubspotCreateOptions"
                        v-model="cloverActions.transactionCreate"
                        item-text="name"
                        item-value="value"
                        :label="systemB.title + ' Action'"
                    ></v-select>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
    <v-card outlined>
        <v-card-title>Deleting Transactions</v-card-title>
        <v-card-text>
            <v-row class="d-flex flex-row align-center">
                <v-col cols="5"> 
                        <v-select
                        :items="cloverDeleteOptions"
                        v-model="cloverDelecteSelected"
                        disabled
                        item-text="name"
                        item-value="value"
                        :label="systemA.title + ' Action'"
                        ></v-select>
                 </v-col>
                <v-col cols="2" class="text-center"> <v-icon>mdi-arrow-right</v-icon> </v-col>
                <v-col cols="5"> 
                    <v-select
                        :items="hubspotDelecteOptions"
                        v-model="cloverActions.transactionDelete"
                        item-text="name"
                        item-value="value"
                        :label="systemB.title + ' Action'"
                    ></v-select>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>

</v-container>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: "Transactions",
    props: {
        systemA: null,
        systemB: null,
    },
    computed: {
        ...mapGetters({
            cloverActions: 'clover/getCloverCompanyActions'
        })
    },
    data() {
        return{
            cloverCreateSelected: 0,
            cloverCreateOptions: [
                {name: 'A Transaction is Created or updated', value:0}
            ],
            hubspotCreateSelected: null,
            hubspotCreateOptions: [
                {name: 'Create or update the Transaction', value:'9d5c8fdb-86c6-417d-a3a1-d837830af094'},
                {name: 'Do Nothing', value:'5434de27-e044-4353-a49e-3d55528d7890'}
            ],
            cloverDelecteSelected: 0,
            cloverDeleteOptions: [
                {name: 'A Transaction is deleted', value:0}
            ],
            hubspotDeleteSelected: null,
            hubspotDelecteOptions: [
                {name: 'Delete the Transaction', value:'9d5c8fdb-86c6-417d-a3a1-d837830af094'},
                {name: 'Do Nothing', value:'5434de27-e044-4353-a49e-3d55528d7890'}
            ],
            
        }
    }
}
</script>