<template>
    <h1>Upgrading Exising Message IQ Account....</h1>
</template>
<script >
export default{
    async mounted(){
        this.$auth.auth0ClientPromise.then(async () => {
            let existingUserId = this.$route.query.userId;
            if(typeof existingUserId !== 'undefined'){
                let result = await this.$store.dispatch('upgradeUserAccount',existingUserId);
                if(result){
                    window.location.href = '/';
                }
            }else{
                window.location.href = '/';
            }
        });
    }
}
</script>
