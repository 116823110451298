<template>
   <div>
       <v-overlay color="iq_dark" :value="loading">
            <v-icon color="iq_light" x-large>mdi-loading mdi-spin</v-icon>
            <span class="iq_light--text font-weight-bold "> Loading </span>
        </v-overlay>
        <div class="container">
            <v-btn
                v-if="!loading"
                class="vertical-center"
                color="primary"
                @click="goToCheckoutLink()"> 
                Checkout with Stripe 
            </v-btn>
        </div>  
   </div>
</template>
<style scoped>
    .vertical-center {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    .container {
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
<script>

export default{
    data(){
        return {
            hubSpotPortalId: null,
            hubSpotDealId: null,
            hubSpotUserId: null,
            stripeCheckoutUrl: null,
            loading: true
        }
    },
    async mounted(){
        this.hubSpotPortalId = this.$route.query.c;
        this.hubSpotDealId = this.$route.query.co;
        this.hubSpotUserId = this.$route.query.u;

        this.stripeCheckoutUrl = await this.$store.dispatch('stripeCheckout/getStripeCheckoutUrl',{portalId: this.hubSpotPortalId,dealId: this.hubSpotDealId});
        this.loading = false;
    },
    methods: {
        goToCheckoutLink(){
            window.open(this.stripeCheckoutUrl,'_blank');
        }
    }
}
</script>
