import Api from "@/services/api";
import Vue from "vue";
export default {
    namespaced: true,
    state: {

    },
    mutations: {
        
    },
    actions: {
        async getRecipeCategories({}){
            // let token = await Vue.prototype.$auth.getTokenSilently();
            // let config = {
            //     headers: { Authorization: `Bearer ${token}` }
            // };
            let response = await Api().get(`/Web/SmartRamp/Recipes/Categories`,{})
            return response.data;
        },
        async getRecipes({}){
            // let token = await Vue.prototype.$auth.getTokenSilently();
            // let config = {
            //     headers: { Authorization: `Bearer ${token}` }
            // };
            let response = await Api().get(`/Web/SmartRamp/Recipes`,{})
            return response.data;
        },
        async getRecipeById({}, recipeId){
            // let token = await Vue.prototype.$auth.getTokenSilently();
            // let config = {
            //     headers: { Authorization: `Bearer ${token}` }
            // };
            let response = await Api().get(`/Web/SmartRamp/Recipes/${recipeId}`,{})
            return response.data;
        },
        async getRecipes({}){
            // let token = await Vue.prototype.$auth.getTokenSilently();
            // let config = {
            //     headers: { Authorization: `Bearer ${token}` }
            // };
            let response = await Api().get(`/Web/SmartRamp/Recipes`,{})
            return response.data;
        },
        async getRecipeById({}, recipeId){
            // let token = await Vue.prototype.$auth.getTokenSilently();
            // let config = {
            //     headers: { Authorization: `Bearer ${token}` }
            // };
            let response = await Api().get(`/Web/SmartRamp/Recipes/${recipeId}`,{})
            return response.data;
        }
    },
    getters: {
        
    }
};