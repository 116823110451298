import { getInstance } from './index';
import Swal from 'sweetalert2';

export const authGuardIframe = (to, from, next) => {

  const authService = getInstance();

  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      return next();
    }

    // Show user message to login
    Swal.fire({
        title: 'Please Login',
        text: 'Please login by clicking the button below.',
        confirmButtonText: "Log In",
        confirmButtonColor: "#ED0D87"
    }).then(() => {
        let url = window.location.protocol + "//" + window.location.host
        window.open(url, '_blank');
        Swal.fire({
            title: 'Refresh Page',
            text: "After logging in, please click the refresh button below.",
            confirmButtonText: "Refresh",
            confirmButtonColor: "#ED0D87"
        }).then(() => {
            window.location.reload();
        });
    });
  };

  // If loading has already finished, check the auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before checking isAuthenticated
  authService.$watch('loading', (loading) => {
    fnBrowserDetect();
    if (loading === false) {
      return fn();
    }
  });

  var getBrowserInfo = function() {
    var ua= navigator.userAgent, tem, 
    M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE '+(tem[1] || '');
    }
    if(M[1]=== 'Chrome'){
        tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
        if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
    return M.join(' ');
};

  function fnBrowserDetect(){         
    let browserName;
    if(getBrowserInfo().match(/Safari/i)){
      browserName = "safari";
      test();
    }else{
      browserName="No browser detection";
    }
  }

  function test(){
    var promise = document.hasStorageAccess();
    promise.then(
    function (hasAccess) {
      if(!hasAccess){
        Swal.fire({
          title: '<strong>Iframes Disabled</strong>',
          html:
            'In order to use Message IQ in HubSpot you must enable Iframe support in Safari. To do this, go to' +
            '<br>Safari Settings > Privacy and disable Prevent Cross-Site Tracking. Once this has been done refresh this page.</br>',
          showCloseButton: false,
          showCancelButton: false,
          focusConfirm: false
        })
      }

    }
);
  }


 
};