import Vue from 'vue'
import moment from 'moment';

Vue.mixin({
    data: function() {
        return {
            appBarHeight: 32,
            appNavWidthMini: 90,
            appNavWidthExpanded: 250,
            appHeaderToolbar: 64,
            
            dataTableFooter: 60,

            conversationsNavWidth: 300,
            conversationsNavFilterElement: 48,

            conversationToolbarHeight: 49,
            dividerHeight: 1,
            
            settingsHeadingElement: 112,
            //notifications allowed by browser check up var 
            NotificationAllowed: false,

            //notifications data
            notificationTimer: null,
            notificationQueue: [],
            notificationTimerStartTime: null,
        }
    },
    mounted() {


    },
    methods: {
        //notifications 
        async preloadImage(url) {
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.src = url;
              img.onload = () => {
                resolve(img); // Resolve with the preloaded image
              };
              img.onerror = (error) => {
                reject(error);
              };
            });
        },
        
        async showNotification(options) {
            var preloadedImage = null;
            var imageSrc = null;
            if( options.image !== null){
                try {
                    // Preload the external image
                    preloadedImage = await this.preloadImage(options.image);
                    imageSrc = preloadedImage.src;
                }catch (error) {
                    console.error('Image preload error:', error);
                }              
            }

            if (Notification.permission === "granted") {
                await navigator.serviceWorker.ready.then(function(registration) {
                        registration.showNotification(options.title, {
                        body: options.body,
                        icon: options.icon,
                        badge: options.badge,
                        image: imageSrc,
                        tag: options.tag,
                        requireInteraction: options.requireInteraction,
                        timestamp: options.timestamp,
                        // actions: options.actions,
                        renotify: options.renotify
                    });
              });
            }else if(Notification.permission === "denied"){
                this.requestNotifications();

            }else if(Notification.permission === "default"){
                this.requestNotifications();
            }
        },
        open(){
            
        },

        handleResize() { //LAYOUT TOOL: global handler
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        preloadImage(url) {
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.src = url;
        
              img.onload = () => {
                resolve(img); // Resolve with the preloaded image
              };
        
              img.onerror = (error) => {
                reject(error);
              };
            });
        },
        
        async showNotification(options) {
            var preloadedImage = null;
            var imageSrc = null;
            if( options.image !== null){
                try {
                    // Preload the external image
                    preloadedImage = await this.preloadImage(options.image);
                    imageSrc = preloadedImage.src;
                }catch (error) {
                    console.error('Image preload error:', error);
                }              
            }

            if (Notification.permission === "granted") {
                navigator.serviceWorker.ready.then(function(registration) {
                        registration.showNotification(options.title, {
                        body: options.body,
                        icon: options.icon,
                        badge: options.badge,
                        image: imageSrc,
                        tag: options.tag,
                        requireInteraction: options.requireInteraction,
                        timestamp: options.timestamp,
                        actions: options.actions,
                        renotify: options.renotify
                    });
              });
            }else if(Notification.permission === "denied"){
                this.requestNotifications();

            }else if(Notification.permission === "default"){
                this.requestNotifications();
            }
        },
        open(){

        },
        requestNotifications() {
            Notification.requestPermission().then(permission => {
              this.permission = permission;
            });
        },
        chatWindowScroll(){
            const container = document.getElementById('conversation-pane');
            container.scrollTop = container.scrollHeight;
        },
        formatPhoneNumber(phoneNumberString){
            var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
            var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return ['(', match[2], ') ', match[3], '-', match[4]].join('');
            }
            return null;
        },
        formatDateTimeToDate(dateTime){
            return moment(dateTime).format('M/D/YYYY');
        },
        capitalizeFirstLetter(string){
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        commaSeparateNumber(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        splitDecimals(x){
            let priceSplit = x.toString().split(".");
            let array = [];

            if( priceSplit[0] !== 'undefined'){
                array.push(priceSplit[0]);
            }

            if( priceSplit[1]){
                if (priceSplit[1].length < 2){// checkup for  JS removal of 0s in cents
                    array.push( "." +priceSplit[1]+ "0");
                }else{
                    array.push( "." +priceSplit[1]);
                }
            }
            else{ // in case it's empty JS will erase 00 
                array.push( ".00");
            }

            return array;

        },
        numberWithCommas(x,toCurrency) {
            let number = null;
            if(typeof x === "string"){
                number = parseInt(x);
            }
            else{
                number =  x;
            }
            if(toCurrency){
                return number.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                })
            }else{
                return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
        timeDifference(current, previous) {
            var msPerMinute = 60 * 1000;
            var msPerHour = msPerMinute * 60;
            var msPerDay = msPerHour * 24;
            var msPerMonth = msPerDay * 30;
            var msPerYear = msPerDay * 365;

            var elapsed = current - previous;
            if (elapsed < msPerMinute) {
                return Math.round(elapsed/1000) + ' seconds ago';   
            }

            else if (elapsed < msPerHour) {
                return Math.round(elapsed/msPerMinute) + ' minutes ago';   
            }

            else if (elapsed < msPerDay ) {
                return Math.round(elapsed/msPerHour ) + ' hours ago';   
            }

            else if (elapsed < msPerMonth) {
                return Math.round(elapsed/msPerDay) + ' days ago';   
            }

            else if (elapsed < msPerYear) {
                return Math.round(elapsed/msPerMonth) + ' months ago';   
            }

            else {
                return Math.round(elapsed/msPerYear ) + ' years ago';   
            }
        },

        formatDay(day){
            return moment(day).format("MM/DD/YYYY");
        },
        
        formatHour(day){
            return moment.utc(day).local().format("hh:mm a");
        },

        getInitials(value){
            let initials = "";
            if(value === 'Integrate IQ'){
                initials = "II";
            }else{   
                initials = value.split(" ").map((n)=>n[0]).join("");
             }
            return initials;
         },
         validPhone (phone) {
            if (/^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/.test(phone)){
                return true;
            }else{
                return false;
            }
        },
        includeThousandsComma(x){
            const str = x.toLocaleString();
            return str;
        },
        convertLargeNumberToLetters(num){
            num = num.toString().replace(/[^0-9.]/g, '');
            if (num < 1000) {
                return num;
            }
            let si = [
              {v: 1E3, s: "K"},
              {v: 1E6, s: "M"},
              {v: 1E9, s: "B"},
              {v: 1E12, s: "T"},
              {v: 1E15, s: "P"},
              {v: 1E18, s: "E"}
              ];
            let index;
            for (index = si.length - 1; index > 0; index--) {
                if (num >= si[index].v) {
                    break;
                }
            }
            return (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;

        },
        stringSpaceRemoval(str){
            return str.replace(/\s/g, '');
        },
        roundToTwoDecimalPlaces(num) {
            if (!num) return null;
            
            if(num.toString().indexOf('.')==-1) return num.toString()+'.00'
            var num_string = Number(num).toFixed(2);
            var num_array = num_string.split(".")
            if(num_array[1].length == 1) return num_string+"0"
            return num_string
        },
        formatNumberTwoDecimals(num) {
            if (num === null) {
                return "0.00";
            }
            return num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        },
        getCurrentYear(){
            return new Date().getFullYear();
        },
        formatIQName(param){
            if(param === "SmartRamp") return "Smart Ramp"
            const parts = param.split("IQ");
            return parts.join(" IQ").trim();
        }

    }
  })

