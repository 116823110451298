<template>
    <div style="height: 100% !important">
        <v-overlay color="iq_dark" :value="loading">
            <v-icon color="iq_light" x-large>mdi-loading mdi-spin</v-icon>
            <span class="iq_light--text font-weight-bold ">Fetching HubSpot Price Lists...</span>
        </v-overlay>
        <v-container fill-height fluid>
            <v-row align="center"
                   justify="center">
                <v-col md="8" cols="12">
                
                    <v-card flat class="ma-auto" elevation="0">
                        <v-card-title class="text-h5">
                            Customize your PriceIQ List
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" md="7">
                                    <v-text-field style="display: block !important"
                                        label="Name"
                                        v-model="listName"
                                        required
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <v-text-field style="display: block !important"
                                        label="Discount Percentage"
                                        v-model="discountPercent"
                                        append-icon="mdi-percent"
                                        ref="dPercent"
                                        @change="updateDiscount"
                                        required
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                        </v-row>

                    </v-card-text>
                    <v-card-text>
                            <v-autocomplete
                                v-model="included"
                                :items="items"
                                item-text="name"
                                @change="updateIncluded"
                                outlined
                                chips
                                small-chips
                                return-object
                                :disabled='listsDisabled'
                                :item-disabled="disableItemExcluded"
                                clearable
                                deletable-chips
                                prepend-inner-icon="mdi-package-variant-plus"
                                label="Select Product to Include"
                                multiple
                                counter
                            >
                                <template v-slot:counter="data">
                                    <span class="caption">Included total: {{data.props.value}}</span>
                                </template>
                            </v-autocomplete>
                        <v-checkbox class="mt-n6"
                            v-model="allProducts"
                            @change="changeAllProducts"
                            hide-details
                            :label="'Use all  '+ this.items.length + ' products'"
                        ></v-checkbox>
                    </v-card-text>
                    <v-card-text class="pt-0">
                        <v-expand-transition>
                            <div v-if="listsDisabled">
                                <v-autocomplete class="mt-3"
                                    v-model="excluded"
                                    :items="items"
                                    item-text="name"
                                    @change="updateExcluded"
                                    outlined
                                    chips
                                    small-chips
                                    return-object
                                    :item-disabled="disableItemIncluded"
                                    clearable
                                    deletable-chips
                                    prepend-inner-icon="mdi-package-variant-minus"
                                    label="Select Product to Exclude"
                                    multiple
                                    counter
                                >
                                    <template v-slot:counter="data">
                                        <span class="caption">Excluded total: {{data.props.value}}</span>
                                    </template>
                                </v-autocomplete>
                            </div>
                        </v-expand-transition>

                        
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="d-flex">
                        <div>
                            <v-btn class="me-3" elevation="0" to="/PriceIQ">Cancel</v-btn>
                            <v-btn color="primary" elevation="0" @click="submitList" :loading="loadingbtn">Save Price List</v-btn>
                        </div>
                        <div class="ml-3">
                            <span class="body-1" v-if="!listsDisabled"> <strong>{{this.included.length}}</strong> products selected</span>
                            <span class="body-1" v-else> <strong>{{ this.items.length - this.excluded.length }}</strong> products selected</span>
                        </div>
                    </v-card-actions>
                    </v-card>

                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany'
        }), 
    },
    data(){
        return {
            loading: true,
            listsDisabled: false,
            items: [],
            selectedCount: 0,
            priceListId: null,
            listName: '',
            discountPercent: null,
            allProducts:null,
            included:[],
            excluded:[],
            loadingbtn: false,
        }
    },
    async created(){
        await this.$auth.auth0ClientPromise.then(async () => {
            
            if(Object.keys(this.currentCompany).length === 0){
                await this.$store.dispatch('getUserCompany');
            }

            let products = await this.getHubSpotProducts();

            products.forEach( (product) => {

                this.items.push({
                    name: product.properties.name,
                    id: product.id
                });

            });
        });

        await this.getPriceListData();


        if(this.$route.query.hsConnected === "success"){
            this.$swal({
                icon: 'success',
                title: 'HubSpot Connected',
                text: 'You have successfully connected to HubSpot!',
                toast: true,
                timer: 3000,
                position: 'top-end'
            });
        }else if(this.$route.query.hsConnected === "fail"){
            this.$swal({
                icon: 'error',
                title: 'Error connecting to HubSpot',
                text: 'There was an error connecting to HubSpot. Please try again later.',
                toast: true,
                timer: 3000,
                position: 'top-end'
            });
        }

        this.loading = false;
    },
    methods: {
        async getPriceListData(){
            this.priceListId = this.$route.query.listId;
            if(this.priceListId != 'null'){
                let listInfo = await this.$store.dispatch('priceIQ/getPriceListById', {"priceListId": this.priceListId});
                if(listInfo != undefined){
                    this.listName = listInfo.name;
                    this.discountPercent = this.roundToTwoDecimalPlaces(listInfo.discount);
                    this.allProducts = listInfo.allProducts;
                    this.listsDisabled = listInfo.allProducts;

                    if(!this.allProducts){
                        for await (let product of listInfo.priceListProducts) {
                            let hubspotProduct = this.items.find(p => p.id == product.productId);
                            if(hubspotProduct != undefined) {
                                this.included.push({
                                    name: hubspotProduct.name,
                                    id: hubspotProduct.id
                                });
                            }              
                        }
                    }else{
                        for await (let product of listInfo.priceListProducts) {
                            let hubspotProduct = this.items.find(p => p.id == product.productId);
                            if(hubspotProduct != undefined) {
                                this.excluded.push({
                                    name: hubspotProduct.name,
                                    id: hubspotProduct.id
                                });
                            }              
                        }
                    }
                }
            }
        },
        async getHubSpotProducts(portalId){
            let response = await this.$store.dispatch('priceIQ/getHubSpotProducts');
            if(!response){
                this.$swal({
                    title: `Unable to fetch HubSpot product information.`,
                    icon: 'warning',
                    showDenyButton: false,
                    confirmButtonText: "Close"
                });
            } else {
                return response;
            }
        },
        async submitList() {

            let productList = [];

            if(!this.allProducts){
                if(this.included !== undefined) {
                    this.included.forEach( (product) => {
                        productList.push(product.id);
                    });
                }
            }else{
                if(this.excluded !== undefined) {
                    this.excluded.forEach( (product) => {
                        productList.push(product.id);
                    });
                }
            }


            if(this.listName != undefined && this.discountPercent != undefined) {
                
                if((this.allProducts == undefined || !this.allProducts) && productList.length == 0) {

                    this.$swal({
                            title: `Please select products to include or exclude`,
                            icon: 'warning',
                            showDenyButton: false,
                            confirmButtonText: "Close"
                    });

                } else {
                    let response = null;
                    this.loadingbtn = true;
                    if(this.priceListId != 'null'){
                         response = await this.$store.dispatch('priceIQ/updatePriceListInfo', {
                            "priceListId": this.priceListId,
                            "name": this.listName,
                            "discount": this.discountPercent,
                            "allProducts": (this.allProducts == undefined) ? false : this.allProducts,
                            "products": productList
                        });
                    }else{
                         response = await this.$store.dispatch('priceIQ/createPriceList', {
                            "name": this.listName,
                            "discount": this.discountPercent,
                            "allProducts": (this.allProducts == undefined) ? false : this.allProducts,
                            "products": productList
                        });
                    }

                    this.loadingbtn = false;
                    if(response) {
                        this.$router.push('/PriceIQ'); 
                    } else {
                        this.$swal({
                            title: `Error creating price list. Please try again later.`,
                            icon: 'warning',
                            showDenyButton: false,
                            confirmButtonText: "Close"
                        });
                    }  

                } 

            } else {

                this.$swal({
                        title: `Please enter a price list name and discount percentage`,
                        icon: 'warning',
                        showDenyButton: false,
                        confirmButtonText: "Close"
                });

            }

            

        },
        updateIncluded() {
            this.selectedCount = this.included.length;
            this.selectedCount += this.excluded.length;
            this.included.forEach( (includedProduct) => {
                
                this.excluded.forEach( (excludedProduct) => {

                    if(includedProduct.id == excludedProduct.id) {
                        this.$swal({
                            title: `You cannot include and exclude the same product.`,
                            icon: 'warning',
                            showDenyButton: false,
                            confirmButtonText: "Close"
                        });

                        this.included.splice(this.included.indexOf(includedProduct), 1);
                        this.selectedCount-=1;
                    }

                });

            });
            
        },
        updateExcluded() {
            this.selectedCount = this.included.length;
            this.selectedCount += this.excluded.length;
            this.included.forEach( (includedProduct) => {
                
                this.excluded.forEach( (excludedProduct) => {

                    if(includedProduct.id == excludedProduct.id) {
                        this.$swal({
                            title: `You cannot include and exclude the same product.`,
                            icon: 'warning',
                            showDenyButton: false,
                            confirmButtonText: "Close"
                        });

                        this.excluded.splice(this.excluded.indexOf(excludedProduct), 1);
                        this.selectedCount-=1;

                    }

                });

            });
            
        },
        updateDiscount() {
            if( parseInt(this.discountPercent) < 0 || parseInt(this.discountPercent) > 100 || isNaN(this.discountPercent) ) {
                this.$refs.dPercent.reset();

                this.$swal({
                    title: `You may only select a discount ranging from 0% to 100%.`,
                    icon: 'warning',
                    showDenyButton: false,
                    confirmButtonText: "Close"
                });
            } 

            if(!Number.isInteger(Number(this.discountPercent))){
                if(!this.isTwoDecimalPlaces(this.discountPercent)) {
                    this.$swal({
                        title: `You may only select a discount with two decimal digits`,
                        icon: 'warning',
                        showDenyButton: false,
                        confirmButtonText: "Close"
                    });
                }
            }

            this.discountPercent = this.roundToTwoDecimalPlaces(this.discountPercent);
        },
        isTwoDecimalPlaces(num) {
            return /^\d+\.\d{2}$/.test(num) || /^\d+\.\d{1}$/.test(num);
        },
        existsInArray(arr, id) {            
            return arr.some(item => item.id === id);
        },
        disableItemExcluded(item){
            return this.existsInArray(this.excluded, item.id);
        },
        disableItemIncluded(item){
            return this.existsInArray(this.included, item.id);
        },
        changeAllProducts() {
            this.listsDisabled = this.allProducts;
            this.included = [];
            this.excluded = [];
        },
        showHubSpotSuccessMessage(){
                this.$swal({
                    icon: 'success',
                    title: 'HubSpot Connected',
                    text: 'You have successfully connected to HubSpot!',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });
                this.overlayNotHubspot = false;
            },
            showHubSpotErrorMessage(){
                this.$swal({
                    icon: 'error',
                    title: 'Error connecting to HubSpot',
                    text: 'There was an error connecting to HubSpot. Please try again later.',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });
            },
    }
}
</script>
