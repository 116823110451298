import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,{staticClass:"d-flex"},[_c(VCol,{attrs:{"cols":"6"}},[_c(VTextarea,{staticClass:"chat-bubble-override right pt-4",attrs:{"flat":"","rows":"5","hint":"Type a message to calculate","persistent-hint":"","auto-grow":""},on:{"input":_vm.onChangeMessage},scopedSlots:_vm._u([{key:"message",fn:function({message,key}){return [_c('span',{staticClass:"ml-8 d-block text-center",attrs:{"id":key},domProps:{"innerHTML":_vm._s(message)}})]}}]),model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"mt-n14",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-emoticon-happy-outline ")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,[_c('VEmojiPicker',{on:{"select":_vm.selectEmoji}})],1)],1)],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VCardText,[_c(VList,{staticClass:"transparent"},[_c(VListItem,{staticClass:"min-height"},[_c(VListItemTitle,[_vm._v("Encoding Used: ")]),_c(VListItemSubtitle,{staticClass:"text-right"},[_vm._v(" "+_vm._s(this.encoding)+" ")])],1),_c(VListItem,{staticClass:"min-height"},[_c(VListItemTitle,[_vm._v("Characters in SMS: ")]),_c(VListItemSubtitle,{staticClass:"text-right"},[_vm._v(" "+_vm._s(this.characters)+" ")])],1),_c(VListItem,{staticClass:"min-height"},[_c(VListItemTitle,[_vm._v("Number of segments: ")]),_c(VListItemSubtitle,{staticClass:"text-right"},[_vm._v(" "+_vm._s(this.segments)+" ")])],1),_c(VListItem,{staticClass:"min-height"},[_c(VListItemTitle,[_vm._v("Segment Length: ")]),_c(VListItemSubtitle,{staticClass:"text-right"},[_vm._v(" "+_vm._s(this.length)+" ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }