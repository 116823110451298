<template>
    <v-card elevation="2" outlined class="my-3 lighten-2" >
        <v-card-title class="d-flex justify-space-between align-center">
            <span :class="this.color + '--text'">
                <v-icon class="xx-large" :color="this.color">{{this.icon}}</v-icon>
                {{this.name}} 
                <span  :class="getState(avgState(this.entries)).color+ '--text body-2' "> ({{avgState(this.entries)}} %  / {{this.entries.length}} day avg)</span>
            </span>
            <span :class="getState(avgState(this.entries)).color+ '--text' ">
                {{getState(avgState(this.entries)).state }}
                <v-chip x-small :class="getState(avgState(this.entries)).color"></v-chip>

            </span>
        </v-card-title>
        <v-card-text>
            <div  class="d-flex">
                <v-tooltip top v-for="(entry, entryIndex) in this.entries" :key="entryIndex" :color="getState(entry.uptime).color" >
                    <template v-slot:activator="{ on, attrs }">
                        <div :class="getState(entry.uptime).color" class="entry" v-bind="attrs" v-on="on"></div>
                    </template>
                    <v-list-item  >
                        <v-list-item-content >
                            <v-list-item-title class="iq_light--text text-h5"> {{entry.uptime}}% </v-list-item-title>
                            <v-list-item-subtitle class="iq_light--text">  {{entryDate(entry.dateStamp)}} </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <span></span>
                </v-tooltip>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import moment from "moment";

export default {
    name: 'UpTimeBot',
    props: {
        name: String,
        entries: Array,
        icon: String,
        color: String
    },
    data () {
      return {
          
      }
    },
    computed: {
    },
    methods: {
        entryDate( date ) {
            return moment(date).format('MMMM DD YYYY')
        },
        between(x, min, max) {
            return x >= min && x <= max;
        },
        getState( percent ){
            var state = null;
            if( this.between( percent, 0,50)){
                state =  { "state":"offline", "color":"error"};
            }
            if( this.between( percent, 50,75)){
                state =    { "state":"Partial Access", "color":"warning"};
            }
            if( this.between( percent, 75,100)){
                state =    { "state":"Operational", "color":"success"};
            }
            return state;
        },
        avgState (array){
            var length = array.length;
            var total = 0;
            for (var i = 0; i < array.length; i++) {
                total += array[i].uptime;
            }
            
            var avg = total / length;

            return avg.toFixed(2);
        }
    }
}
</script>
<style lang="scss">
    .entry{
        background-color: #cccccc;
        padding: 10px 5px;
        margin: 1px;
        flex-grow: 1;
        border-radius: 4px;
    }
</style>