<template>
    <iframe
            :src="url"
            width="100%"
            height="100%"
            frameborder="0" >
    </iframe>
</template>
<script>
    export default{
        data(){
            return{
                url: "https://training.serviceassistant.com/176515-T/Login"
            }
        },
        created(){
            let id = this.$route.query.realGreenId;
            if(id !== undefined){
                this.url = `https://training.serviceassistant.com/176515-T/Customer/Customer/Index/${id}/Detail`
            }
        }
    }
</script>