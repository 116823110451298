<template>
    <v-container class="d-flex align-center justify-center" >
        <v-card elevation="10" min-width="350px" max-width="450px" rounded outlined>
            <v-card-title class="primary iq_light--text" >Registration</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-6">
                <v-text-field
                    outlined dense
                    v-model="companyName"
                    label="Company Name"
                    :rules="[rules.required]"
                ></v-text-field>
                <v-text-field
                    outlined dense
                    v-model="companyUrl"
                    label="Domain"
                    :rules="[rules.required]"
                ></v-text-field>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="d-flex justify-space-between">
                <v-btn text>Cancel</v-btn>
                <v-btn color="primary" outlined :disabled="!companyName || !companyUrl"  :loading="loading" @click.prevent="submitRegistration()">Register</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data () {
        return {
            loading: false,
            merchantId: null,
            companyName: null,
            companyUrl: null,
            createCompany:true,
            rules: {
                required: value => !!value || 'Required.',
            }
        }
    },
    methods: {
        async submitRegistration(){
            this.loading = true;
            if (this.createCompany) {
            let result = await this.$store.dispatch('smartRamp/registerUser',{
                 app: this.$route.query.app,
                 companyName: this.companyName,
                 domain: this.companyUrl,
                 mid: null,
             });
             if(result){
                let resultData = await this.$store.dispatch('smartRamp/getUserCompany');
                if(resultData){
                    this.$router.push('/' + this.$route.query.app);
                }else{
                    this.showError('An unexpected error has occurred');
                }
             }else{
                this.showError('There was an error when registering the company');
             }
                event.target.style.pointerEvents = 'none';
                event.target.style.color = 'rgba(0,0,0,.26)';
                this.createCompany = false;
            }
             setTimeout(() => {
                this.createCompany = true;
                event.target.style.pointerEvents = '';
                event.target.style.color = '#f50886';
            }, 5000)
            this.loading = false;

        },

        showError(text){
            this.$swal({
                icon: 'error',
                title: text,
                text: '',
            });
        }

    }
}
</script>