<template>
    <div>
        <productPage 
            :pageData="this.page" 
            :productData="this.products[0]" 
            :recipe="this.recipe"
            :productDetailGrid="productDetailGrid"
        ></productPage>    
        <v-container>
            <v-tabs 
            background-color="transparent"
            class="mt-5"
            grow
            v-model="tab">
                <v-tab class="font-weight-bold text-h6" v-if="systems.length > 0" >Systems</v-tab>
                <v-tab class="font-weight-bold text-h6">Objects</v-tab>
                <!-- <v-tab class="font-weight-bold text-h6">Triggers</v-tab> -->
                <v-tab class="font-weight-bold text-h6">Fields</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" class="pt-8 pb-5">
                <v-tab-item class="" v-if="systems.length > 0">
                    <v-row class="d-flex align-end">
                        <v-col cols="12" md="12" >
                            <h2>System available for integrations</h2>
                            <p>{{this.recipe.systemsDescription}}</p>
                        </v-col>
                        <v-col cols="12" md="6" offset-md="6">
                            <v-toolbar class="flex-grow-0" light elevation="0" flat>
                                <v-text-field
                                    v-model="systemsSearch"
                                    clearable
                                    dense
                                    outlined
                                    flat
                                    solo
                                    hide-details
                                    prepend-inner-icon="mdi-magnify"
                                    label="Search Integration Systems"
                                    class="flex-grow-1 mr-1 compact"
                                ></v-text-field>
                                <v-btn-toggle
                                    v-model="systemsSortDesc"
                                    mandatory
                                >
                                    <v-btn
                                    depressed
                                    :value="false"
                                    >
                                        <v-icon small>mdi-arrow-up</v-icon>
                                    </v-btn>
                                    <v-btn
                                    depressed
                                    :value="true"
                                    >
                                        <v-icon small>mdi-arrow-down</v-icon>
                                    </v-btn>
                                </v-btn-toggle>
                            </v-toolbar>
                        </v-col>
                    </v-row>
                    
                    <!-- <v-list>
                        <v-row>
                            <v-col cols="3"  v-for="(system, systemIndex) in systems" :key="systemIndex">
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-img 
                                        :src="require('@/assets/images/integrations/' + system.src )"
                                        contain
                                        max-height="75px"
                                        ></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-capitalize font-weigth-bold text-h6">{{system.name}} </v-list-item-title>
                                    </v-list-item-content>

                                    </v-list-item-avatar>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-list> -->

                    <v-data-iterator
                        :items="this.systems"
                        :page.sync="systemsPage"
                        :search="systemsSearch"
                        :sort-by="systemsSortBy"
                        :sort-desc="systemsSortDesc"
                        hide-default-footer
                        :items-per-page="systemsItemsPerPage"
                        :footer-props="{
                        itemsPerPageOptions: [8,16,22,25,50,100, -1]
                    }">
                        <template v-slot:default="props">
                            <v-row>
                                <v-col cols="12" md="6" lg="4" xl="3"   v-for="(item, systemIndex) in  props.items" :key="systemIndex">
                                    <v-hover v-slot="{ hover }">
                                        <v-card min-height="120px" :elevation="hover ? 2 : 0" rounded outlined class="pa-0 d-flex flex-start flex-nowrap flex-row">
                                            <v-card-text class="d-flex flex-row flex-nowrap align-center ">
                                                        <v-img 
                                                        position="top left"
                                                        :src="require('@/assets/images/integrations/' + item.src )"
                                                        contain
                                                        max-height="80px"
                                                        max-width="80px"
                                                        ></v-img>
                                                    <span class="text-capitalize font-weigth-bold text-h6 pl-3">{{item.title}} </span>
                                            </v-card-text>
                                            <v-card-actions :class="{ 'show-btns': hover }">
                                                    <v-btn :color="hover ? 'primary': 'iq_opaque'" target="_blank" text :href="item.link" > More info...</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-hover>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-iterator>
                    <p>If your system is not listed, contact us.</p>
                </v-tab-item>


                <v-tab-item>
                    <v-container>
                    <v-row>
                        <v-col cols="12" md="6" lg="4" xl="3" v-for="object in recipe.objects" :key="object.name">
                            <v-card :color="object.color" min-height="150px" class="d-flex align-start flex-wrap flex-grow-1" height="100%" rounded outlined>
                                <v-card-title class="d-flex flex-start" style="width: 100%">
                                    <v-icon class="xxx-large">{{object.icon}}</v-icon>
                                    <span class="text-h5 text-no-wrap pl-3"> {{object.name}}</span>
                                </v-card-title>
                                <v-card-actions class="text-center" style="margin: 0 auto;">
                                    <v-btn block text @click="goToFieldsAndCategory(object)" v-if="object.fields.length">See Fields</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                    </v-container>
                </v-tab-item>
<!-- 

                <v-tab-item>
                    hi Triigers
                </v-tab-item> -->


                <v-tab-item>

                    <v-row class="d-flex align-end">
                        <v-col cols="12" md="12">
                            <h2>Fields Available for integration </h2>
                        </v-col>
                        <v-col cols="12" md="4">
                        <p class="text-uppercase text-h6" v-if="fieldsViewByObject !== null">Currently seeing <span class="font-weight-bold">{{fieldsViewByObject}}</span> Fields,  <v-btn x-large color="primary" @click="clearFieldsViewByObject" text> click to view all.</v-btn></p> 
                         
                        </v-col>
                        <v-col cols="12" md="8">
                            <v-toolbar class="flex-grow-0" light elevation="0" flat>
                                <v-text-field
                                    v-model="fieldsSearch"
                                    clearable
                                    dense
                                    outlined
                                    flat
                                    solo
                                    hide-details
                                    prepend-inner-icon="mdi-magnify"
                                    label="Search"
                                    class="flex-grow-1 mr-1 compact"
                                ></v-text-field>
                                <v-select 
                                    flat
                                    dense
                                    outlined
                                    solo
                                    :clearable="fieldsViewByObject !== null"
                                    hide-details
                                    label="View Only" 
                                    item-text="label"
                                    item-value="value"
                                    prepend-inner-icon="mdi-eye"
                                    :items="fieldsViewByObjectSelect"
                                    v-model="fieldsViewByObject"
                                    class="flex-grow-1 mr-1"
                                ></v-select>
                                <v-select
                                    v-model="fieldsSortBy"
                                    :items="fieldsSortByItemKeys"
                                    item-text="label"
                                    item-value="value"
                                    flat
                                    dense
                                    solo
                                    outlined
                                    hide-details
                                    prepend-inner-icon="mdi-sort-variant"
                                    label="Sort by"
                                    class="flex-grow-0 mx-2"
                                ></v-select>
                                <v-btn-toggle
                                    v-model="fieldsSortDesc"
                                    mandatory
                                >
                                    <v-btn
                                    depressed
                                    :value="false"
                                    >
                                        <v-icon small>mdi-arrow-up</v-icon>
                                    </v-btn>
                                    <v-btn
                                    depressed
                                    :value="true"
                                    >
                                        <v-icon small>mdi-arrow-down</v-icon>
                                    </v-btn>
                                </v-btn-toggle>
                            </v-toolbar>
                        </v-col>
                    </v-row>

                    <v-data-iterator
                        :items="filteredByObject"
                        :page.sync="fieldsPage"
                        :search="fieldsSearch"
                        :sort-by="fieldsSortBy"
                        hide-default-footer
                        :sort-desc="fieldsSortDesc"
                        :items-per-page="fieldsItemsPerPage"
                        :footer-props="{
                        itemsPerPageOptions: [8,16,22,25,50,100, -1]
                    }">
                        <template v-slot:default="props">
                            <v-row>
                                <v-col cols="6" md="4" lg="3" xl="2"  v-for="(item, systemIndex) in  props.items" :key="systemIndex" :class="item.object">
                                    <v-hover v-slot="{ hover }">
                                        <v-card :elevation="hover ? 2 : 0" outlined rounded class="pa-0" :color="item.object.color">
                                            <v-list-item three-line class="d-flex flex-row align-center">
                                                <v-list-item-avatar>
                                                    <v-icon x-large>{{item.object.icon}}</v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title class="text-capitalize font-weigth-bold text-h6">{{item.label}} </v-list-item-title>
                                                    <v-list-item-subtitle class="body-1"> {{item.object.name}}</v-list-item-subtitle>
                                                    <v-list-item-subtitle class="caption"> User Picks: {{item.isRequired ? 'Required' : 'Yes'}} </v-list-item-subtitle>
                                                </v-list-item-content>
                                            
                                            </v-list-item>
                                        </v-card>
                                    </v-hover>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-iterator>
                </v-tab-item>
            </v-tabs-items>
        </v-container>
    </div>
</template>
<script>
import productPage from '../../components/SmartRamp/shared/_productView.vue';

export default {
    components:  {
        productPage
    },
    data () {
        return {
            tab: null,
            page: {
                title: "Integrate IQ Product",
                isRecipe: false,
            },
            recipe: {},
            products: [
                {
                    productName: "Customer Followup",
                    productIcon: "$integrateIQIcon",
                    productColor: "primary",
                    isPremium: null, //true or false
                    isApp: false,
                    productCategory: null, //"Marketing"
                    productTags: [
                        { tagName: 'marketing'},
                        { tagName: 'tracking'},
                        { tagName: 'follwup'},
                    ],
                    productRate: null, //0.0-5.0
                    productRatings: null, //####
                    productVideo: null,//"https://play.vidyard.com/wmNeRogV5s6e5MCeAnP9i9.jpg",
                    productVideoID: null,//"wmNeRogV5s6e5MCeAnP9i9",
                    productInfoHTML: "",
                    productDo: 'Creates lead prospect in CRM. If prospect exists, we add new lead data to existing record.',
                    productUse: 'Form on web site is completed.  Trade show lead is captured.  Phone or live chat lead is collected.',
                    productUpdates: 'Company or contact',
                    productWhy: 'Sales needs to follow up quickly with a new lead to get the best chance of making the sale.',
                    productDetailGrid: [
                        {name: 'Automation', value: true, detail: 'Create or update record from other system data' },
                        {name: 'Visibility', value: true, detail: 'Let user see key data from other system' },
                        {name: 'Reporting', value: true, detail: 'Let user build and combine reports from other system data'},
                        {name: 'Communication', value: true, detail: 'Trigger communication or segmentation based on other system data'}
                    ],
                    productSyncType: 'Create',
                    productRelated: [],
                    /*
                    [
                        { productName: "IntegrateIQ", productIcon: "$integrateIQIcon", productColor:"secondary", link:'/products'},
                        { productName: "MonitorIQ", productIcon: "$integrateIQIcon", productColor:"success", link: '/products'}
                    ],
                    */
                    productActions: []
                    /*
                     [
                         { title: 'Request a Demo', color:'primary', icon: '', link: '/' , isExternal: false },
                         { title: 'Start Yours Now', color:'primary', icon: '', link: '/' , isExternal: false },
                    ]
                    */
                },
                {
                    productName: "Global revenue",
                    productIcon: "$integrateIQIcon",
                    productColor: "success",
                    isPremium: true, 
                    isApp: false,
                    productCategory: "Finance", 
                    productTags: [
                        { tagName: 'marketing'},
                        { tagName: 'messaging'},
                        { tagName: 'communication'},
                    ],
                    productRate: 3.5,
                    productRatings: 2500, 
                    productVideo: "https://play.vidyard.com/wmNeRogV5s6e5MCeAnP9i9.jpg",
                    productVideoID: "wmNeRogV5s6e5MCeAnP9i9",
                    productInfoHTML: "<h3 class='font-weight-bold'>Why do you need MessageIQ?</h3><p>Commodo amet ea elit esse incididunt ut reprehenderit magna nisi non tempor eiusmod do. Deserunt nulla eu incididunt id aute enim. Velit deserunt duis officia qui consectetur consectetur id ea.Id magna aute cupidatat in labore labore. Ipsum enim minim magna sint velit ex sint occaecat sunt Lorem laboris labore ipsum officia. Adipisicing ut do proident aliquip adipisicing sint deserunt in id pariatur incididunt. Officia quis amet anim incididunt consequat consectetur sit.Consequat Lorem Lorem dolore in aute. Laborum eiusmod et magna mollit dolor anim ex occaecat dolor veniam. Cillum labore incididunt amet ea consectetur qui laborum proident sit nulla dolor. Nulla non ipsum esse voluptate exercitation quis do cillum est excepteur incididunt quis non do. Nisi adipisicing aliqua magna fugiat irure eiusmod ipsum dolor velit aliqua non. Proident exercitation Lorem ullamco Lorem labore ut voluptate consectetur.</p>",
                    productDo: 'Creates lead prospect in CRM. If prospect exists, we add new lead data to existing record.',
                    productUse: 'Form on web site is completed.  Trade show lead is captured.  Phone or live chat lead is collected.',
                    productUpdates: 'Company or contact',
                    productWhy: 'Sales needs to follow up quickly with a new lead to get the best chance of making the sale.',
                    productDetailGrid: [
                        {name: 'Automation', value: true, detail: 'Create or update record from other system data'},
                        {name: 'Visibility', value: true, detail: 'Let user see key data from other system'},
                        {name: 'Reporting', value: true,detail: 'Let user build and combine reports from other system data'},
                        {name: 'Communication', value: true,detail: 'Trigger communication or segmentation based on other system data'}
                    ],
                    productRelated: [
                        { productName: "IntegrateIQ", productIcon: "$integrateIQIcon", productColor:"secondary", link:'/products'},
                        { productName: "MonitorIQ", productIcon: "$integrateIQIcon", productColor:"success", link: '/products'}
                    ],
                    productActions: [
                         { title: 'Request a Demo', color:'primary', icon: '', link: '/' , isExternal: false },
                         { title: 'Start Using now', color:'primary', icon: '', link: '/' , isExternal: false },
                    ]
                },
            ],
            systemsSearch: '',
            systemsFiter: {},
            systemsOrderByKeys: [],
            systemsSortDesc: false, 
            systemsPage: 1, 
            systemsItemsPerPage: -1, 
            systemsSortBy: 'name', 
            systems: [],
            systems_backup: [
                { name: 'recurly',                  title:'recurly',    link:'https://integrateiq.com/integrations/hubspot-recurly/',        src: 'Recurly.png'},
                { name: 'salesforce',               title:'Sales Force',    link:'https://integrateiq.com/integrations/hubspot-salesforce/',        src: 'SalesForce.png'},
                { name: 'sendgrid',                 title:'sendGrid',    link:'https://integrateiq.com/integrations/hubspot-sendgrid/',        src: 'SendGrid.png'},
                { name: 'smartsheet',               title:'Smartsheet',    link:'https://integrateiq.com/integrations/hubspot-smartsheet/',        src: 'smartsheet.png'},
                { name: 'square',                   title:'Square',    link:'https://integrateiq.com/integrations/hubspot-square/',        src: 'square.png'},
                { name: 'stripe',                   title:'Stripe',    link:'https://integrateiq.com/integrations/hubspot-stripe/',        src: 'Stripe.png'},
                { name: 'sugarcrm',                 title:'Sugar CRM',    link:'https://integrateiq.com/integrations/hubspot-sugarcrm/',        src: 'SugarCRM.png'},
                { name: 'toast',                    title:'Toast',    link:'',        src: 'toast.png'},
                { name: 'trello',                   title:'Trello',    link:'https://integrateiq.com/integrations/hubspot-trello/',        src: 'Trello.png'},
                { name: 'xero',                     title:'Xero',    link:'https://integrateiq.com/integrations/hubspot-xero/',        src: 'xero.png'},
                { name: 'zendesk',                  title:'ZenDesk',    link:'https://integrateiq.com/integrations/hubspot-zendesk-integration/',        src: 'zendesk.png'},
                { name: 'zohocrm',                  title:'Zoho CRM',    link:'https://integrateiq.com/integrations/hubspot-zoho-crm/',        src: 'ZOHOCRM.png'},
                { name: 'activecampaign',           title:'Active Campaign',    link:'https://integrateiq.com/integrations/hubspot-active-campaign/',        src: 'ActiveCampaign.png'},
                { name: 'brighttree',               title:'Brighttree',    link:'https://integrateiq.com/integrations/hubspot-brightree/',        src: 'brighttree.png'},
                { name: 'bullhorn',                 title:'Bullhorn',    link:'https://integrateiq.com/integrations/hubspot-bullhorn/',        src: 'bullhorn.png'},
                { name: 'chargebee',                title:'Chargebee',    link:'https://integrateiq.com/integrations/hubspot-chargebee/',        src: 'ChargeBee.png'},
                { name: 'chargify',                 title:'Chargify',    link:'https://integrateiq.com/integrations/hubspot-chargify/',        src: 'chargify.png'},
                { name: 'clover',                   title:'Clover',    link:'https://integrateiq.com/integrations/hubspot-clover/',        src: 'clover.png'},
                { name: 'drift',                    title:'Drift',    link:'https://integrateiq.com/integrations/hubspot-drift/',        src: 'drift.png'},
                { name: 'freshbooks',               title:'Freshbooks',    link:'https://integrateiq.com/integrations/hubspot-freshbooks/',        src: 'freshbooks.png'},
                { name: 'freshdesk',                title:'Freshdesk',    link:'https://integrateiq.com/integrations/hubspot-freshdesk/',        src: 'Freshdesk.png'},
                { name: 'getresponse',              title:'Getresponse',    link:'https://integrateiq.com/integrations/hubspot-getresponse/',        src: 'getResponse.png'},
                { name: 'hubspot',                  title:'Hubspot',    link:'',        src: 'Hubspot.png'},
                { name: 'insightly',                title:'Insightly',    link:'https://integrateiq.com/integrations/hubspot-insightly/',        src: 'insightly.png'},
                { name: 'intercom',                 title:'Intercom',    link:'https://integrateiq.com/integrations/hubspot-intercom/',        src: 'intercom.png'},
                { name: 'jira',                     title:'Jira',    link:'https://integrateiq.com/integrations/hubspot-jira/',        src: 'jira.png'},
                { name: 'keap',                     title:'Keap',    link:'https://integrateiq.com/integrations/hubspot-keap/',        src: 'Keap.png'},
                { name: 'klaviyo',                  title:'Klaviyo',    link:'https://integrateiq.com/integrations/hubspot-klaviyo/',        src: 'klaviyo.png'},
                { name: 'marketo',                  title:'Marketo',    link:'https://integrateiq.com/integrations/hubspot-marketo/',        src: 'marketo.png'},
                { name: 'microsoftdynamics365crm',  title:'Microsoft Dynamics 365 CRM',    link:'https://integrateiq.com/integrations/hubspot-dynamics-365-crm/',        src: 'MicrosoftDynamics365CRM.png'},
                { name: 'microsoftdynamicsax',      title:'Microsoft Dynamics AX',    link:'https://integrateiq.com/integrations/hubspot-microsoft-ax-integration/',        src: 'MicrosoftDynamicsAX.png'},
                { name: 'microsoftdynamicsgp',      title:'Microsoft Dynamics GP',    link:'https://integrateiq.com/integrations/hubspot-microsoft-dynamics-gp/',        src: 'MicrosoftDynamicsGP.png'},
                { name: 'mindbody',                 title:'Mindbody',    link:'https://integrateiq.com/integrations/hubspot-mindbody/',        src: 'mindbody.png'},
                { name: 'netsuite',                 title:'NetSuite',    link:'https://integrateiq.com/integrations/hubspot-netsuite-integration/',        src: 'netsuite.png'},
                { name: 'oracleeloquia',            title:'Oracle Eloquia',    link:'https://integrateiq.com/integrations/hubspot-eloqua/',        src: 'OracleEloquia.png'},
                { name: 'oraclesalescloud',         title:'oracle Sales Cloud',    link:'https://integrateiq.com/integrations/hubspot-oracle-sales-cloud/',        src: 'oracleSalesCloud.png'},
                { name: 'pipedrive',                title:'Pipedrive',    link:'https://integrateiq.com/integrations/hubspot-pipedrive-integration/',        src: 'pipedrive.png'},
                { name: 'quickbooks',               title:'Quickbooks',    link:'https://integrateiq.com/integrations/hubspot-quickbooks-integration/',        src: 'quickbooks.png'},
            ],
            objects: [
                {name: 'Contacts', value:'Contact',color:"primary lighten-4", icon:"mdi-account-check"},
                {name: 'Company' , value:'Company',color:"warning lighten-4", icon:"mdi-domain"},
                {name: 'Deal' , value:'Deal',color:"iq_lightblue lighten-5", icon:"mdi-handshake"},
                {name: 'Products' , value:'Products',color:"iq_aquagreen lighten-4", icon:"mdi-cube"},
                {name: 'Deal/Fullfilment' , value:'Deal/Fullfilment',color:"success lighten-4", icon:"mdi-cube-send"},
                {name: 'Deal/Invoice', value:'Deal/Invoice',color:"iq_desaturatedblue lighten-4", icon:"mdi-receipt"},
            ],
            triggers:[
                {name: 'Lifecycle Stage'},
                {name: 'Deal Pipeline/Stage'},
                {name: 'Lead Score'},
                {name: 'Custom Property'},
            ],
            fieldsViewByObject: null,
            fieldsViewByObjectSelect: [
                { id:"0" , value: "Contact", label: "Contact", count: null},
                { id:"1" , value: "Company", label: "Company", count: null},
                { id:"2" , value: "Deal", label: "Deal", count: null},
                { id:"3" , value: "Products", label: "Products", count: null},
                { id:"4" , value: "Deal/Fullfilment", label: "Deal/Fullfilment", count: null},
                { id:"5" , value: "Deal/Invoice", label: "Deal/Invoice", count: null},
                { id:"6" , value: null, label: "All", count: null}
            ],
            fieldsSearch: '',
            fieldsFiter: {},
            fieldsOrderByKeys: [],
            fieldsSortDesc: false, 
            fieldsPage: 1, 
            fieldsItemsPerPage: -1, 
            fieldsSortBy: 'object',
            fieldsSortByItemKeys: [
                {label: 'Order By Name', value:'label'},
                {label: 'Order By Type', value: 'object'},
            ],
            objectCategories: [],
            fields:[
                {name: 'First Name',  object: 'Contact', customerPicks:"No", sampleData:""},
                {name: 'Last Name',  object: 'Contact', customerPicks:"No", sampleData:""},
                {name: 'Email',  object: 'Contact', customerPicks:"No", sampleData:""},
                {name: 'Phone Number',  object: 'Contact', customerPicks:"No", sampleData:""},
                {name: 'Company Name',  object: 'Contact', customerPicks:"Yes", sampleData:""},
                {name: 'Street Address',  object: 'Contact', customerPicks:"No", sampleData:""},
                {name: 'Street Address 2',  object: 'Contact', customerPicks:"No", sampleData:""},
                {name: 'City',  object: 'Contact', customerPicks:"Yes", sampleData:""},
                {name: 'State/Region',  object: 'Contact', customerPicks:"Required", sampleData:""},
                {name: 'POstal Code',  object: 'Contact', customerPicks:"No", sampleData:""},
                {name: 'ID',  object: 'Contact', customerPicks:"No", sampleData:""},
                {name: 'Contact Owner',  object: 'Contact', customerPicks:"Required", sampleData:""},
                {name: 'Custom field',  object: 'Contact', customerPicks:"Yes", sampleData:""},
                {name: 'Custom field',  object: 'Contact', customerPicks:"No", sampleData:""},
                {name: 'Custom field',  object: 'Contact', customerPicks:"Required", sampleData:""},
                {name: 'Custom field',  object: 'Contact', customerPicks:"No", sampleData:""},
                {name: 'Custom field',  object: 'Contact', customerPicks:"Yes", sampleData:""},
                {name: 'Company Name',  object: 'Company', customerPicks:"No", sampleData:""},
                {name: 'Phone Number',  object: 'Company', customerPicks:"Required", sampleData:""},
                {name: 'Website URL',  object: 'Company', customerPicks:"No", sampleData:""},
                {name: 'Address 1',  object: 'Company', customerPicks:"Required", sampleData:""},
                {name: 'Address 2',  object: 'Company', customerPicks:"No", sampleData:""},
                {name: 'City',  object: 'Company', customerPicks:"Required", sampleData:""},
                {name: 'State',  object: 'Company', customerPicks:"Yes", sampleData:""},
                {name: 'Postal Code',  object: 'Company', customerPicks:"No", sampleData:""},
                {name: 'Create Date',  object: 'Company', customerPicks:"Yes", sampleData:""},
                {name: 'ID',  object: 'Company', customerPicks:"Yes", sampleData:""},
                {name: 'Company Owner',  object: 'Company', customerPicks:"Required", sampleData:""},
                {name: 'Custom field',  object: 'Company', customerPicks:"No", sampleData:""},
                {name: 'Custom field',  object: 'Company', customerPicks:"No", sampleData:""},
                {name: 'Custom field',  object: 'Company', customerPicks:"Required", sampleData:""},
                {name: 'Custom field',  object: 'Company', customerPicks:"No", sampleData:""},
                {name: 'Deal Name',  object: 'Deal', customerPicks:"Yes", sampleData:""},
                {name: 'Close Date',  object: 'Deal', customerPicks:"Required", sampleData:""},
                {name: 'Stage',  object: 'Deal', customerPicks:"Required", sampleData:""},
                {name: 'Pipeline',  object: 'Deal', customerPicks:"No", sampleData:""},
                {name: 'Deal Amount',  object: 'Deal', customerPicks:"Required", sampleData:""},
                {name: 'Create date',  object: 'Deal', customerPicks:"Required", sampleData:""},
                {name: 'ID',  object: 'Deal', customerPicks:"No", sampleData:""},
                {name: 'Custom field',  object: 'Deal', customerPicks:"Required", sampleData:""},
                {name: 'Custom field',  object: 'Deal', customerPicks:"Yes", sampleData:""},
                {name: 'Custom field',  object: 'Deal', customerPicks:"Yes", sampleData:""},
                {name: 'Custom field',  object: 'Deal', customerPicks:"Required", sampleData:""},
                {name: 'Custom field',  object: 'Deal', customerPicks:"No", sampleData:""},
                {name: 'Line item name',  object: 'Products', customerPicks:"Yes", sampleData:""},
                {name: 'Line item description',  object: 'Products', customerPicks:"Yes", sampleData:""},
                {name: 'Line item unit price',  object: 'Products', customerPicks:"No", sampleData:""},
                {name: 'Line item unit cost',  object: 'Products', customerPicks:"Required", sampleData:""},
                {name: 'Line item quantity',  object: 'Products', customerPicks:"No", sampleData:""},
                {name: 'Custom field',  object: 'Products', customerPicks:"Required", sampleData:""},
                {name: 'Custom field',  object: 'Products', customerPicks:"Yes", sampleData:""},
                {name: 'Fullfillment ID',  object: 'Deal/Fullfilment', customerPicks:"Required", sampleData:""},
                {name: 'Tracking ID',  object: 'Deal/Fullfilment', customerPicks:"No", sampleData:""},
                {name: 'Tracking Carrier',  object: 'Deal/Fullfilment', customerPicks:"No", sampleData:""},
                {name: 'Custom field',  object: 'Deal/Fullfilment', customerPicks:"No", sampleData:""},
                {name: 'Custom field',  object: 'Deal/Fullfilment', customerPicks:"Yes", sampleData:""},
                {name: 'Invoice ID',  object: 'Deal/Fullfilment', customerPicks:"No", sampleData:""},
                {name: 'Payment status',  object: 'Deal/Invoice', customerPicks:"Yes", sampleData:""},
                {name: 'Invoice create date',  object: 'Deal/Invoice', customerPicks:"No", sampleData:""},
                {name: 'Current Balance',  object: 'Deal/Invoice', customerPicks:"No", sampleData:""},
                {name: 'Custom field',  object: 'Deal/Invoice', customerPicks:"No", sampleData:""},
                {name: 'Custom field',  object: 'Deal/Invoice', customerPicks:"Yes", sampleData:""},
            ],
            productDetailGrid: [],
        }
    },
    created(){
      this.$auth.auth0ClientPromise.then(async () => {
          this.recipe = await this.$store.dispatch('recipe/getRecipeById', this.$route.params.id);
          this.setFieldsViewByObjectSelect();
          this.setProductDetailGrid();
      });
    },
    methods: {
        goToExtURL(url){
        },
        goToFieldsAndCategory(object){
            this.tab = 1;
            this.fieldsViewByObject = object.name;
        },
        clearFieldsViewByObject(){
            this.fieldsViewByObject = null;
        },
        setFieldsViewByObjectSelect() {
            let objects = [];
            let count = 0;
            if (this.recipe.objects) {
                this.recipe.objects.map(object => {
                    if (object.fields.length) {
                        objects.push({ id: count, value: object.name, label: object.description, count: null });
                        count++;
                    }
                })
                objects.push({id:"6" , value: null, label: "All", count: null});
            }
            this.fieldsViewByObjectSelect = objects;
        },
        setProductDetailGrid() {
            let detailGrid = [];
            if (this.recipe) {
                this.recipe.automation ? detailGrid.push({ name: 'Automation', value: this.recipe.automation, detail: "Create or update record from other system data"}) : '';
                this.recipe.visibility ? detailGrid.push({ name: 'Visibility', value: this.recipe.visibility, detail: "Let user see key data from other system"}) : '';
                this.recipe.reporting ? detailGrid.push({ name: 'Reporting', value: this.recipe.reporting, detail: "Let user build and combine reports from other system data"}) : '';
                this.recipe.communication ? detailGrid.push({ name: 'Communication', value: this.recipe.communication, detail: "Trigger communication or segmentation based on other system data"}) : '';
            }

            this.productDetailGrid = detailGrid;
        },
    },
    computed: {
        filteredByObject() {
            let fields = [];

            if (this.recipe.objects) {
                this.recipe.objects.forEach(object => {
                    object.fields.map(field => {
                        fields.push({
                            id: field.id,
                            label: field.label,
                            type: field.type,
                            isRequired: field.isRequired,
                            object: {
                                name: object.name,
                                color: object.color,
                                icon: object.icon
                            }
                        })
                    })
                });
            }


            this.fields = fields

            return this.fields.filter((field) => {
                return !this.fieldsViewByObject || (field.object.name === this.fieldsViewByObject);
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
</style>