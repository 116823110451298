<template>
    <div>
        <v-toolbar
        dark
        extension-height="68"
        flat
        color="iq_darkerblue" 
        >
            <v-toolbar-title class="d-flex justify-space-between flex-row flex-wrap" style="width: 100%">
                <span class="d-flex align-center">
                    <span  class="display-3 mr-4">Integration Dashboard</span>
                    <v-btn color="primary" small> New Recipe</v-btn>
                </span>

            </v-toolbar-title>
        </v-toolbar>

        <v-container fluid class="pb-0 mb-n2 mr-auto">
            <v-row class="d-flex align-end">
                <v-col cols="12" md="6">
                    <v-tabs
                        v-model="tab"
                        show-arrows
                        dense
                        grow
                        @change="tabsToSelect"
                        class="flex-grow-1"
                    >
                        <v-tab 
                            v-for="(dashboardTab, tabIndex)  in dashboardTabs"
                            :key="tabIndex"
                            :class="tab  == dashboardTab.id ? 'v-tab--active' : ''"
                        >
                            <v-badge
                                small
                                color="primary"
                                :content="updateCounters (dashboardTab.value)"
                                >
                                {{ dashboardTab.label }} 
                            </v-badge>
                        </v-tab>
                    </v-tabs>  
                </v-col>
                <v-col cols="12" md="6">
                    <v-toolbar
                    class="flex-grow-0"
                    elevation="0"
                    flat
                    >
                        <v-btn-toggle mandatory class="mr-1 d-none" v-model="selectedView">
                            <v-btn v-for="view in views" :key="view.name">
                                <v-icon>{{view.icon}}</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                        <v-text-field
                            v-model="search"
                            clearable
                            dense
                            flat
                            solo
                            outlined
                            hide-details
                            prepend-inner-icon="mdi-magnify"
                            label="Search"
                            class="flex-grow-1 mr-1 compact"
                        ></v-text-field>
                        <v-select 
                            flat
                            dense
                            solo
                            outlined
                            hide-details
                            label="by Type" 
                            item-text="label"
                            item-value="value"
                            prepend-inner-icon="mdi-filter"
                            :items="dashboardTabs"
                            v-model="viewByType"
                            v-on:change="selectedToTabs"
                            class="flex-grow-1 mr-1 "
                        ></v-select>
                        <template v-if="$vuetify.breakpoint.mdAndUp" >
                            <v-select
                                v-model="sortBy"
                                flat
                                dense
                                solo
                                outlined
                                hide-details
                                :items="orderByKeys"
                                item-value="value"
                                item-text="label"
                                prepend-inner-icon="mdi-magnify"
                                label="Sort by"
                                class="flex-grow-0 mr-1"
                            ></v-select>
                            <v-spacer></v-spacer>
                            <v-btn-toggle
                                v-model="sortDesc"
                                mandatory
                            >
                                <v-btn
                                depressed
                                :value="false"
                                >
                                    <v-icon small>mdi-arrow-up</v-icon>
                                </v-btn>
                                <v-btn
                                depressed
                                :value="true"
                                >
                                    <v-icon small>mdi-arrow-down</v-icon>
                                </v-btn>
                            </v-btn-toggle>
                            
                        </template>
                    </v-toolbar>
                </v-col>
            </v-row>
        </v-container>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                {{tab.label}}
            </v-tab-item>
        </v-tabs-items>
        <v-container fluid>
            <v-data-iterator
            :items="filteredItems"
            :page.sync="page"
            :search="search"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :items-per-page="16"
            :footer-props="{
                itemsPerPageOptions: [8,16,22,25,50,100, -1]
            }">
                <template v-slot:default="props">
                    <v-row>
                        <v-col
                            v-for="(item, cardIndex) in props.items"
                            :key="cardIndex"
                            :class="views[selectedView].value"
                        >
                            <v-card
                                :height="views[selectedView].cardHeight"
                                class="card-integration mx-auto d-flex flex-column lighten-4"
                                rounded
                                outlined
                                :color="getColorByCategory( item.recipe[1])"
                                elevation="2"
                            >
                                <v-sheet 
                                    class="systems-sheet pa-1 d-flex"
                                    rounded
                                    elevation="3"
                                    :height="views[selectedView].cardHeight - 30"
                                    :width="views[selectedView].cardHeight * 2"
                                    outlined
                                    :data-border-color="getColorByCategory( item.recipe[1])"
                                > 
                                    <v-row class="d-flex align-center justify-center">
                                        <v-col cols="6" class="text-center">
                                        <!-- <img  :src="require('@/assets/images/integrations/' + getSystemLogoURL(item.source[0]))" /> -->
                                        <v-img 
                                            :src="require('@/assets/images/integrations/' + getSystemLogoURL(item.source[0]))"
                                            contain
                                            max-height="75px"
                                            :alt="item.source[0]"
                                        ></v-img>
                                        {{getSystemTitleURL(item.source[0])}}
                                        </v-col>
                                        <v-col cols="6" class="text-center">
                                            <v-img 
                                                :src="require('@/assets/images/integrations/' + getSystemLogoURL(item.destination[0]))"
                                                contain
                                                max-height="75px"
                                                :alt="item.destination[0]"
                                            ></v-img>
                                            {{getSystemTitleURL(item.destination[0])}}
                                        </v-col>
                                    </v-row>
                                </v-sheet>
                                <div class="card-integration-content d-flex flex-column">
                                    <v-card-title class="d-flex flex-row justify-space-between"> 
                                        <div class="d-inline-flex flex-column align-start">
                                            <h4 class="font-weight-light text-uppercase d-block mb-0">{{item.name}} </h4>
                                            <h6 class="font-weight-light text-uppercase d-block">{{item.recipe[0]}} ({{item.recipe[1]}})</h6>
                                        </div>
                                        <div class="d-flex flex-row align-center" v-if="item.category !=='inDev'">
                                            <span class="mr-3 d-flex flex-row align-center">
                                                <v-progress-circular
                                                :rotate="360"
                                                :size="65"
                                                :width="7"
                                                :value="getProgressPercentages( item.totalEntries, item.processed, item.errorCount)[1] + '%'"
                                                color="success"
                                                class="mr-1"
                                                >
                                                    <span>{{getProgressPercentages( item.totalEntries, item.processed, item.errorCount)[1].toFixed(0)}}%</span>
                                                </v-progress-circular>
                                                <span class="d-flex flex-column">
                                                    <span class="overline">Processed</span>
                                                    <span class="caption">{{item.processed}} entries</span>
                                                </span>
                                                
                                            </span>
                                            <span class="mr-3 d-flex flex-row">
                                                <v-progress-circular
                                                    :rotate="360"
                                                    :size="55"
                                                    :width="5"
                                                    :value="getSuccessRate( item.processed, item.errorCount) + '%'"
                                                    color="success"
                                                    class="mr-1"
                                                    >
                                                    <span class="caption">{{getSuccessRate( item.processed, item.errorCount).toFixed(2)}}%</span>
                                                </v-progress-circular>

                                                <span class="d-flex flex-column">
                                                    <span class="overline  text-uppercase">Succeed Rate</span>
                                                    <span class="caption"> <span class="error--text">{{item.errorCount}} failed </span> of {{item.processed}} processed</span>
                                                </span>
                                                
                                            </span>
                                            <!-- <span class="d-flex flex-row">
                                                <v-progress-circular
                                                indeterminate
                                                :size="55"
                                                :width="1"
                                                class="mr-1"
                                                color="iq_lightblue"
                                                >
                                                <span class="caption">1h:30m</span>
                                                </v-progress-circular>
                                                <span class="d-flex flex-column">
                                                    <span class="overline  text-uppercase">Ellapsed</span>
                                                    <span class="caption">{{item.processed}} of {{item.totalEntries}} entries</span>
                                                </span>
                                            </span> -->
                                            <v-btn small fab text> <v-icon> mdi-dots-vertical </v-icon></v-btn>
                                        </div>
                                        <div class="d-flex flex-row align-center" v-else>
                                            <span class="d-flex flex-column mr-2">
                                                <span class="overline  text-uppercase">Estimated Release:</span>
                                                <span class="caption">{{formatDateTimeToDate(item.estimatedRelease)}}</span>
                                            </span>
                                            <span class="d-flex flex-column mr-2">
                                                <span class="overline  text-uppercase">Last Update:</span>
                                                <span class="caption">{{formatDateTimeToDate(item.lastUpdate)}}</span>
                                            </span>
                                            <span class="d-flex flex-column mr-2">
                                                <span class="overline  text-uppercase">Created:</span>
                                                <span class="caption">{{formatDateTimeToDate(item.created)}}</span>
                                            </span>
                                            <v-btn small fab text> <v-icon> mdi-dots-vertical </v-icon></v-btn>
                                        </div>
                                    </v-card-title>
                                    <v-card-text class="card-contents overflow-hidden v-sheet--offset " v-if="item.category !=='inDev'">
                                        <v-row class="d-flex flex-row align-center">
                                            <v-col cols="auto" class="flex-grow-1">
                                                <v-progress-linear
                                                    background-color="error"
                                                    :value="getProgressPercentages( item.totalEntries, item.processed, item.errorCount)[0]"
                                                    stream
                                                    :buffer-value="getProgressPercentages( item.totalEntries, item.processed, item.errorCount)[1]"
                                                    color="success lighten-2"
                                                    height="10"
                                                ></v-progress-linear>
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn elevation="1" small fab> <v-icon large> mdi-chevron-down </v-icon></v-btn>
                                            </v-col>
                                        </v-row>
                                         
                                    </v-card-text>
                                    <v-card-text class="card-contents overflow-hidden v-sheet--offset " v-else>
                                        <v-stepper alt-labels flat class="opaque" v-model="item.step">
                                            <v-stepper-header>
                                                    <v-stepper-step :complete="item.step  > 1" step="1" >
                                                        Recipe Chosen
                                                        <small>Created</small>
                                                    </v-stepper-step>
                                                    <v-divider></v-divider>
                                                    <v-stepper-step :complete="item.step  > 2" step="2" >
                                                        Building
                                                        <small>In Development</small>
                                                    </v-stepper-step>
                                                    <v-divider></v-divider>
                                                    <v-stepper-step :complete="item.step  > 3" step="3">
                                                        Testing
                                                        <small>Integrity QA</small>
                                                    </v-stepper-step>
                                                    <v-divider></v-divider>
                                                    <v-stepper-step :complete="item.step  > 4" step="4" :editable="item.step === 4" @click="triggerApprovalFlow(item.step)">
                                                        Needs Approval
                                                        <small>Verify and approve</small>
                                                    </v-stepper-step>
                                                    <v-divider></v-divider>
                                                    <v-stepper-step :complete="item.step  > 5" step="5">
                                                        Go Live
                                                        <small>Ready, Set, Go!</small>
                                                    </v-stepper-step>
                                            </v-stepper-header>
                                        </v-stepper>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn small :color="action.color" v-for="(action, actionIndex) in item.actions" :key="actionIndex">
                                            {{action.label}}
                                        </v-btn>
                                    </v-card-actions>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </template>
            </v-data-iterator>
        </v-container>
    </div>
</template>
<script>
import img from '../../../assets/images/integrations/netsuite.png';

export default {
    data () {
        return {
            //data interator
            itemsPerPageArray: [4, 8, 12],
            search: '',
            filter: {},
            orderByKeys: [
                { value: 'name', label:'Order by Name'},
                { value: 'recipe[0]', label:'Order by recipe name'},
                { value: 'name', label:'Order by created date'},
                { value: 'lastRun', label:'Order by last run date'},
                { value: 'processed', label:'Order by Processed Items'},
                { value: 'totalEntries', label:'Order by Size of Integration'},
                { value: 'errorCount', label:'Order by Errors Ocurred'},

            ],
            sortDesc: false,
            page: 1,
            itemsPerPage: 8,
            sortBy: 'name',
            //data interator ends
            tab: 0, 
            dashboardTabs: [
                { id:"0" , value: "Active", label: "Active", count: null},
                // { id:"1" , value: "Completed", label: "Completed", count: null},
                { id:"2" , value: "inDev", label: "inDev", count: null},
                { id:"3" , value: null, label: "All", count: null}
            ],
            viewByType: 'Active',
            cardData: [
                    {
                        name: 'Company Gross Revenue to HS',
                        recipe: ['Revenue Gross','Finance'],
                        category: 'Active',
                        isInDev: false,
                        step: 1,
                        source: ['Freshbooks', 0],
                        destination: ['salesforce', 0],
                        processed: 23323,
                        totalEntries: 33000,
                        errorCount: 10,
                        created: '01 Jan 2020 00:30:00 GMT',
                        lastRun: '01 Jan 2022 00:30:00 GMT',
                        estimatedRelease: '01 Jan 2022 00:30:00 GMT',
                        lastUpdate: '01 Jan 2022 00:30:00 GMT',
                        actions: [
                            {value: null, color: 'primary', label:'Recipe Page', click() { this.goToRecipe() } },
                            {value: null, color: '', label:'Get Help', click() { this.getHelp() } },
                            {value: null, color: '', label:'Logs', click() { this.goToLogs() } },
                        ],
                        secondaryActions: null,
                    },
                    {
                        name: 'Product Sales  to Hubspot Deals',
                        recipe: ['Sales per Product','Sales'],
                        category: 'Active',
                        isInDev: false,
                        step: 1,
                        source: ['activecampaign', 0],
                        destination: ['Hubspot', 0],
                        processed: 2323,
                        totalEntries: 50000,
                        errorCount: 300,
                        created: '01 Jan 2020 00:30:00 GMT',
                        lastRun: '01 Jan 2022 00:30:00 GMT',
                        estimatedRelease: '01 Jan 2022 00:30:00 GMT',
                        lastUpdate: '01 Jan 2022 00:30:00 GMT',
                        actions: [
                            {value: null, color: 'primary', label:'Recipe Page', click() { this.goToRecipe() } },
                            {value: null, color: '', label:'Get Help', click() { this.getHelp() } },
                            {value: null, color: '', label:'Logs', click() { this.goToLogs() } },
                        ],
                        secondaryActions: null,
                    },
                    {
                        name: 'Leads to potential sales',
                        recipe: ['Potential Customers','Marketing'],
                        category: 'Active',
                        isInDev: false,
                        step: 1,
                        source: ['microsoftdynamics365crm', 0],
                        destination: ['Hubspot', 0],
                        processed: 19023,
                        totalEntries: 20000,
                        errorCount: 50,
                        created: '01 Jan 2020 00:30:00 GMT',
                        lastRun: '01 Jan 2022 00:30:00 GMT',
                        estimatedRelease: '01 Jan 2022 00:30:00 GMT',
                        lastUpdate: '01 Jan 2022 00:30:00 GMT',
                        actions: [
                            {value: null, color: 'primary', label:'Recipe Page', click() { this.goToRecipe() } },
                            {value: null, color: '', label:'Get Help', click() { this.getHelp() } },
                            {value: null, color: '', label:'Logs', click() { this.goToLogs() } },
                        ],
                        secondaryActions: null,
                    },
                    {
                        name: 'Website Visitor to Hubspot',
                        recipe: ['Tracked Visitors','Marketing'],
                        category: 'inDev',
                        isInDev: true,
                        step: 4,
                        source: ['marketo', 0],
                        destination: ['sugarcrm', 0],
                        processed: 5323,
                        totalEntries: 10000,
                        errorCount: 500,
                        created: '01 Jan 2020 00:30:00 GMT',
                        lastRun: '01 Jan 2022 00:30:00 GMT',
                        estimatedRelease: '01 Jan 2022 00:30:00 GMT',
                        lastUpdate: '01 Jan 2022 00:30:00 GMT',
                        actions: [
                            {value: null, color:'primary', label:'Recipe Page', click() { this.goToRecipe() } },
                            {value: null, color:'', label:'Get Help', click() { this.getHelp() } },
                            {value: null, color:'', label:'Logs', click() { this.goToLogs() } },
                        ],
                        secondaryActions: null,
                    },
                    {
                        name: 'Update Country Revenue',
                        recipe: ['Revenue per Country','Finance'],
                        category: 'inDev',
                        isInDev: false,
                        step: 2,
                        source: ['NetSuite', 0],
                        destination: ['Quickbooks', 0],
                        processed: 1023,
                        totalEntries: 5000,
                        errorCount: 100,
                        created: '01 Jan 2020 00:30:00 GMT',
                        lastRun: '01 Jan 2022 00:30:00 GMT',
                        estimatedRelease: '01 Jan 2022 00:30:00 GMT',
                        lastUpdate: '01 Jan 2022 00:30:00 GMT',
                        actions: [
                            {value: null, color:'primary', label:'Recipe Page', click() { this.goToRecipe() } },
                            {value: null, color:'', label:'Get Help', click() { this.getHelp() } },
                            {value: null, color:'', label:'Logs', click() { this.goToLogs() } },
                        ],
                        secondaryActions: null,
                    }
                ],
                views: [
                    { value: 'card boxes col-sm-6 col-md-4 col-lg-3 col-xl-2 col-12',               cardHeight: '300', name: 'boxes', icon: 'mdi-view-grid'},
                    { value: 'card-row two-rows col-sm-6 col-md-6 col-lg-6 col-xl-6 col-12',        cardHeight: '300', name: 'twoRow', icon: 'mdi-dots-grid'},
                    { value: 'card-row rows-large col-12',                                          cardHeight: '300', name: 'rowsLarge', icon: 'mdi-view-list'},
                    { value: 'card-row rows-small col-12',                                          cardHeight: '195', name: 'rowsSmall', icon: 'mdi-format-list-bulleted-square'},
                ],
                selectedView: 3,
                integrationSystems: [ 
                    { name: 'recurly',                  title:'recurly',  src: 'Recurly.png'},
                    { name: 'salesforce',               title:'Sales Force',  src: 'SalesForce.png'},
                    { name: 'sendgrid',                 title:'sendGrid',  src: 'SendGrid.png'},
                    { name: 'smartsheet',               title:'Smartsheet',  src: 'smartsheet.png'},
                    { name: 'square',                   title:'Square',  src: 'square.png'},
                    { name: 'stripe',                   title:'Stripe',  src: 'Stripe.png'},
                    { name: 'sugarcrm',                 title:'Sugar CRM',  src: 'SugarCRM.png'},
                    { name: 'toast',                    title:'Toast',  src: 'toast.png'},
                    { name: 'trello',                   title:'Trello',  src: 'Trello.png'},
                    { name: 'xero',                     title:'Xero',  src: 'xero.png'},
                    { name: 'zendesk',                  title:'ZenDesk',  src: 'zendesk.png'},
                    { name: 'zohocrm',                  title:'Zoho CRM',  src: 'ZOHOCRM.png'},
                    { name: 'activecampaign',           title:'Active Campaign',  src: 'ActiveCampaign.png'},
                    { name: 'brighttree',               title:'Brighttree',  src: 'brighttree.png'},
                    { name: 'bullhorn',                 title:'Bullhorn',  src: 'bullhorn.png'},
                    { name: 'chargebee',                title:'Chargebee',  src: 'ChargeBee.png'},
                    { name: 'chargify',                 title:'Chargify',  src: 'chargify.png'},
                    { name: 'clover',                   title:'Clover',  src: 'clover.png'},
                    { name: 'drift',                    title:'Drift',  src: 'drift.png'},
                    { name: 'freshbooks',               title:'Freshbooks',  src: 'freshbooks.png'},
                    { name: 'freshdesk',                title:'Freshdesk',  src: 'Freshdesk.png'},
                    { name: 'getresponse',              title:'Getresponse',  src: 'getResponse.png'},
                    { name: 'hubspot',                  title:'Hubspot',  src: 'Hubspot.png'},
                    { name: 'insightly',                title:'Insightly',  src: 'insightly.png'},
                    { name: 'intercom',                 title:'Intercom',  src: 'intercom.png'},
                    { name: 'jira',                     title:'Jira',  src: 'jira.png'},
                    { name: 'keap',                     title:'Keap',  src: 'Keap.png'},
                    { name: 'klaviyo',                  title:'Klaviyo',  src: 'klaviyo.png'},
                    { name: 'marketo',                  title:'Marketo',  src: 'marketo.png'},
                    { name: 'microsoftdynamics365crm',  title:'Microsoft Dynamics 365 CRM',  src: 'MicrosoftDynamics365CRM.png'},
                    { name: 'microsoftdynamicsax',      title:'Microsoft Dynamics AX',  src: 'MicrosoftDynamicsAX.png'},
                    { name: 'microsoftdynamicsgp',      title:'Microsoft Dynamics GP',  src: 'MicrosoftDynamicsGP.png'},
                    { name: 'mindbody',                 title:'Mindbody',  src: 'mindbody.png'},
                    { name: 'netsuite',                 title:'NetSuite',  src: 'netsuite.png'},
                    { name: 'oracleeloquia',            title:'Oracle Eloquia',  src: 'OracleEloquia.png'},
                    { name: 'oraclesalescloud',         title:'oracle Sales Cloud',  src: 'oracleSalesCloud.png'},
                    { name: 'pipedrive',                title:'Pipedrive',  src: 'pipedrive.png'},
                    { name: 'quickbooks',               title:'Quickbooks',  src: 'quickbooks.png'},
                ]
            }

            
        },
        methods: {
            goToRecipe() {

            },
            getHelp(){

            },
            goToLogs(){

            },
            triggerApprovalFlow(step){
                if(step === 4){

                }
            },
            tabsToSelect(){
                var selectionValue = this.dashboardTabs[this.tab].value;
                this.viewByType = selectionValue;
            },
            selectedToTabs(){
                var index = this.dashboardTabs.findIndex(x => x.value === this.viewByType);
                this.tab = index;
            },
            getSystemLogoURL( systemName ){
                let imageName = systemName.toLowerCase();
                var index = this.integrationSystems.findIndex (x => x.name === imageName );
                return this.integrationSystems[index].src;
            },
            getSystemTitleURL( systemName ){
                let imageName = systemName.toLowerCase();
                var index = this.integrationSystems.findIndex (x => x.name === imageName );
                return this.integrationSystems[index].title;
            },
            getColorByCategory(cat){
                switch (cat){
                    case 'Sales': 
                        return 'iq_lightslategray';
                    break;
                    case 'Marketing':
                        return 'primary';
                    break;
                    case 'Support': 
                        return 'accent';
                    break;
                    case 'Finance':
                        return 'success '
                    break;
                    default:
                        return 'iq_aquagreen'
                }
            },
            getProgressPercentages( total, processed, errors){
                let netProcess  = processed - errors;
                let globalPercent = netProcess * 100 / total;
                let processPercent = processed * 100 / total;
                return [ globalPercent , processPercent];
            },
            getSuccessRate ( processed, errors){
                let rate = 100 - (errors * 100 / processed);
                return rate;
            },
            updateCounters( value ) {
                let count = null;

                if ( value !== null )
                {
                    count = this.cardData.filter(obj => {
                        if (obj.category === value) {
                            return true;
                        }
                        return false;
                    }).length;
                }
                else{
                    count = this.cardData.length;
                }
               
                return count;
            }            
        
        },
        mounted(){
            
        },
        computed: {
            filteredItems() {
                return this.cardData.filter((i) => {
                    return !this.viewByType || (i.category === this.viewByType);
                })
            },

        }
        
}
</script>
<style lang="scss" scoped>

    .card-row{
        padding-left: 62px;
        .card-integration{
            display: inline-flex;
            flex-direction: row !important;
            align-items: center;
            .systems-sheet{
                margin-left: -50px;
                position: relative; 
            }
            .card-integration-content {
                position: relative;
                flex-grow: 1;
            }
        }

        &.rows-small{
            .card-integration{
                height: auto !important;
            }

        }
    }
    .v-stepper.opaque{
        background-color: transparent;
    }
    .card-integration{

    }
</style>