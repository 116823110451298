<template>
    <v-row justify="center">
        <v-dialog v-model="showDialog" persistent max-width="600px" @keydown="handleCloseOnEscape">
            <v-card :ripple="false">
                <v-card-title class="d-flex align-center justify-space-between">
                    <span role="heading" aria-level="2" class="headline">
                        {{ createEditTitle }}
                    </span>
                    <v-btn
                        icon
                        @click="closeDialog"
                        ref="createEditCloseButton"
                        @keydown="handleShiftTabOnModalFirstElement"
                        :aria-label="this.isEdit ? 'Exit edit' : 'Exit add'"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-form ref="addEditProductFrom" v-model="valid">
                                    <v-text-field
                                        outlined
                                        v-model="product.id"
                                        label="Product Id*"
                                        required
                                        :rules="requiredRule.required"
                                        :disabled="this.isEdit"
                                    ></v-text-field>
                                    <!-- v-if="this.isEdit" -->
                                    <v-text-field
                                        outlined
                                        v-model="product.portalId"
                                        label="Portal Id*"
                                        required
                                        :rules="requiredRule.required"
                                        :disabled="this.isEdit"
                                    ></v-text-field>
                                    <v-text-field
                                        outlined
                                        v-model="product.name"
                                        label="Name*"
                                        required
                                        :rules="requiredRule.required"
                                    ></v-text-field>
                                    <v-text-field
                                        outlined
                                        v-model="product.description"
                                        label="Description*"
                                        required
                                        :rules="requiredRule.required"
                                    ></v-text-field>
                                    <v-text-field
                                        outlined
                                        v-model="product.sku"
                                        label="SKU*"
                                        required
                                        :rules="requiredRule.required"
                                    ></v-text-field>
                                    <v-text-field
                                        outlined
                                        v-model="product.systemId"
                                        label="System Id*"
                                        required
                                        :rules="requiredRule.required"
                                    ></v-text-field>
                                    <v-text-field
                                        outlined
                                        v-model="product.hubSpotProductId"
                                        label="HubSpot Product Id*"
                                        required
                                        :rules="requiredRule.required"
                                    ></v-text-field>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn ref="deleteProductButton" text @click="deleteProduct" v-on:keyup.enter="deleteProduct" v-if="isEdit" outlined>Delete Product</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="closeDialog"> Cancel </v-btn>
                    <v-btn color="primary" @keydown="handleTabOnModalLastElement" @click="isEdit ? editProduct() : addProduct()" :disabled="!valid" :loading="loadingBtn" outlined>{{ okButtonText }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
export default {
    props: {
        createEditTitle: {
            type: String,
            default: "Add new product"
        },
        okButtonText: {
            type: String,
            default: "Add"
        },
        isEdit: {
            type: Boolean
        },
        editId: {
            type: String
        },
        editPortalId: {
            type: Number
        }
    },
    mounted() {
        setTimeout(() => {
            if(this.$refs.createEditCloseButton) {
                // this.$refs.createEditCloseButton.focus()
            }
        }, 500);
    },
    async created() {
        if(this.isEdit) {
            const product = await this.$store.dispatch('stockIQ/getProduct', {"id": this.editId, "portalId": this.editPortalId});

            if(product) {
                this.product = product;
            }
        
        }
    },
    data() {
        return {
            showDialog: true,
            valid: false,
            loadingBtn: false,

            product: {
                id: '',
                portalId: "",
                name: "",
                description: "",
                sku: "",
                systemId: "",
                hubSpotProductId: "",
            },

            requiredRule: {
                required: [v => !!v || 'Required.'],
            }
        }
    },
    methods: {
        closeDialog() {
            this.$emit("closeDialog");
        },
        handleCloseOnEscape(e) {
            if(e.key == "Escape") {
                this.closeDialog();
            }
        },
        handleShiftTabOnModalFirstElement(e) {
            if(e.shiftKey && e.key === "Tab") {
                e.preventDefault();
            }
        },
        handleTabOnModalLastElement(e) {
            if(e.shiftKey) return;
            if(!e.shiftKey && e.key === "Tab") e.preventDefault();
        },
        async addProduct() {
            this.loadingBtn = true;
            const response = await this.$store.dispatch('stockIQ/createProduct', { "product": this.product });

            this.loadingBtn = false;

            if(response) {
                this.$emit("closeDialog", true);
            } else {
                this.$swal({
                    title: `Unable to create product.`,
                    icon: 'warning',
                    showDenyButton: false,
                    confirmButtonText: "Close"
                });
            }
        },
        async editProduct() {
            this.loadingBtn = true;
            const response = await this.$store.dispatch('stockIQ/updateProduct', { "product": this.product });

            this.loadingBtn = false;
            
            if(response) {
                this.$emit("closeDialog", true);
            } else {
                this.$swal({
                    title: `Unable to edit this product.`,
                    icon: 'warning',
                    showDenyButton: false,
                    confirmButtonText: "Close"
                });
            }
        },
        async deleteProduct() {
            this.loadingBtn = true;
            const response = await this.$store.dispatch('stockIQ/deleteProduct', {"id": this.editId, "portalId": this.editPortalId});

            this.loadingBtn = false;
            
            if(response && response.status == 200) {
                this.$emit("closeDialog", true);
            } else {
                this.$swal({
                    title: `Unable to delete this product.`,
                    icon: 'warning',
                    showDenyButton: false,
                    confirmButtonText: "Close"
                });
            }
        }
    }
}
</script>