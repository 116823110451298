<template>
    <v-row style="height: 100%;" class="registration-wrapper flex-wrap" >
        <v-overlay :value="loading">
            <v-progress-circular
            indeterminate
            size="70"
            width="7"
            color="primary"
            ></v-progress-circular>
        </v-overlay>
        <v-col md="4" lg="3" cols="12" :style="this.$vuetify.breakpoint.smAndDown ? 'z-index: 0;': 'z-index: 5;' "  class="registration-left px-8" v-if="currentStep">
            <div class=" d-flex flex-column justify-space-between " :class="$vuetify.breakpoint.mdAndUp ? 'wrapper-desktop':'wrapper-mobile'">
                <div class="registration-logo text-center py-6" v-if="$vuetify.breakpoint.mdAndUp">
                    <v-icon class="iq_light--text auto-limited-150">$smartRampIcon</v-icon>
                </div>
                <div class="registration-logo  d-flex justify-center text-center pt-2 pb-2" v-else>
                    <v-icon class="iq_light--text auto-limited-150">$smartRampIcon</v-icon>
                    <v-divider class="ml-6 mr-4"  vertical></v-divider>
                    <v-icon v-if="getProductNameIcon(this.productName)  " class="iq_light--text auto-limited-150"> {{ getProductNameIcon(this.productName)}}</v-icon>     
                </div>

                <div class="registration-info white--text" :class="this.$vuetify.breakpoint.smAndDown ? 'text-center':''" :style="this.$vuetify.breakpoint.smAndDown ? 'z-index: 10;':''">
                    <div class="d-flex flex-row justify-center"><v-icon class="iq_light--text auto-limited-150" v-if="$vuetify.breakpoint.mdAndUp"  > {{ getProductNameIcon(this.productName)}}</v-icon></div>   
                    <span v-if="currentStep.stepTitle" :class="this.$vuetify.breakpoint.smAndDown ? 'text-h5':'text-h2'" class="font-weight-light mb-4 d-block text-center line-1em" >{{ currentStep.stepTitle }}</span>
                    <div v-if="$vuetify.breakpoint.smAndDown">

                        <v-btn x-small rounded color="primary" @click="mobileShowmore = !mobileShowmore">
                            <span v-if="mobileShowmore">Less info...</span>
                            <span v-else>More info...</span>
                        </v-btn>
                        <v-expand-transition>
                            <v-row  v-show="mobileShowmore">
                                <span v-if="currentStep.steptSubTitle" :class="this.$vuetify.breakpoint.smAndDown ? 'body-2':'text-h5'" class="font-weight-thin text-center d-block" >{{ currentStep.steptSubTitle }}</span>
                                <span v-if="currentStep.stepDesc" :class="this.$vuetify.breakpoint.smAndDown ? 'body-1':'text-h5'" class="mt-4 font-weight-thin text-center d-block" v-html="currentStep.stepDesc "></span>
                            </v-row>
                        </v-expand-transition>
                    </div>
                    <div  v-else>
                        <span v-if="currentStep.steptSubTitle" :class="this.$vuetify.breakpoint.smAndDown ? 'body-2':'text-h5'" class="font-weight-thin text-center d-block" >{{ currentStep.steptSubTitle }}</span>
                        <span v-if="currentStep.stepDesc" :class="this.$vuetify.breakpoint.smAndDown ? 'body-1':'text-h5'" class="mt-4 font-weight-thin text-center d-block" v-html="currentStep.stepDesc "></span>
                    </div>


                </div>
                <div :class="this.$vuetify.breakpoint.smAndDown ? 'm-0':'mb-10' " :style="this.$vuetify.breakpoint.smAndDown ? 'z-index: 1 ':''">
                    <v-img 
                        v-if="!imgUrlPromise && imgUrlLoaded"
                        contain
                        transition="scale-transition"
                        :class="this.$vuetify.breakpoint.smAndDown ? 'img-to-bg': ''"
                        :src="imgUrl"
                        max-width="350"
                        class="ml-auto mr-auto"
                    >
                    </v-img>
                </div>
            </div>
            
        </v-col> 
        <v-col cols="12" offset="0" 
            class="pb-16"
            :md="this.$route.name == 'summary' ? '12':'8'" 
            :lg=" this.$route.name === 'summary' ? '12' : '7' " 
            :offset-lg="this.$route.name === 'summary' ? '0' : '1'" 
            :class="this.$route.name == 'summary' ? 'summary':'d-flex flex-column justify-center registration-main'"
        >
            
            <router-view></router-view>
        </v-col>
    </v-row>
</template>
<script>
    import { mapGetters } from 'vuex';
    export default {
        computed:{
            ...mapGetters({
                productName: 'productRegistration/getProductName',
                steps: 'productRegistration/getAppSteps',
                loading: 'productRegistration/getLoading',
            }),
            currentStep() {
                return this.steps.find((step) => step.stepName === this.$route.name) || null;
            },
            imgUrlPromise() {
                return this.imgUrl instanceof Promise;
            },
        },
        data () {
            return {
                imgUrl: null,
                hasSRUI: false,
                imgUrlLoaded: false, // Added new property to track when the imgUrl is loaded
                mobileShowmore: false,
            }
        },
        async created() {
            await this.loadImage();
        },
        watch: {
            'currentStep.stepImg': 'loadImage',
            // Watch for changes in the route
            $route(to, from) {
                // You can access the current route and its parameters here
                // For example, to check the name of the current route
                if (to.name === 'summary') {
                    this.hasSRUI = true;
                    // Render specific content when ChildPageA is active
                    // For example:
                    // this.showChildPageAContent = true;
                    // this.showChildPageBContent = false;
                } else{
                    this.hasSRUI = false;
                    // Render specific content when ChildPageB is active
                    // For example:
                    // this.showChildPageAContent = false;
                    // this.showChildPageBContent = true;
                }
            }
        },
        methods: {
            async loadImage() {
                this.imgUrlLoaded = false; // Set to false before loading image
                const imgFile = this.currentStep ? this.currentStep.stepImg : null;
                if(imgFile) this.imgUrl = await this.getImgUrl(imgFile);
                this.imgUrlLoaded = true; // Set to true once the image is loaded
            },
            async getImgUrl(imgFile) {
                try {
                    const context = await import(`../../../assets/images/product/${this.productName}/${imgFile}.png`);
                    return context.default;
                } catch (error) {
                    console.error("Error loading image:", error);
                    return null;
                }
            },
            getProductNameIcon(productQueryParam){
                let productIcon = null;
                if(productQueryParam){
                    switch(productQueryParam) {
                        case 'StatusIQ':
                            productIcon = "$statusIQIcon";
                            break;
                        case 'MessageIQ':
                            productIcon = "$messageIQIcon";
                            break;
                        case 'PriceIQ':
                            productIcon = "$priceIQIcon";
                            break;
                        case 'StockIQ':
                            productIcon = "$stockIQicon";
                            break;
                    }
                }
                return productIcon;
            }
        }
    }
</script>
<style lang="scss" scoped>
    .line-1em{
        line-height: 1em;
    }
    .wrapper-desktop{
        min-height: 100vh;
        position: sticky;
        top: 0px;
        bottom: 0px;
    }
</style>