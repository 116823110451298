<template>

    <v-container fluid>
        <v-row class="mb-8">
            <v-col cols="12">
                <v-card flat class="theme-breakpoint-card-switch" :dark="$vuetify.breakpoint.smAndDown">   
                    <v-card-title class="d-flex justify-center text-center align-center  title-word-break  font-weight-bold" :class="$vuetify.breakpoint.smAndDown ? 'text-h4':'text-h1'">
                        {{ formatIQName(productName)}} - Payment for {{currentPlan.name}} Plan 
                    </v-card-title>
                    <v-card-subtitle class="d-flex justify-center text-center align-center text-h6 mt-2 mb-8" >
                        Your Payment method for {{formatIQName(productName)}} 
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="8">
                <v-card flat class="theme-breakpoint-card-switch" :dark="$vuetify.breakpoint.smAndDown">   
                    <v-card-text>
                        <form ref="form" @submit.prevent="submitRecurlyPayment" v-show="showForm">

                                <v-row>
                                    <div ref="recurly-card" class="recurly-card" data-recurly="card" style="width: 100%"></div>
                                    <input type="hidden" name="recurly-token" data-recurly="token">
                                    <p v-if="recurlyTokenError !== ''" style="color: red">{{recurlyTokenError}}</p>
                                </v-row>
                                <v-form v-model="valid">
                                    <v-row>
                                        <v-text-field
                                        label="First Name"
                                        data-recurly="first_name"
                                        required
                                        v-model="billing.firstName"
                                        :rules="requiredRule.select"
                                        ></v-text-field>
                                    </v-row>
                                    <v-row>
                                        <v-text-field
                                        label="Last Name"
                                        data-recurly="last_name"
                                        required
                                        v-model="billing.lastName"
                                        :rules="requiredRule.select"
                                        ></v-text-field>
                                    </v-row>
                                    <v-row>
                                        <v-text-field
                                        label="Address"
                                        data-recurly="address1"
                                        required
                                        v-model="billing.address"
                                        :rules="requiredRule.select"
                                        ></v-text-field>
                                    </v-row>
                                    <v-row>
                                        <v-text-field
                                        label="Address 2"
                                        data-recurly="address2"
                                        v-model="billing.address2"
                                        ></v-text-field>
                                    </v-row>
                                    <v-row>
                                        <v-text-field
                                        label="City"
                                        data-recurly="city"
                                        v-model="billing.city"
                                        :rules="requiredRule.select"
                                        required
                                        ></v-text-field>
                                    </v-row>
                                    <v-row> 
                                        <v-select
                                        :items="states"
                                        label="State"
                                        v-model="billing.state"
                                        :rules="requiredRule.select"
                                        required
                                        ></v-select>
                                    </v-row>
                                    <v-row>
                                        <v-text-field
                                        label="Zip"
                                        data-recurly="postal_code"
                                        v-model="billing.zip"
                                        :rules="requiredRule.select"
                                        required
                                        ></v-text-field>
                                    </v-row>
                                    <v-row>
                                        <v-text-field
                                        label="Company"
                                        data-recurly="company_name"
                                        v-model="company.name"
                                        :rules="requiredRule.select"
                                        required
                                        ></v-text-field>
                                    </v-row>
                                </v-form>
                                
                                <input type="hidden" value="US" data-recurly="country" />
                                <input type="hidden" v-model="billing.state" data-recurly="state" />
                        </form>
                        <v-card class="theme-breakpoint-card-switch"  outlined v-show="!showForm" >
                            <v-card-title>Registered card:</v-card-title>
                            <v-card-text class="d-flex flex-column align-center justify-center">
                                    <v-icon v-if="this.billingDetails.billingDetails.cardType === 'Visa'" class="auto-limited-150">$cardVisa</v-icon>
                                    <v-icon v-else-if=" this.billingDetails.billingDetails.cardType === 'MasterCard'" class="auto-limited-150">$cardMastercard</v-icon>
                                    <v-icon v-else-if=" this.billingDetails.billingDetails.cardType === 'American Express'" class="auto-limited-150">$cardAmex</v-icon>
                                    <v-icon v-else-if=" this.billingDetails.billingDetails.cardType === 'Discover'" class="auto-limited-150">$cardDiscovery</v-icon>
                                    <v-icon v-else class="auto-limited-150">mdi-credit-card-outline</v-icon>
                                    <v-text-field
                                        x-large
                                        label="Card Last Four"
                                        v-model="billing.cardLastFour"
                                        :rules="requiredRule.select"
                                        disabled
                                        outlined
                                        hide-details
                                        class="mt-4 flex-grow-0"
                                    >
                                    </v-text-field>
                                </v-card-text>
                                    
                            </v-card>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card rounded elevation="2"  :color="$vuetify.breakpoint.smAndDown ? 'iq_darkerblue lighten-2' : ''" :dark="$vuetify.breakpoint.smAndDown">
                    <v-card-title>Order Summary</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-row class="py-10"> 
                            <v-col class="text-h6">
                                {{ formatIQName(productName)}} <br/> {{currentPlan.name}} Plan
                            </v-col>
                            <v-col>
                                <div class="plan-price text-h3 d-flex flex-start">
                                    <span class="sup font-weight-medium">$</span>
                                    <span class="font-weight-medium">{{splitDecimals(currentPlan.price)[0]}}</span>
                                    <div class="d-flex flex-start flex-column">
                                      <span class="sup font-weight-medium"> {{splitDecimals(currentPlan.price)[1]}} </span>
                                      <span class="sub">{{currentPlan.frequency}}</span>
                                    </div>
                                  </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="d-flex flex-row justify-space-between">
                <v-btn :dark="$vuetify.breakpoint.smAndDown" large outlined color="primary" @click="goBack()"> Back </v-btn>
                <v-btn
                    :dark="$vuetify.breakpoint.smAndDown"
                    large
                    v-on:click="showForm ? submitRecurlyPayment() : submitPayment()"
                    color="primary"
                    :loading="loading"
                    :disabled="!valid || !isCardValidClass"
                >
                    Submit Payment: ${{commaSeparateNumber(currentPlan.price)}}
                </v-btn>
            </v-col>
        </v-row> 
    </v-container>
</template>

<script>
  import { mapGetters } from 'vuex';
  export default {
    name: "ProductPayment",
    computed:{
        ...mapGetters({
            productName: 'productRegistration/getProductName',
            currentPlan: 'productRegistration/getPlanId',
            company: 'company/getCurrentCompany',
            billingDetails:'company/getBillingDetails',
            currentUser: 'getUser',
        })
    },
    data: () => ({
        valid: false,
        billing: {
            recurlyToken: "",
            firstName: "",
            lastName: "",
            address: "",
            address2: "",
            city: "",
            state: "",
            zip: "",
            companyName: "",
            cardLastFour: ""
        },
        states: [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 
            'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 
            'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 
            'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ],
        recurlyTokenError: "",
        loading: false,
        requiredRule: {
                required: v => !!v || 'Required.',
                select: [(v) => !!v || "Required."],
        },
        showForm: false,
        isCardValidClass: false,
    }),
    async created(){
        await this.$store.dispatch('company/getBillingDetailsByCompanyId',this.company.id);
        if(this.billingDetails != null && this.billingDetails != undefined){
            if(this.billingDetails.billingDetails.recurlyCustomerId != null){
                this.billing.cardLastFour = this.billingDetails.billingDetails.cardLastFour;
                this.showForm = false;
                this.valid = true;
                this.isCardValidClass = true;
                
                this.billingDetails.billingDetails.cardType;
            }else{
                this.showForm = true;
                this.billing.firstName = this.billingDetails.billingDetails.firstName;
                this.billing.lastName = this.billingDetails.billingDetails.lastName;
                this.billing.address = this.billingDetails.billingDetails.address;
                this.billing.address2 = this.billingDetails.billingDetails.address2;
                this.billing.city = this.billingDetails.billingDetails.city;
                this.billing.state = this.billingDetails.billingDetails.state;
                this.billing.zip = this.billingDetails.billingDetails.zip;
            }
        }
    },
    mounted(){
        recurly.configure({publicKey: process.env.VUE_APP_RECURLY_ID});
        const elements = recurly.Elements()
        elements.CardElement(this.$refs['recurly-card'])

        this.$store.commit('productRegistration/SET_LOADING', false);
        this.waitForTargetDiv().then(() => {
            this.startWatching();
        });
        
    },
    methods: {
        goBack() {
            this.$store.commit('productRegistration/SET_LOADING', true);
            this.$router.push(`../plan-selection/${this.$route.params.id}`);
        },
        waitForTargetDiv() {
            return new Promise(resolve => {
                    const checkExistence = () => {
                        const targetDiv = document.getElementsByClassName('recurly-hosted-field-card')[0];
                        if (targetDiv) {
                            resolve(targetDiv);
                        } else {
                            setTimeout(checkExistence, 100); // Check again after a short delay
                        }
                    };
                    checkExistence();
                });
            },
        startWatching() {
                this.waitForTargetDiv().then(targetDiv => {
                    const observer = new MutationObserver(mutations => {
                    for (const mutation of mutations) {
                        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                        this.isCardValidClass = targetDiv.classList.contains('recurly-hosted-field-card-valid');
                        }
                    }
                });

                observer.observe(targetDiv, { attributes: true });
            });
        },
        async submitRecurlyPayment(e){
            let self = this;
            let form = this.$refs['form'];
            this.loading = true;
            recurly.token(form, async function (err, token) {
                if (err) {
                    self.recurlyTokenError = err.message;
                    self.loading = false;
                } else {
                     let response = await self.$store.dispatch('productRegistration/createBillingDetails',{
                         token: token.id,
                         billing: self.billing,
                         billingDetails: self.billingDetails,
                         company: self.company,
                         user : self.currentUser.hubSpotUserId,
                         product: self.$route.params.id,
                         price: self.currentPlan.price
                     });

                    if(response){
                        self.$swal({
                            icon: 'success',
                            title: 'Hoorah!',
                            text: 'Your purchase was successful.',
                            confirmButtonText: "Continue",
                        }).then(async result => {
                            self.$store.commit('productRegistration/SET_LOADING', true);
                            self.$store.commit('productRegistration/SET_STEP_COMPLETE', 'payment');

                            if(self.productName === "MessageIQ") {
                                self.$router.push(`/setup/step2`);
                            } else {
                                self.$router.push(`../summary/${self.$route.params.id}`);
                            }
                        });
                    }else{
                        self.$swal({
                            icon: 'error',
                            title: 'Oh no!',
                            text: 'We have encountered an error. Please try again.',
                            confirmButtonText: "Continue",
                        }).then(async result => {
                        });
                    }
                }
            });
        },
        async submitPayment(e){
            this.loading = true;

            let response = await this.$store.dispatch('productRegistration/addRecurlySubscription',{
                token: "",
                billing: this.billing,
                billingDetails: this.billingDetails,
                company: this.company,
                user : this.currentUser.hubSpotUserId,
                product: this.$route.params.id,
                price: this.currentPlan.price
            });

            if(response){
                this.$swal({
                    icon: 'success',
                    title: 'Hoorah!',
                    text: 'Your purchase was successful.',
                    confirmButtonText: "Continue",
                }).then(async result => {
                    this.$store.commit('productRegistration/SET_LOADING', true);
                    this.$store.commit('productRegistration/SET_STEP_COMPLETE', 'payment');

                    if(this.productName === "MessageIQ") {
                        this.$router.push(`/setup/step2`);
                    } else {
                        this.$router.push(`../summary/${this.$route.params.id}`);
                    }
                });
            }else{
                this.$swal({
                    icon: 'error',
                    title: 'Oh no!',
                    text: 'We have encountered an error. Please try again.',
                    confirmButtonText: "Continue",
                }).then(async result => {
                });
            }   
        }
    }

  }
</script>
<style lang="scss">
    
</style>