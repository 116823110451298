import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c(VForm,{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VCol,{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"color":"primary","text":""}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v("mdi-help-circle")]),_vm._v(" How to connect Stock IQ to NetSuite ")],1)],1)],1),_c(VTextField,{attrs:{"rules":_vm.dataRules,"required":"","label":"Account Id"},model:{value:(_vm.netsuite.accountId),callback:function ($$v) {_vm.$set(_vm.netsuite, "accountId", $$v)},expression:"netsuite.accountId"}}),_c(VTextField,{attrs:{"rules":_vm.dataRules,"required":"","label":"Consumer key"},model:{value:(_vm.netsuite.consumerKey),callback:function ($$v) {_vm.$set(_vm.netsuite, "consumerKey", $$v)},expression:"netsuite.consumerKey"}}),_c(VTextField,{attrs:{"rules":_vm.dataRules,"required":"","label":"Consumer Secret"},model:{value:(_vm.netsuite.consumerSecret),callback:function ($$v) {_vm.$set(_vm.netsuite, "consumerSecret", $$v)},expression:"netsuite.consumerSecret"}}),_c(VTextField,{attrs:{"rules":_vm.dataRules,"required":"","label":"Token Id"},model:{value:(_vm.netsuite.TokenId),callback:function ($$v) {_vm.$set(_vm.netsuite, "TokenId", $$v)},expression:"netsuite.TokenId"}}),_c(VTextField,{attrs:{"rules":_vm.dataRules,"required":"","label":"Token Secret"},model:{value:(_vm.netsuite.tokenSecret),callback:function ($$v) {_vm.$set(_vm.netsuite, "tokenSecret", $$v)},expression:"netsuite.tokenSecret"}})],1)],1),_c(VDivider),_c(VCardActions,{staticClass:"d-flex justify-end"},[_c(VBtn,{attrs:{"loading":_vm.loading,"disabled":!_vm.valid,"color":"primary"},on:{"click":_vm.submit}},[_vm._v(" Submit Credentials ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }