<template>
    <v-container >
        <v-card flat>
            <v-card-text class="pa-0">
                <v-row class="d-flex align-center mb-1">
                    <v-col md="6" cols="12" class="d-flex align-center">
                        <h1>Tags</h1>
                    </v-col>   
                    <v-col md="6" cols="12" class="text-right d-flex">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search tags..."
                            single-line
                            hide-details
                            class="mt-0 pt-2"
                        ></v-text-field>
                        <v-btn  outlined color="primary" @click="showAddNewTag" class="ml-2">
                            <span>Create Tag</span>
                        </v-btn>
                    </v-col>
                </v-row>

                <v-data-table
                    :headers="tagsHeaders"
                    :items="tags"
                    :items-per-page="30"
                    dense
                    fixed-header
                    :height="window.height - this.appBarHeight - this.appHeaderToolbar - this.dataTableFooter - 130"
                    class="elevation-1 fill-height"
                    :footer-props="{
                    itemsPerPageOptions: [15,30,50, -1]
                    }"
                >   
                <template v-slot:item.actions="{ item }">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                x-small
                                outlined
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                >
                                    Actions <v-icon small>mdi-chevron-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                v-for="(action, index) in actions"
                                :key="index" 
                                @click="callAction(action.title, item)"
                                :data-user="item.tagName + item.taggedContacts"
                                >
                                    <v-list-item-title>{{ action.title }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>      
            </v-card-text>
        </v-card>

        <v-dialog
            v-model="dialog"
            width="400"
        >
            <v-card>
                <v-card-title class="justify-center pt-8">
                    {{this.isUpdate ?'Update Tag' : 'Create Tag'}}
                </v-card-title>
                <div class="ma-auto position-relative  pb-12" style="max-width: 410px">
                    <v-card-text class="justify-center text-center py-0">
                        <v-container>
                            <v-form v-model="valid" id="tagForm" ref="tagForm">
                                <v-row justify="center" class="pt-6">
                                    <v-col cols="8" class="pa-0">
                                        <v-text-field
                                            class="pt-0"
                                            v-model="tagForm.tagName"
                                            :color="!this.$vuetify.breakpoint.smAndDown ? 'iq_dark' : 'iq_light'"
                                            :background-color="!this.$vuetify.breakpoint.smAndDown ? 'iq_light' : ''"
                                            :dense="this.$vuetify.breakpoint.smAndDown"
                                            label="Tag Name*"
                                            outlined
                                            required
                                            :rules="requiredRule"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="10" class="pa-0">
                                            <v-btn 
                                                x-large 
                                                :disabled="!valid || loadingSubmit" 
                                                outlined 
                                                :loading="loadingSubmit"
                                                color="primary" 
                                                @click="updateTag" 
                                                class="ml-2"
                                                v-if="this.isUpdate"
                                            >
                                                <span>Update</span>
                                            </v-btn>
                                            <v-btn 
                                                x-large 
                                                :disabled="!valid || loadingSubmit" 
                                                outlined 
                                                :loading="loadingSubmit"
                                                color="primary" 
                                                @click="addNewTag" 
                                                class="ml-2"
                                                v-if="!this.isUpdate"
                                            >
                                                <span>Create</span>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>
                </div>
            </v-card>
        </v-dialog>
              
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    data (){
        return {
            search: '',
            show:false,

            tags: [],

            valid: false,
            dialog: false,
            loadingSubmit: false,
            isUpdate : false,

            requiredRule: [
                v => !!v || 'Field is required'
            ],

            tagForm:{
                tagName: null,
                taggedContacts: 0,
                id: null
            },

            window: { //LAYOUT TOOL: store location when page will need vertical space calculations         )
                width: 0,
                height: 0
            },
            actions: [
                { title: 'Update'},
                { title: 'Delete'},
            ],
        }
    },
    created() {
        this.$auth.auth0ClientPromise.then(async () => {
            await this.$store.dispatch('getUserCompany');

            //LAYOUT TOOL: resize handler
            window.addEventListener('resize', this.handleResize);
            this.handleResize();

            this.getTags();
        });
    },
    destroyed() { //LAYOUT TOOL: on leave dispose
        window.removeEventListener('resize', this.handleResize);
    },
    computed: {
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany'
        }),
        tagsHeaders() {
            return [
                {
                    text: "Tag Name", 
                    value: "tagName",
                    align: "center",
                    filter: this.nameFilter,
                },
                {
                    text: "Tagged Contacts",
                    value: "taggedContacts",
                    align: "center",
                },             
                {
                    text: "Actions",
                    value: "actions",
                    align: "center", 
                    groupable: false,
                    filterable: false,
                    sortable: false
                }
            ]
        }
    },
    watch:{
        dialog: function(newValue, old){
            if(!newValue){
                // Closing
                this.clear();
            }
        }
    },
    methods: {
        async getTags() {
            let tags = [];
            let response = await this.$store.dispatch('tag/getTagsByCompanyId',{
                "companyId": this.currentCompany.id
            });

            if(response.length) {
                response.forEach( (tag) => {
                    tags.push({
                        tagName: tag.name,
                        taggedContacts: tag.contacts,
                        id: tag.id
                    });
                });
            }

            this.tags = tags;
        },
        nameFilter(value) {
                // If this filter has no value we just skip the entire filter.
                if (!this.search) {
                    return true;
                }

                // Check if the current loop value (The dessert name)
                // partially contains the searched word.
                return value.toLowerCase().includes(this.search.toLowerCase());
            },
        showAddNewTag () {
            this.tagForm = {};
            this.isUpdate = false;
            this.dialog = true;
        },
        async addNewTag () {
            this.loadingSubmit = true;

            let response = await this.$store.dispatch('tag/createTag', {
                "tag": this.getTagFormat(false)
            });

            if(response){
                this.getTags();
                this.$swal({
                    icon: 'success',
                    title: 'Tag Added',
                    text: 'Tag Successfully Added',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });

                this.clear();
            }else{
                this.showError("We could not add this tag.");
            }

            this.loadingSubmit = false
        },
        getTagFormat(edit) {
            let tag = edit ? {
                Id: this.tagForm.id,
                CompanyId: this.currentCompany.id,
                Name: this.tagForm.tagName,
                Contacts: this.tagForm.taggedContacts
            } :
            { 
                CompanyId: this.currentCompany.id,
                Name: this.tagForm.tagName,
            };

            return tag;

        },
        showError(text){
            this.$swal({
                icon: 'error',
                title: text,
                text: '',
            });
        },
        callAction(action, tag){
            action === "Update" ? this.showUpdateTag(tag) : this.deleteModal(tag);
        },

        async deleteTag(tag){
              let response = await this.$store.dispatch('tag/deleteTag', {"idTag": tag.id});
               if(response){
                this.getTags();
                this.$swal({
                    icon: 'success',
                    title: 'Tag Deleted',
                    text: 'Tag Successfully Deleted',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });               
            }else{
                this.showError("We could not delete this tag.");
            }
        },

       showUpdateTag(tag){
            this.tagForm = {};
            this.isUpdate = true;
            this.dialog = true;

            this.tagForm.tagName = tag.tagName;
            this.tagForm.taggedContacts = tag.taggedContacts
            this.tagForm.id = tag.id
        },

        async updateTag(){
            let tag = this.getTagFormat(true);
            let response = await this.$store.dispatch('tag/updateTag', {"tag": tag});
            if(response){
                this.getTags();
                this.$swal({
                    icon: 'success',
                    title: 'Tag Updated',
                    text: 'Tag Successfully Updated',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });   
                this.dialog = false;            
            }else{
                this.showError("We could not update this tag.");
            }
        },

        deleteModal(tag){
            this.$swal.fire({
                title: 'Are you sure you want to delete this tag?',
                icon: 'question',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Yes',
                denyButtonText: "No",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteTag(tag);
                    }
                })
        },
        clear() {
            this.dialog = false;

            this.tagForm = {};
            this.$refs.tagForm.resetValidation();            
        }

    }
}
</script>