import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,[_vm._v(" Agency Clients "),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.tableData,"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',[_c('td',[_vm._v(_vm._s(row.item.clientName))]),_c('td',[_vm._v(_vm._s(row.item.usersNumber))]),_c('td',[_vm._v(_vm._s(row.item.hubSpotConnection))]),_c('td',[_vm._v(_vm._s(row.item.accountType))]),_c('td',[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"outlined":"","x-small":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Actions "),_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c(VList,_vm._l((_vm.actions),function(action,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.callAction(row.item,action.action)}}},[(action.action == 'login' && _vm.currentCompany.id === row.item.companyId)?_c(VListItemTitle,{attrs:{"disabled":""}},[_vm._v("Logged In")]):_c(VListItemTitle,[_vm._v(_vm._s(action.title))])],1)}),1)],1)],1)])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }