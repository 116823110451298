import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VStepper,{attrs:{"flat":""},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c(VStepperHeader,[_vm._l((_vm.sections),function(section,index){return [_c(VStepperStep,{key:index,attrs:{"step":section.id,"complete":_vm.step > index + 1}},[_vm._v(" "+_vm._s(section.name)+" "),_c('small',{staticClass:"pt-1"},[_vm._v(_vm._s(section.subText))])]),(index !== _vm.sections.length - 1)?_c(VDivider,{key:'key-'+index}):_vm._e()]})],2),_c(VProgressLinear,{attrs:{"value":_vm.step * 100 / _vm.sections.length}}),_c(VStepperItems,{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('router-view',{attrs:{"status":_vm.status}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }