<template>
    <div fill-width justify="center" align="center">
        <v-overlay
            :absolute="absolute"
            :value="!connectedToHubSpot"
        >
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-card class="pa-3" outlined elevation="10" light color="">
                            <v-card-title>
                                You're Not Connected to Hubspot
                            </v-card-title>
                            <v-card-subtitle>
                                Please connect to HubSpot to Start using Price IQ.
                            </v-card-subtitle>
                            <v-divider></v-divider>
                            <v-card-actions class="d-flex justify-center">
                                <v-btn color="success" x-large class="ml-2" @click="hubspotConnect"><v-icon class="iq_light--text">$hubspotIcon</v-icon >Connect to Hubspot</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-overlay>
        <v-overlay color="iq_dark" :value="loading">
            <v-icon color="iq_light" x-large>mdi-loading mdi-spin</v-icon>
            <span class="iq_light--text font-weight-bold ">Pulling Company Price Lists...</span>
        </v-overlay>
        <v-container>
            <v-card flat elevation="0">
                <v-card-text class="pa-0">
                    <v-row class="d-flex align-center mb-1">
                        <v-col md="6" cols="12"  class="d-flex align-center">
                            <h1>Price IQ Price Lists</h1>
                            <v-btn depressed class="ml-4" v-if="!connectedToHubSpot">
                                Connect to HubSpot
                            </v-btn>
                            <v-btn small :to="'/PriceIQ/create?listId='+null" class="ml-4"  color="primary">
                                <v-icon  class="iq_light--text">mdi-plus</v-icon > Add New Price List
                            </v-btn>
                        </v-col>
                        <v-col md="6" cols="12" class="text-right d-flex">
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search Price Lists"
                                single-line
                                hide-details
                                class="mt-0 pt-2"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-data-table
                :headers="headers"
                :items="tableData"
                :search="search"
                :sort-by="['updateDate']"
                :sort-desc="[true]"
                multi-sort
                class="fill-height"
                dense
                fixed-header
                :height="window.height - this.appBarHeight - this.appHeaderToolbar - this.dataTableFooter - 15 "
            >
                <template v-slot:item="row">
                    <tr>
                        <td>{{row.item.name}}</td>
                        <td>{{roundToTwoDecimalPlaces(row.item.discount)}}%</td>
                        <td>{{row.item.productCount}}</td>
                        <td>{{row.item.dealsUsed}}</td>
                        <td>{{formatDateTime(row.item.updateDate)}}</td>
                        <td>
                            <v-row justify="center" class="ma-2">
                                <v-btn outlined color="primary" x-small :to="'/PriceIQ/create?listId='+row.item.id" depressed>
                                    Edit
                                </v-btn>
                            </v-row>
                        </td>
                    </tr>
                </template>
                <template v-slot:no-data>
                    <v-card  flat :min-height="window.height - 32 - 32 - 73 - 80 - 60 - 48 " class="d-flex justify-center align-center">
                        <v-row>
                            <v-col class="pt-8" cols="12">
                                No Data Available
                            </v-col>
                            <v-col class="pb-8" cols="12">
                                <v-btn :to="'/PriceIQ/create?listId='+null"  color="primary">
                                    <v-icon  class="iq_light--text">mdi-plus</v-icon >
                                        Add New Price List
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </template>
            </v-data-table>
            </v-card>
        </v-container>
    </div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany',
            currentCompanyApps: 'company/getCurrentCompanyApps',
            companyPriceLists: 'priceIQ/getCompanyPriceLists'
        }),
        headers() {
            return [
                {
                    text: "Name",
                    align: "start",
                    value: "name",
                    filterable: true,
                },
                {
                    text: "Discount",
                    value: "discount",
                    filterable: true,
                },
                {
                    text: "Products",
                    value: "productCount",
                    filterable: true,
                },
                {
                    text: "Deals Used",
                    value: "dealsUsed",
                    filterable: true,
                },
                {
                    text: "Date Updated",
                    value: "updateDate",
                    filterable: false,
                },
                {
                    text: "Actions",
                    align: "center",
                    filterable: false,

                }
            ]
        }, 
    },
    data(){
        return {
            loading: true,
            tableData: [],
            absolute: true,
            connectedToHubSpot: true,
            search: '',
            window: { //LAYOUT TOOL: store location when page will need vertical space calculations         )
                width: 0,
                height: 0
            },
        }
    },
    created(){
        if(!this.currentCompanyApps.priceIq){
            this.$router.push(`product/plan-selection/PriceIQ`);
        }
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        this.$auth.auth0ClientPromise.then(async () => {
            
            await this.$store.dispatch('getUserCompanyApps','PriceIQ');
            

            //LAYOUT TOOL: resize handler
           

            if(this.$route.query.hsConnectStatus === "success"){
                this.$swal({
                    icon: 'success',
                    title: 'HubSpot Connected',
                    text: 'You have successfully connected to HubSpot!',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });
            }else if(this.$route.query.hsConnectStatus === "fail"){
                this.$swal({
                    icon: 'error',
                    title: 'Error connecting to HubSpot',
                    text: 'There was an error connecting to HubSpot. Please try again later.',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });
            }

            if(!this.currentCompany.isPriceIQConnected){
                this.loading = false;
                this.connectedToHubSpot = false;
            }else{
                let priceLists = await this.getCompanyPriceLists();
                this.tableData = priceLists;
                this.loading = false;
            }
        });
    },
    destroyed() { //LAYOUT TOOL: on leave dispose
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        formatDateTime(dt){
            if(dt !== null){
                return moment(dt).format("MM-DD-YYYY")

            }
            return "";
        },
        async getCompanyPriceLists(){
            let response = await this.$store.dispatch('priceIQ/getCompanyPriceLists');
            if(!response){
                this.$swal({
                    title: `Unable to fetch price list data.`,
                    icon: 'warning',
                    showDenyButton: false,
                    confirmButtonText: "Close"
                });
            } else {
                return response;
            }
        },
        hubspotConnect(){
            window.location.href = process.env.VUE_APP_API_URL + `/api/HubSpotConnect/PriceIQ?companyId=${this.currentCompany.id}`;
        }
    }
}
</script>
