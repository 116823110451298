<template>
  <v-card flat :dark="$vuetify.breakpoint.smAndDown" class="theme-breakpoint-card-switch">
    <v-form v-model="valid" id="companyForm" ref="companyForm">
      <v-row>
        <v-col>
            <h1 :class="this.$vuetify.breakpoint.smAndDown ? 'white--text text-h5 text-center' : 'secondary--text text-h2'">Tell us more about your business</h1>
        </v-col>
        <v-col cols="12">
          <v-text-field
            ref="formEntryInput"
            v-model="companyForm.companyName"
            :color="!this.$vuetify.breakpoint.smAndDown ? 'iq_dark' : 'iq_light'"
            :background-color="!this.$vuetify.breakpoint.smAndDown ? 'iq_light' : ''"
            :dense="this.$vuetify.breakpoint.smAndDown"
            label="Company Name"
            outlined
            required
            :rules="requiredRule.select"

          ></v-text-field>
          <v-text-field
            v-model="companyForm.website"
            :color="!this.$vuetify.breakpoint.smAndDown ? 'iq_dark' : 'iq_light'"
            :background-color="!this.$vuetify.breakpoint.smAndDown ? 'iq_light' : ''"
            :dense="this.$vuetify.breakpoint.smAndDown"
            label="Company URL"
            outlined
            :rules="requiredRule.urlRules"
            required
          ></v-text-field>
          
          <v-autocomplete
            v-model="companyForm.industry"
            :color="!this.$vuetify.breakpoint.smAndDown ? 'iq_dark' : 'iq_light'"
            :background-color="!this.$vuetify.breakpoint.smAndDown ? 'iq_light' : ''"
            :dense="this.$vuetify.breakpoint.smAndDown"
            label="Choose your Industry"
            :items="industry"
            item-value="value"
            item-text="name"
            outlined
            :rules="requiredRule.select"
            required
          >
              <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                      <v-list-item-content>
                          <span><v-list-item-title class="d-flex flex-row justify-space-between">{{ data.item.name}}</v-list-item-title>
                          </span>
                      </v-list-item-content>
                  </template>
              </template>
          </v-autocomplete>

          <v-autocomplete
              v-model="companyForm.yourRole"
              :color="!this.$vuetify.breakpoint.smAndDown ? 'iq_dark' : 'iq_light'"
              :background-color="!this.$vuetify.breakpoint.smAndDown ? 'iq_light' : ''"
              :dense="this.$vuetify.breakpoint.smAndDown"
              label="What's your Role"
              :items="roles"
              item-value="value"
              item-text="name"
              outlined
              required
              :rules="requiredRule.select"
              
              
          >
              <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                      <v-list-item-content>
                          <span><v-list-item-title class="d-flex flex-row justify-space-between">{{ data.item.name}}</v-list-item-title>
                          </span>
                      </v-list-item-content>
                  </template>
              </template>
          </v-autocomplete>

          <v-autocomplete
              v-model="companyForm.primaryTimeZone"
              :color="!this.$vuetify.breakpoint.smAndDown ? 'iq_dark' : 'iq_light'"
              :background-color="!this.$vuetify.breakpoint.smAndDown ? 'iq_light' : ''"
              :dense="this.$vuetify.breakpoint.smAndDown"
              label="Choose Your Time Zone"
              :items="timeZones"
              item-value="value"
              item-text="name"
              outlined
              required
            :rules="requiredRule.select"

          >
              <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                      <v-list-item-content>
                          <span><v-list-item-title class="d-flex flex-row justify-space-between">{{ data.item.name}} <span class="caption"> {{data.item.value}}</span></v-list-item-title>
                          </span>
                      </v-list-item-content>
                  </template>
              </template>
          </v-autocomplete>

        </v-col>
      </v-row>
    </v-form>
    <v-footer 
    :app ="this.$vuetify.breakpoint.smAndDown"
    :class="this.$vuetify.breakpoint.smAndDown ? 'bg-mobile-overlay border-top':'bg-transparent'"
    padless 
    class="px-0 bg-transparent" 
    >
        <v-container fluid class="pa-0">
            <v-row no-gutters>
                <v-col cols="12">
                  <v-card-actions class="d-flex justify-space-between" :class="this.$vuetify.breakpoint.smAndDown ? '':'px-0'">

                    <v-btn :disabled="!valid" x-large color="primary" @click="setAccountModel()"> Next </v-btn>
                  </v-card-actions>
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: "AccountStep3",
   props: {
  },
  async created() {
    let user = await this.$store.dispatch('getUserCompany');
    if(user != null){
      this.companyForm.companyName = this.currentCompany.name;
      this.companyForm.website = this.currentCompany.webSite;
    }

    },
    computed: {
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany'
        })
    },
  data () {
      return {
        valid: false,
        requiredRule: {
          required: v => !!v || 'Required.',
          urlRules: [
            v => (v && v.length >= 3) || 'URL must have more than 3 characters',
                  v => (v && v.length <= 256) || 'URL must be less than 256 characters',
                  /* eslint-disable-next-line no-useless-escape*/
                   v => /^[-a-zA-Z0-9:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9():%_\+.~#?&//=]*)$/.test(v) || 'URL must be valid', 
                   ],
            select: [(v) => !!v || "Required."],
        },

    
        companyForm:{
            companyName: null,
            industry: null,
            yourRole: null,
            primaryTimeZone: null,
            website: null,//TODO: needs rules validation REGEX
        },
        timeZones: [
          {value:"EST", name:" Eastern Standard Time"},
          {value:"CST", name:" Central Standard Time"},
          {value:"MST", name:" Mountain Daylight Time"},
          {value:"PST", name:" Pacific Daylight Time"}
        ],
        industry: [
          {value:"Agriculture / Yard Services", name:"Agriculture / Yard Services"},
          {value:"Automotive", name:"Automotive"},
          {value:"Construction", name:"Construction"},
          {value:"Digital Marketing Agency", name:"Digital Marketing Agency"},
          {value:"Education", name:"Education"},
          {value:"Entertainment", name:"Entertainment"},
          {value:"Finance / Banking", name:"Finance / Banking"},
          {value:"Government / Utilities / Nonprofit", name:"Government / Utilities / Nonprofit"},
          {value:"Gyms / Fitness", name:"Gyms / Fitness"},
          {value:"Healthcare / Medical", name:"Healthcare / Medical"},
          {value:"HR / Staffing", name:"HR / Staffing"},
          {value:"Insurance", name:"Insurance"},
          {value:"Legal", name:"Legal"},
          {value:"Manufacturing", name:"Manufacturing"},
          {value:"Other", name:"Other"},
          {value:"Personal Care / Beauty", name:"Personal Care / Beauty"},
          {value:"Professional Services / Technology", name:"Professional Services / Technology"},
          {value:"Professional Sports", name:"Professional Sports"},
          {value:"Radio / Media", name:"Radio / Media"},
          {value:"Real Estate", name:"Real Estate"},
          {value:"Restaurants", name:"Restaurants"},
          {value:"Retail", name:"Retail"},
          {value:"Telecommunications", name:"Telecommunications"},
          {value:"Transportation / Logistics", name:"Transportation / Logistics"},
          {value:"Veterinary / Animal Care", name:"Veterinary / Animal Care"},
        ],
        roles: [
          {value:"Agency", name:"Agency"},
          {value:"Freelancer", name:"Freelancer"},
          {value:"MarketingAssociate", name:"Marketing Associate"},
          {value:"MarketingLeader", name:"Marketing Leader"},
          {value:"Owner", name:"Owner"},
          {value:"SalesAssociate", name:"Sales Associate"},
          {value:"SalesLeader", name:"Sales Leader"},
          {value:"Other",  name:"Other"},
        ],
        
      }
  },
  watch: {
      companyForm: {
          handler: function (v){
              return this.setupFormObjHasChanged();
          }
      }
  },
  methods: {
        setupFormObjHasChanged(){
          this.$refs.companyForm.validate();
        },
        setAccountModel(){
          this.$store.dispatch('registration/setAccountModel',this.companyForm);
          this.$router.push('/setup/step4')
        },
        getLocalTimeZone() {
        var zone = new Date()
            .toLocaleTimeString("en-us", { timeZoneName: "short" })
            .split(" ")[2];
        var timezone = "";
        switch (zone) {
            case "EDT":
            timezone = "EST";
            break;
            case "CDT":
            timezone = "CST";
            break;
            case "MDT":
            timezone = "MST";
            break;
            case "PDT":
            timezone = "PST";
            break;
            default:
            timezone = zone;
            break;
        }
        this.companyForm.primaryTimeZone = timezone;
    },

  }
  
};
</script>