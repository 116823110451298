import Api from "@/services/api";
import Vue from "vue";
export default {
    namespaced: true,
    state: {
        currentMessageReport:{},
        currentMessageDetailReport:{},
        currentMessagePerformanceData:{}
    },
    mutations: {
        SET_CURRENT_MESSAGE_REPORT(state, currentMessageReport){
            state.currentMessageReport = currentMessageReport;
        },
        SET_CURRENT_MESSAGE_DETAIL_REPORT(state, currentMessageDetailReport){
            state.currentMessageDetailReport = currentMessageDetailReport;
        },
        SET_CURRENT_MESSAGE_PERFORMANCE_DATA(state, currentMessagePerformanceData){
            state.currentMessagePerformanceData = currentMessagePerformanceData;
        }

    },
    actions: {
        async getMessageReport({commit},{id: id, startFilter:startFilter, endFilter:endFilter}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Chart/getMessageReport/${id}/${startFilter}/${endFilter}`,config)
            .catch((err) => {
                console.error(err.message);
            });
            
            if(response && response.data) { commit('SET_CURRENT_MESSAGE_REPORT',response.data) };
            return response && response.data ? response.data : undefined;
        },
        async getMessagDetailReport({commit},{id: id, startFilter:startFilter, endFilter:endFilter, messageName:messageName}){
            
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().put(`/Web/Chart/getMessageDetailReport/${id}/${startFilter}/${endFilter}`,messageName,config)
            .catch((err) => {
                console.error(err.message);
            });

            if(response && response.data) { commit('SET_CURRENT_MESSAGE_DETAIL_REPORT',response.data) };
            return response && response.data ? response.data : undefined;
        },
        async getMessagePerformanceData({commit},{id: id, startFilter:startFilter, endFilter:endFilter}){
            let data = {
                CompanyId: id,
                StartFilter: startFilter,
                EndFilter: endFilter
            };
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Chart/getMessagePerformanceData/${id}/${startFilter}/${endFilter}`,config)

            .catch((err) => {
                console.error(err.message);
            });

            if(response && response.data) { commit('SET_CURRENT_MESSAGE_PERFORMANCE_DATA',response.data) };
            return response && response.data ? response.data : undefined;
            
        },
        async getBroadcastDataReport({},{id: id, startFilter:startFilter, endFilter:endFilter}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Chart/getBroadcastDataReport/${id}/${startFilter}/${endFilter}`,config)
            .catch((err) => {
                console.error(err.message);
            });
            return response.data;
        },
        async getMessageBroadcastReport({},{id: id, startFilter:startFilter, endFilter:endFilter, messageName:messageName}){
            
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().put(`/Web/Chart/getMessageDetailBroadcast/${id}/${startFilter}/${endFilter}`,messageName,config)
            .catch((err) => {
                console.error(err.message);
            });
            return response.data;
        },
        
    },
    getters: {
        getCurrentMessageReport: state => { return state.currentMessageReport},
        getCurrentMessageDetailReport: state => { return state.currentMessageDetailReport},
        getCurrentMessagePerformanceData: state => { return state.getCurrentMessageDetailReport}
    }
};