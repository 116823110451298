<template>
  <v-app>

    <!-- LEFT MAIN NAV **************************************************************** -->
    <v-navigation-drawer v-model="drawer" class="miq-drawer-main" app dark :mini-variant.sync="mini"
      :mini-variant-width="this.appNavWidthMini" expand-on-hover :width="this.appNavWidthExpanded"
      mobile-breakpoint="xs">
      <div class="sidebar-background"></div>
      <v-list-item class="py-2 text-center d-flex justify-center">
        <v-img :max-width="mini ? '' : '150'" transition="scale-transition" class="logoSmartRamp" contain
          src="../../assets/images/customIconSVGs/smartRamp.svg">
        </v-img>
      </v-list-item>

      <v-divider></v-divider>

      <!--  <v-list class="pa-0" nav z-index="1">
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.link"
          :target="item.openTab ? '_blank' : ''"
          :target="item.openTab ? '_blank' : ''"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
          
        </v-list-item>
      </v-list>-->

      <v-list class="pa-0" nav z-index="1" rounded expand>
        <template v-for="(item, itemIndex) in items" :group="item.title">

          <v-list-item v-if="checkApp(item.app) && !item.subItems" :key="item.title" :to="item.link" class="mb-1"
            :class="mini ? 'mx-2' : ''">
            <v-list-item-icon class="pl-1">
              <v-icon
                :class="[!mini && !startsWithDollar(item.icon) ? 'ml-3' : '', startsWithDollar(item.icon) ? 'menu-icon-large' : 'menu-icon-medium']">{{
                item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="pl-4">{{ item.title }}</v-list-item-title>
          </v-list-item>

          <v-list-group v-else-if="checkApp(item.app)" no-action :group="item.title" :key="itemIndex" class=" pa-0 mb-1"
            :value="isRouteInMyChilds(itemIndex) ? 'itemIndex' : 'itemIndex'" v-model="itemsExpanded"
            :disabled="!item.enabled">
            <v-list-item slot="activator" :to="item.link" color="white"
              :class="[(isRouteInMyChilds(itemIndex) ? 'v-list-item--active parent' : ''), (!mini ? 'ml-n2' : '')]"
              class="mb-0" :disabled="!item.enabled">
              <v-list-item-icon class="pl-1">
                <v-icon
                  :class="[!mini && !startsWithDollar(item.icon) ? 'ml-3' : '', startsWithDollar(item.icon) ? 'menu-icon-large' : 'menu-icon-medium']">{{
                  item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="pl-4">{{ item.title }}</v-list-item-title>
            </v-list-item>

            <v-list-item v-for='subItem in item.subItems' :key="subItem.title" :to="subItem.link" color="white">
              <v-list-item-icon>
                <v-icon small>{{ subItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ subItem.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>

      <template v-slot:append>
        <v-list-item two-line class="d-flex align-center">
          <v-list-item-avatar size="56" class="overflow-visible">
            <v-speed-dial v-model="fab" style="left:0px; bottom:0px;" direction="top" open-on-hover
              transition="slide-y-reverse-transition" bottom left absolute>


              <template #activator>

                <v-btn fab v-model="fab" v-if="checkIfImpersonationIsActive()">
                  <v-icon v-if="fab">
                    mdi-close
                  </v-icon>
                  <v-avatar v-else size="56">
                    <v-icon x-large>mdi-domino-mask</v-icon>
                  </v-avatar>
                </v-btn>
                <v-btn fab v-model="fab" v-else>
                  <v-icon v-if="!$auth.isAuthenticated">
                    mdi-close
                  </v-icon>
                  <v-avatar v-else size="56">
                    <v-img :src="$auth.user.picture"></v-img>
                  </v-avatar>
                </v-btn>
              </template>

              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn fab v-bind="attrs" v-on="on" v-on:click="goSettings" dark>
                    <v-icon large>mdi-account-edit-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit account</span>
              </v-tooltip>

              <v-tooltip right v-if="userRole === 'SuperAdmin'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn fab v-bind="attrs" v-on="on" v-on:click="goToAdminReport" dark>
                    <v-icon large>mdi-account-key-outline</v-icon>
                  </v-btn>
                </template>
                <span>Admin Report</span>
              </v-tooltip>

              <v-tooltip right v-if="checkIfImpersonationIsActive()">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn fab v-bind="attrs" v-on="on" v-on:click="exitImpersonation" dark>
                    <v-icon large>mdi-domino-mask</v-icon>
                  </v-btn>
                </template>
                <span>Exit Impersonation</span>
              </v-tooltip>


              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn fab v-bind="attrs" v-on="on" v-on:click="logout" dark>
                    <v-icon large>mdi-exit-to-app</v-icon>
                  </v-btn>
                </template>
                <span>Exit App</span>
              </v-tooltip>
            </v-speed-dial>

          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-if="$auth.isAuthenticated" class="caption"> {{ $auth.user.name }}</v-list-item-title>
            <v-list-item-subtitle class="caption"> System Admin</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </template>
    </v-navigation-drawer>
    <!-- GLOBAL LOGGED IN SYSTEM BAR **************************************************************** -->
    <v-system-bar :height="this.appBarHeight" lights-out dark color="iq_darkerblue"
      :style="drawer ? 'padding-left: 90px' : ''">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <span class="system-bar-title font-weight-thin subtitle-1 text-uppercase"> {{ currentRouteName }} </span>
      <v-spacer></v-spacer>
      <span class="system-bar-title mr-2 font-weight-bold subtitle-1 text-uppercase"> {{ currentCompanyName }} </span>
      <!--<v-spacer></v-spacer>-->
      <!--  <v-btn class="pr-2 pl-2" text small>
      <v-icon >mdi-phone</v-icon>
      <v-badge
          :class="this.$vuetify.breakpoint.smAndDown ?  'pr-4' : 'pr-5'"
          color="error"
          content="6"
          :offset-y=" this.$vuetify.breakpoint.smAndDown ? '7' : '15'"
          offset-x="18"
        >
        <span class="d-none d-md-flex">Calls</span>
      </v-badge>
    </v-btn>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          text
          class="btn-target"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          v-for="(item, callNotification,indexCall ) in callNotifications"
          :key="indexCall"
        >
          <v-list-item-icon>
              <v-icon  v-if="item.type === 'missed'">mdi-call-missed</v-icon>
              <v-icon  v-else>mdi-phone</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.number }}
            </v-list-item-title>
            <v-list-item-subtitle class="caption">
              {{item.callTime}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="goToCalls">
         <v-list-item-icon>
              <v-icon>mdi-phone</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Go to Call Logs
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu> -->
      <!-- <v-divider vertical></v-divider> -->
      <!-- <v-btn class="pr-4 pl-2" text small @click="goToConversations">
      <v-icon >mdi-message</v-icon>
      <v-badge
          :class="this.$vuetify.breakpoint.smAndDown ?  'pr-4' : 'pr-5'" 
          color="primary"
          content="10"
          :offset-y=" this.$vuetify.breakpoint.smAndDown ? '7' : '15'"
          offset-x="18"
        >
        <span class="d-none d-md-flex">New Messages</span>
       </v-badge>
    </v-btn> -->
      <!--  <v-divider vertical></v-divider> -->
      <!-- <v-switch
          v-model="$vuetify.theme.dark"
    ></v-switch> -->
    </v-system-bar>
    <!-- Main loading area for this template **************************************************************** -->
    <v-main :class="stringSpaceRemoval(currentRouteName)">
      <slot />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

let htmlTag = document.getElementsByTagName('html')[0];

export default {
  name: "UserPage",
  data() {
    return {
      //global variables for impersonation
      isImpersonationActive: false,
      impersonationAccountName: null,
      //global variables for layout 
      //local vars
      drawer: true,
      mini: true,
      fab: false,
      itemsExpanded: true,
      items: [
        { title: 'Start', app: null, icon: 'mdi-rocket-launch-outline', link: '/', enabled: true, expanded: false, subItems: null },
        {
          title: 'Message IQ', app: 'MessageIQ', icon: '$messageIQIcon', link: '/conversations', enabled: true, expanded: true, subItems: [
            { title: 'Conversations', icon: 'mdi-forum-outline', link: '/conversations' },
            { title: 'Calls', icon: 'mdi-phone-outline', link: '/calls' },
            { title: 'Contacts', icon: 'mdi-contacts-outline', link: '/contacts' },
            { title: 'Charts', icon: 'mdi-chart-areaspline', link: '/charts' },
            { title: 'Settings', icon: 'mdi-cog-outline', link: '/settings' },
            { title: 'Help', icon: 'mdi-heart-outline', link: '/help' }
          ]
        },
        { title: 'Status IQ', app: 'StatusIQ', icon: '$statusIQIcon', link: '/statusiq', enabled: true, expanded: false, subItems: [
          { title: 'App customization', icon: 'mdi-cog-outline', link: '/statusiq/app-customization' },
        ] },
        { title: 'Stock IQ', app: 'StockIQ', icon: '$stockIQicon', link: '/stockiq', enabled: true, expanded: false, subItems: null },
        { title: 'Price IQ', app: 'PriceIQ', icon: '$priceIQIcon', link: '/priceiq', enabled: true, expanded: false, subItems: null },
        {
          title: 'Integrations', icon: 'mdi-database-outline', link: '#', enabled: true, expanded: true, subItems: [
            { title: 'Library', icon: 'mdi-forum-outline', link: '/cards' },
            // { title: 'Wizard', icon: 'mdi-phone-outline', link: '/wizard' }
          ]
        },
        { title: 'Product Suggestion', icon: 'mdi-information-slab-circle-outline', link: '/product-idea' }

      ],
      items3: [
        { title: 'Start', icon: 'mdi-rocket-launch-outline', link: '/', enabled: true, expanded: false, subItems: null },
        {
          title: 'Message IQ', icon: '$miqIcon', link: '/conversations', enabled: true, expanded: true, subItems: [
            { title: 'Conversations', icon: 'mdi-forum-outline', link: '/conversations' },
            { title: 'Calls', icon: 'mdi-phone-outline', link: '/calls' },
            { title: 'Contacts', icon: 'mdi-contacts-outline', link: '/contacts' },
            { title: 'Charts', icon: 'mdi-chart-areaspline', link: '/charts' },
            { title: 'Settings', icon: 'mdi-cog-outline', link: '/settings' },
            { title: 'Help', icon: 'mdi-heart-outline', link: '/help' }
          ]
        },
        {
          title: 'Integrations', icon: 'mdi-database', link: '/integrations', enabled: true, expanded: false, subItems: [
            { title: 'Directory', icon: 'mdi-book', link: '/cards' },
            { title: 'Dashboard', icon: 'mdi-database-cog', link: '/integrations' }
          ]
        },
        {
          title: 'Tools', icon: 'mdi-toolbox-outline', link: '', enabled: true, expanded: false, subItems: [
            { title: 'Data Integrity tool', icon: 'mdi-check-all', link: '/appsbot' }
          ]
        }

      ],
      items2: [
        { title: 'Start', icon: 'mdi-rocket-launch-outline', link: '/' },
        { title: 'Conversations', icon: 'mdi-forum-outline', link: '/conversations' },
        { title: 'Calls', icon: 'mdi-phone-outline', link: '/calls' },
        { title: 'Contacts', icon: 'mdi-contacts-outline', link: '/contacts' },
        { title: 'Charts', icon: 'mdi-chart-areaspline', link: '/charts' },
        { title: 'Settings', icon: 'mdi-cog-outline', link: '/settings' },
        { title: 'Help', icon: 'mdi-heart-outline', link: '/help' },
      ],


      callNotifications: [
        { number: '(636) 249-6781', type: 'inbound', callTime: '05 Jan 2017 22:33:47' },
        { number: '(636) 249-6781', type: 'missed', callTime: '05 Jan 2017 22:33:47' },
        { number: '(636) 249-6781', type: 'missed', callTime: '05 Jan 2017 22:33:47' },
        { number: '(636) 249-6781', type: 'inbound', callTime: '05 Jan 2017 22:33:47' }
      ],
    }
  },
  computed: {
    ...mapGetters({
      userRole: 'getUserRole',
      currentCompany: 'company/getCurrentCompany',
      currentCompanyApps: 'company/getCurrentCompanyApps',
      user: 'getUser'
    }),
    currentRouteName() {
      return this.$route.name;
    },
    isRouteCurrent(title) {
      return this.$route.name === title;
    },
    currentCompanyName() {
      let company = '';
      if (this.currentCompany != undefined && this.currentCompany != null) {
        if (this.currentCompany.name != undefined && this.currentCompany.name != null) {
          company = this.currentCompany.name;
        }
      }
      return company;
    },

  },
  watch: {
    mini: {
      handler: function (v) {
        if (v) {
          this.itemsExpanded = false;
        }
        else {
          this.itemsExpanded = true;
        }
      }
    }
  },
  methods: {
    checkApp(app) {
      let show = false;
      switch (app) {
        case 'MessageIQ':
          if (this.currentCompanyApps.messageIq) {
            show = true;
          }
          break;
        case 'StatusIQ':
          if (this.currentCompanyApps.statusIq) {
            show = true;
          }
          break;
        case 'StockIQ':
          if (this.currentCompanyApps.stockIq) {
            show = true;
          }
          break;
        case 'PriceIQ':
          if (this.currentCompanyApps.priceIq) {
            show = true;
          }
          break;
        default:
          show = true;
          break;
      }
      return show;
    },
    startsWithDollar(str) {
      return str.charAt(0) === "$";
    },
    //Router Go to places methods: 
    goToCalls() {
      this.$router.push('/calls');
    },
    dropdownNav() {

    },
    goToConversations() {
      this.$router.push('/conversations');
    },
    logout() {
      this.$router.push('/logout');
    },
    goToAdminReport() {
      this.$router.push('/admin/report')
    },
    goSettings() {
      this.$router.push('/settings')
    },
    goSettings() {
      this.$router.push('/settings')
    },
    checkIfImpersonationIsActive() {
      if (this.userRole === "SuperAdmin" && this.currentCompany.id !== "158b7073-5e61-4df7-8585-ea40298231f8") {
        return true;
      } else if (this.user !== null) {
        if (this.user.agencyId !== null) return true;
      } else {
        return false;
      }
    },
    async exitImpersonation() {
      if (this.user.agencyId !== null) {
        await this.$store.dispatch('loginAsAgencyCompany', this.user.agencyId);
        this.$router.push('/');
      } else {
        await this.$store.dispatch('impersonateUser', "158b7073-5e61-4df7-8585-ea40298231f8");
        this.$router.push('/');
      }
    },
    isRouteAChild(link) {
      var currentRoute = this.$route.path;
      var arrayIndex = this.items.map(x => x.link).indexOf(currentRoute);
      var found = null;
      if (arrayIndex !== -1) {
        found = false;

      }
      else {
        const items = this.items.length;
        let i = 0;
        for (i; i < this.items.length; i++) {
          if (this.items[i].subItems != null) {
            var subArrayIndex = this.items[i].subItems.map(x => x.link).indexOf(currentRoute);
            if (subArrayIndex !== -1) {
              found = true;
            }

          }

        }
      }

      return found;
    },
    isRouteInMyChilds(index) {
      var currentRoute = this.$route.path;
      var childs = this.items[index];
      var found = false;
      if (childs !== null) {
        var subArrayIndex = this.items[index].subItems.map(x => x.link).indexOf(currentRoute);
        if (subArrayIndex !== -1) {
          found = true;
        }
      }
      return found;
    },
  }
}
</script>
<style lang="scss">
.v-list-item--active.parent {
  &::before {
    opacity: 0.15;
    box-shadow: 0px 3px 15px 0px #000000 inset;
  }
}

.logoMini {
  .v-image__image {
    max-width: 60%;
  }
}

.logoSmartRamp {
  background-size: contain !important;
}
</style>