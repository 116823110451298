<template>
    
</template>
<script>
import { audience } from '../../auth_config.json';
export default {
    async beforeCreate(){
        var isAuthenticated = await this.$auth.checkIfAuthenticated();
        if(!isAuthenticated){
            this.$auth.loginWithRedirect({
                audience: audience
            });
        }
    }
}
</script>