<template>
    <v-container>
        <v-card flat>
            <v-card-text>
                <v-form v-model="valid">
                    <v-row>
                        <v-col cols="12" class="d-flex justify-center">
                            <v-btn color="primary" text> <v-icon class="mr-2">mdi-help-circle</v-icon> How to connect Stock IQ to NetSuite </v-btn>
                        </v-col>
                    </v-row>
                    <v-text-field v-model="netsuite.accountId" :rules="dataRules" required label="Account Id"></v-text-field>
                    <v-text-field v-model="netsuite.consumerKey" :rules="dataRules" required label="Consumer key"></v-text-field>
                    <v-text-field v-model="netsuite.consumerSecret" :rules="dataRules" required label="Consumer Secret"></v-text-field>
                    <v-text-field v-model="netsuite.TokenId" :rules="dataRules" required label="Token Id"></v-text-field>
                    <v-text-field v-model="netsuite.tokenSecret" :rules="dataRules" required label="Token Secret"></v-text-field>
                    
                </v-form>

            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="d-flex justify-end">
                <v-btn 
                    :loading="loading"
                    :disabled="!valid" 
                    color="primary" 
                    @click="submit"
                >
                    Submit Credentials
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data () {
        return {
            valid: false,   
            loading: false,
            dataRules: [
                v => !!v || 'Field is required'
            ],
            netsuite:{
                accountId: null,
                consumerKey: null,
                consumerSecret: null,
                TokenId: null, 
                tokenSecret: null
            },
        }
    },
    methods: {
        async submit() {
            this.loading = true;
            const result = await this.$store.dispatch('stockIQ/saveCredentials', this.netsuite);

            this.loading = false;
            if(result.status === 200){
                this.$emit("netSuitePopUp", false);
                this.$swal({
                    icon: 'success',
                    title: 'Netsuite Account Connected',
                    text: 'Your account has been successfully connected!',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });
            }else{
                this.$swal({
                    icon: 'error',
                    title: 'Error connecting with Netsuite',
                    text: 'An error occurred while connecting the account. Please try again later.',
                });
            }

        },
    }

}
</script>