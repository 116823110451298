<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card flat class="theme-breakpoint-card-switch" :dark="$vuetify.breakpoint.smAndDown">
          <v-card-title class="d-flex justify-center text-center align-center title-word-break font-weight-bold" :class="$vuetify.breakpoint.smAndDown ? 'text-h4':'text-h1'">
            {{formatIQName(productName)}} - Plan Selection 
          </v-card-title>
          <v-card-subtitle class="d-flex justify-center text-center align-center text-h6 mt-2 mb-8">
            Select your prefered plan for {{formatIQName(productName)}}  
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="plans-container">
      <v-col v-for="plan in plans"
      :key="plan.id"
      cols="12"
      :xl="getColumnSize(4, plans)"
      :lg="getColumnSize(6, plans)"
      :md="getColumnSize(6, plans)"
      >
        <v-card :elevation="plan.id === planId ? '10':'2'" outlined rounded class="pa-4 d-flex flex-column justify-start" :color="$vuetify.breakpoint.smAndDown ? 'iq_darkerblue lighten-2' : ''" height="100%" :dark="$vuetify.breakpoint.smAndDown" >
          <v-card-title class="font-weight-bold text-h5" v-if="plan.name">{{ plan.name }}</v-card-title>
            <v-card-text>
              <div class="my-4 text-subtitle-1">
                <div class="plan-price text-h3 d-flex flex-start flex-wrap" v-if="plan.price !== 0">
                  <span class="sup font-weight-medium">$</span>
                  <span class="font-weight-medium">{{splitDecimals(plan.price)[0]}}</span>
                  <div class="d-flex flex-start flex-column">
                    <span class="sup font-weight-medium"> {{splitDecimals(plan.price)[1]}} </span>
                    <span class="sub">{{plan.frequency}}</span>
                  </div>
                </div>
                <div class="plan-price text-h3 d-flex flex-start" v-else>
                  &nbsp;
                </div>
              </div>
            </v-card-text>
            <v-card-text v-if="plan.description">{{ plan.description}}</v-card-text>
            <v-spacer></v-spacer>

            <v-card-text>
              <v-list dense :color="$vuetify.breakpoint.smAndDown ? 'iq_darkerblue lighten-2' : 'iq_light'">
                <template v-for="feature in plan.features">
                  <v-list-item class="px-0" single-line  v-if="feature.enable === 1" :key="feature.name">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon v-if="feature.enable === 1">mdi-check</v-icon>
                        <v-icon v-else>mdi-close</v-icon>
                        {{ feature.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-for="feature in plan.features">
                  <v-list-item class="px-0"  single-line  v-if="feature.enable === 0" :key="feature.name">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon v-if="feature.enable === 1">mdi-check</v-icon>
                        <v-icon v-else>mdi-close</v-icon>
                        {{ feature.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              
              </v-list>
            </v-card-text>
            <v-card-actions>
                <v-btn block :outlined="plan.id !== planId" color="primary" @click="setPlanId(plan)"> {{ plan.id === planId ? 'Selected' : 'Choose Plan' }} </v-btn>
            </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card flat class="theme-breakpoint-card-switch" :dark="$vuetify.breakpoint.smAndDown">
          <v-card-title class="d-flex justify-center text-center align-center text-h4 font-weight-bold">
            All plans include
          </v-card-title>
          <v-card-subtitle class="d-flex justify-center text-center align-center">
            Our Products are bundled with these features.
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="plans-container">
      <v-col    
      v-for="feature in featuresInclude"
      :key="feature.name"
      cols="12"
      :xl="getColumnSize(3  , featuresInclude)"
      :lg="getColumnSize(3, featuresInclude)"
      :md="getColumnSize(4, featuresInclude)"
      >
        <v-card
          :dark="$vuetify.breakpoint.smAndDown"
          class="p-0 theme-breakpoint-card-switch"
          flat 
        >
          <v-card-title>
            <v-sheet
            v-if="feature.icon !== '$hubspotAppPartner'"
            color="primary lighten-4"
            elevation="0"
            rounded
            height="50"
            width="50"
            class="d-flex align-center justify-center"
          >
            <v-icon :color="$vuetify.breakpoint.smAndDown ? 'iq_darkerblue lighten-2' : 'iq_dark lighten-4'" large>{{feature.icon}}</v-icon>
          </v-sheet>
          <v-sheet
            v-else
            outlined
            color="white"
            elevation="0"
            rounded
            height="50"
            width="150"
            class="d-flex align-center justify-center"
          >
            <v-icon class="auto-limited-120" :color="$vuetify.breakpoint.smAndDown ? 'iq_darkerblue lighten-2' : 'iq_dark lighten-4'">{{feature.icon}}</v-icon>
          </v-sheet>
          </v-card-title>
          <v-card-text>
            <p class="text-h5 font-weight-bold"> {{ feature.name }} </p>
            <div>
              {{ feature.description }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
      <v-footer padless fixed :dark="$vuetify.breakpoint.smAndDown"  style="z-index: 4; backdrop-filter: blur(5px);" :style="$vuetify.breakpoint.smAndDown ? 'background-color: rgba(0,0,0,0.33)':'background-color: rgba(255,255,255,0.33)'">
        <v-container fluid class="pa-0">
          <v-col lg="7" offset-lg="4" class="d-flex flex-row justify-end">
            <v-btn v-if="this.currentPlan.price !== 0" x-large :disabled="nextButton" color="primary" @click="nextPaymentButton()"> Next - Payment </v-btn>
            <v-btn v-else x-large :disabled="nextButton" color="primary" @click="nextPaymentButton()"> Next </v-btn>
          </v-col>
        </v-container>
      </v-footer>  
  </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        name: "PlansSelection",
        computed:{
            ...mapGetters({
              productName: 'productRegistration/getProductName',
              currentPlan: 'productRegistration/getPlanId',
              company: 'company/getCurrentCompany',
              currentUser: 'getUser',
              billingDetails:'company/getBillingDetails',
            })
        },
        data () {
            return{
                plans: [],
                featuresInclude: [],
                nextButton: true,
                planId: null
  
            }
        },
        async created() {
          await this.$store.dispatch('company/getBillingDetailsByCompanyId',this.company.id);
          this.$store.commit('productRegistration/SET_LOADING', true);

          const plan = await this.$store.dispatch('productRegistration/getSmartRampPlans', this.formatIQName(this.$route.params.id));
          const features = await this.$store.dispatch('productRegistration/getAppFeatures', this.formatIQName(this.$route.params.id));

          this.$store.commit('productRegistration/SET_LOADING', false);

          if(plan.data !== undefined){
            this.plans = plan.data
          } 

          if(features.data !== undefined){
            this.featuresInclude = features.data
          } 

          
        },
        methods: {
          setPlanId(planId){
            this.$store.dispatch('productRegistration/setPlanId', planId);
            this.planId = planId.id;
            if(this.currentPlan != null && this.currentPlan != undefined){
              this.nextButton = false;
            }
          },
          async nextPaymentButton(){
            if(this.currentPlan.price !== 0){
              this.$store.commit('productRegistration/SET_STEP_COMPLETE', 'planSelection');
              this.$store.commit('productRegistration/SET_LOADING', true);
              this.$router.push(`../payment/${this.$route.params.id}`);
            }else{
              let response = await this.$store.dispatch('productRegistration/addNewFreeSubscription',{
                company: this.company,
                user : this.currentUser.hubSpotUserId,
                product: this.$route.params.id,
                billingDetails: this.billingDetails,
            });

            if(response){
                this.$swal({
                    icon: 'success',
                    title: 'Awesome!',
                    text: 'We created your account. Let\'s get you set up!',
                    confirmButtonText: "Continue",
                }).then(async result => {
                  this.$store.commit('productRegistration/SET_STEP_COMPLETE', 'planSelection');
                  this.$store.commit('productRegistration/SET_STEP_COMPLETE', 'payment');
                  this.$store.commit('productRegistration/SET_LOADING', true);
                  this.$router.push(`../summary/${this.$route.params.id}`);
                });
            }else{
                this.$swal({
                    icon: 'error',
                    title: 'Oh no!',
                    text: 'We have encountered an error. Please try again.',
                    confirmButtonText: "Continue",
                }).then(async result => {
                });
                
            }   
            }
          },
          getColumnSize(minColumnSize, object) {
            const numItems = object.length;
            const baseColumns = 12; // Total available columns

            // Calculate the column size for each item based on the number of items
            return Math.max(minColumnSize, Math.round(baseColumns / numItems));
          },
          getColumnSizeMax(minColumnSize, maxColumnSize, object) {
            const numItems = object.length;
            const baseColumns = 12; // Total available columns

            // Calculate the column size for each item based on the number of items
            const calculatedColumnSize = Math.max(
              minColumnSize,
              Math.min(Math.round(baseColumns / numItems), maxColumnSize)
            );

            return calculatedColumnSize;
          }

        }
    };
</script>
<style>
    .plans-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
</style>