import store from '../store/index.js';

export const productConfigGuard = async (to, from, next) => {
    const path = to.path.toLowerCase(); 

    const user = await store.dispatch('getUserCompanyGuard');

    if (!user) {

        switch (true) {
            // MessageIQ
            case path === "/conversations":
            case path === "/calls":
            case path === "/contacts":
            case path === "/charts":
            // case path.startsWith("/registration/messageiq"):
            case path.startsWith("/settings"):
                return next('/product/complete-registration/MessageIQ');

            // PriceIQ
            case path === "/priceiq":
            case path === "/priceiq/create":
            case path === "/priceiq/edit/":
                return next('/product/complete-registration/PriceIQ');

            // StatusIQ
            case path === "/statusiq":
            case path === "/statusiq/create":
            case path.startsWith("/statusiq/edit/"):
                return next('/product/complete-registration/StatusIQ');

            // StockIQ
            case path === "/stockiq":
                return next('/product/complete-registration/StockIQ'); 

            default:
                return next('/product/complete-registration/SmartRamp');
        }
    } else {
        switch (true) {
            // MessageIQ
            case path === "/conversations" && user.companyApps.messageIq === false:
            case path === "/calls" && user.companyApps.messageIq === false:
            case path === "/contacts" && user.companyApps.messageIq === false:
            case path === "/charts" && user.companyApps.messageIq === false:
            case path.startsWith("/settings") && user.companyApps.messageIq === false:
                return next('/product/plan-selection/MessageIQ');

            // PriceIQ
            case path === "/priceiq" && user.companyApps.priceIq === false:
            case path === "/priceiq/create" && user.companyApps.priceIq === false:
            case path === "/priceiq/edit/" && user.companyApps.priceIq === false:
                return next('/product/plan-selection/PriceIQ');

            // StatusIQ
            case path === "/statusiq" && user.companyApps.statusIq === false:
            case path === "/statusiq/create" && user.companyApps.statusIq === false:
            case path.startsWith("/statusiq/edit/") && user.companyApps.statusIq === false:
                return next('/product/plan-selection/StatusIQ');

            // StockIQ
            case path === "/stockiq" && user.companyApps.stockIq === false:
                return next('/product/plan-selection/StockIQ');

            default:
                return next();
        }
    }
}
