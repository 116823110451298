import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VCard,{attrs:{"rounded":""}},[_c(VTabs,{attrs:{"background-color":"accent darken-4","centered":"","dark":"","icons-and-text":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider),_vm._l((_vm.sections),function(section){return _c(VTab,{key:section.index,attrs:{"href":'#tab-'+section.index}},[_vm._v(" "+_vm._s(section.name)+" "),_c(VIcon,[_vm._v(_vm._s(section.icon))])],1)})],2),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,{attrs:{"value":"tab-0"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('Contacts',{attrs:{"systemA":this.systems[17],"systemB":this.systems[22]}})],1)],1)],1),_c(VTabItem,{attrs:{"value":"tab-1"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('Products',{attrs:{"systemA":this.systems[17],"systemB":this.systems[22]}})],1)],1)],1),_c(VTabItem,{attrs:{"value":"tab-2"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('Transactions',{attrs:{"systemA":this.systems[17],"systemB":this.systems[22]}})],1)],1)],1),_c(VTabItem,{attrs:{"value":"tab-3"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('Connections',{attrs:{"systemA":this.systems[17],"systemB":this.systems[22]}})],1)],1)],1)],1),_c(VCardActions,{staticClass:"d-flex justify-space-between"},[_c(VBtn,{attrs:{"text":""}},[_vm._v("Close")]),_c(VBtn,{attrs:{"color":"primary","outlined":""},on:{"click":_vm.saveActions}},[_vm._v("Save")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }