<template>
    <v-container>
        <v-card rounded="">
            <v-tabs
                v-model="tab"
                background-color="accent darken-4"
                centered
                dark
                icons-and-text
                >
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab v-for="section in sections" :key="section.index" :href="'#tab-'+section.index">
                        {{section.name}}
                        <v-icon>{{section.icon}}</v-icon>
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-0">
                        <v-card flat>
                            <v-card-text>
                                <Contacts :systemA="this.systems[17]" :systemB="this.systems[22]"></Contacts>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-1">
                        <v-card flat>
                            <v-card-text>
                                <Products :systemA="this.systems[17]" :systemB="this.systems[22]"></Products>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-2">
                        <v-card flat>
                            <v-card-text>
                                <Transactions :systemA="this.systems[17]" :systemB="this.systems[22]"></Transactions>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-3">
                        <v-card flat>
                            <v-card-text>
                                <Connections :systemA="this.systems[17]" :systemB="this.systems[22]"></Connections>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn text>Close</v-btn>
                    <v-btn color="primary" outlined v-on:click="saveActions">Save</v-btn>

                </v-card-actions>
            </v-card>
    </v-container>
</template>
<script>
import Contacts from './_Contacts.vue';
import Products from './_Products.vue';
import Transactions from './_Transactions.vue';
import Connections from './_Connections.vue';
import { mapGetters } from 'vuex';
export default {
    components: {
        Contacts,Products,Transactions,Connections
    },
    computed: {
        ...mapGetters({
            currentCompany: 'clover/getCurrentCompany'
        })
    },
    data () {
        return {
            tab: null,
            sections: [
                {index: 0, name: 'Contacts', icon: 'mdi-card-account-details-outline'},
                {index: 1, name: 'Products', icon: 'mdi-basket'},
                {index: 2, name: 'Transactions', icon: 'mdi-credit-card-multiple-outline'},
                {index: 3, name: 'Connections', icon: 'mdi-connection'},
            ],
            systems: [
                { name: 'recurly',                  title:'Recurly',    link:'https://integrateiq.com/integrations/hubspot-recurly/',        src: 'Recurly.png'},
                { name: 'salesforce',               title:'Sales Force',    link:'https://integrateiq.com/integrations/hubspot-salesforce/',        src: 'SalesForce.png'},
                { name: 'sendgrid',                 title:'SendGrid',    link:'https://integrateiq.com/integrations/hubspot-sendgrid/',        src: 'SendGrid.png'},
                { name: 'smartsheet',               title:'Smartsheet',    link:'https://integrateiq.com/integrations/hubspot-smartsheet/',        src: 'smartsheet.png'},
                { name: 'square',                   title:'Square',    link:'https://integrateiq.com/integrations/hubspot-square/',        src: 'square.png'},
                { name: 'stripe',                   title:'Stripe',    link:'https://integrateiq.com/integrations/hubspot-stripe/',        src: 'Stripe.png'},
                { name: 'sugarcrm',                 title:'Sugar CRM',    link:'https://integrateiq.com/integrations/hubspot-sugarcrm/',        src: 'SugarCRM.png'},
                { name: 'toast',                    title:'Toast',    link:'',        src: 'toast.png'},
                { name: 'trello',                   title:'Trello',    link:'https://integrateiq.com/integrations/hubspot-trello/',        src: 'Trello.png'},
                { name: 'xero',                     title:'Xero',    link:'https://integrateiq.com/integrations/hubspot-xero/',        src: 'xero.png'},
                { name: 'zendesk',                  title:'ZenDesk',    link:'https://integrateiq.com/integrations/hubspot-zendesk-integration/',        src: 'zendesk.png'},
                { name: 'zohocrm',                  title:'Zoho CRM',    link:'https://integrateiq.com/integrations/hubspot-zoho-crm/',        src: 'ZOHOCRM.png'},
                { name: 'activecampaign',           title:'Active Campaign',    link:'https://integrateiq.com/integrations/hubspot-active-campaign/',        src: 'ActiveCampaign.png'},
                { name: 'brighttree',               title:'Brighttree',    link:'https://integrateiq.com/integrations/hubspot-brightree/',        src: 'brighttree.png'},
                { name: 'bullhorn',                 title:'Bullhorn',    link:'https://integrateiq.com/integrations/hubspot-bullhorn/',        src: 'bullhorn.png'},
                { name: 'chargebee',                title:'Chargebee',    link:'https://integrateiq.com/integrations/hubspot-chargebee/',        src: 'ChargeBee.png'},
                { name: 'chargify',                 title:'Chargify',    link:'https://integrateiq.com/integrations/hubspot-chargify/',        src: 'chargify.png'},
                { name: 'clover',                   title:'Clover',    link:'https://integrateiq.com/integrations/hubspot-clover/',        src: 'clover.png'},
                { name: 'drift',                    title:'Drift',    link:'https://integrateiq.com/integrations/hubspot-drift/',        src: 'drift.png'},
                { name: 'freshbooks',               title:'Freshbooks',    link:'https://integrateiq.com/integrations/hubspot-freshbooks/',        src: 'freshbooks.png'},
                { name: 'freshdesk',                title:'Freshdesk',    link:'https://integrateiq.com/integrations/hubspot-freshdesk/',        src: 'Freshdesk.png'},
                { name: 'getresponse',              title:'Getresponse',    link:'https://integrateiq.com/integrations/hubspot-getresponse/',        src: 'getResponse.png'},
                { name: 'hubspot',                  title:'Hubspot',    link:'',        src: 'Hubspot.png'},
                { name: 'insightly',                title:'Insightly',    link:'https://integrateiq.com/integrations/hubspot-insightly/',        src: 'insightly.png'},
                { name: 'intercom',                 title:'Intercom',    link:'https://integrateiq.com/integrations/hubspot-intercom/',        src: 'intercom.png'},
                { name: 'jira',                     title:'Jira',    link:'https://integrateiq.com/integrations/hubspot-jira/',        src: 'jira.png'},
                { name: 'keap',                     title:'Keap',    link:'https://integrateiq.com/integrations/hubspot-keap/',        src: 'Keap.png'},
                { name: 'klaviyo',                  title:'Klaviyo',    link:'https://integrateiq.com/integrations/hubspot-klaviyo/',        src: 'klaviyo.png'},
                { name: 'marketo',                  title:'Marketo',    link:'https://integrateiq.com/integrations/hubspot-marketo/',        src: 'marketo.png'},
                { name: 'microsoftdynamics365crm',  title:'Microsoft Dynamics 365 CRM',    link:'https://integrateiq.com/integrations/hubspot-dynamics-365-crm/',        src: 'MicrosoftDynamics365CRM.png'},
                { name: 'microsoftdynamicsax',      title:'Microsoft Dynamics AX',    link:'https://integrateiq.com/integrations/hubspot-microsoft-ax-integration/',        src: 'MicrosoftDynamicsAX.png'},
                { name: 'microsoftdynamicsgp',      title:'Microsoft Dynamics GP',    link:'https://integrateiq.com/integrations/hubspot-microsoft-dynamics-gp/',        src: 'MicrosoftDynamicsGP.png'},
                { name: 'mindbody',                 title:'Mindbody',    link:'https://integrateiq.com/integrations/hubspot-mindbody/',        src: 'mindbody.png'},
                { name: 'netsuite',                 title:'NetSuite',    link:'https://integrateiq.com/integrations/hubspot-netsuite-integration/',        src: 'netsuite.png'},
                { name: 'oracleeloquia',            title:'Oracle Eloquia',    link:'https://integrateiq.com/integrations/hubspot-eloqua/',        src: 'OracleEloquia.png'},
                { name: 'oraclesalescloud',         title:'oracle Sales Cloud',    link:'https://integrateiq.com/integrations/hubspot-oracle-sales-cloud/',        src: 'oracleSalesCloud.png'},
                { name: 'pipedrive',                title:'Pipedrive',    link:'https://integrateiq.com/integrations/hubspot-pipedrive-integration/',        src: 'pipedrive.png'},
                { name: 'quickbooks',               title:'Quickbooks',    link:'https://integrateiq.com/integrations/hubspot-quickbooks-integration/',        src: 'quickbooks.png'},
            ],
        }
    },
    mounted(){
        this.$auth.auth0ClientPromise.then(async () => {
            if(Object.keys(this.currentCompany).length === 0){
                var mid = this.$route.query.mid;
                
                await this.$store.dispatch('clover/getUserCompany',mid);
                if(mid !== undefined){
                    if(this.currentCompany.merchantId === null){
                        await this.$store.dispatch('clover/setCompanyIdByMerchantId',mid);
                        await this.$store.dispatch('clover/getUserCompany',mid);
                    }
                }
                await this.$store.dispatch('clover/getCloverCompanyActions');
            }else{
                var mid = this.$route.query.mid;
                if(mid !== undefined){
                    if(this.currentCompany.merchantId === null){
                        await this.$store.dispatch('clover/setCompanyIdByMerchantId',mid);
                        await this.$store.dispatch('clover/getUserCompany',mid);
                    }
                }
                await this.$store.dispatch('clover/getCloverCompanyActions');
            }

            if(this.$route.query.hsConnectStatus !== undefined){
                if(this.$route.query.hsConnectStatus === "success"){
                    this.$swal({
                        icon: 'success',
                        title: 'HubSpot Connected',
                        text: 'You have successfully connected to your HubSpot Account.',
                        toast: true,
                        timer: 3000,
                        position: 'top-end'
                    });
                }else{
                    this.$swal({
                        icon: 'error',
                        title: 'Error Connecting to HubSpot',
                        text: 'An error occurred while connecting to HubSpot. Please try again later.',
                        toast: true,
                        timer: 3000,
                        position: 'top-end'
                    });
                }
            }
        });
    },
    methods: {
        async saveActions(){
            let response = await this.$store.dispatch('clover/saveCloverActions');
            if(response){
                this.$swal({
                    icon: 'success',
                    title: 'Actions Updated',
                    text: 'Actions successfully updated.',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });
            }else{
                this.$swal({
                    icon: 'error',
                    title: 'Error Updating Actions',
                    text: 'An error occurred while updating the actions. Please try again later.',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });
            }
        }
    }
}
</script>