import { HubConnectionBuilder } from "@aspnet/signalr";

class MessageHub{
    constructor(){
        this.client = new HubConnectionBuilder()
        .withUrl(process.env.VUE_APP_SIGNALR_URL)
        .build();
    }

    start(){
        this.client.start().catch(err => {
            console.error(err.message);
        });
    }
}

export default new MessageHub();