import Api from "@/services/api";
import Vue from "vue";
export default {
    namespaced: true,
    state: {
        plans: {}
    },
    mutations: {
        SET_PLANS(state, plans){
            state.plans = plans;
        }
    },
    actions: {
        async getPlans({commit}){
            let response = await Api().get(`/Web/Billing/Plans`)
            .catch(err => {
                console.error(err.message);
            });
            commit('SET_PLANS',response.data);
            return response.data;
        },
        async downloadBillingInvoice({},invoiceId){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let invoiceResponse = await Api().get(`/Web/Billing/GetInvoice/${invoiceId}`,config)
            .catch(() => {
                return false;
            });
            window.open(invoiceResponse.data, '_blank');
        }
    },
    getters: {
        getPlans: state => { return state.plans }
    }
};