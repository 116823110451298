import Api from "@/services/api";
import Vue from 'vue';
export default {
    namespaced: true,
    state: {
        currentCompanyPhones: {},
        currentContactPhones: {},
        currentContactMessages: {},
        currentContactUnsubscribe: {},
        currentNewConversation:{}
    },
    mutations: {
        SET_CURRENT_COMPANY_PHONES(state, currentCompanyPhones){
            state.currentCompanyPhones = currentCompanyPhones;
        },
        SET_CURRENT_CONTACT_PHONES(state, currentContactPhones){
            state.currentContactPhones = currentContactPhones;
        },
        SET_CURRENT_CONTACT_MESSAGES(state, currentContactMessages){
            state.currentContactMessages = currentContactMessages;
        },
        SET_CURRENT_CONTACT_UNSUBSCRIBE(state, currentContactUnsubscribe){
            state.currentContactUnsubscribe = currentContactUnsubscribe;
        },
        SET_CURRENT_NEW_CONVERSATION(state, currentNewConversation){
            state.currentNewConversation = currentNewConversation;
        }
    },
    actions: {
        async getCompanyPhones({commit}, id){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Message/GetCompanyPhones/${id}`,config)
            .catch(err => {
                console.error(err.message);
            });
            commit('SET_CURRENT_COMPANY_PHONES',response.data);
            return response.data;
        },
        async getContactMessages({commit},{contactId: contactId, companyPhone: companyPhone}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            if(typeof companyPhone === 'undefined'){
                companyPhone = "";
            }else{
                 if(typeof companyPhone == 'object'){
                    companyPhone = companyPhone.phone.replace("+", '');
                 }else{
                    companyPhone = companyPhone.replace("+", '');
                 }
            }
            const offset = new Date().getTimezoneOffset() / -60;
            let response = await Api().get(`/Web/Message/GetMessagesByContactId/${contactId}?companyPhone=${companyPhone}&timezone=${offset}`,config)
            .catch(err => {
                console.error(err.message);
                return null;
            });
            commit('SET_CURRENT_CONTACT_MESSAGES',response.data);
            return response.data;
        },
        
        async sendMessage({},{contactId: contactId, companyId: companyId, message: message, companyPhone: companyPhone, mmsContent: mmsContent}){
           let result = null;
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            if( mmsContent == undefined){
                result = await Api().post(`/Web/Message/SendMessage`,{
                    companyId: companyId,
                    companyPhone: companyPhone,
                    contactId: contactId,
                    mmsContent: mmsContent,
                    message: message
                },config).catch(() => {
                    return false;
                });
            }else{
                let templateObj = {
                    "CompanyId": companyId,
                    "CompanyPhone": companyPhone,
                    "ContactId": contactId,
                    "Message": message
                };
    
                const formData = new FormData();
                formData.append('file',mmsContent);
                formData.append('jsonData',JSON.stringify(templateObj));
    
                result = await Api().post(`/Web/Message/SendMessageWithImg`,formData,{
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    }
                }).catch(() => {
                    return {
                        'result': false
                    };
                });
            }
            return result;
        },
        async Unsubscribe({},id){
            await Api().put(`/Web/Message/Unsubscribe/${id}`)
            .catch(err => {
                return false;
            });
            return true;
        },

        async getnewConversation({commit},{companyId: companyId, contactPhone: contactPhone}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Message/StartChat/${companyId}/${contactPhone}`,config)
            .catch(err => {
                console.error(err.message);
            });
            commit('SET_CURRENT_NEW_CONVERSATION',response.data);
            return response.data;
        },

        async getContactPhones({commit}, {contactId: contactId, companyId: companyId, type:type}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Message/GetPhoneNumberContacts/${contactId}/${companyId}/${type}`,config)
            .catch(err => {
                console.error(err.message);
            });
            commit('SET_CURRENT_CONTACT_PHONES',response.data);
            return response.data;
        },

        
        async getAvailablePhones({}, {hubspotPortalId: hubspotPortalId, hubspotUserId: hubspotUserId}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Message/GetAvailablePhones/${hubspotPortalId}/${hubspotUserId}`,config)
            .catch(err => {
                console.error(err.message);
            });
            return response.data;
        },

        async getCompanyPhonesByUserId({commit}, id){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let response = await Api().get(`/Web/Message/GetCompanyPhonesByUserId/${id}`,config)
            .catch(err => {
                console.error(err.message);
            });
            commit('SET_CURRENT_COMPANY_PHONES',response.data);
            return response.data;
        },
        
},
    getters: {
        getCurrentCompanyPhones: state => { return state.currentCompanyPhones},
        getCurrentContactPhones: state => { return state.currentContactPhones},
        getCurrentContactMessages: state => { return state.currentContactMessages},
        getCurrentContactUnsubscribe: state => { return state.currentContactUnsubscribe},
        getCurrentNewConversation: state => { return state.currentNewConversation}
    }
};