<template>
    <div style="overflow: hidden !important; height: 100% !important; padding-bottom: 50px;">
        <v-overlay color="iq_dark" :value="loading">
            <v-icon color="iq_light" x-large>mdi-loading mdi-spin</v-icon>
            <span class="iq_light--text font-weight-bold "> {{loadingMessage}} </span>
        </v-overlay>
        <v-card elevation="2" v-if="!loading" style="color: black;">
            <v-simple-table>
                <template v-slot:default>
                <thead>
                    <tr>
                        <!-- <th class="text-left">
                            <strong>SKU Number</strong>
                        </th> -->
                        <th class="text-left">
                            <strong>Product Name</strong>
                        </th>
                        <th class="text-left">
                            <strong>Quantity Quoted</strong>
                        </th>
                        <th class="text-left">
                            <strong>Available Inventory</strong>
                        </th>
                        <th class="text-left">
                            <strong>Variance</strong>
                        </th>
                        <th class="text-left">
                            <strong>Price</strong>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="item in inventoryDetails.items"
                    :key="item.id"
                    v-bind:style="[ ( (item.availableInventory-item.requestedAmount) < 0) ? {'background': 'rgb(255, 0, 0, 0.2)', 'color': 'red', 'font-weight': 'bold'} : {} ]"
                    >
                        <!-- <td>{{ item.sku }}</td> -->
                        <td>{{ item.name }}</td>
                        <td>{{ commaSeparateNumber(item.requestedAmount) }}</td>
                        <td>{{ commaSeparateNumber(item.availableInventory) }}</td>
                        <td>{{ commaSeparateNumber(item.availableInventory-item.requestedAmount) }}</td>
                        <td>${{ commaSeparateNumber(item.itemPrice) }}</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </v-card>

        <v-row justify="center" class="mt-7" v-if="!loading">
            
                <v-btn elevation="0" color="primary" outlined @click="removeOutOfStock()">Remove out of Stock Products</v-btn>
                <v-btn elevation="0" color="primary" outlined class="ml-3" @click="updateMaxInventory()">Update Products to Max</v-btn>

        </v-row>

    </div>

</template>
<script>
import { mapGetters } from 'vuex';
export default{
    data(){
        return{
            loading: true,
            hubSpotPortalId: null,
            hubSpotDealId: null,
            hubSpotUserId: null,
            loadingMessage: "Pulling inventory data"
        }
    },
    computed: {
        ...mapGetters({
            inventoryDetails: 'stockIQ/getInventoryDetails'
        })
    },
    async mounted(){
        // Hide Intercom Launcher
        Intercom('update', {
            "hide_default_launcher": true
        });
        
        this.hubSpotPortalId = this.$route.query.c;
        this.hubSpotDealId = this.$route.query.co;
        this.hubSpotUserId = this.$route.query.u;

        this.getInventory();
    },
    methods: {
        async getInventory(){
            let response = await this.$store.dispatch('stockIQ/getInventoryDetails',{
                portalId: this.hubSpotPortalId,
                dealId: this.hubSpotDealId,
                userId: this.hubSpotUserId
            });

            if(response !== null){
                this.loading = false;
            }else{
                this.$swal({
                    icon: 'error',
                    title: 'Error Pulling Inventory Data',
                    text: 'There was an error pulling the inventory data. Please try again later.',
                });
            }
        },
        async updateMaxInventory() {
            let self = this;
            this.loading = true;
            this.loadingMessage = "Updating inventory data";

            let response = await this.$store.dispatch('stockIQ/updateMaxInventory',{
                portalId: this.hubSpotPortalId,
                dealId: this.hubSpotDealId,
                userId: this.hubSpotUserId
            });

            if(response){
                this.loading = false;
                this.$swal({
                    icon: 'success',
                    title: 'Updated Inventory Data',
                    text: 'Updated quoted quantity to maximum quantity in stock.',
                }).then(function() {
                    self.loading = true;
                    self.loadingMessage = "Pulling inventory data"
                    self.getInventory();
                });
                
            }else{
                this.loading = false;
                this.$swal({
                    icon: 'error',
                    title: 'Error Pulling Inventory Data',
                    text: 'There was an error updating inventory data. Please try again later.',
                });
            }
            
        },
        async removeOutOfStock() {
            let self = this;
            this.loading = true;
            this.loadingMessage = "Removing Out of Stock Items";

            let response = await this.$store.dispatch('stockIQ/removeOutOfStock',{
                portalId: this.hubSpotPortalId,
                dealId: this.hubSpotDealId,
                userId: this.hubSpotUserId
            });

            if(response){
                this.loading = false;
                this.$swal({
                    icon: 'success',
                    title: 'Updated Inventory Data',
                    text: 'Removed out of stock products.',
                }).then(function() {
                    self.loading = true;
                    self.loadingMessage = "Pulling inventory data"
                    self.getInventory();
                });
            }else{
                this.loading = false;
                this.$swal({
                    icon: 'error',
                    title: 'Error Pulling Inventory Data',
                    text: 'There was an error updating inventory data. Please try again later.',
                });
            }

        },
        commaSeparateNumber(number){
            return number.toLocaleString("en-US");
        }
    }
}
</script>
