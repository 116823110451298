<template>
    <v-card elevation="0">
    <v-toolbar
        dark
        color="iq_darkerblue" 
        id="SettingsNav"
       
    >
        <v-toolbar-title class="display-3">SmartRamp Admin </v-toolbar-title>
        
        <template v-slot:extension>
            <v-container class="pa-0">
                <v-tabs
                    v-model="tabs"
                    show-arrows
                    grow
                    background-color="iq_darkerblue"
                >
                    <v-tab 
                        v-for="setting  in settingComponents"
                        :key="setting.id"
                        :to="setting.route"
                        :class="tabs  == setting.route ? 'v-tab--active' : ''"
                    >
                        {{ setting.name }}
                    </v-tab>
                </v-tabs>
            </v-container>
        </template> 
    </v-toolbar>
    <v-tabs-items v-model="tabs">
        <router-view></router-view>
    </v-tabs-items>
  </v-card> 
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            userRole: 'getUserRole',
            currentCompany: 'company/getCurrentCompany',
            user: 'getUser'
        }),
    },
    data () {
        return{
            tabs: '/admin/report/companies',
            settingsViewport: null,
            settingComponents:[
                    {id:'1', name: 'Admin Report', comp: 'AdminReportData', route:'/admin/report/companies'},
            ]
        } 
    },

    mounted(){
        this.$auth.auth0ClientPromise.then(async () => {
            if(Object.keys(this.currentCompany).length === 0){
                    await this.$store.dispatch('getUserCompany');
            }
            if(this.userRole === "SuperAdmin"){
                this.settingComponents = this.settingComponents.concat([
                    {id:'2', name: 'Overage Report', comp: 'OverageReport', route:'/admin/report/overage'},
                   
                ]);
            }
        });
    }
  
}
</script>