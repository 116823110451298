<template>
    <v-card>
        <v-overlay :value="loading">
                <v-progress-circular
                indeterminate
                size="70"
                width="7"
                color="primary"
                ></v-progress-circular>
            </v-overlay>
        <v-card-title>
            <v-spacer></v-spacer>
            <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                v-model="date"
                label="Search by date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
            </template>
            <v-date-picker
                 v-model="dateFormat"
                type="month"
                min="2022-08"
                scrollable
            >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="clearSearch"
            >
                Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(date)"
            >
                OK
            </v-btn>
            </v-date-picker>
         </v-dialog>  
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search by name"
                @click:clear="clearSearch"
                single-line
                hide-details
            ></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="tableData"
            :items-per-page="25"
            dense
        >
            <template v-slot:item="row">
                <tr>
                    <td>{{row.item.companyName}}</td>
                    <td>{{row.item.email}}</td>
                    <td>{{row.item.fullName}}</td>
                    <td>{{row.item.renewalDay}}</td>
                    <td>{{formatDateTime(row.item.reportDate)}}</td>
                    <td>{{row.item.plan}}</td>
                    <td>{{formatDecimal(row.item.planCredits)}}</td>
                    <td>{{formatDecimal(row.item.useage)}}</td>
                    <td>{{formatDecimal(row.item.overage)}}</td>
                    <td>{{formatDecimal(row.item.rate)}}</td>
                    <td>{{formatDecimal(row.item.amountDue)}}</td>
                    <td>{{row.item.statusName}}</td>
                    <td>
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                outlined
                                x-small
                                color="primary"
                                v-bind="attrs"
                                v-on="on"
                                >
                                    Actions <v-icon small>mdi-chevron-down</v-icon>
                                
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                v-for="(action, index) in actions"
                                :key="index" 
                                @click="callAction(row.item,action.title)"
                                >
                                    <v-list-item-title>{{ action.title }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-dialog
            v-model="dialog"
            width="500"
        >
        <v-card>
                <v-card-title class="justify-left py-5 title">
                    Update Overage
                </v-card-title>
                <div class="ma-auto position-relative  py-8 tabs" style="max-width: 450px">
                   
                    <v-card-text class="my-8 px-0">
                        <v-form  v-model="updateOverageFormValid" id="updateOverageForm" ref="updateOverageForm" lazy-validation>
                            <v-row class="d-flex flex-column justify-space-between" style="height: 100%">
                                <v-col class="flex-grow-1" >
                                    <v-text-field
                                        type="number"
                                        v-model="rowSelected.rate"
                                        item-text="Rate"
                                        label="Rate"
                                    ></v-text-field>  
                                </v-col>
                                <v-col class="flex-grow-1" >
                                    <v-autocomplete
                                        outlined
                                        :items="listStatus"
                                        v-model="rowSelected.status"
                                        item-text="status"
                                        item-value="id"
                                        label="Select Status" 
                                    ></v-autocomplete>  
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                        
                </div>
                <v-card-actions class="">    
                    <v-footer absolute>
                        <v-row>
                            <v-col cols="12" class="d-flex justify-end py-5">
                                <div>
                                    <v-btn class="mx-2" color="primary"  @click="clear()" outlined>Cancel</v-btn>
                                    <v-btn color="primary"
                                        @click="updateData()">
                                        <span>Update</span>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-footer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
    
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
    name: "AdminReport",
    computed: {
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany',
            userRole: 'getUserRole'
        }),
        headers() {
        return [
                {
                    text: "Company Name",
                    align: "start",
                    value: "companyName",
                    filter: this.nameFilter,
                },
                {
                    text: "Email",
                    align: "start",
                    value: "email",
                },
                {
                    text: "User Name",
                    align: "start",
                    value: "fullName",
                },
                {
                    text: "Renewal Day",
                    value: "renewalDay"
                },
                {
                    text: "Report Date",
                    value: "reportDate",
                    filter: f => { 
                        if(this.dateFormat == undefined){
                            return true;
                        }
                        this.date =moment(this.dateFormat).format("MM-YYYY");
                        let date = moment(f).format("MM-YYYY");

                        return ( date + '' ).toLowerCase().includes( this.date.toLowerCase() ) }
                },
                {
                    text: "Plan",
                    value: "plan"
                },
                {
                    text: "Plan Credits",
                    value: "planCredits"
                },
                {
                    text: "Useage",
                    value: "useage"
                },
                {
                    text: "Overage",
                    value: "overage"
                },
                {
                    text: "Rate",
                    value: "rate"
                },
                {
                    text: "Amount Due",
                    value: "amountDue"
                },
                {
                    text: "Status",
                    value: "statusName"
                },
                {
                    text: "Actions",
                    value: "id"
                }
        ]
      },
    },
    data(){
        return{
            searchDate: '',
            search: '',
            allData: [],
            tableData: [],
            date: undefined,
            dateFormat: undefined,
            modal: false,
            loading: true,
            actions: [
                { title: 'Edit'}
            ],
            dialog: false,
            updateOverageFormValid: true,
            updateOverageForm: {
                rate:0,
                status:{}
            },
            rowSelected:{},
            listStatus:[],
            row:{},
            actualDate: moment().format("MMMM YYYY")

        }
    },
    created(){
        this.$auth.auth0ClientPromise.then(async () => {
            if(Object.keys(this.currentCompany).length === 0){
                await this.$store.dispatch('getUserCompany');
                
                if(this.userRole === "SuperAdmin"){
                    this.getAdminReportData();
                    this.getOverageStatuses();
                }else{
                    this.$router.push("/");
                }
            }else{
                if(this.userRole === "SuperAdmin"){
                    this.getAdminReportData();
                    this.getOverageStatuses();
                }else{
                    this.$router.push("/");
                }
            }
        });
    },
    methods: {
        formatDateTime(dt){
            if(dt !== null){
                return moment(dt).format("MMMM YYYY")

            }
            return "";
        },

        formatDecimal(number){
            if(number != undefined && number != null){
                return number.toLocaleString(); 
            }
        },
        nameFilter(value) {
            // If this filter has no value we just skip the entire filter.
            if (!this.search) {
            return true;
            }

            // Check if the current loop value (The dessert name)
            // partially contains the searched word.
            return value.toLowerCase().includes(this.search.toLowerCase());
        },

        async getAdminReportData() {
            this.loading = true;
            let overageReportData = await this.$store.dispatch("admin/getOverageReport");
            this.tableData = overageReportData;
            this.allData = overageReportData;
            this.loading = false;
        },

        async getOverageStatuses(){
             this.listStatus = await this.$store.dispatch("admin/getOverageStatuses");
        },

        clearSearch() { 
            this.modal = false;
            this.date = '';
            this.date =  undefined;
            this.dateFormat = undefined;
            this.tableData = this.allData;
        },

        callAction(row,actionName){
           if(actionName ==="Edit"){
              this.dialog = true;
              if(row.rate != null && row.rate != undefined){
                this.rowSelected.rate = row.rate;
              }
              if(row.statusId != null && row.statusId != undefined){
                this.rowSelected.status = row.statusId;
              }
              this.row = row;    
           }
        },

        async updateData(){
            if(this.rowSelected.rate != null && this.rowSelected.rate != undefined){
                this.row.rate = this.rowSelected.rate;
                this.row.amountDue = (this.row.rate * this.row.overage);
            }
            this.row.statusId = this.rowSelected.status;

            let response = await this.$store.dispatch("admin/updateOverageStatus",{data: this.row});
            if(response){
                this.clear();
                await this.getAdminReportData();
                await this.$swal({
                    icon: 'success',
                    title: 'Row Updated',
                    text: 'Row Successfully Updated',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });
                setTimeout(() => {
                }, "3000")
               
            }else{
                this.showError('We could not update this row.');
            }
        },
        showError(text){
            this.$swal({
                icon: 'error',
                title: text,
                text: '',
            });
        },
        clear(){
            this.rowSelected = {};
            this.row = {};
            this.dialog = false;
        }

    }
}
</script>