<template>
    <div style="height: 100% !important">
        <v-overlay opacity="0.85" color="iq_light" :value="loading">
            <v-icon color="primary" x-large>mdi-loading mdi-spin</v-icon>
            <span class="primary--text font-weight-bold "> Pulling Price Data... </span>
        </v-overlay>
        <v-overlay opacity="0.85" color="iq_light" :value="applying">
            <v-icon color="primary" x-large>mdi-loading mdi-spin</v-icon>
            <span class="primary--text font-weight-bold "> Applying Price Data... </span>
        </v-overlay>
        <v-container fill-height fluid v-if="!displayTable">
        
            <v-row align="center" justify="center">
                <v-col>
                
                    <center>                        
                        <v-icon color="primary" size="250">$priceIQIcon</v-icon>

                    </center>

                    <div class="ma-auto pa-8"  v-if="!showMessage">
                        <v-autocomplete
                            class="inline-autocomplete"
                            :items="items"
                            item-text="name"
                            label="Select a Price List"
                            @change="pullPriceListInfo"
                            v-model="selectedList"
                            return-object
                            outlined
                            hide-details=""
                        >
                            <template v-slot:selection="{item}">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title> <b>{{item.name}}</b> [ {{roundToTwoDecimalPlaces(item.discount)}}% ]</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <template v-slot:item="{item, attrs, on}">
                                <v-list-item v-on="on" v-bind="attrs" >
                                    <v-list-item-content>
                                        <v-list-item-title> <b>{{item.name}}</b> [ {{roundToTwoDecimalPlaces(item.discount)}}% ]</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        
                        </v-autocomplete>
                    </div>
                    <div class="d-flex justify-center flex-column align-center" v-else>
                            <v-card-title v-if="hasDiscount"> This deal already has a discount</v-card-title>
                            <v-card-title v-else>Please add line items to apply the discount</v-card-title>
                            <v-btn v-if="hasDiscount" outlined color="primary" @click="revertDiscount()" :disabled="applyRevert">Revert Discounted Pricing</v-btn>
                    </div>
                </v-col>
            </v-row>

        </v-container>

        <v-card elevation="0" v-if="displayTable" style="color: black;">
            <v-container fluid>
                <v-row>
                    <v-col align="end">
                        <v-autocomplete class="inline-autocomplete"
                            :items="items"
                            item-text="name"
                            label="Select a Price List"
                            @change="pullPriceListInfo"
                            v-model="selectedList"
                            return-object
                            outlined
                            hide-details
                            :disabled="discountapplied"
                            dense
                        >
                            <template v-slot:selection="{item}">
                                <v-list-item class="compact-item-list" >
                                    <v-list-item-content class="py-0">
                                        <v-list-item-title> <b> {{item.name}}  </b>[ {{roundToTwoDecimalPlaces(item.discount)}}% ]</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <template v-slot:item="{item, attrs, on}">
                                <v-list-item v-on="on" v-bind="attrs" >
                                    <v-list-item-content>
                                        <v-list-item-title> <b>{{item.name}}</b> [ {{roundToTwoDecimalPlaces(item.discount)}}% ]</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                    </v-autocomplete>
                <v-data-table
                :headers="listHeaders"
                :items="priceListItems"
                fixed-header
                dense
                :height="window.height - this.dataTableFooter - 80 "
                :items-per-page="-1"
                class="mt-2 compact-footer-data-table"
                >   
                <template slot="body.append">
                    <tr class="primary--text sticky-footer-row" v-if="$vuetify.breakpoint.smAndUp">
                        <th class="error darken-1 iq_light--text" v-for="header in listHeaders" :key="header.text">
                                <span v-if="header.value === 'sku'"></span>
                                <strong class="float-right body-1" v-else-if="header.value === 'name'">Totals</strong>
                                <template v-else-if="header.value === 'quantity'">
                                    <span class="float-right body-1"> {{ sumField(header.value)}}  </span>
                                </template>
                                <template v-else>
                                    <span class="float-right body-1 nowrapNumber"> <span>$</span> {{ formatNumberTwoDecimals(sumField(header.value)) }}</span>
                                </template>
                            
                        </th>
                    </tr>
                    <tr v-else class="d-flex flex-column primary--text sticky-footer-row error darken-1 rounded-t-xl iq_light--text">
                        <div class="d-flex justify-space-between px-3 pb-1" style="width:100%"  v-for="header in listHeaders" :key="header.text">
                            <div v-if="header.value === 'sku'"></div>
                            <div class="body-1 d-block text-left font-weight-bold py-1 flex-grow-1"  v-else-if="header.value === 'name'">
                                <div>Totals:</div>
                                <v-divider></v-divider>
                            </div>
                            <template v-else-if="header.value === 'quantity'">
                                <span class="mr-auto caption text-capitalize">{{ header.value}}</span><span class="float-right caption"> {{ sumField(header.value)}}:  </span>
                            </template>
                            <template v-else>
                                <span class="mr-auto caption text-capitalize">{{ header.value}}</span> <span class="float-right caption nowrapNumber"> <span>$</span> {{ formatNumberTwoDecimals(sumField(header.value)) }}</span>
                            </template>
                        </div>
                    </tr>

                </template>
                <template v-slot:item.originalProductPrice="{item}">
                    <span>$</span>  {{ formatNumberTwoDecimals(item.originalProductPrice) }}
                </template>
                <template v-slot:item.originalTotal="{item}">
                    <span>$</span> {{ formatNumberTwoDecimals(item.originalTotal) }}
                </template>
                <template v-slot:item.discountedProductPrice="{item}">
                    <span>$</span> {{ formatNumberTwoDecimals(item.discountedProductPrice) }}
                </template>
                <template v-slot:item.discountedTotal="{item}">
                    <span>$</span> {{ formatNumberTwoDecimals(item.discountedTotal) }}
                </template>
                <template v-slot:item.newPrice="{item}">
                    <span>$</span> {{ formatNumberTwoDecimals(item.newPrice) }}
                </template>
                
            </v-data-table>

                    </v-col>
                </v-row>
            </v-container>
            
            <!-- <v-simple-table class="px-5">
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">
                                <strong>SKU Number</strong>
                            </th>
                            <th class="text-left">
                                <strong>Product Name</strong>
                            </th>
                            <th class="text-left">
                                <strong>Quantity</strong>
                            </th>
                            <th class="text-left">
                                <strong>Original Price</strong>
                            </th>
                            <th class="text-left">
                                <strong>Original Total</strong>
                            </th>
                            <th class="text-left">
                                <strong>Discounted Price</strong>
                            </th>
                            <th class="text-left">
                                <strong>Discounted Total</strong>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="item in priceListItems"
                        :key="item.lineItemId"
                        >
                            <td>{{ item.sku }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.quantity }}</td>
                            <td>${{ item.originalProductPrice.toLocaleString() }}</td>
                            <td>${{ item.originalTotal.toLocaleString() }}</td>
                            <td>${{ item.discountedProductPrice.toLocaleString() }}</td>
                            <td>${{ item.discountedTotal.toLocaleString() }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table> -->

            <v-container class="px-3 py-0" style="margin: 0; padding: 0;" fluid>
                <center>
                    <v-btn x-small block color="primary" @click="updatePricing()" :disabled="isDiscountedCount === 0">Apply Discounted Pricing</v-btn>
                </center>
            </v-container>

        </v-card>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Templates from '../../components/AdminComponents/Templates.vue';
export default{
  components: { Templates },
    data(){
        return{
            hasDiscount:false,
            hasProducts:false,
            showMessage:false,
            loading: true,
            applying: false,
            displayTable: false,
            hubSpotPortalId: null,
            hubSpotDealId: null,
            hubSpotUserId: null,
            items: [],
            priceListItems: [], 
            isDiscountedCount:0,
            applyRevert:false,
            totalDiscount: 0,
            selectedList: null,
            countTotal : 0,
            discountapplied:false,

            listHeaders :[
                    {
                        text: "SKU Number", 
                        value: "sku",
                        align: "start"
                    },
                    {
                        text: "Product Name",
                        value: 'name', 
                        align: "start"
                    },
                    {
                        text: "Quantity",
                        value: "quantity", 
                        align: "end"
                    }, 
                    {
                        text: "Original Price (unit)",
                        value: "originalProductPrice",
                        align: "end"
                    },
                    {
                        text: "Original Total",
                        value: "originalTotal",
                        align: "end"
                    },
                    {
                        text: "Per Unit Discount",
                        value: "discountedProductPrice",
                        align: "end"
                    },
                    {
                        text: "Total Discount",
                        value: "discountedTotal",
                        align: "end"
                    },
                    {
                        text: " New Total",
                        value: "newPrice",
                        align: "end"
                    }
            ],
            window: { //LAYOUT TOOL: store location when page will need vertical space calculations         )
                width: 0,
                height: 0
            },
        }
    },
    computed: {
        ...mapGetters({
        })

    },
    async mounted(){
        // Hide Intercom Launcher
        Intercom('update', {
            "hide_default_launcher": true
        });
        
        this.hubSpotPortalId = this.$route.query.c;
        this.hubSpotDealId = this.$route.query.co;
        this.hubSpotUserId = this.$route.query.u;

        this.hasDiscount = await this.$store.dispatch('priceIQ/CheckForDiscount',{
            portalId: this.hubSpotPortalId,
            dealId: this.hubSpotDealId
        });
        if(this.hasDiscount){
            this.showMessage = true;
            this.loading = false;
        }else{
             this.hasProducts = await this.$store.dispatch('priceIQ/CheckForProducts',{
                portalId: this.hubSpotPortalId,
                dealId: this.hubSpotDealId
            });

            if(this.hasProducts){
                let response = await this.$store.dispatch('priceIQ/getCompanyPriceListsById',{
                    portalId: this.hubSpotPortalId
                });

                if(response !== null){
                    this.loading = false;
                    this.items = response.sort((a, b) => a.name.localeCompare(b.name));
                }else{
                    this.$swal({
                        icon: 'error',
                        title: 'Error Pulling Price List Data',
                        text: 'There was an error pulling price data. Please try again later.',
                    });
                }
            }else{
                this.showMessage = true;
                this.loading = false;
            }
        

       
        }

        
    },
    created(){
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() { //LAYOUT TOOL: on leave dispose
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        sumField(key) {
            // sum data in give key (property)
            return this.priceListItems.reduce((a, b) => a + (b[key] || 0), 0);
        },
        async pullPriceListInfo() {
            
            this.loading = true;

            let response = await this.$store.dispatch('priceIQ/calculatePriceList',{
                portalId: this.hubSpotPortalId,
                dealId: this.hubSpotDealId,
                priceListId: this.selectedList.id
            });

            if(response !== null) {
                this.loading = false;
                this.displayTable = true;

                this.priceListItems = response.lineItems.filter(item => item.originalProductPrice !== 0 && item.originalProductPrice !== null);
                this.isDiscountedCount = this.priceListItems.filter(p => p.isDiscounted == true).length;
                
                this.productsHasDiscount(this.isDiscountedCount);

                this.countTotal = 0;
                this.priceListItems.forEach(element => {
                    if(element.isDiscounted){
                        this.countTotal = this.countTotal + element.discountedTotal;
                   // }else{
                    //    this.countTotal = this.countTotal + element.originalTotal;
                    }
                    
                });

                this.calculateTotalDiscount(this.priceListItems);

            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Error Pulling Price List Data',
                    text: 'There was an error pulling price data. Please try again later.',
                });
            }
            

        },
        calculateTotalDiscount(items) {
            if (!items.length) return;

            let totalPrice = 0;
            this.totalDiscount = 0;
            
            items.map(item => totalPrice += item.originalTotal);

            if (this.roundToTwoDecimalPlaces(Number(totalPrice) * Number(this.selectedList.discount / 100))) {
                this.totalDiscount = this.roundToTwoDecimalPlaces(Number(totalPrice) * Number(this.selectedList.discount / 100));
            }
        },
        async updatePricing() {
            this.$swal({
                    title: `Are you sure you want to update pricing for  ${this.isDiscountedCount} items?`,
                    text: `Click confirm below to update pricing for a total of $${this.formatNumberTwoDecimals(this.countTotal)} discount`,
                    icon: 'waring',
                    showDenyButton: true,
                    confirmButtonText: "Confirm",
                    denyButtonText: "Cancel"
            }).then(async result => {
                    if(result.isConfirmed){
                        
                        let itemList = [];
                        this.applying = true;

                        this.priceListItems.forEach( (item) => {
                            itemList.push(item.lineItemId);
                        });
                        
                        let response = await this.$store.dispatch('priceIQ/updatePriceList',{
                            portalId: this.hubSpotPortalId,
                            dealId: this.hubSpotDealId,
                            priceListId: this.selectedList.id,
                            userId: this.hubSpotUserId,
                            itemsList: itemList
                        });

                        if(response) {
                            this.$swal({
                                icon: 'success',
                                title: 'Prices Updated',
                                html: `Pricing has been updated for ${this.isDiscountedCount} items. <div class="mt-3"><i aria-hidden="true" class="v-icon notranslate mdi mdi-alert-octagon-outline theme--light primary--text" style="font-size: 60px;"></i></div> <b class="pt-2 d-block font-weight-bold">Please close this window and refresh the page.</b>`,
                            });
                            this.applying = false;
                            this.isDiscountedCount = 0;
                            this.discountapplied = true;
                        } else {
                            this.applying = false;
                            this.$swal({
                                icon: 'error',
                                title: 'Error Updating Prices',
                                text: 'There was an error updating prices. Please try again later.',
                            });
                        }
                    }
            });
        },
        async revertDiscount(){
            this.$swal({
                    title: `Are you sure you want to update pricing?`,
                    text: `Click confirm below to update pricing`,
                    icon: 'waring',
                    showDenyButton: true,
                    confirmButtonText: "Confirm",
                    denyButtonText: "Cancel"
            }).then(async result => {
                    if(result.isConfirmed){
                        
                        let itemList = [];
                        this.applying = true;

                        this.priceListItems.forEach( (item) => {
                            itemList.push(item.lineItemId);
                        });
                        
                        let response = await this.$store.dispatch('priceIQ/revertDiscount',{
                            portalId: this.hubSpotPortalId,
                            dealId: this.hubSpotDealId,
                            userId: this.hubSpotUserId
                        });

                        if(response) {
                            this.applying = false;
                            this.applyRevert = true;

                            this.$swal({
                                icon: 'success',
                                title: 'Prices Updated',
                                html: `Pricing has been updated. <div class="mt-3"><i aria-hidden="true" class="v-icon notranslate mdi mdi-alert-octagon-outline theme--light primary--text" style="font-size: 60px;"></i></div> <b class="pt-2 d-block font-weight-bold">Please close this window and refresh the page.</b>`,
                            });
                        } else {
                            this.applying = false;
                            this.$swal({
                                icon: 'error',
                                title: 'Error Updating Prices',
                                text: 'There was an error updating prices. Please try again later.',
                            });
                        }
                    }
            });
        },
        formatCurrency(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        productsHasDiscount(discountCount) {
            if(discountCount === 0) {
                this.$swal({
                    icon: 'error',
                    title: 'No eligible products selected',
                });
            }
        },
    }
}
</script>
<style lang="scss">
    .inline-autocomplete{
        .v-select__selections{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            .v-list-item{
                flex: 0 0 auto;
            }
            input{
                flex: 1 1 auto;
            }
        }
    }
    
    .nowrapNumber{
        white-space: nowrap;
    }
    .sticky-footer-row{
        position: sticky;
        bottom: 0px;
    }
    .compact-item-list{
        min-height: auto;
    }
    .compact-footer-data-table{
       .v-data-footer{
            .v-data-footer__select{
                .v-input{
                    margin-top: 0px;
                    margin-bottom: 0px;
                }
            }
       }
    }
</style>

