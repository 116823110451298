import store from '../store/index.js';

export const smarRampRegGuard = async (to, from, next) => {
    const user = await store.dispatch('getUserCompanyGuard');

    if (!user) {
        return next('/product/complete-registration/SmartRamp');
    }

    return next();
}