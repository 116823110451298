import Api from "@/services/api";
import Vue from "vue";
import router from "../../router";
export default {
    namespaced: true,
    state: {
        user: null,
        currentCompany: {},
        userRole: null,
        companyCloverActions: {
            contactCreate: null,
            contactDelete: null,
            productCreate: null,
            productDelete: null,
            transactionCreate: null,
            transactionDelete: null
        }
    },
    mutations: {
        SET_USER(state,user){
            state.user = user;
        },
        SET_USER_ROLE(state,userRole){
            state.userRole = userRole;
        },
        SET_CURRENT_COMPANY(state,currentCompany){
            state.currentCompany = currentCompany;
        },
        SET_COMPANY_CLOVER_ACTIONS(state,cloverActions){
            state.companyCloverActions = {
                contactCreate: cloverActions.contactCreateOrUpdate,
                contactDelete: cloverActions.contactCreateOrUpdate,
                productCreate: cloverActions.productCreateOrUpdate,
                productDelete: cloverActions.productDelete,
                transactionCreate: cloverActions.transactionCreateOrUpdate,
                transactionDelete: cloverActions.transactionDelete,
            };
        }
    },
    actions: {
        async getUserCompany({dispatch,commit},mid){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            var user = await Api().get(`/Web/User/GetUserAndCompany/Clover`,config).catch(async err => {
                if(err.response.status === 404){
                    var company = await Api().get(`/Web/Company/GetCompanyByMID/${mid}`,config).catch(err => {
                        if(err.response.status === 404){
                            router.push({path: '/clover/registration', query: { mid: mid}});
                        }
                    });
                    
                    let authUser = await Vue.prototype.$auth.user;

                    await Api().post(`/Web/User/CreateIntegrationUser`,{
                        firstName: authUser.given_name,
                        lastName: authUser.family_name,
                        email: authUser.email,
                        companyId: company.data.id,
                        accountId: mid
                    },config);
                    
                    var user = await Api().get(`/Web/User/GetUserAndCompany/Clover`,config).catch(err => {
                        console.error(err.message);
                    });
                    commit("SET_USER",user.data.user);
                    commit("SET_USER_ROLE",user.data.userRole);
                    commit("SET_CURRENT_COMPANY",user.data.company);
                    dispatch('clover/getCloverCompanyActions');
                    return user.data;
                }
            });
            commit("SET_USER",user.data.user);
            commit("SET_USER_ROLE",user.data.userRole);
            commit("SET_CURRENT_COMPANY",user.data.company);
            return user.data;
        },
        async registerUser({},{mid: mid, companyName: companyName, domain: domain}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let authUser = await Vue.prototype.$auth.user;
            await Api().post(`/Web/User/CreateIntegrationUser`,{
                firstName: authUser.given_name,
                lastName: authUser.family_name,
                email: authUser.email,
                accountId: mid,
                companyName: companyName,
                domainName: domain
            },config).catch(err => {
                console.error(err.message);
                return false;
            });
            return true;
        },
        async setCompanyIdByMerchantId({state},mid){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            await Api().put(`/Web/Company/SetCompanyIdByMerchantId/${state.currentCompany.id}/${mid}`,{},config).catch(err => {
                console.error(err.message);
                return false;
            });
            return true;
        },
        async getCloverCompanyActions({commit}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let actions = await Api().get(`/Web/Company/GetCloverActions`,config).catch(err => {
                console.error(err.message);
            });
            if(actions.status === 200){
                commit('SET_COMPANY_CLOVER_ACTIONS',actions.data);
            }
        },
        async saveCloverActions({state}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            let data = {
                companyId: state.currentCompany.id,
                contactCreateOrUpdate: state.companyCloverActions.contactCreate,
                contactDelete: state.companyCloverActions.contactDelete,
                productCreateOrUpdate: state.companyCloverActions.productCreate,
                productDelete: state.companyCloverActions.productDelete,
                transactionCreateOrUpdate: state.companyCloverActions.transactionCreate,
                transactionDelete: state.companyCloverActions.transactionDelete
            };
            await Api().post(`/Web/Company/SaveCloverActions`,data,config).catch(err => {
                return false;
            });
            return true;
        }
    },
    getters: {
        getCurrentCompany: state => { return state.currentCompany },
        getCloverCompanyActions: state => { return state.companyCloverActions }
    }
};