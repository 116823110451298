<template>
    <v-container fluid>
        
        <v-row class="d-flex align-center ">
            <v-col cols="12">
                <v-card flat class="my-10">
                    <v-card-text>
                        <v-row class="d-flex flex-row align-center">
                            <v-col cols="12" md="8" lg="9">

                                <h1 v-if=" currentPlan.price == 0" class="display-4 font-weight-bold iq_dark--text">Success! {{ formatIQName(productName) }} Free Plan was successfully added.</h1>
                                <h1 v-else class="display-4 font-weight-bold iq_dark--text">Success! {{ formatIQName(productName) }} purchase was Successful!</h1>

                                <p class="text-h6 my-5">You're set, continue to setup or learn more about {{ formatIQName(productName) }} in your tutorial video.</p>
                                <v-card-actions class="my-5">
                                    <v-btn large color="primary" @click="getStarted(productName)"> Launch App</v-btn>
                                    <v-btn large text color="secondary" @click="playTutorial"> <v-icon>mdi-play</v-icon> Play Tutorial Video</v-btn>
                                </v-card-actions>
                            </v-col>
                            <v-col cols="12" md="4" lg="3">
                                <v-img 
                                    contain
                                    transition="scale-transition"
                                    src="../../../assets/images/product/SmartRamp/clip-start-up.png"
                                    max-width="100%"
                                    class="ml-auto mr-auto"
                                >
                                </v-img>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-row v-if="showTutorial" class="d-flex align-center justify-center"  >
                    <v-overlay v-model="showTutorial" z-index="49"> </v-overlay>
                    <v-card width="100%" :max-width="this.$vuetify.breakpoint.mdAndDown ? '100%':'75%'" class="align-center d-flex flex-column" style="z-index: 50;" elevation="0" flat rounded>
                        <v-card-title style="width:100%;" class="d-flex flex-grow-1 flex-row justify-end">
                            <v-btn fab text @click="this.pauseAllPlayers"><v-icon>mdi-close</v-icon></v-btn>
                        </v-card-title>
                        <v-card-text >                        
                            <v-img
                                style="width: 100%; margin: auto; display: block;"
                                class="vidyard-player-embed"
                                :src="getUrlsAndIdsByAppName(productName)[0].appIntroVideoUuid"
                                :data-uuid="getUrlsAndIdsByAppName(productName)[0].appIntroVideoUuid"
                                data-v="4"
                                data-type="inline"
                                />
                        </v-card-text>
                    </v-card>
                </v-row>
              
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card flat>
                    <v-card-title>
                        <h1>You may also like:  </h1>
                    </v-card-title>
                </v-card>
                
            </v-col>
        </v-row>
        <v-row v-if="productName !== 'MessageIQ'">
            <v-col cols="12">
                <v-card flat>
                    <v-card-title>Smart Ramp</v-card-title>
                    <v-card-title class="display-1">Message IQ</v-card-title>
                    <v-card-subtitle>Send text messages from HubSpot just like email.</v-card-subtitle>
                    <v-card-text>
                        <v-row class="d-flex align-center">
                            <v-col cols="12" md="8">
                                <v-card class="mb-6" elevation="2">
                                    <v-card-title>Your Customers Prefer SMS </v-card-title>
                                    <v-card-text>SMS has a 98% open rate vs 22.5% for email. Message IQ offers the deepest integration with HubSpot available.</v-card-text>
                                </v-card>
                                <v-card class="mb-6" elevation="2">
                                    <v-card-title>Text messaging your way </v-card-title>
                                    <v-card-text>Automated, broadcast or 1:1 messages are a breeze with Message IQ.</v-card-text>
                                </v-card>
                                <v-card class="mb-6" elevation="2">
                                    <v-card-title>Full reporting </v-card-title>
                                    <v-card-text>Simple one-touch reports for messages sent and delivered, response rates, phone calls and opt-outs.</v-card-text>
                                </v-card>
                            </v-col>                            
                            <v-col cols="12" md="4">
                                <v-card flat>
                                    <v-img 
                                        contain
                                        transition="scale-transition"
                                        src="../../../assets/images/product/SmartRamp/messageiq-diamond.png"
                                        max-width="100%"
                                        class="ml-auto mr-auto"
                                    >
                                    </v-img>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="getStarted('MessageIQ')" color="primary"> {{ !companyApps.messageIq ? 'Purchase' : 'Launch'}} </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <!-- <v-col cols="4">
                <v-card rounded elevation="2"> 
                    <v-card-title>widget</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        widget content
                    </v-card-text>
                </v-card>
            </v-col> -->
        </v-row>
        <v-row class="row d-flex flex-row justify-space-around">
            <v-col cols="12" md="12" lg="6" v-if="productName !== 'StatusIQ'">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6">
                                <v-img 
                                        contain
                                        transition="scale-transition"
                                        src="../../../assets/images/product/SmartRamp/stocks-iq.png"
                                        max-width="100%"
                                        class="ml-auto mr-auto"
                                    >
                                </v-img>
                            </v-col>
                            <v-col cols="6">
                                <v-card-subtitle>Smart Ramp</v-card-subtitle>
                                <v-card-title class="display-1">Status IQ</v-card-title>
                                <v-card-subtitle>
                                    Enable customers to self-serve status updates based on Deal or Ticket pipeline stages.
                                </v-card-subtitle>
                                <v-card-text>
                                    <v-list dense class="feature-list">
                                          <v-list-item class="px-0" single-line>
                                            <v-list-item-content>
                                              <v-list-item-title>
                                                <v-icon>mdi-check</v-icon>
                                                Eliminate customer service calls
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                          <v-list-item class="px-0" single-line>
                                            <v-list-item-content>
                                              <v-list-item-title>
                                                <v-icon>mdi-check</v-icon>
                                                2-minute setup
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                          <v-list-item class="px-0" single-line>
                                            <v-list-item-content>
                                              <v-list-item-title>
                                                <v-icon>mdi-check</v-icon>
                                                Empower customer self-service
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                          <v-list-item class="px-0" single-line>
                                            <v-list-item-content>
                                              <v-list-item-title>
                                                <v-icon>mdi-check</v-icon>
                                                No technology knowledge needed
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                      </v-list>
                                </v-card-text>
                                <v-card-actions>
                                        <v-btn @click="getStarted('StatusIQ')" color="primary"> {{ !companyApps.statusIq ? 'Purchase' : 'Launch'}} </v-btn>
                                </v-card-actions>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12"  md="12" lg="6" v-if="productName !== 'StockIQ'">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6">
                                <v-img 
                                        contain
                                        transition="scale-transition"
                                        src="../../../assets/images/product/SmartRamp/stocks-iq.png"
                                        max-width="100%"
                                        class="ml-auto mr-auto"
                                    >
                                </v-img>
                            </v-col>
                            <v-col cols="6">
                                <v-card-subtitle>Smart Ramp</v-card-subtitle>
                                <v-card-title class="display-1">Stock IQ</v-card-title>
                                <v-card-text>
                                    <v-list dense  class="feature-list">
                                          <v-list-item class="px-0" single-line>
                                            <v-list-item-content>
                                              <v-list-item-title>
                                                <v-icon>mdi-check</v-icon>
                                                Check real-time product inventory on HubSpot Deal record
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                          <v-list-item class="px-0" single-line>
                                            <v-list-item-content>
                                              <v-list-item-title>
                                                <v-icon>mdi-check</v-icon>
                                                Remove out of stock items from Deal
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                          <v-list-item class="px-0" single-line>
                                            <v-list-item-content>
                                              <v-list-item-title>
                                                <v-icon>mdi-check</v-icon>
                                                Update HubSpot Deal record to max available
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                      </v-list>
                                </v-card-text>
                                <v-card-actions>
                                        <v-btn @click="getStarted('StockIQ')" color="primary"> {{ !companyApps.stockIq ? 'Purchase' : 'Launch'}} </v-btn>
                                </v-card-actions>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12"  md="12" lg="6" v-if="productName !== 'PriceIQ'">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6">
                                <v-img 
                                        contain
                                        transition="scale-transition"
                                        :class="this.$vuetify.breakpoint.smAndDown ? 'img-to-bg': ''"
                                        src="../../../assets/images/product/SmartRamp/priceiq.png"
                                        max-width="100%"
                                        class="ml-auto mr-auto"
                                    >
                                </v-img>
                            </v-col>
                            <v-col cols="6">
                                <v-card-subtitle>Smart Ramp</v-card-subtitle>
                                <v-card-title class="display-1">Price IQ</v-card-title>
                                <v-card-text>
                                    <v-list dense  class="feature-list">
                                          <v-list-item class="px-0" single-line>
                                            <v-list-item-content>
                                              <v-list-item-title>
                                                <v-icon>mdi-check</v-icon>
                                                Sync price lists from ERP to HubSpot
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                          <v-list-item class="px-0" single-line>
                                            <v-list-item-content>
                                              <v-list-item-title>
                                                <v-icon>mdi-check</v-icon>
                                                Select from multiple price lists and apply correct pricing
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                          <v-list-item class="px-0" single-line>
                                            <v-list-item-content>
                                              <v-list-item-title>
                                                <v-icon>mdi-check</v-icon>
                                                Apply or Avoid discounting to a single line item or all
                                              </v-list-item-title>
                                            </v-list-item-content>
                                          </v-list-item>
                                      </v-list>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn @click="getStarted('PriceIQ')" color="primary"> {{ !companyApps.priceIq ? 'Purchase' : 'Launch'}} </v-btn>
                                </v-card-actions>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
 
</template>
<script>
    import { mapGetters } from 'vuex';
    import VS2 from 'vue-script2'
    import VidyardEmbed from '@vidyard/embed-code';

    export default {
        name: "ProductSummary",
        computed:{
            ...mapGetters({
                productName: 'productRegistration/getProductName',
                currentPlan: 'productRegistration/getPlanId',
                companyApps: 'company/getCurrentCompanyApps'
            })
        },
        data () {
            return{
                showTutorial:false,
                loadingVideo: false,
                launcherItems: [
                    {
                        appName: 'INTEGRATE IQ', 
                        appIcon: '$integrateIQIcon',
                        appColor: 'primary',
                        appLink: '/cards',
                        appDescription: 'Connect any cloud software with HubSpot through our 30+ sync recipes and get launched in less than 30 days.',
                        appLearnMoreLink: null, 
                        appIntroVideoSrc: 'https://play.vidyard.com/p8gh63GnCBorGGbSuDMhrz.jpg',
                        appIntroVideoUuid: 'p8gh63GnCBorGGbSuDMhrz', 
                        appIntroTitle: 'Intro to IntegrateIQ',
                        appIntroText:'Take a look at the advantages and great technology behind our tool.',
                        hasInlineHelpList: true,
                        showInlineHelp: false,
                        isLive: true,
                        isDemo: false,
                        InlineHelpList: [],
                    },
                    {
                        appName: 'MessageIQ', 
                        appIcon: '$messageIQIcon',
                        appColor: 'primary',
                        appLink: '/conversations',
                        purchaseLink: '/setup',
                        appDescription: 'Send 1:1, broadcast and automated SMS messages from inside HubSpot.',
                        appLearnMoreLink: null, 
                        appIntroVideoSrc: 'https://play.vidyard.com/wmNeRogV5s6e5MCeAnP9i9.jpg',
                        appIntroVideoUuid: 'wmNeRogV5s6e5MCeAnP9i9', 
                        appIntroTitle: ' Intro to Message IQ',
                        appIntroText:'Take a look at the advantages and great technology behind our tool.',
                        hasInlineHelpList: true,
                        showInlineHelp: false,
                        isLive: false,
                        isDemo: false,
                        InlineHelpList: [
                            { name:'QUICK START',        icon:'mdi-flash',  description:'Follow our quick start guide and immediately impact your business.', linkText: '', link: '', duration:'2 minutes', src:'https://play.vidyard.com/EuqNX6zbNWcrPPMANQKztU.jpg', uuid:'EuqNX6zbNWcrPPMANQKztU'},
                            { name:'TAKE IF FOR A SPIN', icon:'mdi-steering',  description:'Orient yourself with this quick tour of the place.', linkText: '', link: '', duration:'2 minutes', src:'https://play.vidyard.com/XXesvzwZ8BQnEqCwfQPjTY.jpg', uuid:'XXesvzwZ8BQnEqCwfQPjTY'},
                            { name:'DIVE DEEP INTO THE HOW TO\’S', icon:'mdi-steering',  description:'Learn how to use the full power of the 4 C’s with this video playlist.', linkText: '', link: '', duration:'2 minutes', src:'https://play.vidyard.com/MDNZe2vNmeYHPRkYTbYBr7.jpg', uuid:'MDNZe2vNmeYHPRkYTbYBr7'},
                            { name:'LEARN FROM THE IQ PLAYBOOK', icon:'mdi-steering',  description:'Elevate your IQ interactions with live video training of best practices.', linkText: '', link: '', duration:'2 minutes', src:'https://play.vidyard.com/m3Et1vguKMPXD2E7nAYbKZ.jpg', uuid:'m3Et1vguKMPXD2E7nAYbKZ'},
                            { name:'SCHEDULE YOUR 1:1 STRATEGY SESSION', icon:'mdi-calendar-cursor',  description:'Book time now to get your personalized IQ plan.', linkText: 'Schedule', link: 'https://meetings.hubspot.com/tim1303/miq-strategy-session?embed=true', duration:'2 minutes', src:'', uuid:''}
                        ],
                    },
                    {
                        appName: 'StatusIQ',     
                        appIcon: '$statusIQIcon',
                        appColor: 'iq_slategray',
                        appLink: '/statusiq',
                        purchaseLink: '/product/plan-selection/StatusIQ',
                        appDescription: 'Empower your customers to access their current status of anything found in a HubSpot dropdown property or pipeline.',
                        appLearnMoreLink: null, 
                        appIntroVideoSrc: 'https://play.vidyard.com/YxJqZ14M5cuP5imJCEhjHw.jpg',
                        appIntroVideoUuid: 'YxJqZ14M5cuP5imJCEhjHw', 
                        appIntroTitle: 'Intro to MIQ',
                        appIntroText:'Take a look at the advantages and great technology behind our tool.',
                        hasInlineHelpList: true,
                        showInlineHelp: false,
                        isLive: false,
                        isDemo: false,
                        InlineHelpList: [],
                    },
                    {
                        appName: 'PriceIQ', 
                        appIcon: '$priceIQIcon',
                        appColor: 'iq_slategray',
                        appLink: '/priceiq',
                        purchaseLink: '/product/plan-selection/PriceIQ',
                        appDescription: 'Automatically update line item pricing based on company price lists, lookup and manage price lists, all from inside HubSpot.',
                        appLearnMoreLink: null, 
                        appIntroVideoSrc: 'https://play.vidyard.com/jrU5jwMU91SDpDKhb4E8z7.jpg',
                        appIntroVideoUuid: 'jrU5jwMU91SDpDKhb4E8z7', 
                        appIntroTitle: 'Intro to MIQ',
                        appIntroText:'Take a look at the advantages and great technology behind our tool.',
                        hasInlineHelpList: true,
                        showInlineHelp: false,
                        isLive: false,
                        isDemo: false,
                        InlineHelpList: [],
                    },
                    {
                        appName: 'StockIQ', 
                        appIcon: '$stockIQicon',
                        appColor: 'iq_slategray',
                        appLink: '/stockiq',
                        purchaseLink: '/product/plan-selection/StockIQ',
                        appDescription: 'Search inventory levels, verify line items are in stock before you tell the customer... all from inside HubSpot.',
                        appLearnMoreLink: null, 
                        appIntroVideoSrc: 'https://play.vidyard.com/gvZypYLZ3W38bT3w6GTPbL.jpg',
                        appIntroVideoUuid: 'gvZypYLZ3W38bT3w6GTPbL', 
                        appIntroTitle: 'Intro to MIQ',
                        appIntroText:'Take a look at the advantages and great technology behind our tool.',
                        hasInlineHelpList: true,
                        showInlineHelp: false,
                        isLive: false,
                        isDemo: false,
                        InlineHelpList: [],
                    }
                ],
                appIntroVideoSrc:null,
                appIntroVideoUuid:null

            }
        },
        created() {
            this.$store.commit('productRegistration/SET_LOADING', false);
            
          
        },
        methods:{
            getStarted(appName){
                this.$router.push(`../plan-selection/${appName}`);
            },
            playTutorial(){
                this.showTutorial = true;      
                VS2.load('https://play.vidyard.com/embed/v4.js').then(function () {
                        VidyardEmbed.api.renderDOMPlayers();
                        for (const playerId in VidyardEmbed.players) {
                        if (VidyardEmbed.players.hasOwnProperty(playerId)) {
                        VidyardEmbed.players[playerId].play();
                        }
                    }
                }) 
            },
            hideTutorial(){
                pauseAllPlayers();
            },
            getUrlsAndIdsByAppName(appName) {
                const matchingApps = this.launcherItems.filter(app => app.appName === appName);
                return matchingApps;
            },
            pauseAllPlayers() {
                // Loop through all players and pause them
                for (const playerId in VidyardEmbed.players) {
                    if (VidyardEmbed.players.hasOwnProperty(playerId)) {
                    VidyardEmbed.players[playerId].pause();
                    }
                }
                this.showTutorial = false;
            }
        }


    };
</script>
<style lang="scss" scoped>
    .plans-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    }
    .feature-list{
        .v-list-item__title{
            white-space: wrap;
        }
    }
</style>