<template>
    <div>
      <v-img
        :src="thumbnailSrc"
        @click="openViewer"
        class="clickable-image"
        height="100%"
        contain
      ></v-img>
  
      <v-dialog v-model="viewerOpen" width="auto" max-width="80%">
        <v-card>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeViewer">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
          <v-card-text class="d-flex justify-center align-center">
            <v-img class="clickable-image"  contain max-height="100%" min-width="200" :src="fullSizeSrc" ></v-img>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      thumbnailSrc: {
        type: String,
        required: true
      },
      fullSizeSrc: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        viewerOpen: false
      };
    },
    methods: {
      openViewer() {
        this.viewerOpen = true;
      },
      closeViewer() {
        this.viewerOpen = false;
      }
    }
  };
  </script>
  
  <style lang="scss">
  .clickable-image {
    cursor: pointer;
    max-width: fit-content;
  }
  
  </style>
  