import Api from "@/services/api";
import Vue from "vue";
export default {
    namespaced: true,
    state: {
        inventoryDetails: [],
        products: [],
    },
    mutations: {
        SET_INVENTORY_DETAILS(state, inventoryDetails){
            state.inventoryDetails = inventoryDetails;
        },
        SET_PRODUCTS(state, products){
            state.products = products;
        },
    },
    actions: {
        async getInventoryDetails({commit},{portalId: portalId, dealId: dealId, userId: userId}){
            let response = await Api().get(`/Web/SmartRamp/StockIQ/${portalId}/GetInventory/${dealId}/${userId}`)
            .catch(err => {
                console.error(err.message);
                return null;
            });
            commit('SET_INVENTORY_DETAILS',response.data);
            return response.data;
        },
        async removeOutOfStock({commit},{portalId: portalId, dealId: dealId, userId: userId}){
            
            let response = await Api().put(`/Web/SmartRamp/StockIQ/${portalId}/UpdateOutOfStock/${dealId}/${userId}`)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            return true;
        },
        async updateMaxInventory({commit},{portalId: portalId, dealId: dealId, userId: userId}){
            let response = await Api().put(`/Web/SmartRamp/StockIQ/${portalId}/UpdateMaxInventory/${dealId}/${userId}`)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            return true;
        },
        async saveCredentials({state,commit}, netsuite){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().post(`/Web/SmartRamp/StockIQ/SaveCredentials`, netsuite, config)
            .catch(err => {
                console.error(err.message);
                return(err.response);
            });

            return response;
        },
        async getCompanyNetSuitePortals({}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().get(`/Web/SmartRamp/StockIQ/GetCompanyNetSuitePortals`, config)
            .catch(err => {
                console.error(err.message);
                return(err.response);
            });

            return response;
        },
        async getInventoryLocations({}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().get(`/Web/SmartRamp/StockIQ/GetInventoryLocations`, config)
            .catch(err => {
                console.error(err.message);
                return(err.response);
            });

            return response.data;
        },
        async getCurrentInventoryLocation({}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().get(`/Web/SmartRamp/StockIQ/GetInventoryLocation`, config)
            .catch(err => {
                console.error(err.message);
                return(err.response);
            });

            return response.data;
        },
        async saveCompanyLocation({state,commit}, locationId){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().post(`/Web/SmartRamp/StockIQ/SaveLocation`, locationId, config)
            .catch(err => {
                console.error(err.message);
                return(err.response);
            });

            return response;
        },
        async getProductsList({commit}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            let response = await Api().get(`/Web/SmartRamp/StockIQ/GetProducts`,config)
            .catch(err => {
                console.error(err.message);
                return false;
            });

            commit('SET_PRODUCTS', response.data);
            return response.data;
        },
        async getProduct({commit}, {id: id, portalId: portalId}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            let response = await Api().get(`/Web/SmartRamp/StockIQ/GetProduct/${id}/${portalId}`,config)
            .catch(err => {
                console.error(err.message);
                return false;
            }); 

            return response.data;
        },
        async createProduct({commit}, {product: product}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { 
                    "Authorization": `Bearer ${token}`, 
                    "Content-Type": "application/json"
                }
            };

            let response = await Api().post(`/Web/SmartRamp/StockIQ/CreateProduct`, {
                Id: product.id,
                PortalId: product.portalId,
                Name: product.name,
                Description: product.description,
                Sku: product.sku,
                SystemId: product.systemId,
                HubSpotProductId: product.hubSpotProductId,
            }, config)
            .catch(err => {
                console.error(err.message);
                return false;
            }); 

            return response.data;
        },
        async updateProduct({commit}, {product: product}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { 
                    "Authorization": `Bearer ${token}`, 
                    "Content-Type": "application/json"
                }
            };

            let response = await Api().put(`/Web/SmartRamp/StockIQ/UpdateProduct`, {
                Id: product.id,
                PortalId: product.portalId,
                Name: product.name,
                Description: product.description,
                Sku: product.sku,
                SystemId: product.systemId,
                HubSpotProductId: product.hubSpotProductId,
            }, config)
            .catch(err => {
                console.error(err.message);
                return false;
            }); 

            return response.data;
        },
        async deleteProduct({commit}, {id: id, portalId: portalId}){
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            let response = await Api().delete(`/Web/SmartRamp/StockIQ/DeleteProduct/${id}/${portalId}`,config)
            .catch(err => {
                console.error(err.message);
                return false;
            }); 

            return response;
        },
    },
    getters: {
        getInventoryDetails: state => { return state.inventoryDetails },
        getProducts: state => { return state.products },
    }
};