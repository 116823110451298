<template>
<div>
    <v-toolbar
        dark
        flat
        color="iq_darkerblue" 
        id="RecipeHead"
    >
        <v-toolbar-title class="display-3">
            Find your integration recipe
        </v-toolbar-title>
    </v-toolbar>
  <v-container fluid>
    <v-row>
      <v-col cols="auto">
        <v-navigation-drawer 
        permanent
        v-model="filterNavDrawer"
        class="d-none d-sm-none d-md-flex"
        >

            <v-expansion-panels popout flat v-model="filterPanel">
              <v-expansion-panel @click="clearAdvFilters">
                <v-expansion-panel-header class="pa-0"> 
                  <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-h6">
                          Recipe Categories
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Select to filter
                        </v-list-item-subtitle>
                      </v-list-item-content>
                  </v-list-item>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    

                    <v-divider></v-divider>

                    <v-list
                      nav
                      rounded
                    >
                      <v-list-item-group
                          color="primary"
                          mandatory
                          @change="menuToSelected"
                          v-model="menuSelectedIndex"
                      >
                      <template v-for="(category, i) in categories">
                        <v-list-item
                            :key="i"
                          >
                            <v-list-item-icon>
                              <v-icon 
                                :color="getColorByCategory(category.label)"
                              >
                                {{ category.icon }}
                              </v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title>{{ category.label }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                      </template>
                      
                      </v-list-item-group>
                    </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel  @click="clearSimpleFilters">
              
                <v-expansion-panel-header class="pa-0">
                  <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-h6">
                            Advanced Filters
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Source and Destination 
                          </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <p class="body-2">
                      Get an accurate filter for recipes considering your source and destination systems.
                    </p>
                     <v-select 
                      outlined
                      label="Source Category"
                      hint="Type of System Source"
                      persistent-hint
                      item-text="label"
                      item-value="value"
                      prepend-inner-icon="mdi-filter"
                      :items="categories"
                      v-model="advFilterSourceCategory"
                      v-on:change="filterListBySourceSelection"
                    ></v-select>
                     <v-select 
                      outlined
                      label="Destination Category" 
                      hint="Type of System Destination"
                      persistent-hint
                      item-text="label"
                      item-value="value"
                      prepend-inner-icon="mdi-filter"
                      :items="categories"
                      v-model="advFilterDestinationCategory"
                      v-on:change="filterListByDestinationSelection"
                    ></v-select>
                    <v-btn bloc outlined @click="clearAdvFilters">Clear Advanced Filters</v-btn>
                    
                </v-expansion-panel-content>
              </v-expansion-panel>

            </v-expansion-panels>

           
        </v-navigation-drawer>
      </v-col>
      <v-col>
        <v-toolbar
                    class="flex-grow-0"
                    flat
                  >
                    <v-text-field
                      v-model="search"
                      clearable
                      flat
                      solo
                      outlined
                      hide-details
                      prepend-inner-icon="mdi-magnify"
                      label="Search"
                      class="flex-grow-0 mr-1"

                    ></v-text-field>

                    <!-- <v-select 
                      flat
                      solo
                      outlined
                      hide-details
                      label="by Type" 
                      item-text="label"
                      item-value="value"
                      prepend-inner-icon="mdi-filter"
                      :items="categories"
                      v-model="filterByType"
                      v-on:change="selectedToMenu"
                      class="flex-grow-0 mr-1"
                    ></v-select> -->
                          
                    <template v-if="$vuetify.breakpoint.mdAndUp">
                      <v-spacer></v-spacer>
                      <v-select
                        v-model="sortBy"
                        flat
                        solo
                        outlined
                        hide-details
                        :items="keys"
                        item-text="label"
                        item-value="value"
                        prepend-inner-icon="mdi-magnify"
                        label="Sort by"
                      class="flex-grow-0 mr-1"
                      ></v-select>
                      <v-spacer></v-spacer>
                      <v-btn-toggle
                        v-model="sortDesc"
                        mandatory
                      >
                        <v-btn
                          large
                          depressed
                          :value="false"
                        >
                          <v-icon>mdi-arrow-up</v-icon>
                        </v-btn>
                        <v-btn
                          large
                          depressed
                          :value="true"
                        >
                          <v-icon>mdi-arrow-down</v-icon>
                        </v-btn>
                      </v-btn-toggle>
                    </template>
                </v-toolbar>
                
        <v-data-iterator
          :items="filteredItems"
          :page.sync="page"
          :search="search"
          :sort-by="sortBy.toLowerCase()"
          :sort-desc="sortDesc"
          :items-per-page="-1"
          :footer-props="{
            itemsPerPageOptions: [8,16,22,25,50,100, -1]
          }"
        >
         <!-- <template v-slot:header>
            <v-toolbar
              dark
              color="blue darken-3"
              class="mb-1"
            >
              <v-text-field
                v-model="search"
                clearable
                flat
                solo-inverted
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
              ></v-text-field>

              <v-select 
                flat
                solo-inverted
                hide-details
                label="by Type" 
                item-text="label"
                item-value="value"
                prepend-inner-icon="mdi-filter"
                :items="categories"
                v-model="filterByType"
                v-on:change="selectedToMenu"
              ></v-select>
                    
              <template v-if="$vuetify.breakpoint.mdAndUp">
                <v-spacer></v-spacer>
                <v-select
                  v-model="sortBy"
                  flat
                  solo-inverted
                  hide-details
                  :items="keys"
                  prepend-inner-icon="mdi-magnify"
                  label="Sort by"
                ></v-select>
                <v-spacer></v-spacer>
                <v-btn-toggle
                  v-model="sortDesc"
                  mandatory
                >
                  <v-btn
                    large
                    depressed
                    color="blue"
                    :value="false"
                  >
                    <v-icon>mdi-arrow-up</v-icon>
                  </v-btn>
                  <v-btn
                    large
                    depressed
                    color="blue"
                    :value="true"
                  >
                    <v-icon>mdi-arrow-down</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </template>
            </v-toolbar>
          </template> -->

          <template v-slot:default="props">
            <v-row class="mt-4">
              <v-col
                v-for="item in props.items"
                :key="item.name"
                cols="12"
                sm="6"
                md="4"
                lg="3"
                xl="2"
              >
                <v-card height="250" class="card-recipe mt-2 mx-auto d-flex flex-column" elevation="1" rounded outlined >
                  <v-sheet
                      dark
                      rounded
                      elevation="2"
                      outlined
                      class="card-title v-sheet--offset mx-auto pa-3 d-flex flex-column justify-start lighten-2"
                      color="primary"
                      width="calc(100% - 15px)"
                      min-height="70"
                      >                      
                      <h3 class="font-weight-medium textline-1-2 flex-grow-1 iq_light--text" :class="item.type !== 'Live' ? 'text--darken-1':''" >
                      {{ item.name }}                                    
                      </h3>
                      
                      <div class="d-flex justify-center align-center">
                        <v-chip class="my-2" x-small v-if="item.type !== 'Live'" >  {{item.type}} </v-chip>
                        <!-- <v-btn fab text x-small color="pa-0" @click="item.infoOverlay = true"> 
                          <v-icon small>mdi-information-outline</v-icon>
                        </v-btn> -->
                      </div>
                  </v-sheet>
                  <!-- <v-fade-transition>
                    <v-overlay
                        absolute
                        opacity="0.8"
                        :value="item.infoOverlay"
                        class="overlay-fill-width pt-16 overflow-x-hidden"
                    >
                      <v-container fluid class="d-flex flex-start flex-column flex-grow-1">
                        <v-row>
                          <v-col cols="12" class="d-flex flex-row py-1">
                            <v-list-item two-line class="pl-0">
                              <v-list-item-avatar :color="getColorByCategory(item.category)">
                                {{ splitName(item.author[0])[0][0]}}{{ splitName(item.author[0])[1][0]}}
                              </v-list-item-avatar>
                              <v-list-item-content>
                                {{item.author[0]}}
                                <small>{{item.author[1]}}</small>
                              </v-list-item-content>
                            </v-list-item>
                            <v-btn
                              fab x-small
                              @click="item.infoOverlay = false"
                              >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col cols="12" class="py-1 d-flex flex-column">
                                <span class="d-flex flex-row align-center caption">
                                  Rating: 
                                  <v-rating
                                    color="warning"
                                    length="5"
                                    readonly
                                    half-increments
                                    size="16"
                                    :value="item.rate[0]"
                                  ></v-rating>
                                  <span>{{item.rate[0]}}</span>
                                  <span>({{ formatNumber(item.rate[1])}})</span>
                                </span>
                                <span class="caption">Created: <v-chip x-small>{{formatDate(item.created)}}</v-chip> </span>
                                <span class="caption">LastUse: <v-chip  x-small>{{formatDate(item.lastUse)}}</v-chip></span>
                                <span class="caption">Used: <v-chip x-small> {{ item.uses  }}</v-chip> times.</span>
                          </v-col>
                          <v-col  cols="12" class="my-1 py-0">
                            <v-chip  :color="getColorByCategory(item.category)" class="mr-1 mb-1" x-small v-for="(tag, tagIndex) in item.tags" :key="tagIndex" >
                              {{tag}}
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-overlay>
                  </v-fade-transition> -->
                
                  <v-card-text class="card-contents flex-grow-1 overflow-y-auto v-sheet--offset body-1 font-weight-medium pa-2 d-flex flex-column">
                    <h4 class="d-block dynamic-chips font-weight-light">
                          <v-chip 
                          :class="[cat.catName === filterByType ? 'filteredByMe' : '', 
                          cat.catID === advFilterSourceCategory ? 'filterSourceByMe':'', 
                          cat.catID === advFilterDestinationCategory ? 'filterDestinationByMe':'',
                          cat.catType
                          ]" 
                          class="mb-1 mr-1" small v-for="(cat,catIndex) in item.data" :key="catIndex" 
                          :color="getColorByCategory(cat.catName)"
                          > 
                            <v-icon class="iq_light--text mr-1"  small v-if="cat.catType === 'source'">mdi-tray-arrow-up</v-icon>
                            <v-icon class="iq_light--text mr-1"  small v-else>mdi-tray-arrow-down</v-icon>
                            <span class="iq_light--text ml-1"> {{cat.catName}} </span>

                          </v-chip>
                    </h4>
                    <div class="flex-grow-1 d-flex align-end">
                    {{item.description}}
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions class="">
                    <v-btn class="font-weight-bold" color="accent" :to="item.toAddress" text> Learn More</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>

          <!-- <template v-slot:footer>
            <v-row
              class="mt-2"
              align="center"
              justify="center"
            >
              <span class="grey--text">Items per page</span>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    text
                    color="primary"
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ itemsPerPage }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(number, index) in itemsPerPageArray"
                    :key="index"
                    @click="updateItemsPerPage(number)"
                  >
                    <v-list-item-title>{{ number }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-spacer></v-spacer>

              <span
                class="mr-4
                grey--text"
              >
                Page {{ page }} of {{ numberOfPages }}
              </span>
              <v-btn
                fab
                dark
                color="blue darken-3"
                class="mr-1"
                @click="formerPage"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                fab
                dark
                color="blue darken-3"
                class="ml-1"
                @click="nextPage"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-row>
          </template> -->
        </v-data-iterator>      
      
      </v-col>
    
    </v-row>
  </v-container>
</div>    
</template>
<script>
import moment from "moment";

  export default {
    data () {
      return {
        filterNavDrawer: true,
        filterPanel: 0,
        advFilterSourceCategory: null,
        advFilterDestinationCategory: null,
        itemsPerPageArray: [4, 8, 12],
        search: '',
        filter: {},
        sortDesc: false,
        page: 1,
        itemsPerPage: 8,
        sortBy: 'name',
        keys: [
          {value:'name', label:'Order by name'},
          // {value:'type', label:'Order by type'},
          // {value:'created', label:'Order by created'},
          // {value:'lastUse', label:'Order by lastUse'},
          // {value:'uses', label:'Order by uses'},
          // {value:'rate', label:'Order by rate'}
        ],
        categories: [

        ],
        filterByType: null,
        menuSelectedIndex: 0,
        cardData: [
            // {
            //     name: 'Sales per Product', 
            //     category: 'Sales',
            //     type: 'Live',
            //     description: 'Kaka Ad aute labore ex ea reprehenderit. Velit nisi non consequat id esse Lorem exercitation tempor ex ex non in eiusmod anim. Proident anim ullamco nulla mollit ipsum commodo id pariatur deserunt fugiat consectetur veniam nisi. Anim ea do quis ad dolor ex commodo sunt eu nulla quis labore adipisicing esse.Excepteur commodo tempor incididunt fugiat eiusmod. Id fugiat cillum officia adipisicing eu voluptate in fugiat sint aute minim dolore. Voluptate ipsum pariatur consequat magna qui dolor. Esse laborum dolor ad sint dolore. Enim consequat pariatur excepteur occaecat enim eiusmod elit ea magna minim. ',
            //     tags: ['hubspot', 'development', 'sales'],
            //     rate: ['4.5','2100'],
            //     videoUrl: "url",
            //     uses: 3313,
            //     created: '01 Jan 2020 00:30:00 GMT',
            //     lastUse: '01 Jan 2022 00:30:00 GMT',
            //     author: ['Kate Davidson', 'Founder of Integrate IQ'],
            //     infoOverlay: false
            // },
        ],
        rawRecipeData: [],
        parsedRecipeList: []
      }
    },
    computed: {
        numberOfPages () {
            return Math.ceil(this.items.length / this.itemsPerPage)
        },
        filteredKeys () {
            return this.keys.filter(key => key !== 'Name')
        },
        filteredItems() {
            return this.cardData.filter((i) => {
                return !this.filterByType || (i.category.includes(this.filterByType));
            })
        },
        updateSelected(){
            this.filterByType = this.categories[selected];
        }
    },
    async created(){
      let categories = await this.$store.dispatch('recipe/getRecipeCategories');
      this.parseCategories(categories);
      let recipes = await this.$store.dispatch('recipe/getRecipes');
      this.rawRecipeData = recipes;
      this.parseRecipes(recipes);
    },
    methods: {
        parseCategories(categories){
          let categoriesList = [];
          let all = { 
              value: null, 
              label: "All", 
              icon:'mdi-text-box-multiple-outline'
          };
          categoriesList.push(all);
          categories.forEach(cat => {
            let catToAdd = { 
              value: cat.id, 
              label: cat.name, 
              icon:'mdi-text-box-multiple-outline'
            };
            categoriesList.push(catToAdd);
          });
          this.categories = categoriesList;
        },
        clearAdvFilters(){
          this.advFilterSourceCategory = null;
          this.advFilterDestinationCategory =  null;
          this.cardData = this.parsedRecipeList;
        },
        clearSimpleFilters(){
          this.filterByType = null;
        },
        parseRecipes(recipes){
          let recipeList = [];
          recipes.forEach(recipe => {
            let recipeCategories = [];
            let sourceCat = [];
            let destCat = [];
            let dataCat = [];
            recipe.categories.forEach(cat => {
              let pushableObj = {catName: cat.name, catType: cat.isSource ? 'source':'destination', catID: cat.id };
              dataCat.push(pushableObj);

              recipeCategories.push(cat.name);
              if(cat.isSource){
                sourceCat.push(cat.id);
              }
              if(cat.isDestination){
                destCat.push(cat.id);
              }
            });
            let recipeToAdd = {
              id: recipe.id,
              name: recipe.title, 
              category: recipeCategories,
              type: 'Live',
              description: recipe.shortDescription,
              sources: sourceCat,
              destinations: destCat,
              toAddress: `/recipe/${recipe.id}`,
              data: dataCat
            };
            recipeList.push(recipeToAdd);
          });
          this.cardData = recipeList;
          this.parsedRecipeList = recipeList;
        },
        splitName( fullName) {
          return fullName.split(' ');
        },
        formatDate( date ) {
          return moment(date).format('MM/DD/YYYY')
        },
        formatNumber ( number ) {
          if (typeof number === "string") {
                    number = parseInt(number);
          }
          return number.toLocaleString();
        },
        nextPage () {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage () {
            if (this.page - 1 >= 1) this.page -= 1
        },
        updateItemsPerPage (number) {
            this.itemsPerPage = number
        },
        SetFilterByIndex(index){
          this.filterByType = this.categories[index].value;
        },
        selectedToMenu() {
          var index = this.categories.findIndex(x => x.value === this.filterByType);
          this.menuSelectedIndex =  index;
        },
        menuToSelected() {
            if(this.menuSelectedIndex === 0){
              this.filterByType = null;
            }else{
              var sectionValue = this.categories[ this.menuSelectedIndex].label;
              this.filterByType = sectionValue;
            }
        },
        getColorByCategory(cat){
            switch (cat){
                case 'Sales': 
                    return 'iq_lightslategray';
                break;
                case 'Marketing':
                    return 'primary';
                break;
                case 'Support': 
                    return 'accent';
                break;
                case 'Billing':
                    return 'success'
                break;
                case 'Operations':
                    return 'success'
                break;
                default:
                    return 'iq_dark'
            }
        },
        filterListBySourceSelection(){
          if(this.advFilterDestinationCategory !== null){
            if(this.advFilterSourceCategory === null){
              let recipes = this.parsedRecipeList.filter(recipe => recipe.destinations.includes(this.advFilterDestinationCategory));
              this.cardData = recipes;
            }else{
              let recipes = this.parsedRecipeList.filter(recipe => recipe.sources.includes(this.advFilterSourceCategory));
              recipes = recipes.filter(recipe => recipe.destinations.includes(this.advFilterDestinationCategory));
              this.cardData = recipes;
            }
          }else{
            if(this.advFilterSourceCategory === null){
              this.clearAdvFilters();
            }else{
              let recipes = this.parsedRecipeList.filter(recipe => recipe.sources.includes(this.advFilterSourceCategory));
              this.cardData = recipes;
            }
          }
        },
        filterListByDestinationSelection(){
          if(this.advFilterSourceCategory !== null){
            if(this.advFilterDestinationCategory === null){
              let recipes = this.parsedRecipeList.filter(recipe => recipe.sources.includes(this.advFilterSourceCategory));
              this.cardData = recipes;
            }else{
              let recipes = this.parsedRecipeList.filter(recipe => recipe.sources.includes(this.advFilterSourceCategory));
              recipes = recipes.filter(recipe => recipe.destinations.includes(this.advFilterDestinationCategory));
              this.cardData = recipes;
            }
          }else{
            if(this.advFilterDestinationCategory === null){
              this.clearAdvFilters();
            }else{
              let recipes = this.parsedRecipeList.filter(recipe => recipe.destinations.includes(this.advFilterDestinationCategory));
              this.cardData = recipes;
            }
          }
        },
    },
  }
</script>
<style lang="scss" scoped>
.v-overlay { 
  &.overlay-fill-width{
    .v-overlay__content{
      width: 100%;
    }
  }
}
.dynamic-chips{
  display: inline-flex !important;
  .v-chip{
    &.destination{
      order: 1;
    }
    &.source{
      order: 0;
    }
    &.filteredByMe  {
      order: -1 !important;
      .v-chip__content span{
        font-weight: 700;
        text-decoration: underline;
      }
    }
    &.filterSourceByMe{
      order: -1 !important;
      .v-chip__content span{
        font-weight: 700;
        text-decoration: underline;
        &.v-icon{
          display: block;
        }
      }
    }
    &.filterDestinationByMe{
      order: 2 !important;
      .v-chip__content span{
        font-weight: 700;
        text-decoration: underline;
        &.v-icon{
          display: block;
        }
      }
    }
  }
}
.card-recipe {
  .card-title{
    z-index: 6;
    &.v-sheet--offset {
      top: -15px;
      position: relative;
    }
  }
  .card-contents{
      position: relative;
      top: -10px;
  }
}
.textline-1-2{
  line-height: 1.2em;
}

</style>