
<template>
    <v-container >
              <v-row>
                  <v-col>
                        <h1>Integrations</h1>
                        <v-btn v-if="currentCompany.isHubSpotIntegrated" disabled>HubSpot Connected</v-btn>
                        <v-btn v-else v-on:click="goToHubSpotConnect()">Connect to HubSpot</v-btn>
                  </v-col>
              </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: "Integrations",
    computed: {
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany'
        })
    },
    created(){
        this.$auth.auth0ClientPromise.then(async () => {
            if(Object.keys(this.currentCompany).length === 0){
                await this.$store.dispatch('getUserCompany');                
            }
            if(this.$route.query.result === "success"){
                this.$swal({
                    icon: 'success',
                    title: 'HubSpot Connected',
                    text: 'You have successfully connected to HubSpot!',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });
            }else if(this.$route.query.result === "fail"){
                this.$swal({
                    icon: 'error',
                    title: 'Error connecting to HubSpot',
                    text: 'There was an error connecting to HubSpot. Please try again later.',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });
            }
        });
    },
    methods: {
        goToHubSpotConnect(){
            window.location.href = process.env.VUE_APP_API_URL + `/api/HubSpotConnect?companyId=${this.currentCompany.id}`;
        }
    }
}
</script>