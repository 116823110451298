<template>
    <div>
        <v-toolbar
        dark
        extension-height="68"
        flat
        color="iq_darkerblue" 
        >
            <v-toolbar-title class="d-flex justify-space-between flex-row flex-wrap" style="width: 100%">
                <span class="d-flex align-center">
                    <span  class="display-3 mr-4">{{pageData.title}}</span>
                </span>
            </v-toolbar-title>
        </v-toolbar>
        <v-container class="pa-0" fluid >
            <v-card dark class="pa-4 darken-4" flat color="iq_desaturatedblue">
                <v-row class="d-flex">
                    <v-col cols="12" :md="productData.productVideo != null ? '6':'12'">
                        <v-card class="fill-height d-flex flex-column justify-space-between" color="transparent" flat elevation="0">
                            <v-card-title class="pt-0" >
                                <v-icon :color="productData.productColor" class="xxxx-large" v-if="productData.productIcon && productData.isApp">{{ productData.productIcon }}</v-icon>
                                <span class="display-2 font-weight-thin">{{this.recipe.title}}</span>
                                <span v-if="productData.isPremium != null">
                                    <v-chip class="ml-4" small label color="warning" v-if="productData.isPremium"> Premium </v-chip>
                                    <v-chip class="ml-4" small label color="sucess" v-else> Free </v-chip>
                                </span>
                            </v-card-title>
                            <v-card-subtitle v-if="productData.productRate > 0" class="d-flex flex-row align-center">
                                <span v-if="productData.productCategory != null" class="body-1 font-weight">{{productData.productCategory}}</span>
                                    
                                <span v-if="productData.productRate != null">
                                    <v-rating
                                        background-color="iq_light darken-4"
                                        color="warning"
                                        half-increments=true
                                        empty-icon="mdi-star"
                                        full-icon="mdi-star"
                                        half-icon="mdi-star-half-full"
                                        hover
                                        readonly
                                        length="5"
                                        size="23"
                                        :value="productData.productRate"
                                    ></v-rating>
                                    <span class="body-1 mr-1">{{productData.productRate}}</span>
                                    <span class="caption">({{convertLargeNumberToLetters(productData.productRatings)}})</span>
                                </span>
                            </v-card-subtitle>

                             <v-card-subtitle class="pt-2" v-if="this.recipe.tags.length > 0">
                                <v-chip small class="mr-1" color="iq_lightslategray" v-for="(tag, tagIndex) in this.recipe.tags" :key="tagIndex"> {{ tag.name }}</v-chip>
                            </v-card-subtitle>


                            <v-card-text class="white--text" v-html="this.recipe.longDescription"></v-card-text> 
                            <v-card-text class="body-1 white--text">
                                <v-row>
                                    <v-col cols="12">
                                        <h3 v-if="this.recipe.whatItDoes">What it does?</h3>
                                        <p>{{this.recipe.whatItDoes}}</p>
                                        <h3 v-if="this.recipe.useCases">Common Use Cases</h3>
                                        <p>{{this.recipe.useCases}}</p>
                                        <h3 v-if="this.recipe.why">Why this recipe?</h3>
                                        <p>{{this.recipe.why}}</p>
                                        <h3 v-if="this.recipe.whatItCouldUpdate">What it could Update? </h3>
                                        <p>{{this.recipe.whatItCouldUpdate}}</p>
                                        <h3 v-if="this.productDetailGrid.length">What you get?</h3>
                                        <template v-for="set in this.productDetailGrid" >   
                                            <v-tooltip top color="primary">
                                                <template v-slot:activator="{ on, attrs }">            
                                                    <v-chip color="success" class="mr-1 mt-1" v-bind="attrs" v-on="on" :key="set.name" v-if="set.value">{{set.name}}</v-chip>
                                                </template>
                                                <span>{{set.detail}}</span>
                                            </v-tooltip>
                                        </template>
                                    </v-col>
                                </v-row>
                            </v-card-text>


                            <v-card-text  v-if="productData.productRelated.length > 0">
                                <div class="pb-1" v-if="productData.productRelated.length > 0">
                                    <span v-if="productData.isApp">Related Products:</span>
                                    <span v-else>Compatible Tools:</span>
                                </div>
                                <div  v-if="productData.productRelated.length > 0">
                                    <v-chip :to="subProduct.link" x-large label class="rounded-lg mr-1" :color="subProduct.productColor"  v-for="(subProduct, subProductIndex) in productData.productRelated" :key="subProductIndex"> 
                                        <v-icon class="xx-large"> {{subProduct.productIcon}}</v-icon>
                                        {{ subProduct.productName}}
                                    </v-chip>
                                </div>
                            </v-card-text>

                            <v-card-actions class="d-flex flex-row justify-end" v-if="productData.productActions.length > 0"> 
                                <v-btn large :outlined="prodActionIndex === 0 ? true: false" :to="prodAction.link" :color="prodAction.color" v-for="(prodAction, prodActionIndex) in productData.productActions" :key="prodActionIndex"> 
                                    <v-icon>{{prodAction.icon}}</v-icon>
                                    {{ prodAction.title}}
                                </v-btn>
                            </v-card-actions>

                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6" v-if="productData.productVideo != null">
                                <script2 type="text/javascript" async src="https://play.vidyard.com/embed/v4.js"></script2>
                                <img
                                    style="width: 100%; margin: auto; display: block;"
                                    class="vidyard-player-embed"
                                    :src="productData.productVideo"
                                    :data-uuid="productData.productVideoID"
                                    data-v="4"
                                    data-type="inline"
                                />
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
    </div>
</template>
<script>
export default {
    props: {
        pageData: {
            type: Object,
            default: null
        },
        productData: {
            type: Object,
            default: null
        },
        recipe: {
            type: Object,
            default: null
        },
        productDetailGrid: {
            type: Array,
            default: null
        }
    },
    data () {
      return {

      }
    },
    created(){

    }, 
    methods: {
        hasAnyFeatureOn( objectArrays ){
            var has = false;
            has = objectArrays.some(objectArray => objectArray.value === true);
            return has;
        },
    }
}
</script>