<template>
    <v-card flat :dark="$vuetify.breakpoint.smAndDown" class="theme-breakpoint-card-switch">
        <v-form v-model="valid" id="areaCodeForm" ref="areaCodeForm" lazy-validation>
            <v-row>
                <v-col cols="12">
                    <h1 :class="this.$vuetify.breakpoint.smAndDown ? 'white--text text-h5 text-center' : 'secondary--text text-h2'" v-if="!iqNumber"> Get your IQ number</h1>
                    <h1 :class="this.$vuetify.breakpoint.smAndDown ? 'white--text text-h5 text-center' : 'secondary--text text-h2'" v-else>Here's your local IQ Number</h1>
                </v-col>
                <v-col cols="12" offset="0">
                    <v-card flat v-if="!iqNumber"  class="mb-6 elevation-exp-1 theme-breakpoint-card-switch" >
                        <v-row>
                        
                            <v-col cols="12" md="7">
                                <v-card-title>Enter a valid US area code </v-card-title>
                                <v-card-subtitle>We'll look for a valid number within this area</v-card-subtitle>
                                </v-col>
                            <v-col cols="12" md="5">
                                <v-card-text>
                                <v-form v-model="valid" id="areaCodeForm" ref="areaCodeForm">
                                    <v-otp-input
                                        placeholder="#"
                                        class="otp-size-up"
                                        ref="AreaCodeInput"
                                        length="3"
                                        type="number"
                                        v-model="areaCode"
                                        :disabled="loading"
                                        @finish="onFinish"
                                    ></v-otp-input>
                                    <v-overlay absolute :value="loading" :color="!this.$vuetify.breakpoint.smAndDown ? 'iq_light' : 'iq_dark'" opacity="0.75">
                                        <v-progress-circular
                                        indeterminate
                                        size="70"
                                        width="7"
                                        color="primary"
                                        ></v-progress-circular>
                                    </v-overlay>
                                </v-form>  
                            </v-card-text>
                            
                            </v-col>
                        </v-row>
                        
                        
                        <v-card-actions class="d-flex flex-column" v-if="noMatch && !loading">
                            <v-divider></v-divider>
                            <p class="error--text"> 
                                <v-icon color="error--text">mdi-alert</v-icon>
                                THERE ARE NO AVAILABLE NUMBERS IN THAT AREA CODE. PLEASE ENTER A DIFFERENT AREA CODE.
                            </p>
                        </v-card-actions>
                    </v-card>
                    <v-expand-transition>
                        <v-card flat v-if="iqNumber" class="mb-6 elevation-exp-1 theme-breakpoint-card-switch">
                            <v-card-title>IQ Number Found </v-card-title>
                            <v-card-subtitle>This will be used for MessageIQ to send the SMS</v-card-subtitle>
                            <v-card-text>
                                <h1 class="display-2 text-center">
                                {{iqNumber}}
                                </h1>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-space-between flex-wrap">                               
                                <v-btn text  color="primary" @click="resetForm"> Try Another Area Code </v-btn>
                                <v-btn outlined color="primary" @click="resetIQNumber">Find me a different Number in {{areaCode}} </v-btn>
                            </v-card-actions>
                            
                        </v-card>
                    </v-expand-transition>


                </v-col>
            </v-row>
        </v-form>
        <v-footer 
        :app ="this.$vuetify.breakpoint.smAndDown"
        :class="this.$vuetify.breakpoint.smAndDown ? 'bg-mobile-overlay border-top':'bg-transparent'"
        padless 
        class="px-0 bg-transparent" 
        >
            <v-container fluid class="pa-0">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-card-actions v-if="isTrial" class="d-flex justify-space-between " :class="this.$vuetify.breakpoint.smAndDown ? '':'px-0'">
                                <v-btn x-large outlined color="primary" to="step1"> Back </v-btn>
                                <v-btn :disabled="!iqNumber" :loading="loadingTrial"
                                x-large color="primary"  v-on:click="saveTrialData"> Next </v-btn>
                        </v-card-actions>

                        <v-card-actions v-else class="d-flex justify-space-between " :class="this.$vuetify.breakpoint.smAndDown ? '':'px-0'">
                                <v-btn x-large outlined color="primary" :disabled="!createTrial" to="step2"> Back </v-btn>
                                <v-btn :disabled="!iqNumber" x-large color="primary" to="step4"> Next </v-btn>
                        </v-card-actions>
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>
        
    </v-card>
</template>
<script>
//TODO: missing ICON from custom Library IconMoon
export default {
    name: "AccountStep4",
    data () {

        return {
            valid: false,
            loading: false,
            areaCode: '',
            iqNumber: null,
            noMatch: false,
            isTrial:false,
            createTrial: true,
            loadingTrial:false
        }
    },
    created() {
        if(this.$route.fullPath.match('registration/MessageIQ')){
            this.isTrial = true;     
        }
    },
    methods: {
      async onFinish (code) {
            this.noMatch  = false;

            this.loading = true
            let number = await this.getPhoneNumber(code);
            this.iqNumber = number;
            this.loading = false;
            if(number === ''){
                this.noMatch  = true;
            }            
        },
        async resetIQNumber(){
            let number = await this.getPhoneNumber(this.areaCode);
             this.iqNumber = number;
        },
        resetForm(){
            this.iqNumber = null
            this.areaCode = ''
        },
        async getPhoneNumber(value) {
            this.iqNumber = null
            let phoneNumber = await this.$store.dispatch('registration/getPhoneNumber', value);
            return phoneNumber;
        },

        async saveTrialData(){
            if (this.createTrial) {
                this.createTrial = false;
                this.loadingTrial = true;
                let result = await this.$store.dispatch("registration/registerTrialMessageIQ");
                    if(result){
                        this.$router.push('/registration/MessageIQ/step3');
                    }else{
                        this.$swal({
                            icon: 'error',
                            title: 'Error Creating Account',
                            text: 'An error occurred while creating your account. Please try again later.',
                        })
                    }
            }
            setTimeout(() => {
                this.loadingTrial = false;
                this.createTrial = true;
            }, 30000)
        }
    },
   
}
</script>