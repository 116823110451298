import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"mt-n1 pt-0"},[_c(VRow,{staticClass:"py-6"},[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-row align-start my-3"},[_c(VIcon,{staticClass:"xx-large"},[_vm._v("mdi-file-sign")]),_c('div',{staticClass:"ml-2"},[_c('h1',{staticClass:"display-1"},[_vm._v("Summary and Approval")]),_c('h4',{staticClass:"body-2"},[_vm._v("Check your selected options before continue")])])],1),_c('p',[_vm._v(" Et laborum enim dolore mollit ipsum Lorem aute ullamco nulla ullamco adipisicing deserunt.")])])],1),_c(VCard,{attrs:{"rounded":"","elevation":"1","outlined":""}},[_c('Status',{attrs:{"wizardState":this.status,"stepName":_vm.currentRouteName,"isLarge":true}})],1),_c(VRow,[_c(VCol,[_vm._v("   ")])],1),_c(VDivider),_c(VFooter,{staticClass:"pt-4",attrs:{"color":"iq_opaque"}},[_c(VBtn,{attrs:{"large":"","color":"primary"}},[_vm._v(" Approve ")]),_c(VBtn,{attrs:{"text":"","large":""}},[_vm._v(" Cancel ")]),_c(VBtn,{staticClass:"ml-auto",attrs:{"to":"/wizard/fields","color":"secondary","text":"","large":""}},[_vm._v(" Back ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }