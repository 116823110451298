<template>
    <dashboardLauncher></dashboardLauncher>
</template>
<script>
import dashboardLauncher from '../../components/SmartRamp/Dashboard/advancedDashboardLauncher.vue';

export default {
    components: {
        dashboardLauncher
    },
    data() {
        return {

        }
    }
}
</script> 