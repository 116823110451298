import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VCard,{staticClass:"flex-grow-1",attrs:{"width":"100%","outlined":""}},[_c(VCardText,{staticClass:"text-center d-flex flex-column align-center justify-center"},[_c(VImg,{attrs:{"position":"top left","src":require('@/assets/images/integrations/' + _vm.systemA.src ),"contain":"","max-height":"60px","max-width":"60px"}}),_c('h2',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.systemA.title)+" - Connection")]),(!_vm.currentCompany.cloverConnected)?_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.goToCloverConnect}},[_vm._v("Connect "+_vm._s(_vm.systemA.title))]):_c(VChip,{attrs:{"color":"success","large":""}},[_c(VIcon,[_vm._v("mdi-check-network-outline ")]),_vm._v(" "+_vm._s(_vm.systemA.title)+" - Connected ")],1)],1)],1)],1),_c(VCol,{staticClass:"text-center d-flex flex-column align-center justify-center",attrs:{"cols":"6"}},[_c(VCard,{staticClass:"flex-grow-1",attrs:{"width":"100%","outlined":""}},[_c(VCardText,{staticClass:"text-center d-flex flex-column align-center justify-center"},[_c(VImg,{attrs:{"position":"top left","src":require('@/assets/images/integrations/' + _vm.systemB.src ),"contain":"","max-height":"60px","max-width":"60px"}}),_c('h2',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.systemB.title)+" - Connection")]),(!_vm.currentCompany.cloverHubSpotConnected)?_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.goToHubSpotConnect}},[_vm._v("Connect "+_vm._s(_vm.systemB.title))]):_c(VChip,{attrs:{"color":"success","large":""}},[_c(VIcon,[_vm._v("mdi-check-network-outline ")]),_vm._v(" "+_vm._s(_vm.systemB.title)+" - Connected")],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }