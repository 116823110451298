import Api from "@/services/api";
import Vue from 'vue';
export default {
    namespaced: true,
    actions: {
        async getTagsByCompanyId({}, {companyId:companyId}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().get(`/Web/Tag/GetAllTagsByCompany/${companyId}`,config)
            .catch(err => {
                console.error(err.message);
            });
            return response.data;
        },
        async createTag({},{tag: tag}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().post(`/Web/Tag/CreateTag`, tag, config)
            .catch(err => {
                console.error(err.message);
            });
            return response;
        },
        async updateTag({}, {tag: tag}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().put(`/Web/Tag/UpdateTag`, tag, config)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            return response.status;
        },
        async deleteTag({}, {idTag: idTag}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().delete(`/Web/Tag/DeleteTag/${idTag}`, config)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            return response.status;
        },

        async incrementOrDecreaseTagContacts({}, {idTag: idTag,increment:increment}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().put(`/Web/Tag/IncrementContact/${idTag}/${increment}`, config)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            return response.status;
        },

        async createContactTag({}, {idTag: idTag,idContact:idContact}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().post(`/Web/Tag/CreateContactTag/${idTag}/${idContact}`, {}, config)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            return response.status;
        },
        
        async deleteContactTag({}, {idTag: idTag,idContact:idContact}){
            let authToken = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${authToken}` }
            };
            let response = await Api().delete(`/Web/Tag/DeleteContactTag/${idTag}/${idContact}`, config)
            .catch(err => {
                console.error(err.message);
                return false;
            });
            return response.status;
        },
    }
}