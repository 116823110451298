import Api from "@/services/api";
import Vue from "vue";
export default {
    namespaced: true,
    state: {
        customers: null,
        syncs: null,
        subSyncs: null,
        actionData: null,
        
        execution: null,
        executionLoading: false,
        executionsSearchQuery: {},
        executionsPageNumber: 1,
        executionsPageSize: 50,
        executionsSortBy: { key: "start", order: "desc" },
        executionsTotalItems: 0,


        actions: null,
        actionsLoading: false,        
        actionsSearchQuery: {},
        actionsPageNumber: 1,
        actionsPageSize: 25,
        actionsSortBy: { key: "actionTime", order: "desc" },
        actionsTotalItems: 0,
    },
    mutations: {
        SET_CUSTOMERS(state, customers) {
            state.customers = customers;
        },
        SET_SYNCS(state, syncs) {
            state.syncs = syncs;
        },
        SET_SUBSYNCS(state, subSyncs) {
            state.subSyncs = subSyncs;
        },


        SET_EXECUTION(state, execution) {
            state.execution = execution;
        },
        SET_EXECUTION_LOADING(state, executionLoading) {
            state.executionLoading = executionLoading;
        },
        SET_EXECUTIONS_SEARCH_QUERY(state, query) {
            state.executionsSearchQuery = query;
        },
        SET_EXECUTIONS_PAGE_NUMBER(state, pageNumber) {
            state.executionsPageNumber = pageNumber;
        },
        SET_EXECUTIONS_PAGE_SIZE(state, pageSize) {
            state.executionsPageSize = pageSize;
        },
        SET_EXECUTIONS_SORT_BY(state, sortBy) {
            state.executionsSortBy = sortBy;
        },
        SET_EXECUTIONS_TOTAL_ITEMS(state, total) {
            state.executionsTotalItems = total;
        },

        SET_ACTIONS(state, actions) {
            state.actions = actions;
        },
        SET_ACTIONS_LOADING(state, actionsLoading) {
            state.actionsLoading = actionsLoading;
        },
        SET_ACTIONS_SEARCH_QUERY(state, query) {
            state.actionsSearchQuery = query;
        },
        SET_ACTIONS_PAGE_NUMBER(state, pageNumber) {
            state.actionsPageNumber = pageNumber;
        },
        SET_ACTIONS_PAGE_SIZE(state, pageSize) {
            state.actionsPageSize = pageSize;
        },
        SET_ACTIONS_SORT_BY(state, sortBy) {
            state.actionsSortBy = sortBy;
        },
        SET_ACTIONS_TOTAL_ITEMS(state, total) {
            state.actionsTotalItems = total;
        },

              
        SET_ACTIONS_DATA(state, data) {
            state.actionData = data;
        },
    },
    actions: {
        //API Calls
        async getCustomers({ commit }) {
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            let response = await Api().get(`/Web/IQIntegrations/GetCustomers`, config)
                .catch(err => {
                    console.error(err.message);
                });

            commit('SET_CUSTOMERS', response.data);
        },
        async getSyncs({ commit }, customerId) {
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            let response = await Api().get(`/Web/IQIntegrations/GetSyncs/${customerId}`, config)
                .catch(err => {
                    console.error(err.message);
                });

            commit('SET_SYNCS', response.data);
        },
        async getSubSyncs({ commit }, syncId) {
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            let response = await Api().get(`/Web/IQIntegrations/GetSubSyncs/${syncId}`, config)
                .catch(err => {
                    console.error(err.message);
                });

            commit('SET_SUBSYNCS', response.data);
        },
        /** EXECUTIONS ACTIONS STARTS *******************************************************/

        /**
         * Fetches executions based on the provided parameters.
         * @async
         * @function getExecutions
         * @param {Object} context - The Vuex context object.
         * @param {string} subSyncId - The ID of the sub-sync.
         * @param {number} [page] - The page number for pagination.
         * @param {number} [pageSize] - The number of items per page.
         * @param {{ key: "start" | "end", order: "asc" | "desc", startDate?: string }} [sortBy] - An object specifying the sort key and order, the startDate prop of this object should be on ISOString format.
         * @param {{ status: string }} [filters] - An object specifying the status filter.
         * @returns {Promise<void>} A promise that resolves with the fetched executions.
         */
        async getExecutions({ commit, state }, subSyncId) {
            commit('SET_EXECUTION_LOADING', true);
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            const { executionsPageNumber, executionsPageSize, executionsSortBy, executionsSearchQuery } = state;
            const params = {
                ...executionsSearchQuery,
                "page": executionsPageNumber,
                "pageSize": executionsPageSize,
                "SortByJson": executionsSortBy,
            };
            
            let response = await Api().get(`/Web/IQIntegrations/GetExecutions/${subSyncId}`, { params, ...config })
                .catch(err => {
                    console.error(err.message);
                });
        
            commit('SET_EXECUTIONS_TOTAL_ITEMS', response.data.total);
            commit('SET_EXECUTION_LOADING', false);
            
            commit('SET_EXECUTION', response.data);
        },
        setExecutionsPageNumber({ commit, state }, pageNumber) {
            commit('SET_EXECUTIONS_PAGE_NUMBER', pageNumber);
        },
        incrementExecutionsPageNumber({ commit, state }) {
            const PageNumber = state.executionsPageNumber + 1;
            commit('SET_EXECUTIONS_PAGE_NUMBER', PageNumber);

        },
        decreaseExecutionsPageNumber({ commit, state }) {
            const PageNumber = state.executionsPageNumber - 1;
            commit('SET_EXECUTIONS_PAGE_NUMBER', PageNumber);
        },
        setExecutionsPageSize({ commit }, pageSize) {
            commit('SET_EXECUTIONS_PAGE_SIZE', pageSize);
        },
        /** EXECUTIONS ACTIONS ENDS *******************************************************/






        /**
        * Fetches actions based on the provided parameters.
        * @async
        * @function getActions
        * @param {Object} context - The Vuex context object.
        * @param {string} executionId - The ID of the execution.
        * @param {number} [page] - The page number for pagination.
        * @param {number} [pageSize] - The number of items per page.
        * @param {{ order: "asc" | "desc", startDate?: string }} [sortBy] - An object specifying the sort key and order, the startDate prop of this object should be on ISOString format.
        * @param {{ status?: string, recordId?: string, type?: string, name?: string }} [filters] - An object specifying the status filter.
        * @returns {Promise<void>} A promise that resolves with the fetched executions.
        */
        async getActions({ commit, state }, executionId) {
            commit('SET_ACTIONS_LOADING', true);

            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            const { actionsPageNumber, actionsPageSize, actionsSortBy, actionsSearchQuery } = state;
            const params = {
                ...actionsSearchQuery,
                "page": actionsPageNumber,
                "pageSize": actionsPageSize,
                "SortByJson": actionsSortBy,
            };
            let response = await Api().get(`/Web/IQIntegrations/GetActions/${executionId}`, { params, ...config })
                .catch(err => {
                    console.error(err.message);
                });

            commit('SET_ACTIONS_TOTAL_ITEMS', response.data.total);
            commit('SET_ACTIONS', response.data);
            commit('SET_ACTIONS_LOADING', false);

            
        },
        setActionsPageNumber({ commit, state }, pageNumber) {
            commit('SET_ACTIONS_PAGE_NUMBER', pageNumber);
        },
        incrementActionsPageNumber({ commit, state }) {
            const PageNumber = state.actionsPageNumber + 1;
            commit('SET_ACTIONS_PAGE_NUMBER', PageNumber);

        },
        decreaseActionsPageNumber({ commit, state }) {
            const PageNumber = state.actionsPageNumber - 1;
            commit('SET_ACTIONS_PAGE_NUMBER', PageNumber);
        },
        setActionsPageSize({ commit }, pageSize) {
            commit('SET_ACTIONS_PAGE_SIZE', pageSize);
        },
        






        async getActionsData({ commit }, actionId) {
            let token = await Vue.prototype.$auth.getTokenSilently();
            let config = {
                headers: { Authorization: `Bearer ${token}` }
            };

            let response = await Api().get(`/Web/IQIntegrations/GetActionData/${actionId}`, config)
                .catch(err => {
                    console.error(err.message);
                });

            if (response.data) response.data = JSON.parse(response.data);

            commit('SET_ACTIONS_DATA', response.data);
            return response.data;
        },

        //State Maintenance 
        async clearFromCustomer({ commit }) {
            commit('SET_SYNCS', null);
            commit('SET_SUBSYNCS', null);
            commit('SET_EXECUTION', {"data": {"data": []}});
            commit('SET_ACTIONS', null);
            commit('SET_ACTIONS_DATA', null);
        },
        async clearFromSyncs({ commit }) {
            commit('SET_SUBSYNCS', null);
            commit('SET_EXECUTION', {"data": {"data": []}});
            commit('SET_ACTIONS', null);
            commit('SET_ACTIONS_DATA', null);

        },
        async clearFromSubSyncs({ commit }) {
            commit('SET_EXECUTION', {"data": {"data": []}});
            commit('SET_ACTIONS', null);
            commit('SET_ACTIONS_DATA', null);

        },
        async clearFromExecution({ commit }) {
            commit('SET_ACTIONS', null);
            commit('SET_ACTIONS_DATA', null);
        },
    },
    getters: {
        getCustomers: state => { return state.customers },
        getSyncs: state => { return state.syncs },
        getSubSyncs: state => { return state.subSyncs },

        getExecution: state => { return state.execution },
        getExecutionLoading: state => { return state.executionLoading },
        getExecutionsSearchQuery: state => state.executionsSearchQuery,
        getExecutionsPageNumber: state => state.executionsPageNumber,
        getExecutionsPageSize: state => state.executionsPageSize,
        getExecutionsSortBy: state => state.executionsSortBy,
        getExecutionsTotalItems: state => state.executionsTotalItems,

        getActions: state => { return state.actions },
        getActionsLoading: state => { return state.actionsLoading},
        getActionsSearchQuery: state => state.actionsSearchQuery,
        getActionsPageNumber: state => state.actionsPageNumber,
        getActionsPageSize: state => state.actionsPageSize,
        getActionsSortBy: state => state.actionsSortBy,
        getActionsTotalItems: state => state.actionsTotalItems,

        getActionsData: state => { return state.actionData },

    }
};