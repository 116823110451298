<template>
    <button v-if="$auth.isAuthenticated" @click="logout">Log Out</button>
    <p v-else>Not Authenticated</p>
</template>
<script>
export default {
    async mounted(){
        var isAuthenticated = await this.$auth.checkIfAuthenticated();
        if(isAuthenticated){
            this.logout();
        }
    },
    methods: {
        logout(){
            this.$auth.logout({
                returnTo: window.location.origin
            });
        }
    }
}
</script>