import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"elevation":"0"}},[_c(VToolbar,{attrs:{"dark":"","color":"iq_darkerblue","id":"SettingsNav"},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c(VContainer,{staticClass:"pa-0"},[_c(VTabs,{attrs:{"show-arrows":"","grow":"","background-color":"iq_darkerblue"},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.settingComponents),function(setting){return _c(VTab,{key:setting.id,class:_vm.tabs  == setting.route ? 'v-tab--active' : '',attrs:{"to":setting.route}},[_vm._v(" "+_vm._s(setting.name)+" ")])}),1)],1)]},proxy:true}])},[_c(VToolbarTitle,{staticClass:"display-3"},[_vm._v("SmartRamp Admin ")])],1),_c(VTabsItems,{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }