<template>
    <v-container class="my-5" >
        <v-card flat>
            <v-card-title  class="text-h5 pl-0 pb-5">
                User Settings
            </v-card-title>
            <v-card-text>
                <v-switch inset label="Enable Browser Notifications" v-model="userNotifications" @change="updateNotifications"></v-switch>
            </v-card-text>
            <v-alert v-if="!NotificationAllowed">
                <div> 
                    <v-icon>mdi-bell-off</v-icon> 
                    <h4>Your operating system/browser combination does not allow PWA notifications from third parties. If you enable it for your user, you'll receive notifications in your MIQ account in Windows and MacOS but not for this specific device.</h4>
                </div>
            </v-alert>
            <v-divider></v-divider>
            <v-card-actions>
                <v-btn color="primary" @click="updateNotifications">Save</v-btn>
            </v-card-actions>
            <v-card-title class="text-h5 pl-0 pb-5">
                How to Guides for notifications:
            </v-card-title>
            <v-card-text class="pa-0">
                <v-expansion-panels popout>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="text-h5"> <v-icon>mdi-microsoft-windows</v-icon> Enable  Notifications in Windows</div>
                        </v-expansion-panel-header>
                        <v-divider></v-divider>
                        <v-expansion-panel-content class="pt-9">
                            <v-row>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col>
                                            <h2><v-icon>mdi-google-chrome</v-icon> Using Chrome</h2>
                                            <a target="_blank" href="https://knowledge.workspace.google.com/kb/how-to-enable-browser-notifications-000007831"> How to enable notifications in Chrome</a>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" >
                                    <v-row>
                                        <v-col>
                                            <h2> <v-icon>mdi-microsoft-edge</v-icon> Using Edge</h2>
                                            <a target="_blank" href="https://support.microsoft.com/en-us/microsoft-edge/manage-website-notifications-in-microsoft-edge-0c555609-5bf2-479d-a59d-fb30a0b80b2b#:~:text=Remove%20or%20block%20notifications%20in,are%20currently%20sending%20you%20notifications"> How to enable notifications in Edge</a>
                                            
                                        </v-col>
                                    </v-row>

                                </v-col>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col>
                                            <h2> <v-icon>mdi-firefox</v-icon> Using Firefox</h2>
                                            <a target="_blank" href="https://support.mozilla.org/en-US/kb/push-notifications-firefox"> How to enable notifications in Firefox</a>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col>
                                            <h2>Configure windows notifications</h2>
                                            <a target="_blank" href="https://support.microsoft.com/en-us/windows/change-notification-settings-in-windows-8942c744-6198-fe56-4639-34320cf9444e"> How to configure notifications in Windows</a>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="text-h5"> <v-icon>mdi-apple</v-icon>  Enable  notifications in MacOS </div>
                        </v-expansion-panel-header>
                        <v-divider></v-divider>
                        <v-expansion-panel-content class="pt-9">
                            <v-row>
                                <v-col cols="12">
                                    <v-row>
                                        <v-col>
                                            <h2><v-icon>mdi-google-chrome</v-icon> Using Chrome</h2>
                                            <a target="_blank" href="https://support.google.com/chrome/answer/3220216?hl=en&co=GENIE.Platform%3DDesktop"> How to enable notifications in Chrome</a>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" >
                                    <v-row>
                                        <v-col>
                                            <h2> <v-icon>mdi-apple-safari</v-icon> Using Safari</h2>
                                            <a target="_blank" href="https://support.apple.com/guide/safari/customize-website-notifications-sfri40734/mac"> How to enable notifications in Safari</a>
                                            
                                        </v-col>
                                    </v-row>

                                </v-col>
                                <v-col cols="12" >
                                    <v-row>
                                        <v-col>
                                            <h2>Configure MacOS Notifications</h2>
                                            <a target="_blank" href="https://support.apple.com/guide/mac-help/change-notifications-settings-mh40583/14.0/mac/14.0"> How to configure notifications in MacOS</a>
                                            
                                        </v-col>
                                    </v-row>

                                </v-col>
                               
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
           
        </v-card> 
    </v-container>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import ImageViewer from '../shared/_imageViewer.vue';

export default {
    components: {
        ImageViewer
    },
    computed:{
        ...mapGetters({
            user: 'getUser'
        }),
        userNotifications: {
            get() {
                return this.$store.getters.getUserNotifications;
            },
            set(value) {
                this.$store.commit('SET_USER_NOTIFICATIONS', value);
            }
        }
    },
    data(){
        return {
            NotificationAllowed: false,
        };
    },
    methods: {
        ...mapMutations({
            setNotifications: 'SET_USER_NOTIFICATIONS',
        }),
        ...mapActions({
            updateUserNotification: 'updateUserNotification',
        }),
        async updateNotifications() {
            await this.$store.dispatch('updateUserNotification', {
                Id: this.user.id,
                Notifications: this.userNotifications
            });
        },
    },
    created() {},
    mounted() {
        if ("Notification" in window) {
            // Browser supports notifications
            this.NotificationAllowed = true;                 
        }else{
            this.NotificationAllowed = false;
        }

    },
}
</script>