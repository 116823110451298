<template>
    <h1>Verifying Company...</h1>
</template>
<script >
export default{
    async mounted(){
        let companyId = this.$route.query.token;
        if(typeof companyId !== 'undefined'){
            let result = await this.$store.dispatch('company/verifyCompany',companyId);
            if(result){
                window.location.href = '/';
            }
        }else{
            window.location.href = '/';
        }
    }
}
</script>