<template>
    <div id="dashboardLauncher">
        <v-container fluid>
            <v-card elevation="0">
                <v-expansion-panels v-model="panels" multiple>
                    <v-expansion-panel class="liveApps" v-if="launchItemsLive.length > 0">
                        <v-expansion-panel-header class="text-h6 font-weight-light">Your Apps</v-expansion-panel-header>
                        <v-expansion-panel-content>
                                <v-row>
                                    <template v-for="launchItem in launchItemsLive">
                                        <v-col
                                        cols="12"
                                        sm="6"
                                        md="4"
                                        lg="3"
                                        xl="2"
                                        v-if="launchItem.isLive === 1"
                                        :key="launchItem.name"
                                        >
                                            <v-card 
                                            class="card-branded fill-height d-flex flex-column justify-space-between"
                                            elevation="10"
                                            outlined rounded
                                            :class="launchItem.color !== null ? 'darken-1' : ''"
                                            :dark="launchItem.color !== null ? true : false"
                                            :color="launchItem.color !== null ? launchItem.color : 'miqlightslategray'"
                                            >                                        
                                                <div class="card-branded-overlay"></div>
                                                <v-card-title class="pb-0">
                                                    <v-sheet class=" ml-n6 d-flex align-center justify-center lighten-1" :color="launchItem.color !== null ? launchItem.color : 'secondary'"  dark elevation="10"  rounded width="100px" height="100px">
                                                        <div class="text-center d-flex align-center justify-center">
                                                            <v-icon class="xxxx-large">{{launchItem.icon}}</v-icon>
                                                            <div class="caption d-block">{{launchItem.name}}</div>
                                                        </div>
                                                    </v-sheet>
                                                    <v-spacer></v-spacer>
                                                    <span class="d-flex flex-wrap">
                                                        <v-btn fab class="pr-2 pl-2" text small v-for="notification in launchItem.notifications" :key="notification.title" :to="notification.link">
                                                            <v-icon >{{notification.icon}}</v-icon>
                                                            <v-badge
                                                                color="error"
                                                                :content="notification.count"
                                                                offset-y="-5"
                                                                offset-x="15"
                                                                >
                                                            </v-badge>
                                                        </v-btn>

                                                        <v-menu offset-y close-on-click v-if="launchItem.additionalLinks">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn icon small v-bind="attrs" v-on="on">
                                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <v-list>
                                                                <v-list-item 
                                                                    v-for="(additionalLink, linkIndex) in launchItem.additionalLinks"
                                                                    :key="linkIndex"
                                                                    :to="additionalLink.link"
                                                                >
                                                                    <v-list-item-icon>
                                                                        <v-icon> {{additionalLink.icon}}</v-icon>
                                                                    </v-list-item-icon>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title> {{ additionalLink.title }}</v-list-item-title>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-menu>
                                                    </span>
                                                </v-card-title>
                                                <v-card-actions class="d-flex justify-end flex-wrap">
                                                    <v-btn  block :color="launcherAction.color" :text="launcherAction.color === null ? 'true':'false'" v-for="launcherAction in launchItem.launcherActions" :key="launcherAction.title" :to="launcherAction.link">
                                                        <v-icon class="pr-1"> {{launcherAction.icon}}</v-icon>  {{ launcherAction.title }}
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-col>
                                    </template>
                                </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>  
                    <v-expansion-panel class="promoApps"  v-if="launchItemsAvailable.length > 0">
                        <v-expansion-panel-header class="text-h6 font-weight-light">Apps Available for you.</v-expansion-panel-header>
                        <v-expansion-panel-content>
                                <v-row>
                                    <template v-for="launchItem in launchItemsAvailable">
                                        <v-col
                                        cols="12"
                                        md="6"
                                        lg="4"
                                        v-if="launchItem.isLive === 0"
                                        :key="launchItem.name"
                                        >                                                
                                            <v-sheet outlined class=" fill-height d-flex flex-column lighten-4" elevation="1" :color="launchItem.color !== null ? launchItem.color : 'miqlightslategray'" rounded>
                                                <v-card 
                                                class="card-branded fill-height d-flex flex-column "
                                                rounded
                                                :class="launchItem.color !== null ? 'darken-1' : ''"
                                                >
                                                    <div class="card-branded-overlay" :data-color="launchItem.color"></div>
                                                    <v-card-title>
                                                        <h4 class="d-block">{{launchItem.name}}</h4>
                                                        <v-spacer></v-spacer>
                                                        <v-dialog
                                                        v-for="promoCTA in launchItem.promoCTAs" :key="promoCTA.urlID"
                                                        transition="dialog-top-transition"
                                                        max-width="1200"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                            <v-btn text x-large color="iq_qhite" v-bind="attrs" v-on="on" >
                                                                <v-icon large class="mr-1"> {{promoCTA.icon}} </v-icon>
                                                                {{ promoCTA.title}}
                                                            </v-btn>
                                                            </template>
                                                            <template v-slot:default="dialog">
                                                                <v-card>
                                                                    <v-toolbar
                                                                    color="primary"
                                                                    dark
                                                                    > 
                                                                    {{ promoCTA.title}}
                                                                    </v-toolbar>
                                                                    <v-card-text>
                                                                        <img
                                                                            style="width: 100%; margin: auto; display: block;"
                                                                            class="vidyard-player-embed"
                                                                            :src="promoCTA.url"
                                                                            :data-uuid="promoCTA.urlID"
                                                                            data-v="4"
                                                                            data-type="inline"
                                                                        />
                                                                    </v-card-text>
                                                                    <v-card-actions class="justify-end">
                                                                    <v-btn
                                                                        text
                                                                        @click="dialog.value = false"
                                                                    >Close</v-btn>
                                                                    </v-card-actions>
                                                                </v-card>
                                                            </template>
                                                        </v-dialog>
                                                    </v-card-title>
                                                    <v-card-subtitle class=" pt-1 d-flex justify-end">
                                                        <v-chip v-for="category in launchItem.categories" :key="category" class="mr-1" outlined x-small>{{category}}</v-chip>
                                                    </v-card-subtitle>
                                                    <v-card-text class="flex-grow-1">
                                                        <span v-html="launchItem.description"></span>
                                                    </v-card-text>
                                                    <v-card-text class="flex-grow-1">
                                                        <span v-html="launchItem.promoDescription"></span>
                                                        <v-row no-gutters>
                                                            <v-col cols="12" md="6" class="d-flex flex-wrap" v-for="(promoActionsLink, linkIndex) in launchItem.promoActionsLinks" :key="linkIndex">

                                                            <v-dialog
                                                                transition="dialog-top-transition"
                                                                max-width="1200"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn class="ma-1" text small v-bind="attrs" v-on="on"> 
                                                                        <v-icon small> {{promoActionsLink.icon}} </v-icon>
                                                                        {{promoActionsLink.title}}
                                                                    </v-btn>
                                                                </template>
                                                                <template v-slot:default="dialog">
                                                                <v-card>
                                                                    <v-toolbar
                                                                    color="primary"
                                                                    dark
                                                                    > 
                                                                    {{promoActionsLink.title}}</v-toolbar>
                                                                    <v-card-text>
                                                                    <!-- Put this wherever you would like your player to appear -->
                                                                        <img
                                                                        style="width: 100%; margin: auto; display: block;"
                                                                        class="vidyard-player-embed"
                                                                        :src="promoActionsLink.url"
                                                                        :data-uuid="promoActionsLink.urlID"
                                                                        data-v="4"
                                                                        data-type="inline"
                                                                        />

                                                                    </v-card-text>
                                                                    <v-card-actions class="justify-end">
                                                                    <v-btn
                                                                        text
                                                                        @click="dialog.value = false"
                                                                    >Close</v-btn>
                                                                    </v-card-actions>
                                                                </v-card>
                                                                </template>
                                                            </v-dialog>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                    <v-divider></v-divider>
                                                    <v-card-actions class="d-flex justify-space-between flex-wrap ">
                                                        <template  v-for="promoAction in launchItem.promoActions">
                                                            <v-btn class="ma-1"  v-if="promoAction.isExternal" :color="promoAction.color" :key="promoAction.title" :href="promoAction.link" target="_blank" >
                                                                <v-icon> {{promoAction.icon}}</v-icon>{{ promoAction.title}}
                                                            </v-btn>
                                                            <v-btn class="ma-1"  v-else :color="promoAction.color" :key="promoAction.title" :to="promoAction.link">
                                                                <v-icon> {{promoAction.icon}}</v-icon>{{ promoAction.title}}
                                                            </v-btn>
                                                        </template>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-sheet>
                                        </v-col>
                                    </template>
                                </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel class="upcommingApps"  v-if="launchItemsWIP.length > 0">
                        <v-expansion-panel-header  class="text-h6 font-weight-light">In Dev Apps and Services</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <template v-for="launchItem in launchItemsWIP">
                                    <v-col
                                        cols="12"
                                        md="6"
                                        lg="4"
                                        v-if="launchItem.isLive === -1"
                                        :key="launchItem.name"
                                        >
                                            <v-sheet outlined class=" fill-height d-flex flex-column lighten-4" elevation="1" :color="launchItem.color !== null ? launchItem.color : 'miqlightslategray'" rounded>
                                            <v-card 
                                            class="card-branded fill-height d-flex flex-column justify-space-between"
                                            rounded
                                            :class="launchItem.color !== null ? 'darken-1' : ''"
                                            :dark="launchItem.color !== null ? true : false"
                                            :color="launchItem.color !== null ? launchItem.color : 'miqlightslategray'"
                                            >
                                                <div class="card-branded-overlay"></div>
                                                <v-card-title>
                                                    <h4 class="d-block">{{launchItem.name}}</h4>
                                                    <v-spacer></v-spacer>
                                                    <v-dialog
                                                    v-for="promoCTA in launchItem.promoCTAs" :key="promoCTA.urlID"
                                                    transition="dialog-top-transition"
                                                    max-width="1200"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                        <v-btn text x-large color="iq_qhite" v-bind="attrs" v-on="on" >
                                                            <v-icon large class="mr-1"> {{promoCTA.icon}} </v-icon>
                                                            {{ promoCTA.title}}
                                                        </v-btn>
                                                        </template>
                                                        <template v-slot:default="dialog">
                                                            <v-card>
                                                                <v-toolbar
                                                                color="primary"
                                                                dark
                                                                > 
                                                                {{ promoCTA.title}}
                                                                </v-toolbar>
                                                                <v-card-text>
                                                                    <img
                                                                        style="width: 100%; margin: auto; display: block;"
                                                                        class="vidyard-player-embed"
                                                                        :src="promoCTA.url"
                                                                        :data-uuid="promoCTA.urlID"
                                                                        data-v="4"
                                                                        data-type="inline"
                                                                    />
                                                                </v-card-text>
                                                                <v-card-actions class="justify-end">
                                                                <v-btn
                                                                    text
                                                                    @click="dialog.value = false"
                                                                >Close</v-btn>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </template>
                                                    </v-dialog>
                                                </v-card-title>
                                                <v-card-subtitle class=" pt-1 d-flex justify-end">
                                                    <v-chip v-for="category in launchItem.categories"  :key="category" class="mr-1" outlined x-small>{{category}}</v-chip>
                                                </v-card-subtitle>
                                                <v-card-text class="flex-grow-1">
                                                    <span v-html="launchItem.description"></span>
                                                </v-card-text>
                                                <v-card-text class="flex-grow-1">
                                                    <span v-html="launchItem.promoDescription"></span>
                                                    <v-row no-gutters>
                                                        <v-col cols="12" md="6" class="d-flex flex-wrap" v-for="(promoActionsLink, linkIndex) in launchItem.promoActionsLinks" :key="linkIndex">

                                                        <v-dialog
                                                            transition="dialog-top-transition"
                                                            max-width="1200"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn class="ma-1" text small v-bind="attrs" v-on="on"> 
                                                                    <v-icon small> {{promoActionsLink.icon}} </v-icon>
                                                                    {{promoActionsLink.title}}
                                                                </v-btn>
                                                            </template>
                                                            <template v-slot:default="dialog">
                                                            <v-card>
                                                                <v-toolbar
                                                                color="primary"
                                                                dark
                                                                > 
                                                                {{promoActionsLink.title}}</v-toolbar>
                                                                <v-card-text>
                                                                <!-- Put this wherever you would like your player to appear -->
                                                                    <img
                                                                    style="width: 100%; margin: auto; display: block;"
                                                                    class="vidyard-player-embed"
                                                                    :src="promoActionsLink.url"
                                                                    :data-uuid="promoActionsLink.urlID"
                                                                    data-v="4"
                                                                    data-type="inline"
                                                                    />

                                                                </v-card-text>
                                                                <v-card-actions class="justify-end">
                                                                <v-btn
                                                                    text
                                                                    @click="dialog.value = false"
                                                                >Close</v-btn>
                                                                </v-card-actions>
                                                            </v-card>
                                                            </template>
                                                        </v-dialog>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                                <v-divider></v-divider>
                                                <v-card-actions class="d-flex justify-space-between flex-wrap ">
                                                    <template  v-for="promoAction in launchItem.promoActions">
                                                        <v-btn class="ma-1"  v-if="promoAction.isExternal" :color="promoAction.color" :key="promoAction.title" :href="promoAction.link" target="_blank" >
                                                            <v-icon> {{promoAction.icon}}</v-icon>{{ promoAction.title}}
                                                        </v-btn>
                                                        <v-btn class="ma-1"  v-else :color="promoAction.color" :key="promoAction.title" :to="promoAction.link">
                                                            <v-icon> {{promoAction.icon}}</v-icon>{{ promoAction.title}}
                                                        </v-btn>
                                                    </template>
                                                </v-card-actions>
                                            </v-card>
                                            </v-sheet>
                                        </v-col>
                                </template>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card>
        </v-container>
    </div>
</template>
<script>
export default {
    data(){
        return {
            panels: [0,1,2],
            isMobile: false,
            launchItems : [
                {
                    name: "",
                    icon: "$messageIQIcon" ,
                    productImgSrc: null,
                    description: "Every Message IQ acccount intelligently routes and tracks text messages just like emails for incredible deliverability and engagement.  It even tracks your phone calls!  All inside HubSpot.",
                    color: "primary",
                    categories: ["Communication", "Hubspot", "SMS"],
                    //if is live
                    isLive: 1,
                    additionalLinks: [
                        { title: 'MIQ Settings', icon: 'mdi-cog-outline', link: '/settings'},
                        { title: 'Calls', icon: 'mdi-phone-outline', link: '/calls'},
                        { title: 'Contacts', icon: 'mdi-contacts-outline', link: '/contacts'},
                        { title: 'Charts', icon: 'mdi-chart-areaspline', link: '/charts'},
                    ],
                    notifications: [
                        { title: 'calls', icon: 'mdi-phone', count: 4, link: '/calls'},
                        { title: 'messages', icon: 'mdi-message', count: 10, link: '/conversations'}
                    ],
                    launcherActions: [
                        { title: 'Launch MIQ', color:null, icon: 'mdi-play', link: '/conversations'}
                    ],
                    //if is not live
                    promoDescription:  " <p>Our goal is to help you WOW prospects and customers by creating smarter and easier interactions. Thank you for trusting us to help. </br> <b>Click the video below to get started!</b></p>",
                    promoCTAs: [
                        { title: 'What Is Message IQ?', description: '', icon:'mdi-play-circle', url:'https://play.vidyard.com/wmNeRogV5s6e5MCeAnP9i9.jpg', urlID:'wmNeRogV5s6e5MCeAnP9i9', cilck() { this.modalVideo() }}
                    ],
                    promoActionsLinks:  [
                        { title: 'Quick Start', description: '', icon:'mdi-play', url:'https://play.vidyard.com/XXesvzwZ8BQnEqCwfQPjTY.jpg', urlID:'XXesvzwZ8BQnEqCwfQPjTY', click() { this.modalVideo()} },
                        { title: 'Take it for a spin', description: '', icon:'mdi-play', url:'https://play.vidyard.com/MDNZe2vNmeYHPRkYTbYBr7.jpg', urlID:'MDNZe2vNmeYHPRkYTbYBr7', click() { this.modalVideo()} },
                        { title: 'Dive Deep into the how to\'s ', description: '', icon:'mdi-play', url:'', urlID:3, click() { this.modalVideo()} },
                        { title: 'Schedule your 1:1 strategy session', description: '', icon:"mdi-play",url:'', urlID:4, click() { this.modalVideo()} },
                        { title: 'Learn from the IQ Playbook', description: '', icon:"mdi-play",url:'', urlID:5, click() { this.modalVideo()} },
                    ],
                    promoActions:  [
                        { title: 'Request a Demo', color:'secondary', icon: '', link: '/start' , isExternal: false },
                        { title: 'Learn more', color:'secondary', icon: '', link: 'https://messageiq.io/' , isExternal: true },
                        { title: 'Beging Using MIQ', color:'primary', icon: '', link: '/cards' , isExternal: false }
                    ],
                },
                {
                    name: "",
                    icon: "$integrateIQIcon",
                    productImgSrc: null,
                    description: "Find the status of your current integrations, create new ones and followup on developing progress of  you integrations",
                    color: "success",
                    categories: ["Data Integrations"],
                    //if is live
                    isLive: 1,
                    additionalLinks: [
                        { title: 'Integration Settings', icon: 'mdi-cog-outline', link: '/settings'},
                        { title: 'Integrations History', icon: 'mdi-history', link: '/cards'},
                    ],
                    notifications: [
                        { title: 'Systems Status', icon: 'mdi-server-network', count: 4, statusCode: null, link: '/calls'},
                        { title: 'Running', icon: 'mdi-database-clock', count: 10,  statusCode: null,  link: '/conversations'},
                        { title: 'Completed', icon: 'mdi-database-check', count: 1,  statusCode: null,  link: '/conversations'}
                    ], 
                    launcherActions: [
                        { title: 'View Integrations', color:null, icon: 'mdi-folder-outline', link: '/integrations'},
                        { title: 'Start New Integration', color:null, icon: 'mdi-folder-plus-outline', link: '/cards'},
                    ],
                    //if is not live
                    promoDescription:  "<p>When integrations aren’t done right, they wreak havoc on your system, create more work for you and give you useless reports. Our connectors that work with over 100 systems are pre-built and fully customizable. There’s no such thing as a one-size-fits-all integration so go with our tailored approach that fits your specific needs and future-proofs your system.</p>",
                    promoCTAs:[],
                    promoActions:  [
                        { title: 'Request a demo', color:'secondary', icon: '', link: 'https://integrateiq.com/integrations/', isExternal: true },
                        { title: 'Learn More', color:'secondary', icon: '', link: 'https://integrateiq.com/integrations/', isExternal: true },
                        { title: 'Explore Integrations', color:'primary', icon: '', link: '/cards' , isExternal: false }
                    ],
                },
                {
                    name: "Integrity tool",
                    icon: "mdi-check-all",
                    productImgSrc: null,
                    description: null,
                    color: "accent",
                    categories: ["Data Integrations"],
                    //if is live
                    isLive: 0,
                    additionalLinks: [],
                    notifications: [], 
                    launcherActions: [],
                    //if is not live
                    promoDescription:  "<p>When integrations aren’t done right, they wreak havoc on your system, create more work for you and give you useless reports. Our connectors that work with over 100 systems are pre-built and fully customizable. There’s no such thing as a one-size-fits-all integration so go with our tailored approach that fits your specific needs and future-proofs your system.</p>",
                    promoCTAs:[
                        { title: 'What Is the Integrity tool?', description: '', icon:'mdi-play-circle', url:'https://play.vidyard.com/wmNeRogV5s6e5MCeAnP9i9.jpg', urlID:'wmNeRogV5s6e5MCeAnP9i9', cilck() { this.modalVideo() }}
                    ],
                    promoActions:  [
                        { title: 'Request a demo', color:'secondary', icon: '', link: 'https://integrateiq.com/integrations/', isExternal: true },
                        { title: 'Learn More', color:'secondary', icon: '', link: 'https://integrateiq.com/integrations/', isExternal: true },
                        { title: 'Begin Using Now', color:'primary', icon: '', link: '/cards' , isExternal: false }
                    ],
                },
                {
                    name: "Global Settings",
                    icon: "mdi-cogs" ,
                    productImgSrc: null,
                    description: "Get here for global smart ramp apps and configuration",
                    color: null,
                    categories: ["Smart Ramp"],
                    //if is live
                    isLive: -1,
                    additionalLinks: null,
                    notifications: null,
                    launcherActions: [
                        { title: 'Configure Smart Ramp', color:'primary', icon: 'mdi-play', link: '/settings'}
                    ],
                    //if is not live
                    promoDescription:  " <p>Hi <b>I'm html</b></p>",
                    promoCTAs:[],
                    promoActions:  [],
                }
            ]
        }
    },
    computed: {
        launchItemsLive(){
             var array = this.launchItems.filter( x => x.isLive === 1);
             return array;
        },
        launchItemsAvailable(){
             var array = this.launchItems.filter( x => x.isLive === 0);
             return array;
        },
        launchItemsWIP(){
             var array = this.launchItems.filter( x => x.isLive === -1);
             return array;
        }
    },
    watch: {
        isMobile: {
            handler: function(v){
                if(v){
                    this.panels = [0];
                }
                else{
                    this.panels = [0,1,2];
                }
            }
        }
    },
    beforeDestroy () {
      if (typeof window === 'undefined') return
      window.removeEventListener('resize', this.onResize, { passive: true })
    },
    mounted () {
      this.onResize()
      window.addEventListener('resize', this.onResize, { passive: true })
    },
    methods: {
        onResize () {
            this.isMobile = window.innerWidth < 600
        },
    },
}
</script>
<style lang="scss" scoped>
.card-branded *{
    position: relative;
}
.card-branded .card-branded-overlay{
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: auto;
        position: absolute;
}

.card-branded .card-branded-overlay::before{
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        position: absolute;
        background-image: url('../../../assets/images/logo-color-lg.png');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: 150px 50%;
        background-blend-mode:saturation;
        opacity: 0.1;
        filter: grayscale(0.66) drop-shadow(-50px 25px 10px rgba(245, 8, 134, 0.33));
    }
    

</style>    