<template>
    <v-row style="height: 100%;" class="registration-wrapper">
        <v-col md="4" lg="3" cols="12"  class="d-flex flex-column justify-space-between registration-left" >
            <div class="registration-logo text-center py-6">
                    <v-img 
                    transition="scale-transition"
                    src="../assets/images/logo-white.png"
                    :max-width="this.$vuetify.breakpoint.smAndDown ? '150' : '250'"
                    
                    class="ml-auto mr-auto"
                    >
                    </v-img>
            </div>
            <div class="registration-info white--text" :class="this.$vuetify.breakpoint.smAndDown ? 'text-center':''">
                <span :class="this.$vuetify.breakpoint.smAndDown ? 'text-h4':'text-h2'" class="font-weight-light mb-4 d-block" v-if="StepNameTitle">{{  StepNameTitle }}</span>
                <span :class="this.$vuetify.breakpoint.smAndDown ? 'body-2':'text-h5'" class="font-weight-thin d-block" v-if="StepSubText1">{{ StepSubText1 }}</span>
                <span :class="this.$vuetify.breakpoint.smAndDown ? 'body-2':'text-h5'" class="mt-4 font-weight-thin d-block" v-if="StepSubText2">{{ StepSubText2}}</span>
            </div>
            <div>
                    <v-img 
                        v-if="StepImage"
                        contain
                        transition="scale-transition"
                        :class="this.$vuetify.breakpoint.smAndDown ? 'img-to-bg': ''"
                        :src="getImgUrl(StepImage)"
                        max-width="450"
                        class="ml-auto mr-auto"
                        >
                    </v-img>

                <v-stepper
                flat
                alt-labels
                class="registration-stepper bg-transparent"
                :class="this.$vuetify.breakpoint.smAndDown ? 'mt-3' : 'mt-12'"
                v-model=" Step"
                >
                    <v-stepper-header class="d-flex flex-row flex-nowrap ">
                        <v-stepper-step color="primary" v-for="step in steps" v-bind:key="step.id" :step="step.id" :complete="Step > step.id" class="pa-0">
                        </v-stepper-step>
                    </v-stepper-header>
                </v-stepper>
           </div>
        </v-col> 
        <v-col cols="12" offset="0" md="6" offset-md="1"  lg="7" offset-lg="1" class="d-flex flex-column justify-center registration-main">
            <router-view></router-view>
        </v-col>
    </v-row>
</template>
<script>
export default {
    data () {
      return {
            darkmode: false,
            steps:[ 
                {id:'1', name:"Step1", nameTitle: 'Did you know?', subText1: 'Click Rates on SMS are over 400% higher than email. With Message IQ, you can track performance of your SMS campaigns to optimize them just like you would your email campaigns.', subText2: "", route:'/registration/MessageIQ/step1', stepImage: 'company-info'},
                {id:'2', name:"Step2", nameTitle: 'Did You Know?', subText1: 'Your IQ Number is your new smart phone number. We will provision a local phone number in your area code to send and receive texts (and calls) on your behalf.', subText2: "", route:'/registration/MessageIQ/step3', stepImage: 'area-code'},
                {id:'3', name:"Step3", nameTitle: 'The Most Advanced SMS Integration with HubSpot', subText1: 'Our philosophy is simple: if you can do it with email, you should be able to do it with SMS. This means adding it to your workflows, tracking clicks, replies, and deliverability, and just sending a one-to-one message.', subText2: "", route:'/registration/MessageIQ/step6', stepImage: 'hubspot-integration'},
            ],
            Step: null,
            StepPosition: null,
            StepName: null,
            StepNameTitle: null,
            StepSubText1: null,
            StepSubText2: null,
            StepRoute: null,
            StepImage: null,
            // {id:'4', name:"Step4", title: 'Did you know?', subText1: 'In a survey of over 200,000 people, a major healthcare brand asked their customers how they’d prefer to receive communication. 49% said they preferred to receive SMS over email.', subText2: "", route:'/setup/step4', stepImage: 'messageiq-number'},
      }
    },
    methods: {
         getImgUrl(imgFile) {
            var images = require.context('../assets/images/', false, /\.png$/)
            return images('./' + imgFile + ".png")
        },
        getPageIndex(stepName){
            var stepIndex = this.steps.map(x => x.name).indexOf(stepName);
            this.Step = stepIndex + 1;
            this.StepPosition = stepIndex;
            if(stepIndex != -1) 
            {
                this.StepNameTitle = this.steps[stepIndex].nameTitle;
                this.StepSubText1 = this.steps[stepIndex].subText1;
                this.StepSubText2 = this.steps[stepIndex].subText2;
                this.StepRoute = this.steps[stepIndex].route;
                this.StepImage = this.steps[stepIndex].stepImage;
            }
        }
    },
    watch: {
        $route (to, from){
            this.getPageIndex (to.name);
        },
        StepPosition: function(newValue, oldValue){
            if(newValue != -1) 
            {
                this.StepNameTitle = this.steps[newValue].nameTitle;
                this.StepSubText1 = this.steps[newValue].subText1;
                this.StepSubText2 = this.steps[newValue].subText2;
                this.StepRoute = this.steps[newValue].route;
                this.StepImage = this.steps[newValue].stepImage;
            }
        }
    },
    created(){
        var stepIndex = this.steps.map(x => x.name).indexOf(this.$route.name);
        this.Step = stepIndex + 1;
        this.StepPosition = stepIndex;
    },

    computed: {
        currentRouteName() {
            this.StepName = this.$route.name; 
            return this.$route.name
        },
        currentRoutePath() {
            return this.$route.path
        },
        pageIndex() {
            var stepIndex = this.steps.map(x => x.name).indexOf(this.StepName);
            this.Step = stepIndex + 1;
            this.StepPosition = stepIndex;

            return stepIndex;
        },
        setThemeBySize(){
            if( this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm' ) {
                this.darkmode = true;
                this.$vuetify.theme.dark = this.darkmode;
            }else{
                this.darkmode = false;
                this.$vuetify.theme.dark = this.darkmode;
            }
            return this.darkmode
        }
    },

}
</script>
<style lang="scss" scoped>

</style>