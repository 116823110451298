<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="10" offset-md="1">
        <v-card :dark="$vuetify.breakpoint.smAndDown" flat class="theme-breakpoint-card-switch">
          <v-card-title class="d-flex justify-center  title-word-break text-center align-center font-weight-bold" :class="$vuetify.breakpoint.smAndDown ? 'text-h4':'text-h1'">
            Complete Registration
          </v-card-title>
          <v-card-subtitle class="d-flex justify-center text-center align-center text-h6 mt-2 mb-8"> 
            Smart Ramp - {{ formatIQName(productName) }}  Account 
          </v-card-subtitle>
          <v-card-text>
            <v-form v-model="valid">
              <v-text-field
                outlined
                v-model="company.companyName"
                label="Company Name*"
                required
                :rules="requiredRule.select"
              ></v-text-field>
              <v-text-field
                outlined
                v-model="company.companyURL"
                label="Company URL*"
                :rules="requiredRule.urlRules"
              ></v-text-field>
              <v-text-field
                outlined
                v-model="company.userPhone" 
                v-mask="phoneMask"
                label="Phone number*"
                :rules="requiredRule.phoneRules"
              ></v-text-field>
           
              <v-checkbox v-model="company.agreedTerms" class="align-checkbox-start">
                <template v-slot:label>
                  <div>
                    By checking here you agree to receive periodic messages from Smart Ramp related to your account and other products. Message and data rates may apply. You can reply “STOP” to mobile messages at any time to unsubscribe or reply “HELP” for help. Check out our                 
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          href="https://messageiq.io/terms-of-service-2/"
                          @click.stop
                          v-on="on"
                        >
                          Terms and Conditions
                        </a>
                      </template>
                      Opens in new window
                    </v-tooltip>
                     and 
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <a
                          target="_blank"
                          href="https://messageiq.io/privacy-policy-2/"
                          @click.stop
                          v-on="on"
                        >
                          Privacy Policy
                        </a>
                      </template>
                      Opens in new window
                    </v-tooltip>
                  </div>
                </template>
              </v-checkbox>
          
              <v-btn block :disabled="!valid" :loading="loading" x-large color="primary" @click="submit()"> Complete Registration </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
        
      </v-col>
    </v-row>
   
  </v-container>
</template>
<script>
    import { mapGetters } from 'vuex';
    export default {
        name: "CompleteRegistration",
        data: () => ({
            valid: false,
            checkbox: false,
            loading: false,
            agreePhoneUsage: false,
            phoneMask: '(###) ###-####',
            company:{
                companyName: '',
                companyURL: '',
                userPhone: null,
                agreedTerms: false, 
                agreementTermsUrl: 'http://www.vuetify.com/terms'
            },
            requiredRule: {
                required: v => !!v || 'Required.',
                urlRules: [
                        v => (v && v.length >= 3) || 'URL must have more than 3 characters',
                        v => (v && v.length <= 256) || 'URL must be less than 256 characters',
                        v => !!v || "Required.",
                        /* eslint-disable-next-line no-useless-escape*/
                        v => /^[-a-zA-Z0-9:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9():%_\+.~#?&//=]*)$/.test(v) || 'URL must be valid', 
                    ],
                select: [(v) => !!v || "Required."],
                phoneRules : [
                  v => !!v || "Required.",
                  /* eslint-disable-next-line no-useless-escape*/
                  v => /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/.test(v) || 'Phone number must be valid',
                ]
            },

        }),
        computed:{
            ...mapGetters({
                productName: 'productRegistration/getProductName'
            }),
        },
        created() {
          this.$auth.auth0ClientPromise.then(async () => {
            await this.$store.dispatch('getUserCompany');
          });
        },
        methods: {
          async submit(){
            this.loading = true;
            await this.$store.dispatch('productRegistration/setCurrentCompany', this.company);
            const result = await this.$store.dispatch('productRegistration/registerCompanyProduct', this.$route.params.id);

            self.loading = false;
            
             if(result){
               this.$router.push(`../plan-selection/${this.$route.params.id}`);
             }else{
                //TODO:SHOW ERROR MESSAGE
             }
          },
        }

    }
</script>
<style lang="scss">
.v-input--checkbox.align-checkbox-start{
  .v-input__slot{
    display: flex;
    align-items: flex-start;
  }
}
</style>