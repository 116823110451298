<template>
    <container>
        <v-card>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="searchPerson"
                    append-icon="mdi-magnify"
                    label="Search by user"
                    @click:clear="clearSearch"
                    @change="searchByPerson"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="tableData"
                dense
            >
                <template v-slot:item="row">
                    <tr>
                        <td>{{row.item.clientName}}</td>
                        <td>{{row.item.usersNumber}}</td>
                        <td>{{formatDateTime(row.item.lastLoginDate)}}</td>
                        <td>{{row.item.hubSpotConnection}}</td>
                        <td>{{row.item.accountType}}</td>
                        <td>{{row.item.canSendMessages ? "Enable" : "Disable"}}</td>
                        <td>{{row.item.agencyName}}</td>

                        <td>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    outlined
                                    x-small
                                    color="primary"
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                        Actions <v-icon small>mdi-chevron-down</v-icon>
                                    
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-for="(action, index) in filteredActions(row.item)"
                                        :key="index" 
                                        @click="callAction(row.item,action.action)"
                                    >
                                        <v-list-item-title v-if="action.action == 'login' && currentCompany.id === row.item.companyId" disabled>Logged In</v-list-item-title>
                                        <v-list-item-title v-else-if="action.action === 'enableSms' && row.item.canSendMessages === true">Disable SMS</v-list-item-title>
                                        <v-list-item-title v-else>{{ action.title }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog v-model="dialog" scrollable max-width="360px">
            <v-card>
                <v-card-title>Select Agency</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 300px;">
                    <v-radio-group v-model="selectedAgency" column>
                        <v-radio
                            v-for="agency in agencyList"
                            :key="agency.value"
                            :label="agency.label"
                            :value="agency.value"
                        ></v-radio>
                    </v-radio-group>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="primary" text @click="dialog = false">Close </v-btn>
                    <v-btn color="primary" text @click="updateRelationship()"> Update agency </v-btn>
                    <v-btn  v-if="hasAgency" color="primary" text @click="removeAgencyRelationship()"> Remove agency </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </container>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
    name: "AdminReport",
    computed: {
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany',
            userRole: 'getUserRole'
        }),
        headers() {
        return [
            {
                text: "Client Name",
                align: "start",
                value: "clientName",
                filter: this.nameFilter,
            },
            {
                text: "Total Users",
                value: "usersNumber"
            },
            {
                text: "Last Login Date",
                value: "lastLoginDate"
            },
            {
                text: "HubSpot Connection",
                value: "hubSpotConnection"
            },
            {
                text: "Account Type",
                value: "accountType"
            },
            {
                text: "Send SMS",
                value: "canSendMessages",
                sortable: false,
                filterable: false
            },
            {
                text: "Agency",
                value: "agencyName",
                sortable: false,
                filterable: false
            },
            {
                text: "Actions",
                value: "companyId"
            }
        ]
      },
    },
    data(){
        return{
            search: '',
            searchPerson: '',

            allData: [],
            tableData: [],
            actions: [
                { title: 'Login', action: 'login' },
                { title: 'Mark As Spam', action: 'markAsSpam' },
                { title: 'Enable SMS', action: 'enableSms' },
                { title: 'Delete', action: 'delete' },
                { title: 'Delete SMS Queue', action: 'deleteQueue' },
                { title: 'Associate to Agency', action: 'associateToCompany' }
            ],
            agencyList: [],
            dialog: false,
            selectedAgency: null,
            hasAgency: false,
            selectecCompany: null
        }
    },
    created(){
        this.$auth.auth0ClientPromise.then(async () => {
            if(Object.keys(this.currentCompany).length === 0){
                await this.$store.dispatch('getUserCompany');
                
                if(this.userRole === "SuperAdmin"){
                    this.getAdminReportData();
                }else{
                    this.$router.push("/");
                }
            }else{
                if(this.userRole === "SuperAdmin"){
                    this.getAdminReportData();
                }else{
                    this.$router.push("/");
                }
            }
        });
    },
    methods: {
        filteredActions(item) {
            return this.actions.filter(action => {
                if (action.action === 'associateToCompany' && item.isAgency) {
                    return false;
                }
                return true;
            });
        },
        formatDateTime(dt){
            if(dt !== null){
                return moment(dt).format("MM/DD/YYYY hh:mm:ss")
            }
            return "";
        },
        async callAction(company,action){
            if(action == 'login'){
                await this.$store.dispatch('impersonateUser',company.companyId);
                this.$router.push('/');
            }else if(action == 'markAsSpam'){
                this.$swal({
                    title: `Are you sure you want to mark ${company.clientName} as spam? This will blacklist their HubSpot Portal ID and all email domains associated with this account.`,
                    icon: 'waring',
                    showDenyButton: true,
                    confirmButtonText: "Yes",
                    denyButtonText: "No"
                }).then(async result => {
                    if(result.isConfirmed){
                        await this.$store.dispatch('company/markCompanyAsSpam',company.companyId);
                        this.$swal({
                            icon: 'success',
                            title: 'Company Marked As Spam',
                            text: `${company.clientName} has been marked as a spammer. It is now safe to delete the account`,
                            toast: true,
                            timer: 3000,
                            position: 'top-end'
                        });
                    }
                });
            } else if (action == 'enableSms') {
                this.enableSms(company);
            }else if(action == 'delete'){
                this.$swal({
                    title: `Are you sure you want to delete this company?`,
                    icon: 'waring',
                    showDenyButton: true,
                    confirmButtonText: "Yes",
                    denyButtonText: "No"
                }).then(async result => {
                    if(result.isConfirmed){
                        await this.$store.dispatch('company/deleteCompanyById',company.companyId);
                        this.getAdminReportData();
                        this.$swal({
                            icon: 'success',
                            title: 'Successfully Delete Company',
                            text: 'Company has been successfully deleted',
                            toast: true,
                            timer: 3000,
                            position: 'top-end'
                        });
                    }
                });
            }else if(action == 'deleteQueue'){
                this.$swal({
                    title: `Are you sure you want to delete this company messages queue?`,
                    icon: 'waring',
                    showDenyButton: true,
                    confirmButtonText: "Yes",
                    denyButtonText: "No"
                }).then(async result => {
                    if(result.isConfirmed){
                        await this.$store.dispatch('company/deleteCompanyQueueById',company.companyId);
                        this.getAdminReportData();
                        this.$swal({
                            icon: 'success',
                            title: 'Successfully Delete Company messages queue',
                            text: 'Company messages queue has been successfully deleted',
                            toast: true,
                            timer: 3000,
                            position: 'top-end'
                        });
                    }
                });
            }else if(action == 'associateToCompany'){
                this.dialog = true;
                this.selectedAgency = null;
                this. hasAgency = false;
                this.selectecCompany = null;
                this.selectecCompany = company;
                if(company.agencyId != '00000000-0000-0000-0000-000000000000'){
                    const agencyToSelect = this.agencyList.find(agency => agency.value === company.agencyId);
                    if (agencyToSelect) {
                        this.selectedAgency = agencyToSelect.value;
                        this.hasAgency = true;
                    }
                }
            } 
        },

        nameFilter(value) {
            // If this filter has no value we just skip the entire filter.
            if (!this.search) {
            return true;
            }

            // Check if the current loop value (The dessert name)
            // partially contains the searched word.
            return value.toLowerCase().includes(this.search.toLowerCase());
        },

        async getAdminReportData() {
            let adminReportData = await this.$store.dispatch("admin/getAdminReport");
            this.tableData = adminReportData;
            this.allData = adminReportData;

            this.agencyList = adminReportData.filter(item => item.isAgency === true).map(item => ({
                label: item.clientName, 
                value: item.companyId, 
            }));
        },

        async searchByPerson(){
            let newData = [];

            if (this.searchPerson !== '') {
                let users = await this.$store.dispatch('admin/getCompaniesByUser',{search: this.searchPerson.toLowerCase()})

                this.allData.map((row => {
                    if(users.find((user) => user.companyId == row.companyId))newData.push(row);
                }))

                this.tableData = newData;

            } else {
                this.getAdminReportData();
            }

        },
        clearSearch() { 
            this.searchPerson = '';
            this.getAdminReportData();
        },
        enableSms(company) {
            const sendSms = company.canSendMessages;
            this.$swal({
                title: sendSms 
                    ? `Are you sure?`
                    : `Are you sure?`,
                text: sendSms 
                    ? `Do you want to prevent ${company.clientName} from sending messages? This will block the company's ability to send messages.`
                    : `Do you want to allow ${company.clientName} to send messages? This allows companies with no registered or active campaigns to send messages.`,
                icon: 'waring',
                showDenyButton: true,
                confirmButtonText: "Yes",
                denyButtonText: "No"
            }).then(async result => {
                if(result.isConfirmed){
                    let success = await this.$store.dispatch('company/enableSms',company.companyId);
                    if (success) {
                        const companyToUpdate = this.tableData.find(row => row.companyId === company.companyId);
                        if (companyToUpdate) companyToUpdate.canSendMessages = !companyToUpdate.canSendMessages;

                        this.$swal({
                            icon: sendSms ? 'warning' : 'success',
                            title: sendSms ? 'SMS disabled' : 'SMS enabled',
                            text: sendSms 
                                ? `${company.clientName} cannot send messages.`
                                : `${company.clientName} can send messages.`,
                            toast: true,
                            timer: 5000,
                            position: 'top-end'
                        });
                    } else {
                        this.$swal({
                            icon: 'error',
                            title: 'Ops an error has occurred',
                            text: sendSms 
                                ? `Error occurred while disabling ${company.clientName} messages.`
                                : `Error occurred while enabling ${company.clientName} messages.`,
                            toast: true,
                            timer: 5000,
                            position: 'top-end'
                        });
                    }
                }
            });
        },
        async updateRelationship(){

            this.$swal({
                    title: `Are you sure you want to associate this company with the agency?`,
                    icon: 'waring',
                    showDenyButton: true,
                    confirmButtonText: "Yes",
                    denyButtonText: "No"
            }).then(async result => {
                if(result.isConfirmed){
                    await this.$store.dispatch('admin/updateAgencyRelationship',{companyId:this.selectecCompany.companyId,agencyId:this.selectedAgency});
                    this.getAdminReportData();
                    this.$swal({
                        icon: 'success',
                        title: 'Successfully Associate Company',
                        text: 'Company has been successfully associated',
                        toast: true,
                        timer: 3000,
                        position: 'top-end'
                    });
                }
            });

            this.dialog = false;
        },
        async removeAgencyRelationship(){
            this.$swal({
                    title: `Are you sure you want to remove this association?`,
                    icon: 'waring',
                    showDenyButton: true,
                    confirmButtonText: "Yes",
                    denyButtonText: "No"
            }).then(async result => {
                if(result.isConfirmed){
                    await this.$store.dispatch('admin/removeAgencyRelationship',{companyId:this.selectecCompany.companyId});
                    this.getAdminReportData();
                    this.$swal({
                        icon: 'success',
                        title: 'Successfully Remove Association',
                        text: 'Association has been successfully removed',
                        toast: true,
                        timer: 3000,
                        position: 'top-end'
                    });
                }
            });
            this.dialog = false;

        }
    }
}
</script>