import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"pa-0",attrs:{"fluid":""}},[_c(VCard,{attrs:{"flat":""}},[_c(VToolbar,{attrs:{"dark":"","color":"iq_darkerblue","app":""}},[_c(VToolbarTitle,{staticClass:"display-3 mb-1"},[_c('div',{staticClass:"d-flex flex-column"},[_vm._v(" Uptime - MIQ Applications "),_c('span',{staticClass:"caption"},[_vm._v("Check our apps unbeatable stability during the last 30 days")])])]),_c(VToolbarItems)],1)],1),_c(VContainer,_vm._l((_vm.apps),function(app){return _c('div',{key:app.name},[_c('UpTimeBot',{attrs:{"icon":app.icon,"color":app.color,"name":app.name,"entries":app.entries}})],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }