<template>
    <v-container >
              <v-row > 
                  <v-col cols="4" class="pr-0">    
                    <v-text-field
                        clearable
                        v-model="search"
                        prepend-inner-icon="mdi-magnify"
                        label="Search Templates"
                        single-line
                        solo
                        hide-details="true"
                        flat
                    ></v-text-field>
                    <v-divider></v-divider>
                    <v-list 
                    dense
                    id="scroll-target"
                    style="max-height: 100%"
                    class="overflow-y-auto list-editor border-right"
                    :height="window.height - this.appBarHeight - this.settingsHeadingElement - this.dividerHeight - 12  - this.conversationsNavFilterElement - 12">
                        <v-subheader class="body-1">Existing Templates: </v-subheader>
                        <v-list-item-group color="primary" dense>
                         <v-list-item 
                            class="chat-bubble left d-flex flex-column"
                            v-for="(template) in searching"
                            :key="template.id"
                            @click="editTemplate(template)"
                            hint="Keep a short yet significative name."
                        >
                            <v-list-item-title class="overline pb-1">
                            {{template.name}}
                            </v-list-item-title>
                            <v-list-item-content v-html="template.value" class="body-2">
                            </v-list-item-content>
                        </v-list-item>
                        </v-list-item-group>

                    </v-list>
                  </v-col>
                  <v-col cols="8" class="pl-0 pb-0">
                    <v-card elevation="1" class="fill-height"               
                    :min-height="window.height - this.appBarHeight - this.settingsHeadingElement - this.dividerHeight - 12  - this.conversationsNavFilterElement - 12">
                        
                            <v-card-title>
                                <h3 v-if="!selectedTemplate.id">Create New Template</h3>
                                <h3 v-else>Edit Template</h3>
                            </v-card-title>
                            <v-card-text class="pb-10">
                                <v-form  v-model="templateValid" id="templateForm" ref="templateForm" lazy-validation>
                                    <v-row class="d-flex flex-column justify-space-between" style="height: 100%">
                                        <v-col class="flex-grow-1" >
                                            <v-text-field
                                                outlined
                                                label= "Template Name"
                                                v-model="selectedTemplate.name"
                                                @change="dirtyForm"
                                                @keydown="[templateObjHasChanged, dirtyForm]"
                                                :rules="[templateRules.required]"
                                            ></v-text-field>
                                            <div class="chat-bubble-override chat-bubble-override-div left theme--light mb-5">
                                                <label>Template Message body:</label>
                                                <div                               
                                                    ref="me"
                                                    class="pa-4 text-iq_dark font-weight-medium"
                                                    style="height: 100%; overflow-y: scroll; max-height: 105px;"
                                                    contenteditable = "true"
                                                    @input.stop="handleInput"
                                                    v-html="selectedTemplate.value"
                                                    @keypress.stop="handleKeyPress"
                                                    @mouseup="handleMouseUp"
                                                >
                                                </div>
                                                <v-divider></v-divider>
                                                <v-menu
                                                    v-model="menu"
                                                    :close-on-content-click="true"
                                                    :nudge-width="200"
                                                    offset-x>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            class="">
                                                            <v-icon >
                                                                mdi-emoticon-happy-outline
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <v-card>
                                                        <VEmojiPicker @select="selectEmoji" />
                                                    </v-card>
                                                </v-menu>
                                            </div>
                                            <v-file-input
                                                outlined
                                                prepend-icon=""
                                                accept=".jpeg,.png,.jpg,.gif"
                                                prepend-inner-icon="mdi-paperclip"
                                                v-model="selectedTemplate.imgUrl"
                                                chips
                                                label="Upload Image (optional)"
                                            ></v-file-input>
                                        </v-col>
                                    </v-row>
                            </v-form>
                            <v-autocomplete
                                v-model="hsTokenSelected"
                                :items="hsTokens"
                                placeholder="Hubspot Contact Token"
                                clearable
                                outlined
                                hide-details="true"
                                item-text="text"
                                item-value="name"
                                :append-icon=" hsTokenSelected === null ? '':'mdi-arrow-up-right'"
                                @change="addToken"
                                ref="hstoken"
                            >
                                <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                        <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                        <v-list-item-content>
                                        
                                            <v-list-item-title v-html="data.item.text" class="text-wrap text-bubble text-caption" ></v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                            <v-checkbox
                                class="mt-1"
                                v-model="checkUser"
                                label="Is it for personal use?"
                                hide-details
                            ></v-checkbox>
                        </v-card-text>
                        <v-card-actions>    
                            <v-footer absolute>
                                <v-row>
                                    <v-col cols="12" class="d-flex justify-space-between">
                                        <div>
                                            <!--TODO:  Need to improve on form checkups so that when a change is made it would show btn status inmediately -->
                                            <v-btn color="primary" v-if="!selectedTemplate.id" @click="saveTemplate" :disabled="!templateValid || !formState.formIsDirty || this.selectedTemplate.name === null || this.selectedTemplate.templateBody === null">
                                                <span>Save New Template</span>
                                            </v-btn>
                                            <v-btn v-else color="primary" @click="saveTemplate">
                                                <span>Save Edits</span>
                                            </v-btn>
                                            <v-btn color="primary"  @click="clearTemplate" outlined>Clear</v-btn>
                                        </div>
                                        <v-btn color="error" v-if="selectedTemplate.id" v-on:click="deleteTemplate"> Delete Selected </v-btn>
                                    </v-col>
                                </v-row>
                            </v-footer>
                        </v-card-actions>
                    </v-card>
                    
                  </v-col>
              </v-row>
    </v-container>
</template>
<script>
import { VEmojiPicker } from 'v-emoji-picker';
import { mapGetters } from 'vuex';
import Mark from 'markup-js';

export default {
    computed: {
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany',
            currentUser: 'getUser',
            companyTemplates: 'template/getCompanyTemplates'
        }),
        searching () {
            if (!this.search) return this.templates

            const search = this.search.toLowerCase()

            return this.templates.filter(template => {
            const text = template.templateBody.toLowerCase()
            const name = template.name.toLowerCase()

            return text.indexOf(search) > -1 ||  name.indexOf(search) > -1 
            })
        },
        editor() {
            return this.$refs.myQuillEditor.quill
        }
    },
    components: {
        VEmojiPicker
    },  
    data (){
        return {
            menu: false,
            hsTokens: [],
            hsTokenSelected: null,
            templates: [],
            template: '',
            templateFormat: '',
            context: {},
            search: '',
            selectedTemplate:{},
            templateValid: true,
            formState:{
                formIsDirty: false
            },
            templateRules: {
                required: v => !!v || 'Required.'
            },
            window: {
                width: 0,
                height: 0
            },
            checkUser: false,
            cursorPosition: 0
        }
    },
    async created() {
         this.$auth.auth0ClientPromise.then(async () => {
            if(Object.keys(this.currentCompany).length === 0){
                await this.$store.dispatch('getUserCompany');
                if(this.currentCompany.isHubSpotIntegrated){
                    await this.$store.dispatch('getUserCompany');
                    await this.getTemplates();
                    await this.getProperties();
                    this.setUpContext();
                    this.parseCompanyTemplates();
                }else{
                    this.$swal({
                        title: `Please connect to HubSpot to Send Message.`,
                        icon: 'error',
                        confirmButtonText: "Connect to HubSpot"
                    }).then(async result => {
                        if(result.isConfirmed){
                            this.$router.push("/settings/integrations");
                        }
                    });
                }
            }else{
                if(this.currentCompany.isHubSpotIntegrated){
                    await this.getTemplates();
                    await this.getProperties();
                    this.setUpContext();
                    this.parseCompanyTemplates();
                }else{
                    this.$swal({
                        title: `Please connect to HubSpot to Send Message.`,
                        icon: 'error',
                        confirmButtonText: "Connect to HubSpot"
                    }).then(async result => {
                        if(result.isConfirmed){
                            this.$router.push("/settings/integrations");
                        }
                    });
                }
            }
        });

        window.addEventListener('resize', this.handleResize);
        this.handleResize();

    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    watch: {
        selectedTemplate: {
            handler: function(v){
                return this.templateObjHasChanged();
            },
            deep: true
        }
    },
    methods: {
        handleInput() {
            // Update cursor position whenever input event is triggered
            const selection = window.getSelection();
            this.cursorPosition = selection.focusOffset;
        },
        handleMouseUp() {
        // Update cursor position whenever the user clicks inside the contenteditable div
            const selection = window.getSelection();
            this.cursorPosition = selection.focusOffset;
        },
        // selectEmoji(emoji) {
        //     this.selectedTemplate.value = this.$refs.me.innerHTML.replaceAll('&nbsp;',' ');
        //     if (this.selectedTemplate.value == undefined) {
        //         this.selectedTemplate.value = "";
        //     }

        //     // Insert the emoji at the cursor position
        //     this.selectedTemplate.value =
        //     this.selectedTemplate.value.slice(0, this.cursorPosition) + emoji.data +
        //     this.selectedTemplate.value.slice(this.cursorPosition);

        //     this.$refs.me.innerHTML = this.selectedTemplate.value;
        // },
        selectEmoji(emoji) {
            this.selectedTemplate.value = this.$refs.me.innerHTML;
            if(this.selectedTemplate.value == undefined) {
                this.selectedTemplate.value = "";
            }
            this.selectedTemplate.value = this.selectedTemplate.value + emoji.data;
            this.$refs.me.innerHTML = this.selectedTemplate.value;
        },
        editTemplate(template){
            if(template.userId != "00000000-0000-0000-0000-000000000000"){
                this.checkUser = true;
            }else{
                this.checkUser = false;
            }
            this.selectedTemplate = Object.assign({}, template);
            this.formState.formIsDirty = false;
            this.templateValid = true;
        },
        clearTemplate(){
            this.$refs.hstoken.reset();
            this.selectedTemplate ={};
            this.formState.formIsDirty = false;
            this.checkUser = false;
        },
        dirtyForm(){
            this.formState.formIsDirty = true;
        },

        async getTemplates(){
            await this.$store.dispatch('template/getCompanyTemplatesByCompanyId',{companyId:this.currentCompany.id,userId:this.currentUser.userId});
        },

        async saveTemplate(){
            this.selectedTemplate.value = this.$refs.me.innerHTML;

            let successText = "updated";
            let user = "00000000-0000-0000-0000-000000000000";
            if(this.checkUser){
                user = this.currentUser.userId;
            }
            //Check if New Template  
            if(!this.selectedTemplate.hasOwnProperty('id')){
                this.selectedTemplate.id = "00000000-0000-0000-0000-000000000000";
                successText = "created";
            }

             let response = await this.$store.dispatch('template/createOrUpdateTemplate',{
                 "template": this.selectedTemplate,
                 "templateFormat": this.swapTags(this.selectedTemplate.value, false),
                 "companyId": this.currentCompany.id,
                 "userId": user
             });

             if(response.result){
                 this.selectedTemplate.id = response.id;
                 await this.getTemplates();
                 this.clearTemplate();
                 this.$swal({
                 icon: 'success',
                 title: `Template ${this.capitalizeFirstLetter(successText)}`,
                 text: `Your template has been successfully ${successText}!`,
                 toast: true,
                 timer: 3000,
                 position: 'top-end'
                 });
            }else{
                this.$swal({
                    icon: 'error',
                    title: 'Error Updating Template',
                    text: 'An error occurred while updating the template. Please try again later.',
                });
            }
            this.parseCompanyTemplates();
        },

        async deleteTemplate(){
            this.$swal({
                title: `Are you sure you want to delete template: ${this.selectedTemplate.name}?`,
                showDenyButton: true,
                confirmButtonText: "Yes",
                denyButtonText: "No"
            }).then(async result => {
                if(result.isConfirmed){
                    let response = await this.$store.dispatch('template/deleteTemplate',{
                        "template": this.selectedTemplate,
                        "companyId": this.currentCompany.id
                    });
                    if(response){
                        await this.getTemplates();
                        this.clearTemplate();
                        this.$swal({
                            icon:  'success',
                            title: 'Template Successfully Deleted',
                            text:  'Template Successfully Deleted',
                            toast: true,
                            timer: 3000,
                            position: 'top-end'
                        });
                    }else{
                        this.$swal({
                            icon: 'error',
                            title: 'Error Deleting Template',
                            text: 'An error occurred while deleting the template. Please try again later.',
                            toast: true,
                            timer: 3000,
                            position: 'top-end'
                        });
                    }
                    this.selectedTemplate = {};
                    this.parseCompanyTemplates();
                }
            });
        },
        templateObjHasChanged(){
            this.$refs.templateForm.validate();
        },
        parseCompanyTemplates(){
            let templates = this.companyTemplates;
            templates.sort((a, b) => a.messageName.toLowerCase() > b.messageName.toLowerCase() ? 1 : -1);
            let formattedTemplates = [];
            templates.forEach(template => {
                let obj = {
                    "id": template.id,
                    "name": template.messageName,
                    "templateBody": template.messageText, 
                    "imgUrl": null,
                    "value": this.swapTags(template.messageText, true),
                    "userId": template.userId
                };
                if(template.fileName !== null){
                    this.$store.dispatch("template/convertBase64StringtoFile",template).then(file => {
                        obj.imgUrl = file;
                    });
                }
                formattedTemplates.push(obj);
            });
            this.templates = formattedTemplates;
        },

        swapTags(value, needTags){
            needTags 
                ? this.hsTokens.map(item => value = value.replace('{{' + item.name + '}}', '<tag contenteditable="false">' + item.text + '</tag>'))
                : this.hsTokens.map(item => value = value.replace('<tag contenteditable="false">' + item.text + '</tag>', ' {{' + item.name + '}} '))

            return value;
        },

        async getProperties(){
            this.hsTokens = [];
            let contactProperties = await this.$store.dispatch('company/getContactProperties', this.currentCompany.id);
            if(contactProperties != undefined){
            contactProperties.map(property  => {this.hsTokens.push({name: property.name, text: property.label})});
            }
        },

        setUpContext() {
            this.hsTokens.map(item => this.context[item.name] = '<tag contenteditable="false">' + item.text + '</tag>');
        },

        // addToken(){
        //     this.selectedTemplate.value = this.$refs.me.innerHTML.replaceAll('&nbsp;',' ');

        //     if(this.selectedTemplate.value !== undefined) {
        //         this.selectedTemplate.value = this.swapTags(this.selectedTemplate.value);
        //     }

        //     let selectedToken = this.hsTokens.find((item) => item.name == this.hsTokenSelected);
        //     selectedToken ? (this.selectedTemplate.value = this.selectedTemplate.value.slice(0, this.cursorPosition) +
        //     ' {{' + selectedToken.name + '}} ' + this.selectedTemplate.value.slice(this.cursorPosition)) : '';

        //     this.templateFormat = Mark.up(this.selectedTemplate.value, this.context);
        //     this.selectedTemplate.value = this.templateFormat;
        //     this.$refs.me.innerHTML = this.templateFormat;
        //     this.$refs.hstoken.reset();
        // },

        addToken(){
            this.selectedTemplate.value = this.$refs.me.innerHTML;

            if(this.selectedTemplate.value !== undefined) {
                this.template = this.swapTags(this.selectedTemplate.value);
            }

            let selectedToken = this.hsTokens.find((item) => item.name == this.hsTokenSelected); 
            selectedToken ? this.template += ' {{' + selectedToken.name + '}} ' : '';

            this.templateFormat = Mark.up(this.template, this.context);
            this.selectedTemplate.value = this.templateFormat;
            this.$refs.me.innerHTML = this.templateFormat;
            this.$refs.hstoken.reset();
        },

        handleKeyPress(e) {
            let value = e.target.innerHTML.replaceAll('<tag contenteditable="false">','').replaceAll('</tag>','').replaceAll('&nbsp;',' ');
        }
    }
}
</script>
<style lang="scss">
    tag{
    display: inline;
    border-radius: 3px;
    color: #fff;
    background-color: #1C62F7;
    opacity: 0.5;
    width: auto;
    flex: 0 0 auto !important;
    padding: 0 5px;
    margin: 0 2px;
    }
    .editable:hover:before {
        content: '✎';
        position: absolute;
        left: -5px;
    }
    .chat-bubble-override-div:focus-within{
        outline: 2px solid rgb(245,8,134);
    }

    [contenteditable]:focus {
        outline: none;
    }

</style>
