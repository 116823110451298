<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        max-width="350px"
        offset-x
        >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            class="pa-0 d-inline popover-trigger"
            elevation="0"
            :color="hintColor"
            x-small
            text
            fab      
            v-bind="attrs"
            v-on="on"
            :aria-label="'Hint.' + title + ',' + content"
            >   
                <v-icon>{{ icon }}</v-icon>
            </v-btn>
        </template>

            
        <v-card>
            <v-card-title tabindex="1" :aria-label="title">  <v-icon :color="hintColor" large>mdi-help-circle</v-icon>  {{ title }} </v-card-title>
            <v-divider></v-divider>
            <v-card-text tabindex="2" :aria-label="content">
                {{ content }}
            </v-card-text>
            <v-card-text v-if="html" v-html="html" class="pb-0">
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
            <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="menu = false"
                >
                    Ok
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>
<script>
export default {
    name: 'Popover',
    props: {
        title: String,
        content: String,
        html: String,
        icon: {
            type: String,
            default: 'mdi-help-circle'
        },
        hintColor: {
            type: String,
            default: 'iq_light'
        }
    },
    data: () => (
    {
      fav: true,
      menu: false,
      message: false,
      hints: true,
    }),
}
</script>