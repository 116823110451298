<template>
    <v-toolbar :height="this.conversationToolbarHeight" class="conversation-view-toolbar border-bottom" dense>
        <v-btn v-if="this.isMultiChat" class="ml-n4" :small="this.$vuetify.breakpoint.smAndDown" plain text icon @click="setConvesationListVisibility"> <v-icon >mdi-page-previous</v-icon> </v-btn>
        
        <v-list-item dense two-line class="conversation-recepient pl-0 mr-auto" style="flex: 0 1 auto;" v-if="currentChattRecepientrPhoneList.length === 1">
                <v-list-item-avatar class="my-0 mr-1">
                            <v-avatar
                                :size="!this.$vuetify.breakpoint.smAndDown ? '50' : ''"
                                class="font-weight-bold text-h5 "
                            >
                           <!-- {{currentChatInfo.firstName[0]}}{{currentChatInfo.lastName[0]}} -->
                           <span v-if="currentChatInfo.firstName !== ''">{{currentChatInfo.firstName[0]}}{{currentChatInfo.lastName[0]}}</span>
                            <v-icon v-else :color=" $vuetify.theme.dark ? 'iq_light--text' : 'iq_dark--text'"> mdi-account-outline </v-icon>
                            </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content class="d-inline-flex" v-if="currentChatInfo.firstName !== ''">
                    <v-list-item-title class="font-weight-medium">{{currentChatInfo.firstName}} {{currentChatInfo.lastName}}</v-list-item-title>
                    <v-list-item-subtitle class=""> {{ formatPhoneNumber(currentChatInfo.phone)}} </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content class="d-inline-flex" v-else>
                    <v-list-item-title class="font-weight-medium">{{currentChatInfo.phone}}</v-list-item-title>
                </v-list-item-content>
        </v-list-item>
        <v-select
            v-else
            class="conversation-phone compact"
            style="flex: 0 1 auto;"
            prepend-inner-icon="mdi-phone"
            v-model="ContactPhoneSelected"
            @change="setContactPhoneSelected"
            :items="currentChattRecepientrPhoneList"
            item-text="alias"
            item-value="alias"
            label="User Contact Phone"
            hide-details
            solo
            return-object
            :menu-props="{ top: false, offsetY: true }"
        >
            <template v-slot:selection="{item, userPhoneIndex}">
                <v-list-item two-line class="px-1">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{item.alias}}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{item.phone}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-slot:item="{active, item, attrs, on}">
                <v-list-item :input-value="active" dense v-on="on" v-bind="attrs" :default="{active}">
                    <v-list-item-content>
                        <v-row class="d-flex flex-nowrap" align="center" no-gutters>
                            <span class="pr-2">{{ item.alias }}</span>
                            <v-spacer></v-spacer>
                            <v-chip v-if="item.phone" text-color="white" color="secondary" small>
                                {{ item.phone}}
                            </v-chip>
                        </v-row>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-select>
        <v-spacer></v-spacer>
        
        <v-list-item dense two-line v-if="BussinessPhones.length === 1" class="flex-compact">
            <v-list-item-content>
                <v-list-item-title>{{BussinessPhones[0].alias}}</v-list-item-title>
                <!-- <v-list-item-subtitle> {{ formatPhoneNumber(BussinessPhones[0].phone)}}</v-list-item-subtitle> -->
            </v-list-item-content>
        </v-list-item>
        <v-select
        v-if="BussinessPhones.length > 1"
            class="conversation-phone compact" 
            style="flex: 0 1 auto;"
            prepend-inner-icon="mdi-phone"
            v-model="PhoneSelected"
            @change="setBussinessPhoneSelected"
            :items="BussinessPhones"
            item-text="alias"
            item-value="phone"
            label="Bussiness Role/Phone"
            hide-details
            solo
            return-object
            :menu-props="{ top: false, offsetY: true }"
        >
            <template v-slot:selection="{ item, phoneIndex}">
                <v-list-item  two-line class="px-1">                                
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.alias }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ formatPhoneNumber(item.phone)}}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-slot:item="{ active, item, attrs, on }">
                <v-list-item  :input-value="active" dense v-on="on" v-bind="attrs" :default="{ active }">
                    <v-list-item-content>
                        <v-row class="d-flex flex-nowrap" align="center" no-gutters>
                            <span class="pr-2">{{ item.alias }} </span>
                            <v-spacer></v-spacer>
                            <v-chip v-if="item.phone" text-color="white" color="secondary" small>
                                {{ formatPhoneNumber(item.phone) }}
                            </v-chip>
                        </v-row>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-select>
        <v-btn 
        :small="this.$vuetify.breakpoint.smAndDown"
        icon
        color="warning"
        @click="setFavorite"
        >
            <v-icon v-if="!isFavorite">mdi-heart-outline</v-icon>
            <v-icon v-else>mdi-heart</v-icon>
        </v-btn>
        <v-btn :small="this.$vuetify.breakpoint.smAndDown" icon @click.stop="setConversationDetailVisibility">
            <v-icon>mdi-badge-account-outline</v-icon>
        </v-btn>
        <v-menu offset-y close-on-click>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon small v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>    
            </template>
            <v-list>
                <v-list-item
                v-for="(action, actionIndex) in conversationActions"
                :key="actionIndex"
                @click="handleConversationMenuClick(actionIndex)"
                >
                <v-list-item-title>
                    <v-icon small> {{ action.icon}}</v-icon> 
                    {{ action.title }}
                </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>  

       

    </v-toolbar>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ConversationToolbar',
    props: {
        isMultiChat: {
            type: Boolean,
            default: true
        },
        isFavorite:{
            type: Boolean,
            default: false
        },
        currentUser: {
            type: Object,
            default: null
        },
        currentChattRecepientrPhoneList: {
            type: Array,
            default: null
        },
        currentChatInfo: {
            type: Object, 
            default:  null
        },
        BussinessPhoneSelected: {
            type: String,
            default: null
        },
        BussinessPhones: {
            type: Array,
            default: null
        },
        conversationsList: {
            type: Boolean,
            default: true
        }, 
        conversationDetail: {
            type: Boolean, 
            default: false
        },
        ContactSelected: {
            type: String,
            default: null
        },
        entries: {
            type: Array,
            default: null
        },
        chatEntries: {
            type: Array,
            default: null
        },
        isConversation:{
            type:Boolean,
            default: false
        },
        ContactPhoneSelected: null,
    },
    data () {
      return {
        PhoneSelected:"",
        read: false,
        conversationActions: [
            { title: 'Mark as unread.', icon: "mdi-eye", click() { this.markConversationAsRead()  }},
            { title: 'Close Conversation', icon: "mdi-archive", click() { this.archiveConversationModal() }},
        ],

      }
    },
    methods: {
        setConvesationListVisibility(){
            this.$emit("setConvesationListVisibility", !this.conversationsList);
        },
        setConversationDetailVisibility(){
            this.$emit("setConversationDetailVisibility", !this.conversationDetail);
        },
        setBussinessPhoneSelected(){
            this.$emit("setBussinessPhoneSelected", this.PhoneSelected);
        },
        setContactPhoneSelected(){
            this.$emit("setContactPhoneSelected", this.ContactPhoneSelected);
        },
        handleConversationMenuClick(index) {
            this.conversationActions[index].click.call(this)
        },
        setReadValue(){
            this.$emit("setReadValue", this.read);
        },
        markConversationAsRead(){
           let resultArchive = this.$store.dispatch('contact/markContactConversationAsReadOrUnread', {"id": this.ContactSelected, "read": this.read});
           if(resultArchive){
                    let conversation = null;
                    var contactIndex = this.entries.findIndex(x => x.id == this.ContactSelected);
                    if (contactIndex >= 0){
                        let count = 0;

                       if(this.read){
                            conversation = this.entries[contactIndex];
                            conversation.status= '',
                            conversation.pending = count;
                            conversation.color =  '#2196F3';
                            this.entries[contactIndex] = conversation;
                            this.read = false;
                            this.conversationActions[0].title = 'Mark as unread. ';
                    
                       }else{
                            if(this.chatEntries.length > 0){
                                this.chatEntries.forEach(element => {
                                    if(element.outbound == false){
                                        count++;
                                    }
                                });
                            }

                            if (count === 0) return;

                            conversation = this.entries[contactIndex];
                            conversation.status= 'new';
                            conversation.pending = count;
                            conversation.color =  '#f8f3f5'
                            this.entries[contactIndex] = conversation;
                            this.read = true;
                            this.conversationActions[0].title = 'Mark as read. ';
                       }

                       this.setReadValue();
                    }
                  
                }

        },
        archiveConversationModal(){
            this.$swal.fire({
                title: 'Are you sure you want archive this conversation?',
                icon: 'question',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Yes',
                denyButtonText: "No",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.archiveConversation();
                    }
                })
        },
        async archiveConversation(){
            let resultArchive = this.$store.dispatch('contact/archiveConversation',{"id": this.ContactSelected, "archive": true});
                if(resultArchive){
                    this.$swal({
                        icon: 'success',
                        title: 'Archived conversation',
                        text: 'Conversation successfully archived.',
                        toast: true,
                        timer: 3000,
                        position: 'top-end'
                    });

                    var contactIndex = this.entries.findIndex(x => x.id == this.ContactSelected);
                    if (contactIndex >= 0){
                        this.entries.splice(contactIndex, 1);
                        this.ContactSelected =  this.entries[0].id;
                    }
                }else{
                    this.$swal({
                        icon: 'error',
                        title: 'Error archiving conversation',
                        text: 'An error occurred while archiving the conversation. Please try again later.',
                        toast: true,
                        timer: 3000,
                        position: 'top-end'
                    });
                }
        },

        async setFavorite(){
            let result = true;
            if(this.isFavorite == true){
                result = false;
            } 

            await this.$store.dispatch('contact/setIsFavorite', {"id": this.currentSelectedContact, "isFavorite": result});
            this.isFavorite = result;
            this.$emit('setFavorite', this.isFavorite)

            let conversation = null;
            if (this.isConversation == false){
                var contactIndex = this.entries.findIndex(x => x.id == this.currentSelectedContact);
                if (contactIndex >= 0){
                    conversation = this.entries[contactIndex];
                    if(conversation != undefined){
                        conversation.isFavorite = result;
                        this.entries[contactIndex] = conversation;
                    }
                }
            }
        },
        setConversationAction() {
            this.read = false;
            this.conversationActions[0].title = 'Mark as unread. ';
        }
       
    },
    computed: {
        ...mapGetters({
            currentSelectedContact: 'contact/getCurrentSelectedContact'
        }),
        getBussinessPhoneSelected(){
            return this.PhoneSelected;
        }
    }
    
}
</script>