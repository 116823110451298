<template>
    <v-container >
        <v-row class="d-flex">
            <v-col cols="6"> 
                <v-textarea
                    flat
                    class="chat-bubble-override right pt-4 "
                    v-model="message"
                    v-on:input="onChangeMessage"
                    rows="5"
                    hint="Type a message to calculate"
                    persistent-hint
                    auto-grow
                >
                <template v-slot:message="{message,key}">
                    <span :id="key" class="ml-8 d-block text-center" v-html="message"></span>
                </template>
                </v-textarea>
                
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-x
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="mt-n14"
                        >
                            <v-icon >
                                mdi-emoticon-happy-outline
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <VEmojiPicker @select="selectEmoji" />
                    </v-card>
                </v-menu>
            </v-col>
            <v-col cols="6"> 
                <v-card-text>
                    <v-list class="transparent" >
                        <v-list-item class="min-height">
                            <v-list-item-title>Encoding Used: </v-list-item-title>
                            <v-list-item-subtitle class="text-right">
                                {{this.encoding}}
                            </v-list-item-subtitle>
                        </v-list-item>

                        <v-list-item class="min-height">
                            <v-list-item-title>Characters in SMS: </v-list-item-title>
                            <v-list-item-subtitle class="text-right">
                                {{this.characters}}
                            </v-list-item-subtitle>
                        </v-list-item>

                        <v-list-item class="min-height">
                            <v-list-item-title>Number of segments: </v-list-item-title>
                            <v-list-item-subtitle class="text-right">
                                {{this.segments}}
                            </v-list-item-subtitle>
                        </v-list-item>

                        <v-list-item class="min-height">
                            <v-list-item-title>Segment Length: </v-list-item-title>
                            <v-list-item-subtitle class="text-right">
                                {{this.length}}
                            </v-list-item-subtitle>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { VEmojiPicker } from 'v-emoji-picker';
export default {
    data (){
        return {
            message: '',
            encoding: 'GSM-7',
            characters: 0,
            segments: 0,
            length: 0,
            menu: false

        }
    },
    components: {
        VEmojiPicker
    }, 
    methods: {
        onChangeMessage(){
            this.characters = this.message.match(/./gu) ? this.message.match(/./gu).length : this.message.length;

            let isGsm = true;
            let gsm = "@£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞ^{}\\[~]|€ÆæßÉ!\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà\n ";

            for (var i = 0; i < this.message.length; i++) {
                if (gsm.indexOf(this.message.charAt(i)) === -1) isGsm = false;
            }

            this.encoding = isGsm ? 'GSM-7' : 'UCS-2';
            this.length = isGsm ? 160 : 70;

            this.segments = Math.ceil(this.characters / this.length);
        
        },

        selectEmoji(emoji) {
            if(this.message == undefined){
                this.message = "";
            }
            this.message = this.message + emoji.data;
            this.onChangeMessage();
        }
    }
}
</script>

<style lang="scss">
    .min-height{
        min-height: 26px;
    }
</style>