<template>

    <v-app>
      <v-main :class="currentRouteName">
            <v-container class="fill-height py-0" fluid>
                <slot />
            </v-container>
      </v-main>
    </v-app>


</template>

<script>
export default {
  name: "Standalone App",
  data () {
    return{
    }
  },
  computed: {
      currentRouteName() {
          return this.$route.name;
      }
  }
  
};
</script>