<template>
<v-card elevation="0">
    <CardView></CardView>
</v-card> 
</template>
<script>
import CardView from '../../components/SmartRamp/Dashboard/dashboardCardView.vue';

export default {
    components: {
        CardView
    }
}
</script>