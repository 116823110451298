<template>
    <v-container class="mt-n1 pt-0">
        <v-row class="py-3">
           <v-col cols="6">
                <div class="d-flex flex-row align-start my-3"> 
                    <v-icon class="xx-large">mdi-flash-outline</v-icon>
                    <div class="ml-2">
                        <h1 class="display-1"> Triggers </h1>
                        <h4 class="body-2">Select trigger that  will initialize your integration3</h4>
                    </div>
                </div>
                <p>Ea dolor ut duis esse occaecat nisi. Labore nulla officia minim est. Cillum fugiat aliqua enim dolor.
                    Labore nisi esse quis occaecat mollit magna Lorem quis eu eu proident Lorem irure. Nisi id nostrud dolor culpa incididunt magna id. Qui cupidatat cillum sit est cillum culpa velit. Nisi laborum dolore dolore do cillum excepteur occaecat in ipsum do aliquip proident enim culpa. Reprehenderit veniam consequat reprehenderit incididunt qui dolore amet irure tempor Lorem sint.
                </p>
           </v-col>
           <v-col cols="6">
                <Status :wizardState="this.status" :stepName="currentRouteName"></Status>
           </v-col>
        </v-row>
        <v-card rounded elevation="1" outlined>
            <v-expansion-panels focusable v-model="panel">
                <v-expansion-panel v-for="trigger in triggers" :key="trigger.id" class="mt-0">
                    <v-expansion-panel-header>
                        <span class="text-h6 flex-grow-0">{{trigger.name}} <span class="ml-2 font-weight-medium primary--text" v-if="trigger.isRequired">Required *</span></span>
                            <v-chip color="accent" class="flex-grow-0 ml-3" v-if="trigger.value !== undefined">
                                <v-icon>mdi-check</v-icon>
                                {{trigger.selected}}
                            </v-chip>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="mt-4">
                        <v-data-iterator
                            :items=" trigger.options"
                            :page.sync="triggersPage"
                            :search="triggersSearch"
                            :sort-by="triggersSortBy"
                            :sort-desc="triggersSortDesc"
                            :items-per-page="triggersItemsPerPage"
                            :footer-props="{
                                itemsPerPageOptions: [8,16,22,25,50,100, -1]
                            }">
                                <template v-slot:default="props">
                                <v-item-group v-model="trigger.value">
                                    <v-row>
                                        <v-col cols="6"  sm="4" md="3"  xl="2"  v-for="(item, optionIndex) in  props.items" :key="optionIndex" class="pa-1">
                                            <v-hover v-slot="{ hover }">
                                                <v-item v-slot="{ active, toggle }" @change="getSelectedTriggerName(item.optionName, trigger.id)">    
                                                    <v-card min-height="185px" max-height="185px" :elevation="hover ? 2 : 0" rounded outlined class="pa-0 d-flex flex-column flex-start align-start text-break justify-space-between lighten-5" :color="active ? 'info' : ''" @click="toggle">
                                                        <v-card-title class="d-flex justify-center" style="width: 100%">
                                                            <span class="text-uppercase font-weight-medium text-tight">{{item.optionName}} </span>
                                                        </v-card-title>
                                                        <v-card-text class="d-flex flex-column justify-center flex-grow-1 align-center overflow-y-auto pb-0">
                                                                <p>{{item.optionDescription}}</p>
                                                        </v-card-text> 
                                                        <!-- <v-card-actions class="d-flex justify-center" style="width: 100%">
                                                            <v-btn color="primary" @click.stop="showFullDescription" class="font-weight-medium" text v-if="item.showMore">More...</v-btn>
                                                        </v-card-actions> -->
                                                    </v-card>
                                                </v-item> 
                                            </v-hover>
                                        </v-col>
                                    </v-row>
                                </v-item-group>
                                </template>
                            </v-data-iterator>
                    </v-expansion-panel-content>

                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
        <v-row>
            
            <v-col> &nbsp;  
            </v-col>

        </v-row>
        <v-divider></v-divider>
        <v-footer color="iq_opaque" class="pt-4">
            <v-btn :disabled="this.triggers[0].value === undefined || this.triggers[1].value === undefined || this.triggers[0].selected === this.triggers[1].selected" to="/wizard/objects" large color="primary"> Continue </v-btn>
            <v-btn text large > Cancel </v-btn>
            <v-btn to="/wizard/" color="secondary" text large class="ml-auto" > Back </v-btn>
        </v-footer>

    </v-container>
</template>
<script>
import Status from "./_status.vue";

export default {
    components: {
        Status
    },
    props: ['status'],
    data () {
        return{
            panel: 0,
            triggers: [
                {id: '1', name: 'Triggger', isRequired: true, value: undefined, selected: null, 
                    options:[
                        {optionID: '1', optionName: 'Deal-Pipeline / Stage', optionDescription: 'Laborum mollit incididunt reprehenderit laborum .', showMore:true},
                        {optionID: '2', optionName: 'Life-Cycle / Stage', optionDescription: 'Eu ipsum duis mollit labore magna elit ipsum in.', showMore:true},
                        {optionID: '3', optionName: 'Lead Source', optionDescription: 'Minim cupidatat aliquip cupidatat Lorem nisi mollit nisi anim ex do.', showMore:true},
                        {optionID: '4', optionName: 'Close Date', optionDescription: 'Ex reprehenderit ad mollit non non fugiat adipisicing laborum.', showMore:true},
                        {optionID: '5', optionName: 'Other Date', optionDescription: 'Deserunt do cupidatat cillum ea sit minim voluptate irure fugiat pariatur.', showMore:true},
                    ]
                },
                {id: '2', name: 'When to Trigger', isRequired: true, value: undefined, selected: null, 
                    options:[
                        {optionID: '1', optionName: 'Exists', optionDescription: 'Lorem eiusmod sit consequat voluptate cupidatat incididunt ipsum magna.', showMore:true},
                        {optionID: '2', optionName: 'Is_Known', optionDescription: 'Ut mollit mollit dolor voluptate.', showMore:true},
                        {optionID: '3', optionName: 'Is Not Empty', optionDescription: 'Eiusmod velit est in exercitation laboris dolor sunt mollit mollit fugiat esse enim.', showMore:true},
                        {optionID: '4', optionName: 'Is Today', optionDescription: 'Veniam sint nisi nostrud anim nostrud voluptate laboris.', showMore:true},
                        {optionID: '5', optionName: 'Is Not Null', optionDescription: 'Aute in occaecat nostrud cillum nulla.', showMore:true},
                    ]
                }
            ],
            triggersSearch: '',
            triggersFiter: {},
            triggersOrderByKeys: [],
            triggersSortDesc: false, 
            triggersPage: 1, 
            triggersItemsPerPage: -1, 
            triggersSortBy: 'name',
        }
    },
    methods: {
        getSelectedTriggerName(elem, position){
            //sets the system title to the selection
            this.triggers[position-1].selected = elem;
            setTimeout(() => this.panel = this.panel + 1, 500);
            //set status to show on next step using Route props
            
            switch(position) {
                case '1':
                    this.status[2].value = elem;
                    break;
                case '2':
                    this.status[3].value = elem;
                    break;
            }
        }, 
        showFullDescription(){
            
        }
    },
    computed:{
        currentRouteName() {
            return this.$route.name;
        },
    }
   
}

</script>
<style lang="scss" scoped>
    .text-tight{
        padding-top: 3px;
        line-height: 1em;
        word-break: keep-all;
    }
</style>