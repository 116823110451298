<template>
    <v-container>
        <v-row>
            <v-col cols="6" >
                <v-card width="100%" outlined class=" flex-grow-1">
                    <v-card-text  class="text-center d-flex flex-column align-center justify-center">
                    <v-img 
                        position="top left"
                        :src="require('@/assets/images/integrations/' + systemA.src )"
                        contain
                        max-height="60px"
                        max-width="60px"
                    ></v-img>  
                    <h2 class="mb-3">{{systemA.title}} - Connection</h2>     
                    <v-btn v-if="!currentCompany.cloverConnected" color="primary" v-on:click="goToCloverConnect">Connect  {{systemA.title}}</v-btn>
                    <v-chip color="success" large v-else > <v-icon>mdi-check-network-outline </v-icon> {{systemA.title}} - Connected </v-chip>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="6" class="text-center d-flex flex-column align-center justify-center">
                <v-card width="100%" outlined class=" flex-grow-1">
                    <v-card-text  class="text-center d-flex flex-column align-center justify-center">
                        <v-img 
                            position="top left"
                            :src="require('@/assets/images/integrations/' + systemB.src )"
                            contain
                            max-height="60px"
                            max-width="60px"
                        ></v-img>  
                        <h2 class="mb-3">{{systemB.title}} - Connection</h2> 
                        <v-btn v-if="!currentCompany.cloverHubSpotConnected" color="primary" v-on:click="goToHubSpotConnect">Connect  {{systemB.title}}</v-btn>
                        <v-chip color="success" large v-else > <v-icon>mdi-check-network-outline </v-icon> {{systemB.title}} - Connected</v-chip>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        systemA: null,
        systemB: null,
    },
    computed: {
        ...mapGetters({
            currentCompany: 'clover/getCurrentCompany'
        })
    },
    data () {
        return{
            systemAConnected: true,
            systemBConnected: true,
        }
    },
    methods: {
        goToHubSpotConnect(){
            window.location.href = `${process.env.VUE_APP_API_URL}/api/HubSpotConnect/Clover?companyId=${this.currentCompany.id}`;
        },
        goToCloverConnect(){
            window.location.href = `${process.env.VUE_APP_API_URL}/api/Integrations/Clover/Auth/Connect`;
        }
    }
}
</script>