import store from '../store/index.js'
import productRegistration from '../store/modules/SmartRamp/productRegistration.js';

export const productRegGuard = async (to, from, next) => {
    const path = to.path;
    const queryParams = to.query;
    const urlParam = to.params.id;

    const state = store.state;
    const prodRegState = productRegistration.state;

    const regex = /\/setup\?id=\d+/;

    if(state.setupRoute && regex.test(state.setupRoute)) return next(state.setupRoute);

    const user = await store.dispatch('getUserCompanyAppsGuard');

    if(!validProduct(urlParam)) return next('/product/complete-registration/SmartRamp');
    state.productRegistration.productName = urlParam;

    if (user) {
        const userApps = user.companyApps;
        const planSelection = prodRegState.steps.planSelection;
        const payment = prodRegState.steps.payment;

        const planRoute = `/product/plan-selection/${urlParam}`;
        const paymentRoute = `/product/payment/${urlParam}`;
        const summaryRoute = `/product/summary/${urlParam}`;

        setUserStateVariables(state, user);

        // If the user came with the complete registration but they have company send them to plan-selection.
        if(path === `/product/complete-registration/${urlParam}`) return next(`/product/plan-selection/${urlParam}`);

        // If the user has the app send them to the config page of the product.
        if(hasProduct(urlParam, userApps) && path !== `/product/summary/${urlParam}`) return goToConfigurationPage(urlParam, next);

        await store.dispatch('productRegistration/getAppsSteps', formatIQName(urlParam));

        switch (path) {
            case planRoute:
                return next();
            case paymentRoute:
                // If plan selection not complete send them back to plan selection.
                if(!planSelection.complete) return next(planRoute);

                return next();
            case summaryRoute:
                // If plan selection not complete send them back to plan selection.
                if(!planSelection.complete) return next(planRoute);
                // If payment not complete send them back to payment.
                if(!payment.complete) return next(paymentRoute);
                
                return next();
        }
        return next();
    } else {
        await store.dispatch('productRegistration/getAppsSteps', formatIQName(urlParam));
        if(path !== `/product/complete-registration/${urlParam}`) {
            return next(`/product/complete-registration/${urlParam}`);
        }

        return next();
    }

};

const setUserStateVariables = (state, userData) => {
    state.user = userData.user;
    state.userRole = userData.userRole;
    state.company.currentCompany = userData.company;
    state.company.currentCompanyApps = userData.companyApps;
};

const formatIQName = (param) => {
    const parts = param.split("IQ");
    return parts.join(" IQ").trim();
}

const validProduct = (product) => {
    switch (product) {
        case "SmartRamp":
            return true;
        case "StatusIQ":
            return true;
        case "MessageIQ":
            return true;
        case "PriceIQ":
            return true;
        case "StockIQ":
            return true;
        default: 
            return false;

    }
}

const hasProduct = (product, userApps) => {
    switch (product) {
        case "SmartRamp":
            return true;
        case "StatusIQ":
            return userApps.statusIq;
        case "MessageIQ":
            return userApps.messageIq;
        case "PriceIQ":
            return userApps.priceIq; 
        case "StockIQ":
            return userApps.stockIq; 
    }
}

const goToConfigurationPage = (product, next) => {
    switch (product) {
        case "SmartRamp":
            return next('/');
        case "StatusIQ":
            return next('/statusiq');
        case "MessageIQ":
            return next('/conversations');
        case "PriceIQ":
            return next('/priceiq');
        case "StockIQ":
            return next('/stockiq');
    }
}