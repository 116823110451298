<template>
    <v-container class="mt-n1 pt-0">
        <v-row class="py-6">
            <v-col cols="12">
                <div class="d-flex flex-row align-start my-3">
                    <v-icon class="xx-large">mdi-file-sign</v-icon>
                    <div class="ml-2">
                        <h1 class="display-1">Summary and Approval</h1>
                        <h4 class="body-2">Check your selected options before continue</h4>
                    </div>                    
                </div>
                <p>  Et laborum enim dolore mollit ipsum Lorem aute ullamco nulla ullamco adipisicing deserunt.</p>
            </v-col>
        </v-row>
        <v-card  rounded elevation="1" outlined>
                <Status :wizardState="this.status" :stepName="currentRouteName" :isLarge="true"></Status>
        </v-card>

        <v-row>
            <v-col> &nbsp;  
            </v-col>

        </v-row>
        <v-divider></v-divider>
        <v-footer color="iq_opaque" class="pt-4">
            <v-btn  large color="primary"> Approve </v-btn>
            <v-btn text large > Cancel </v-btn>
            <v-btn to="/wizard/fields" color="secondary" text large class="ml-auto" > Back </v-btn>

        </v-footer>
    </v-container>
</template>
<script>
import Status from "./_status.vue";

export default {
    props: ['status'],
    components: {
        Status
    },
    data (){
        return {

        }
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        },        
    }
}
</script>