<template>
    <v-container class="d-flex align-center justify-center" >
        <v-card elevation="10" min-width="350px" max-width="450px" rounded outlined>
            <v-card-title class="primary iq_light--text" >Registration</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-6">
                <v-text-field
                    outlined dense
                    v-model="companyName"
                    label="Company Name"
                    :rules="[rules.required]"
                ></v-text-field>
                <v-text-field
                    outlined dense
                    v-model="companyUrl"
                    label="Domain"
                    :rules="[rules.required]"
                ></v-text-field>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="d-flex justify-space-between">
                <v-btn text>Cancel</v-btn>
                <v-btn color="primary" outlined :disabled="!companyName || !companyUrl">Register</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data () {
        return {
            merchantId: null,
            companyName: null,
            companyUrl: null,
            rules: {
                required: value => !!value || 'Required.',
            }
        }
    },
    mounted(){
        this.merchantId = this.$route.query.mid;
    },
    methods: {
        async submitRegistration(){
            let result = await this.$store.dispatch('clover/registerUser',{
                mid: this.merchantId,
                companyName: this.companyName,
                domain: this.companyUrl
            });
            if(result){
                this.$router.push(`clover?mid=${this.merchantId}`);
            }
        }
    }
}
</script>