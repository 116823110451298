<template>
    <div style="height: 100% !important">
        <v-overlay color="iq_dark" :value="loading">
            <v-icon color="iq_light" x-large>mdi-loading mdi-spin</v-icon>
            <span class="iq_light--text font-weight-bold ">Fetching HubSpot Price Lists...</span>
        </v-overlay>
        <v-container fill-height fluid>
            <v-row align="center"
                   justify="center">
                <v-col>
                
                    <v-card rounded outlined class="ma-auto pa-10" max-width="800">
                        
                        <v-text-field style="display: block !important"
                            label="Name"
                            v-model="listName"
                            required
                            hide-details="auto"
                        ></v-text-field>

                        <v-text-field style="display: block !important" class="mt-8"
                            label="Discount Percentage"
                            v-model="discountPercent"
                            ref="dPercent"
                            @change="updateDiscount"
                            required
                            hide-details="auto"
                        ></v-text-field>

                        <v-checkbox class="mt-8"
                            v-model="allProducts"
                            @change="changeAllProducts"
                            label="Use with all products"
                        ></v-checkbox>
                        
                        <br/>

                        <v-autocomplete class="mt-3"
                            v-model="included"
                            :items="items"
                            item-text="name"
                            @change="updateIncluded"
                            outlined
                            dense
                            chips
                            small-chips
                            return-object
                            :disabled='listsDisabled'
                            label="Select Product to Include"
                            multiple
                        ></v-autocomplete>

                        <v-autocomplete class="mt-3"
                            v-model="excluded"
                            :items="items"
                            item-text="name"
                            @change="updateExcluded"
                            outlined
                            dense
                            chips
                            small-chips
                            return-object
                            :disabled='listsDisabled'
                            label="Select Product to Exclude"
                            multiple
                        ></v-autocomplete>

                        <p> <strong>{{selectedCount}}</strong> products selected</p>

                        <br/>

                        <v-btn class="me-3" elevation="0" to="/PriceIQ">Cancel</v-btn>
                        <v-btn color="primary" elevation="0" @click="submitList">Save Price List</v-btn>

                    </v-card>

                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            currentCompany: 'company/getCurrentCompany'
        })
    },
    data(){
        return {
            loading: true,
            listsDisabled: false,
            items: [],
            selectedCount: 0,
            priceListId: null
        }
    },
    created(){

        this.priceListId = this.$route.query.listId;

        this.$auth.auth0ClientPromise.then(async () => {
            
            if(Object.keys(this.currentCompany).length === 0){
                await this.$store.dispatch('getUserCompany');
            }

            let products = await this.getHubSpotProducts();

            products.forEach( (product) => {

                this.items.push({
                    name: product.properties.name,
                    id: product.id
                });

            });

            let listInfo = await this.$store.dispatch('priceIQ/getPriceListById', {"priceListId": this.priceListId});

                this.listName = listInfo.name;
                this.discountPercent = listInfo.discount;
                this.allProducts = listInfo.allProducts;
                this.listsDisabled = listInfo.allProducts;
        

            this.loading = false;
        });
    },
    methods: {
        async getHubSpotProducts(portalId){
            let response = await this.$store.dispatch('priceIQ/getHubSpotProducts');
            if(!response){
                this.$swal({
                    title: `Unable to fetch HubSpot product information.`,
                    icon: 'warning',
                    showDenyButton: false,
                    confirmButtonText: "Close"
                });
            } else {
                return response;
            }
        },
        async submitList() {

            let productList = [];

            if(this.included !== undefined) {
                this.included.forEach( (product) => {
                    productList.push(product.id);
                });
            }

            let response = await this.$store.dispatch('priceIQ/updatePriceListInfo', {
                "priceListId": this.priceListId,
                "name": this.listName,
                "discount": this.discountPercent,
                "allProducts": (this.allProducts == undefined) ? false : true,
                "products": productList
            });

            if(response) {
                this.$router.push('/PriceIQ'); 
            } else {
                this.$swal({
                    title: `Error updating price list. Please try again later.`,
                    icon: 'warning',
                    showDenyButton: false,
                    confirmButtonText: "Close"
                });
            }

        },
        updateIncluded() {
            this.selectedCount = this.included.length;
            this.selectedCount += this.excluded.length;
            this.included.forEach( (includedProduct) => {
                
                this.excluded.forEach( (excludedProduct) => {

                    if(includedProduct.id == excludedProduct.id) {
                        this.$swal({
                            title: `You cannot include and exclude the same product.`,
                            icon: 'warning',
                            showDenyButton: false,
                            confirmButtonText: "Close"
                        });

                        this.included.splice(this.included.indexOf(includedProduct), 1);
                        this.selectedCount-=1;
                    }

                });

            });
            
        },
        updateExcluded() {
            this.selectedCount = this.included.length;
            this.selectedCount += this.excluded.length;
            this.included.forEach( (includedProduct) => {
                
                this.excluded.forEach( (excludedProduct) => {

                    if(includedProduct.id == excludedProduct.id) {
                        this.$swal({
                            title: `You cannot include and exclude the same product.`,
                            icon: 'warning',
                            showDenyButton: false,
                            confirmButtonText: "Close"
                        });

                        this.excluded.splice(this.excluded.indexOf(excludedProduct), 1);
                        this.selectedCount-=1;

                    }

                });

            });
            
        },
        updateDiscount() {

            if( parseInt(this.discountPercent) < 0 || parseInt(this.discountPercent) > 100 || isNaN(this.discountPercent) ) {
                
                this.$refs.dPercent.reset();

                this.$swal({
                            title: `You may only select a discount ranging from 0% to 100%.`,
                            icon: 'warning',
                            showDenyButton: false,
                            confirmButtonText: "Close"
                });

            }

        },
        changeAllProducts() {
            this.listsDisabled = this.allProducts;
        }
    }
}
</script>
